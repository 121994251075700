const BlackMonkGames = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 200 200'
        width='100%'
        height='100%'
      >
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FCFCFC'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 89.00 
C 0.50 89.00 1.00 89.00 1.00 89.00
C 4.14 44.16 44.14 4.13 89.00 1.00
C 88.99 1.00 89.00 0.00 89.00 0.00
L 0.00 0.00
L 0.00 89.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#656C70'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 89.00 1.00 
C 90.28 0.72 92.50 1.76 93.00 0.00
L 89.00 0.00
C 89.00 0.00 88.99 1.00 89.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#010D15'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 89.00 1.00 
C 44.14 4.13 4.14 44.16 1.00 89.00
C 0.72 90.28 1.76 92.50 0.00 93.00
L 0.00 106.00
C 1.60 106.53 0.72 108.75 1.00 110.00
C 3.44 155.03 43.32 195.14 88.00 199.00
C 89.57 199.39 92.22 198.06 93.00 200.00
L 107.00 200.00
C 107.53 198.40 109.75 199.28 111.00 199.00
C 156.12 195.81 196.53 155.32 199.00 110.00
C 199.28 108.72 198.24 106.50 200.00 106.00
L 200.00 93.00
C 198.40 92.47 199.28 90.25 199.00 89.00
C 195.86 44.16 155.86 4.13 111.00 1.00
C 109.72 0.72 107.50 1.76 107.00 0.00
L 93.00 0.00
C 92.50 1.76 90.28 0.72 89.00 1.00
M 165.00 28.00 
C 167.62 29.89 170.24 32.35 172.00 35.00
C 188.08 52.99 196.90 75.80 197.00 100.00
C 177.67 100.00 158.33 100.00 139.00 100.00
C 129.63 85.96 114.86 72.92 102.00 62.00
C 100.67 62.00 99.33 62.00 98.00 62.00
C 85.12 72.95 70.35 85.97 61.00 100.00
C 41.67 100.00 22.33 100.00 3.00 100.00
C 2.97 75.82 12.01 52.97 28.00 35.00
C 29.89 32.38 32.35 29.76 35.00 28.00
C 70.14 -5.09 129.88 -5.07 165.00 28.00
M 102.00 64.00 
C 107.37 70.51 113.92 76.10 119.75 82.25 C 124.54 88.89 135.03 93.54 135.67 101.67 C 129.86 107.85 123.69 113.70 117.75 119.75 C 111.11 124.54 106.46 135.03 98.33 135.67 C 92.15 129.86 86.30 123.69 80.25 117.75 C 75.46 111.11 64.97 106.46 64.33 98.33 C 70.14 92.15 76.31 86.30 82.25 80.25 C 89.02 75.26 93.50 65.12 102.00 64.00
M 36.00 155.00 
C 35.78 156.28 33.19 156.42 33.00 155.00
C 30.43 147.82 40.11 141.77 42.00 135.00
C 37.46 138.84 27.56 141.81 30.00 149.00
C 25.82 147.22 19.58 141.50 21.00 137.00
C 26.46 141.54 30.38 133.53 35.23 131.23 C 38.60 127.19 47.37 124.45 44.00 118.00
C 49.33 115.32 50.67 124.46 55.00 126.00
C 53.08 133.88 47.12 140.08 43.00 147.00
C 52.69 142.05 70.57 133.05 72.00 151.00
C 66.45 146.30 62.93 155.02 57.92 156.92 C 54.69 161.63 45.64 163.49 48.00 170.00
C 42.96 169.53 38.64 163.68 38.00 159.00
C 45.14 161.86 50.22 153.28 55.00 149.00
C 48.38 149.94 42.80 154.47 36.00 155.00
M 161.00 137.00 
C 162.00 137.11 163.73 136.53 164.00 138.00
C 167.00 138.00 170.00 138.00 173.00 138.00
C 174.00 138.11 175.73 137.53 176.00 139.00
C 179.81 139.85 185.73 137.02 188.00 141.00
C 181.67 142.63 177.38 148.94 170.00 147.00
C 168.12 146.53 165.07 148.06 164.00 146.00
C 161.16 146.59 157.02 144.78 155.00 147.00
C 159.19 149.93 163.05 155.17 168.00 151.00
C 168.65 155.77 162.41 163.38 158.00 165.00
C 161.39 158.10 152.22 152.55 146.00 153.00
C 146.49 148.15 140.20 144.75 136.00 144.00
C 133.52 155.68 146.90 166.68 143.29 176.29 C 133.03 176.36 124.90 165.17 115.00 162.00
C 117.33 168.40 118.61 177.19 126.00 180.00
C 126.63 177.95 129.31 181.80 127.00 182.00
C 123.38 183.98 118.22 187.63 114.00 186.00
C 118.98 174.59 108.32 162.67 103.00 154.00
C 115.11 145.07 124.11 158.69 135.00 163.00
C 132.25 158.03 131.89 148.28 124.00 149.00
C 123.39 142.93 131.72 145.39 134.25 141.25 C 136.88 138.13 138.45 132.15 143.00 132.00
C 140.25 137.76 147.36 142.29 152.00 144.00
C 151.05 139.08 151.43 125.23 144.00 130.00
C 144.63 125.22 148.43 119.65 153.00 118.00
C 152.40 124.67 153.58 130.82 155.00 137.00
C 157.00 137.00 159.00 137.00 161.00 137.00
M 85.00 149.00 
C 98.17 148.57 107.17 167.38 98.75 176.75 C 92.18 188.75 73.06 187.66 67.08 175.92 C 58.60 163.56 70.73 147.60 85.00 149.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#656C70'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 111.00 1.00 
C 111.01 1.00 111.00 0.00 111.00 0.00
L 107.00 0.00
C 107.50 1.76 109.72 0.72 111.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FCFCFC'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 111.00 1.00 
C 155.86 4.13 195.86 44.16 199.00 89.00
C 199.00 88.99 200.00 89.00 200.00 89.00
L 200.00 0.00
L 111.00 0.00
C 111.00 0.00 111.01 1.00 111.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F9F9FA'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 35.00 28.00 
C 33.11 30.62 30.65 33.24 28.00 35.00
C 12.01 52.97 2.97 75.82 3.00 100.00
C 22.33 100.00 41.67 100.00 61.00 100.00
C 70.35 85.97 85.12 72.95 98.00 62.00
C 98.47 60.66 101.58 60.43 102.00 62.00
C 114.86 72.92 129.63 85.96 139.00 100.00
C 158.33 100.00 177.67 100.00 197.00 100.00
C 196.90 75.80 188.08 52.99 172.00 35.00
C 169.38 33.11 166.76 30.65 165.00 28.00
C 129.88 -5.07 70.14 -5.09 35.00 28.00
M 114.00 46.00 
C 108.79 44.78 103.77 48.68 99.00 46.00
C 103.57 42.91 103.13 39.29 100.00 35.00
C 91.82 33.60 84.11 41.45 92.00 47.00
C 77.19 46.87 67.57 60.23 55.00 66.00
C 55.39 75.85 46.60 82.60 45.00 92.00
C 42.25 90.08 42.21 83.91 36.77 83.23 C 31.13 84.25 27.70 81.00 24.30 77.70 C 19.67 73.37 16.37 76.37 12.00 78.00
C 14.25 70.59 18.12 63.66 21.77 56.77 C 24.12 47.16 37.21 49.01 37.00 59.00
C 42.82 55.87 53.50 52.53 54.00 63.00
C 56.82 58.00 50.77 51.68 46.00 50.00
C 46.64 44.05 41.81 37.58 36.00 37.00
C 37.93 31.93 44.52 29.46 49.00 27.00
C 47.89 37.55 59.18 41.49 61.30 50.70 C 66.64 59.73 78.62 48.12 79.00 41.00
C 87.25 46.37 82.13 31.60 86.75 28.75 C 90.49 23.42 88.76 12.37 96.01 11.01 C 104.38 20.95 108.55 35.24 114.00 46.00
M 155.00 30.00 
C 155.48 28.12 157.92 29.75 158.00 31.00
C 155.91 33.57 153.86 44.36 150.08 38.91 C 152.86 25.61 136.73 19.29 130.00 31.00
C 132.22 33.36 130.74 38.07 127.00 37.00
C 124.51 46.29 132.33 55.48 142.00 53.00
C 143.16 50.23 148.24 53.21 145.00 55.00
C 131.42 63.07 111.53 45.79 116.98 30.98 C 121.99 14.97 145.80 18.55 155.00 30.00
M 177.00 56.00 
C 171.43 52.63 165.56 56.32 161.00 60.00
C 166.27 61.95 178.34 68.02 179.00 59.00
C 182.08 62.53 185.25 67.19 186.00 72.00
C 178.06 70.04 165.22 61.28 162.23 74.23 C 157.03 80.98 155.17 91.55 148.00 96.00
C 146.13 83.36 153.00 73.25 158.00 62.00
C 152.73 63.18 147.50 66.69 147.00 72.00
C 142.20 69.77 139.98 62.88 138.00 58.00
C 140.77 57.08 141.90 60.19 144.00 61.00
C 150.62 52.15 168.32 52.95 168.00 41.00
C 172.86 44.52 175.25 50.70 177.00 56.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#041118'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 114.00 46.00 
C 108.55 35.24 104.38 20.95 96.01 11.01 C 88.76 12.37 90.49 23.42 86.75 28.75 C 82.13 31.60 87.25 46.37 79.00 41.00
C 78.62 48.12 66.64 59.73 61.30 50.70 C 59.18 41.49 47.89 37.55 49.00 27.00
C 44.52 29.46 37.93 31.93 36.00 37.00
C 41.81 37.58 46.64 44.05 46.00 50.00
C 50.77 51.68 56.82 58.00 54.00 63.00
C 53.50 52.53 42.82 55.87 37.00 59.00
C 37.21 49.01 24.12 47.16 21.77 56.77 C 18.12 63.66 14.25 70.59 12.00 78.00
C 16.37 76.37 19.67 73.37 24.30 77.70 C 27.70 81.00 31.13 84.25 36.77 83.23 C 42.21 83.91 42.25 90.08 45.00 92.00
C 46.60 82.60 55.39 75.85 55.00 66.00
C 67.57 60.23 77.19 46.87 92.00 47.00
C 84.11 41.45 91.82 33.60 100.00 35.00
C 103.13 39.29 103.57 42.91 99.00 46.00
C 103.77 48.68 108.79 44.78 114.00 46.00
M 96.00 20.00 
C 96.93 23.34 97.40 26.84 99.00 30.00
C 96.73 31.72 93.66 30.73 91.00 31.00
C 92.56 27.53 91.73 21.16 96.00 20.00
M 33.00 69.00 
C 28.82 70.30 18.19 66.16 23.71 61.71 C 28.56 57.00 35.01 63.29 33.00 69.00
M 45.00 65.00 
C 54.55 67.19 50.37 81.62 40.93 76.07 C 29.51 75.48 36.61 62.84 45.00 65.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#DFE1E2'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 96.00 20.00 
C 91.73 21.16 92.56 27.53 91.00 31.00
C 93.66 30.73 96.73 31.72 99.00 30.00
C 97.40 26.84 96.93 23.34 96.00 20.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#031018'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 145.00 55.00 
C 145.64 52.97 143.31 52.94 142.00 53.00
C 132.33 55.48 124.51 46.29 127.00 37.00
C 130.74 38.07 132.22 33.36 130.00 31.00
C 136.73 19.29 152.86 25.61 150.08 38.91 C 153.86 44.36 155.91 33.57 158.00 31.00
C 157.06 30.43 156.18 29.85 155.00 30.00
C 145.80 18.55 121.99 14.97 116.98 30.98 C 111.53 45.79 131.42 63.07 145.00 55.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#737A7E'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 28.00 35.00 
C 30.65 33.24 33.11 30.62 35.00 28.00
C 32.35 29.76 29.89 32.38 28.00 35.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#737A7E'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 172.00 35.00 
C 170.24 32.35 167.62 29.89 165.00 28.00
C 166.76 30.65 169.38 33.11 172.00 35.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#757B7F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 155.00 30.00 
C 156.18 29.85 157.06 30.43 158.00 31.00
C 157.92 29.75 155.48 28.12 155.00 30.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#031118'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 177.00 56.00 
C 175.25 50.70 172.86 44.52 168.00 41.00
C 168.32 52.95 150.62 52.15 144.00 61.00
C 141.90 60.19 140.77 57.08 138.00 58.00
C 139.98 62.88 142.20 69.77 147.00 72.00
C 147.50 66.69 152.73 63.18 158.00 62.00
C 153.00 73.25 146.13 83.36 148.00 96.00
C 155.17 91.55 157.03 80.98 162.23 74.23 C 165.22 61.28 178.06 70.04 186.00 72.00
C 185.25 67.19 182.08 62.53 179.00 59.00
C 178.34 68.02 166.27 61.95 161.00 60.00
C 165.56 56.32 171.43 52.63 177.00 56.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#808689'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 142.00 53.00 
C 143.31 52.94 145.64 52.97 145.00 55.00
C 148.24 53.21 143.16 50.23 142.00 53.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F2F2F3'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 33.00 69.00 
C 35.01 63.29 28.56 57.00 23.71 61.71 C 18.19 66.16 28.82 70.30 33.00 69.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#757B80'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 98.00 62.00 
C 99.33 62.00 100.67 62.00 102.00 62.00
C 101.58 60.43 98.47 60.66 98.00 62.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F9F9F9'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 102.00 64.00 
C 93.50 65.12 89.02 75.26 82.25 80.25 C 76.31 86.30 70.14 92.15 64.33 98.33 C 64.97 106.46 75.46 111.11 80.25 117.75 C 86.30 123.69 92.15 129.86 98.33 135.67 C 106.46 135.03 111.11 124.54 117.75 119.75 C 123.69 113.70 129.86 107.85 135.67 101.67 C 135.03 93.54 124.54 88.89 119.75 82.25 C 113.92 76.10 107.37 70.51 102.00 64.00
M 98.00 74.00 
C 103.77 75.86 100.69 86.59 94.78 83.22 C 88.41 81.84 92.15 72.19 98.00 74.00
M 110.00 86.00 
C 115.49 87.97 114.42 96.65 107.99 95.99 C 101.24 95.62 104.01 84.85 110.00 86.00
M 80.00 91.00 
C 85.33 92.83 84.89 102.58 78.00 101.00 C 71.64 100.14 73.90 90.41 80.00 91.00
M 124.00 99.00 
C 128.45 102.82 124.26 110.88 118.92 108.07 C 112.59 105.04 117.94 96.31 124.00 99.00
M 94.00 104.00 
C 98.83 107.34 94.15 116.50 88.93 113.07 C 82.90 109.91 88.28 101.98 94.00 104.00
M 105.00 116.00 
C 110.21 117.63 108.47 128.09 102.33 125.67 C 96.22 124.31 98.90 114.49 105.00 116.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F0F1F1'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 45.00 65.00 
C 36.61 62.84 29.51 75.48 40.93 76.07 C 50.37 81.62 54.55 67.19 45.00 65.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#07151C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 98.00 74.00 
C 92.15 72.19 88.41 81.84 94.78 83.22 C 100.69 86.59 103.77 75.86 98.00 74.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#07151C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 110.00 86.00 
C 104.01 84.85 101.24 95.62 107.99 95.99 C 114.42 96.65 115.49 87.97 110.00 86.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#656C70'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 93.00 
C 1.76 92.50 0.72 90.28 1.00 89.00
C 1.00 89.00 0.50 89.00 0.00 89.00
L 0.00 93.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#656C70'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 200.00 93.00 
L 200.00 89.00
C 200.00 89.00 199.00 88.99 199.00 89.00
C 199.28 90.25 198.40 92.47 200.00 93.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#07151C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 80.00 91.00 
C 73.90 90.41 71.64 100.14 78.00 101.00 C 84.89 102.58 85.33 92.83 80.00 91.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#08161D'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 124.00 99.00 
C 117.94 96.31 112.59 105.04 118.92 108.07 C 124.26 110.88 128.45 102.82 124.00 99.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#07151C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 94.00 104.00 
C 88.28 101.98 82.90 109.91 88.93 113.07 C 94.15 116.50 98.83 107.34 94.00 104.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#50575C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 110.00 
C 0.50 110.00 1.00 110.00 1.00 110.00
C 0.72 108.75 1.60 106.53 0.00 106.00
L 0.00 110.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#50575C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 199.00 110.00 
C 199.00 109.96 200.00 110.00 200.00 110.00
L 200.00 106.00
C 198.24 106.50 199.28 108.72 199.00 110.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FCFCFC'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 200.00 
L 88.00 200.00
C 88.00 199.50 88.00 199.00 88.00 199.00
C 43.32 195.14 3.44 155.03 1.00 110.00
C 1.00 110.00 0.50 110.00 0.00 110.00
L 0.00 200.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FCFCFC'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 111.00 199.00 
C 111.01 199.00 111.00 200.00 111.00 200.00
L 200.00 200.00
L 200.00 110.00
C 200.00 110.00 199.00 109.96 199.00 110.00
C 196.53 155.32 156.12 195.81 111.00 199.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#05131A'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 105.00 116.00 
C 98.90 114.49 96.22 124.31 102.33 125.67 C 108.47 128.09 110.21 117.63 105.00 116.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F2F3F3'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 33.00 155.00 
C 34.00 155.00 35.00 155.00 36.00 155.00
C 42.80 154.47 48.38 149.94 55.00 149.00
C 50.22 153.28 45.14 161.86 38.00 159.00
C 38.64 163.68 42.96 169.53 48.00 170.00
C 45.64 163.49 54.69 161.63 57.92 156.92 C 62.93 155.02 66.45 146.30 72.00 151.00
C 70.57 133.05 52.69 142.05 43.00 147.00
C 47.12 140.08 53.08 133.88 55.00 126.00
C 50.67 124.46 49.33 115.32 44.00 118.00
C 47.37 124.45 38.60 127.19 35.23 131.23 C 30.38 133.53 26.46 141.54 21.00 137.00
C 19.58 141.50 25.82 147.22 30.00 149.00
C 27.56 141.81 37.46 138.84 42.00 135.00
C 40.11 141.77 30.43 147.82 33.00 155.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F1F2F3'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 127.00 182.00 
C 127.18 181.26 126.95 180.09 126.00 180.00
C 118.61 177.19 117.33 168.40 115.00 162.00
C 124.90 165.17 133.03 176.36 143.29 176.29 C 146.90 166.68 133.52 155.68 136.00 144.00
C 140.20 144.75 146.49 148.15 146.00 153.00
C 152.22 152.55 161.39 158.10 158.00 165.00
C 162.41 163.38 168.65 155.77 168.00 151.00
C 163.05 155.17 159.19 149.93 155.00 147.00
C 157.02 144.78 161.16 146.59 164.00 146.00
C 165.88 146.47 168.93 144.94 170.00 147.00
C 177.38 148.94 181.67 142.63 188.00 141.00
C 185.73 137.02 179.81 139.85 176.00 139.00
C 175.00 138.89 173.27 139.47 173.00 138.00
C 170.00 138.00 167.00 138.00 164.00 138.00
C 163.00 137.89 161.27 138.47 161.00 137.00
C 159.00 137.00 157.00 137.00 155.00 137.00
C 153.58 130.82 152.40 124.67 153.00 118.00
C 148.43 119.65 144.63 125.22 144.00 130.00
C 151.43 125.23 151.05 139.08 152.00 144.00
C 147.36 142.29 140.25 137.76 143.00 132.00
C 138.45 132.15 136.88 138.13 134.25 141.25 C 131.72 145.39 123.39 142.93 124.00 149.00
C 131.89 148.28 132.25 158.03 135.00 163.00
C 124.11 158.69 115.11 145.07 103.00 154.00
C 108.32 162.67 118.98 174.59 114.00 186.00
C 118.22 187.63 123.38 183.98 127.00 182.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#73797E'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 164.00 138.00 
C 163.73 136.53 162.00 137.11 161.00 137.00
C 161.27 138.47 163.00 137.89 164.00 138.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#6F7679'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 176.00 139.00 
C 175.73 137.53 174.00 138.11 173.00 138.00
C 173.27 139.47 175.00 138.89 176.00 139.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#6B7276'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 170.00 147.00 
C 168.93 144.94 165.88 146.47 164.00 146.00
C 165.07 148.06 168.12 146.53 170.00 147.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F3F3F4'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 85.00 149.00 
C 70.73 147.60 58.60 163.56 67.08 175.92 C 73.06 187.66 92.18 188.75 98.75 176.75 C 107.17 167.38 98.17 148.57 85.00 149.00
M 87.00 154.00 
C 95.05 160.48 91.17 181.44 79.00 181.00 C 70.55 174.30 75.33 153.80 87.00 154.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#010D15'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 87.00 154.00 
C 75.33 153.80 70.55 174.30 79.00 181.00 C 91.17 181.44 95.05 160.48 87.00 154.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#72797D'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 36.00 155.00 
C 35.00 155.00 34.00 155.00 33.00 155.00
C 33.19 156.42 35.78 156.28 36.00 155.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#6F767A'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 126.00 180.00 
C 126.95 180.09 127.18 181.26 127.00 182.00
C 129.31 181.80 126.63 177.95 126.00 180.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#72777B'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 88.00 200.00 
L 93.00 200.00
C 92.22 198.06 89.57 199.39 88.00 199.00
C 88.00 199.00 88.00 199.50 88.00 200.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#656C70'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 107.00 200.00 
L 111.00 200.00
C 111.00 200.00 111.01 199.00 111.00 199.00
C 109.75 199.28 107.53 198.40 107.00 200.00 Z'
          />
        </g>
        <g fill='#FCFCFC' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 0.00 89.00 
C 0.50 89.00 1.00 89.00 1.00 89.00
C 4.14 44.16 44.14 4.13 89.00 1.00
C 88.99 1.00 89.00 0.00 89.00 0.00
L 0.00 0.00
L 0.00 89.00 Z'
          />
        </g>
        <g fill='#656C70' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 89.00 1.00 
C 90.28 0.72 92.50 1.76 93.00 0.00
L 89.00 0.00
C 89.00 0.00 88.99 1.00 89.00 1.00 Z'
          />
        </g>
        <g fill='#010D15' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 89.00 1.00 
C 44.14 4.13 4.14 44.16 1.00 89.00
C 0.72 90.28 1.76 92.50 0.00 93.00
L 0.00 106.00
C 1.60 106.53 0.72 108.75 1.00 110.00
C 3.44 155.03 43.32 195.14 88.00 199.00
C 89.57 199.39 92.22 198.06 93.00 200.00
L 107.00 200.00
C 107.53 198.40 109.75 199.28 111.00 199.00
C 156.12 195.81 196.53 155.32 199.00 110.00
C 199.28 108.72 198.24 106.50 200.00 106.00
L 200.00 93.00
C 198.40 92.47 199.28 90.25 199.00 89.00
C 195.86 44.16 155.86 4.13 111.00 1.00
C 109.72 0.72 107.50 1.76 107.00 0.00
L 93.00 0.00
C 92.50 1.76 90.28 0.72 89.00 1.00
M 165.00 28.00 
C 167.62 29.89 170.24 32.35 172.00 35.00
C 188.08 52.99 196.90 75.80 197.00 100.00
C 177.67 100.00 158.33 100.00 139.00 100.00
C 129.63 85.96 114.86 72.92 102.00 62.00
C 100.67 62.00 99.33 62.00 98.00 62.00
C 85.12 72.95 70.35 85.97 61.00 100.00
C 41.67 100.00 22.33 100.00 3.00 100.00
C 2.97 75.82 12.01 52.97 28.00 35.00
C 29.89 32.38 32.35 29.76 35.00 28.00
C 70.14 -5.09 129.88 -5.07 165.00 28.00
M 102.00 64.00 
C 107.37 70.51 113.92 76.10 119.75 82.25 C 124.54 88.89 135.03 93.54 135.67 101.67 C 129.86 107.85 123.69 113.70 117.75 119.75 C 111.11 124.54 106.46 135.03 98.33 135.67 C 92.15 129.86 86.30 123.69 80.25 117.75 C 75.46 111.11 64.97 106.46 64.33 98.33 C 70.14 92.15 76.31 86.30 82.25 80.25 C 89.02 75.26 93.50 65.12 102.00 64.00
M 36.00 155.00 
C 35.78 156.28 33.19 156.42 33.00 155.00
C 30.43 147.82 40.11 141.77 42.00 135.00
C 37.46 138.84 27.56 141.81 30.00 149.00
C 25.82 147.22 19.58 141.50 21.00 137.00
C 26.46 141.54 30.38 133.53 35.23 131.23 C 38.60 127.19 47.37 124.45 44.00 118.00
C 49.33 115.32 50.67 124.46 55.00 126.00
C 53.08 133.88 47.12 140.08 43.00 147.00
C 52.69 142.05 70.57 133.05 72.00 151.00
C 66.45 146.30 62.93 155.02 57.92 156.92 C 54.69 161.63 45.64 163.49 48.00 170.00
C 42.96 169.53 38.64 163.68 38.00 159.00
C 45.14 161.86 50.22 153.28 55.00 149.00
C 48.38 149.94 42.80 154.47 36.00 155.00
M 161.00 137.00 
C 162.00 137.11 163.73 136.53 164.00 138.00
C 167.00 138.00 170.00 138.00 173.00 138.00
C 174.00 138.11 175.73 137.53 176.00 139.00
C 179.81 139.85 185.73 137.02 188.00 141.00
C 181.67 142.63 177.38 148.94 170.00 147.00
C 168.12 146.53 165.07 148.06 164.00 146.00
C 161.16 146.59 157.02 144.78 155.00 147.00
C 159.19 149.93 163.05 155.17 168.00 151.00
C 168.65 155.77 162.41 163.38 158.00 165.00
C 161.39 158.10 152.22 152.55 146.00 153.00
C 146.49 148.15 140.20 144.75 136.00 144.00
C 133.52 155.68 146.90 166.68 143.29 176.29 C 133.03 176.36 124.90 165.17 115.00 162.00
C 117.33 168.40 118.61 177.19 126.00 180.00
C 126.63 177.95 129.31 181.80 127.00 182.00
C 123.38 183.98 118.22 187.63 114.00 186.00
C 118.98 174.59 108.32 162.67 103.00 154.00
C 115.11 145.07 124.11 158.69 135.00 163.00
C 132.25 158.03 131.89 148.28 124.00 149.00
C 123.39 142.93 131.72 145.39 134.25 141.25 C 136.88 138.13 138.45 132.15 143.00 132.00
C 140.25 137.76 147.36 142.29 152.00 144.00
C 151.05 139.08 151.43 125.23 144.00 130.00
C 144.63 125.22 148.43 119.65 153.00 118.00
C 152.40 124.67 153.58 130.82 155.00 137.00
C 157.00 137.00 159.00 137.00 161.00 137.00
M 85.00 149.00 
C 98.17 148.57 107.17 167.38 98.75 176.75 C 92.18 188.75 73.06 187.66 67.08 175.92 C 58.60 163.56 70.73 147.60 85.00 149.00 Z'
          />
        </g>
        <g fill='#656C70' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 111.00 1.00 
C 111.01 1.00 111.00 0.00 111.00 0.00
L 107.00 0.00
C 107.50 1.76 109.72 0.72 111.00 1.00 Z'
          />
        </g>
        <g fill='#FCFCFC' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 111.00 1.00 
C 155.86 4.13 195.86 44.16 199.00 89.00
C 199.00 88.99 200.00 89.00 200.00 89.00
L 200.00 0.00
L 111.00 0.00
C 111.00 0.00 111.01 1.00 111.00 1.00 Z'
          />
        </g>
        <g fill='#F9F9FA' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 35.00 28.00 
C 33.11 30.62 30.65 33.24 28.00 35.00
C 12.01 52.97 2.97 75.82 3.00 100.00
C 22.33 100.00 41.67 100.00 61.00 100.00
C 70.35 85.97 85.12 72.95 98.00 62.00
C 98.47 60.66 101.58 60.43 102.00 62.00
C 114.86 72.92 129.63 85.96 139.00 100.00
C 158.33 100.00 177.67 100.00 197.00 100.00
C 196.90 75.80 188.08 52.99 172.00 35.00
C 169.38 33.11 166.76 30.65 165.00 28.00
C 129.88 -5.07 70.14 -5.09 35.00 28.00
M 114.00 46.00 
C 108.79 44.78 103.77 48.68 99.00 46.00
C 103.57 42.91 103.13 39.29 100.00 35.00
C 91.82 33.60 84.11 41.45 92.00 47.00
C 77.19 46.87 67.57 60.23 55.00 66.00
C 55.39 75.85 46.60 82.60 45.00 92.00
C 42.25 90.08 42.21 83.91 36.77 83.23 C 31.13 84.25 27.70 81.00 24.30 77.70 C 19.67 73.37 16.37 76.37 12.00 78.00
C 14.25 70.59 18.12 63.66 21.77 56.77 C 24.12 47.16 37.21 49.01 37.00 59.00
C 42.82 55.87 53.50 52.53 54.00 63.00
C 56.82 58.00 50.77 51.68 46.00 50.00
C 46.64 44.05 41.81 37.58 36.00 37.00
C 37.93 31.93 44.52 29.46 49.00 27.00
C 47.89 37.55 59.18 41.49 61.30 50.70 C 66.64 59.73 78.62 48.12 79.00 41.00
C 87.25 46.37 82.13 31.60 86.75 28.75 C 90.49 23.42 88.76 12.37 96.01 11.01 C 104.38 20.95 108.55 35.24 114.00 46.00
M 155.00 30.00 
C 155.48 28.12 157.92 29.75 158.00 31.00
C 155.91 33.57 153.86 44.36 150.08 38.91 C 152.86 25.61 136.73 19.29 130.00 31.00
C 132.22 33.36 130.74 38.07 127.00 37.00
C 124.51 46.29 132.33 55.48 142.00 53.00
C 143.16 50.23 148.24 53.21 145.00 55.00
C 131.42 63.07 111.53 45.79 116.98 30.98 C 121.99 14.97 145.80 18.55 155.00 30.00
M 177.00 56.00 
C 171.43 52.63 165.56 56.32 161.00 60.00
C 166.27 61.95 178.34 68.02 179.00 59.00
C 182.08 62.53 185.25 67.19 186.00 72.00
C 178.06 70.04 165.22 61.28 162.23 74.23 C 157.03 80.98 155.17 91.55 148.00 96.00
C 146.13 83.36 153.00 73.25 158.00 62.00
C 152.73 63.18 147.50 66.69 147.00 72.00
C 142.20 69.77 139.98 62.88 138.00 58.00
C 140.77 57.08 141.90 60.19 144.00 61.00
C 150.62 52.15 168.32 52.95 168.00 41.00
C 172.86 44.52 175.25 50.70 177.00 56.00 Z'
          />
        </g>
        <g fill='#041118' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 114.00 46.00 
C 108.55 35.24 104.38 20.95 96.01 11.01 C 88.76 12.37 90.49 23.42 86.75 28.75 C 82.13 31.60 87.25 46.37 79.00 41.00
C 78.62 48.12 66.64 59.73 61.30 50.70 C 59.18 41.49 47.89 37.55 49.00 27.00
C 44.52 29.46 37.93 31.93 36.00 37.00
C 41.81 37.58 46.64 44.05 46.00 50.00
C 50.77 51.68 56.82 58.00 54.00 63.00
C 53.50 52.53 42.82 55.87 37.00 59.00
C 37.21 49.01 24.12 47.16 21.77 56.77 C 18.12 63.66 14.25 70.59 12.00 78.00
C 16.37 76.37 19.67 73.37 24.30 77.70 C 27.70 81.00 31.13 84.25 36.77 83.23 C 42.21 83.91 42.25 90.08 45.00 92.00
C 46.60 82.60 55.39 75.85 55.00 66.00
C 67.57 60.23 77.19 46.87 92.00 47.00
C 84.11 41.45 91.82 33.60 100.00 35.00
C 103.13 39.29 103.57 42.91 99.00 46.00
C 103.77 48.68 108.79 44.78 114.00 46.00
M 96.00 20.00 
C 96.93 23.34 97.40 26.84 99.00 30.00
C 96.73 31.72 93.66 30.73 91.00 31.00
C 92.56 27.53 91.73 21.16 96.00 20.00
M 33.00 69.00 
C 28.82 70.30 18.19 66.16 23.71 61.71 C 28.56 57.00 35.01 63.29 33.00 69.00
M 45.00 65.00 
C 54.55 67.19 50.37 81.62 40.93 76.07 C 29.51 75.48 36.61 62.84 45.00 65.00 Z'
          />
        </g>
        <g fill='#DFE1E2' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 96.00 20.00 
C 91.73 21.16 92.56 27.53 91.00 31.00
C 93.66 30.73 96.73 31.72 99.00 30.00
C 97.40 26.84 96.93 23.34 96.00 20.00 Z'
          />
        </g>
        <g fill='#031018' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 145.00 55.00 
C 145.64 52.97 143.31 52.94 142.00 53.00
C 132.33 55.48 124.51 46.29 127.00 37.00
C 130.74 38.07 132.22 33.36 130.00 31.00
C 136.73 19.29 152.86 25.61 150.08 38.91 C 153.86 44.36 155.91 33.57 158.00 31.00
C 157.06 30.43 156.18 29.85 155.00 30.00
C 145.80 18.55 121.99 14.97 116.98 30.98 C 111.53 45.79 131.42 63.07 145.00 55.00 Z'
          />
        </g>
        <g fill='#737A7E' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 28.00 35.00 
C 30.65 33.24 33.11 30.62 35.00 28.00
C 32.35 29.76 29.89 32.38 28.00 35.00 Z'
          />
        </g>
        <g fill='#737A7E' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 172.00 35.00 
C 170.24 32.35 167.62 29.89 165.00 28.00
C 166.76 30.65 169.38 33.11 172.00 35.00 Z'
          />
        </g>
        <g fill='#757B7F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 155.00 30.00 
C 156.18 29.85 157.06 30.43 158.00 31.00
C 157.92 29.75 155.48 28.12 155.00 30.00 Z'
          />
        </g>
        <g fill='#031118' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 177.00 56.00 
C 175.25 50.70 172.86 44.52 168.00 41.00
C 168.32 52.95 150.62 52.15 144.00 61.00
C 141.90 60.19 140.77 57.08 138.00 58.00
C 139.98 62.88 142.20 69.77 147.00 72.00
C 147.50 66.69 152.73 63.18 158.00 62.00
C 153.00 73.25 146.13 83.36 148.00 96.00
C 155.17 91.55 157.03 80.98 162.23 74.23 C 165.22 61.28 178.06 70.04 186.00 72.00
C 185.25 67.19 182.08 62.53 179.00 59.00
C 178.34 68.02 166.27 61.95 161.00 60.00
C 165.56 56.32 171.43 52.63 177.00 56.00 Z'
          />
        </g>
        <g fill='#808689' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 142.00 53.00 
C 143.31 52.94 145.64 52.97 145.00 55.00
C 148.24 53.21 143.16 50.23 142.00 53.00 Z'
          />
        </g>
        <g fill='#F2F2F3' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 33.00 69.00 
C 35.01 63.29 28.56 57.00 23.71 61.71 C 18.19 66.16 28.82 70.30 33.00 69.00 Z'
          />
        </g>
        <g fill='#757B80' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 98.00 62.00 
C 99.33 62.00 100.67 62.00 102.00 62.00
C 101.58 60.43 98.47 60.66 98.00 62.00 Z'
          />
        </g>
        <g fill='#F9F9F9' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 102.00 64.00 
C 93.50 65.12 89.02 75.26 82.25 80.25 C 76.31 86.30 70.14 92.15 64.33 98.33 C 64.97 106.46 75.46 111.11 80.25 117.75 C 86.30 123.69 92.15 129.86 98.33 135.67 C 106.46 135.03 111.11 124.54 117.75 119.75 C 123.69 113.70 129.86 107.85 135.67 101.67 C 135.03 93.54 124.54 88.89 119.75 82.25 C 113.92 76.10 107.37 70.51 102.00 64.00
M 98.00 74.00 
C 103.77 75.86 100.69 86.59 94.78 83.22 C 88.41 81.84 92.15 72.19 98.00 74.00
M 110.00 86.00 
C 115.49 87.97 114.42 96.65 107.99 95.99 C 101.24 95.62 104.01 84.85 110.00 86.00
M 80.00 91.00 
C 85.33 92.83 84.89 102.58 78.00 101.00 C 71.64 100.14 73.90 90.41 80.00 91.00
M 124.00 99.00 
C 128.45 102.82 124.26 110.88 118.92 108.07 C 112.59 105.04 117.94 96.31 124.00 99.00
M 94.00 104.00 
C 98.83 107.34 94.15 116.50 88.93 113.07 C 82.90 109.91 88.28 101.98 94.00 104.00
M 105.00 116.00 
C 110.21 117.63 108.47 128.09 102.33 125.67 C 96.22 124.31 98.90 114.49 105.00 116.00 Z'
          />
        </g>
        <g fill='#F0F1F1' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 45.00 65.00 
C 36.61 62.84 29.51 75.48 40.93 76.07 C 50.37 81.62 54.55 67.19 45.00 65.00 Z'
          />
        </g>
        <g fill='#07151C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 98.00 74.00 
C 92.15 72.19 88.41 81.84 94.78 83.22 C 100.69 86.59 103.77 75.86 98.00 74.00 Z'
          />
        </g>
        <g fill='#07151C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 110.00 86.00 
C 104.01 84.85 101.24 95.62 107.99 95.99 C 114.42 96.65 115.49 87.97 110.00 86.00 Z'
          />
        </g>
        <g fill='#656C70' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 0.00 93.00 
C 1.76 92.50 0.72 90.28 1.00 89.00
C 1.00 89.00 0.50 89.00 0.00 89.00
L 0.00 93.00 Z'
          />
        </g>
        <g fill='#656C70' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 200.00 93.00 
L 200.00 89.00
C 200.00 89.00 199.00 88.99 199.00 89.00
C 199.28 90.25 198.40 92.47 200.00 93.00 Z'
          />
        </g>
        <g fill='#07151C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 80.00 91.00 
C 73.90 90.41 71.64 100.14 78.00 101.00 C 84.89 102.58 85.33 92.83 80.00 91.00 Z'
          />
        </g>
        <g fill='#08161D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 124.00 99.00 
C 117.94 96.31 112.59 105.04 118.92 108.07 C 124.26 110.88 128.45 102.82 124.00 99.00 Z'
          />
        </g>
        <g fill='#07151C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 94.00 104.00 
C 88.28 101.98 82.90 109.91 88.93 113.07 C 94.15 116.50 98.83 107.34 94.00 104.00 Z'
          />
        </g>
        <g fill='#50575C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 0.00 110.00 
C 0.50 110.00 1.00 110.00 1.00 110.00
C 0.72 108.75 1.60 106.53 0.00 106.00
L 0.00 110.00 Z'
          />
        </g>
        <g fill='#50575C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 199.00 110.00 
C 199.00 109.96 200.00 110.00 200.00 110.00
L 200.00 106.00
C 198.24 106.50 199.28 108.72 199.00 110.00 Z'
          />
        </g>
        <g fill='#FCFCFC' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 0.00 200.00 
L 88.00 200.00
C 88.00 199.50 88.00 199.00 88.00 199.00
C 43.32 195.14 3.44 155.03 1.00 110.00
C 1.00 110.00 0.50 110.00 0.00 110.00
L 0.00 200.00 Z'
          />
        </g>
        <g fill='#FCFCFC' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 111.00 199.00 
C 111.01 199.00 111.00 200.00 111.00 200.00
L 200.00 200.00
L 200.00 110.00
C 200.00 110.00 199.00 109.96 199.00 110.00
C 196.53 155.32 156.12 195.81 111.00 199.00 Z'
          />
        </g>
        <g fill='#05131A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 105.00 116.00 
C 98.90 114.49 96.22 124.31 102.33 125.67 C 108.47 128.09 110.21 117.63 105.00 116.00 Z'
          />
        </g>
        <g fill='#F2F3F3' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 33.00 155.00 
C 34.00 155.00 35.00 155.00 36.00 155.00
C 42.80 154.47 48.38 149.94 55.00 149.00
C 50.22 153.28 45.14 161.86 38.00 159.00
C 38.64 163.68 42.96 169.53 48.00 170.00
C 45.64 163.49 54.69 161.63 57.92 156.92 C 62.93 155.02 66.45 146.30 72.00 151.00
C 70.57 133.05 52.69 142.05 43.00 147.00
C 47.12 140.08 53.08 133.88 55.00 126.00
C 50.67 124.46 49.33 115.32 44.00 118.00
C 47.37 124.45 38.60 127.19 35.23 131.23 C 30.38 133.53 26.46 141.54 21.00 137.00
C 19.58 141.50 25.82 147.22 30.00 149.00
C 27.56 141.81 37.46 138.84 42.00 135.00
C 40.11 141.77 30.43 147.82 33.00 155.00 Z'
          />
        </g>
        <g fill='#F1F2F3' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 127.00 182.00 
C 127.18 181.26 126.95 180.09 126.00 180.00
C 118.61 177.19 117.33 168.40 115.00 162.00
C 124.90 165.17 133.03 176.36 143.29 176.29 C 146.90 166.68 133.52 155.68 136.00 144.00
C 140.20 144.75 146.49 148.15 146.00 153.00
C 152.22 152.55 161.39 158.10 158.00 165.00
C 162.41 163.38 168.65 155.77 168.00 151.00
C 163.05 155.17 159.19 149.93 155.00 147.00
C 157.02 144.78 161.16 146.59 164.00 146.00
C 165.88 146.47 168.93 144.94 170.00 147.00
C 177.38 148.94 181.67 142.63 188.00 141.00
C 185.73 137.02 179.81 139.85 176.00 139.00
C 175.00 138.89 173.27 139.47 173.00 138.00
C 170.00 138.00 167.00 138.00 164.00 138.00
C 163.00 137.89 161.27 138.47 161.00 137.00
C 159.00 137.00 157.00 137.00 155.00 137.00
C 153.58 130.82 152.40 124.67 153.00 118.00
C 148.43 119.65 144.63 125.22 144.00 130.00
C 151.43 125.23 151.05 139.08 152.00 144.00
C 147.36 142.29 140.25 137.76 143.00 132.00
C 138.45 132.15 136.88 138.13 134.25 141.25 C 131.72 145.39 123.39 142.93 124.00 149.00
C 131.89 148.28 132.25 158.03 135.00 163.00
C 124.11 158.69 115.11 145.07 103.00 154.00
C 108.32 162.67 118.98 174.59 114.00 186.00
C 118.22 187.63 123.38 183.98 127.00 182.00 Z'
          />
        </g>
        <g fill='#73797E' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 164.00 138.00 
C 163.73 136.53 162.00 137.11 161.00 137.00
C 161.27 138.47 163.00 137.89 164.00 138.00 Z'
          />
        </g>
        <g fill='#6F7679' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 176.00 139.00 
C 175.73 137.53 174.00 138.11 173.00 138.00
C 173.27 139.47 175.00 138.89 176.00 139.00 Z'
          />
        </g>
        <g fill='#6B7276' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 170.00 147.00 
C 168.93 144.94 165.88 146.47 164.00 146.00
C 165.07 148.06 168.12 146.53 170.00 147.00 Z'
          />
        </g>
        <g fill='#F3F3F4' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 85.00 149.00 
C 70.73 147.60 58.60 163.56 67.08 175.92 C 73.06 187.66 92.18 188.75 98.75 176.75 C 107.17 167.38 98.17 148.57 85.00 149.00
M 87.00 154.00 
C 95.05 160.48 91.17 181.44 79.00 181.00 C 70.55 174.30 75.33 153.80 87.00 154.00 Z'
          />
        </g>
        <g fill='#010D15' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 87.00 154.00 
C 75.33 153.80 70.55 174.30 79.00 181.00 C 91.17 181.44 95.05 160.48 87.00 154.00 Z'
          />
        </g>
        <g fill='#72797D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 36.00 155.00 
C 35.00 155.00 34.00 155.00 33.00 155.00
C 33.19 156.42 35.78 156.28 36.00 155.00 Z'
          />
        </g>
        <g fill='#6F767A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 126.00 180.00 
C 126.95 180.09 127.18 181.26 127.00 182.00
C 129.31 181.80 126.63 177.95 126.00 180.00 Z'
          />
        </g>
        <g fill='#72777B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 88.00 200.00 
L 93.00 200.00
C 92.22 198.06 89.57 199.39 88.00 199.00
C 88.00 199.00 88.00 199.50 88.00 200.00 Z'
          />
        </g>
        <g fill='#656C70' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 107.00 200.00 
L 111.00 200.00
C 111.00 200.00 111.01 199.00 111.00 199.00
C 109.75 199.28 107.53 198.40 107.00 200.00 Z'
          />
        </g>
      </svg>
    </div>
  );
};

export default BlackMonkGames;
