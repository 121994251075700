const FlyingWildHog = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center bg-black sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 864 558'
        width='100%'
        height='100%'
      >
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 558.00 
L 864.00 558.00
L 864.00 0.00
L 0.00 0.00
L 0.00 558.00
M 776.00 120.00 
C 773.70 113.24 775.64 104.51 775.00 97.00 C 776.85 90.65 770.81 77.58 779.01 76.01 C 803.17 76.65 828.66 74.67 852.00 77.00
C 858.39 105.12 850.05 134.88 831.00 156.00
C 829.43 158.28 827.33 160.57 825.00 162.00
C 782.21 204.01 701.04 184.65 683.00 127.00
C 680.67 144.10 682.66 163.15 682.00 181.00
C 661.67 181.00 641.33 181.00 621.00 181.00
C 605.89 153.74 589.51 126.38 573.00 100.00
C 587.00 123.82 580.70 153.55 582.00 181.00
C 561.67 181.00 541.33 181.00 521.00 181.00
C 521.00 126.00 521.00 71.00 521.00 16.00 C 523.03 5.57 540.57 13.45 549.00 11.00 C 560.00 11.00 571.00 11.00 582.00 11.00
C 598.04 39.11 614.82 67.70 632.00 95.00
C 629.35 86.45 624.66 78.57 622.00 70.00
C 620.34 51.74 620.33 30.19 622.00 12.00
C 641.10 9.67 662.15 11.66 682.00 11.00
C 682.67 28.83 680.67 47.99 683.00 65.00
C 702.44 5.63 786.72 -11.03 829.00 34.00
C 817.00 47.50 802.96 59.55 791.00 73.00
C 768.81 48.37 722.24 71.83 732.93 105.07 C 736.21 118.84 749.70 130.08 764.00 130.00 C 780.73 128.48 795.26 120.16 813.00 122.00
C 801.74 119.01 787.19 122.99 776.00 120.00
M 144.00 72.00 
C 143.96 72.00 144.00 73.00 144.00 73.00
C 152.18 72.92 159.88 74.75 168.00 75.00
C 170.16 77.76 168.45 82.49 169.00 86.00
C 181.67 86.00 194.33 86.00 207.00 86.00
C 207.00 106.33 207.00 126.67 207.00 147.00
C 194.33 147.00 181.67 147.00 169.00 147.00
C 168.33 158.17 170.33 170.66 168.00 181.00
C 148.00 181.00 128.00 181.00 108.00 181.00
C 108.00 124.33 108.00 67.67 108.00 11.00
C 144.33 11.00 180.67 11.00 217.00 11.00
C 217.00 31.33 217.00 51.67 217.00 72.00
C 192.67 72.00 168.33 72.00 144.00 72.00
M 287.00 11.00 
C 287.00 46.33 287.00 81.67 287.00 117.00
C 278.40 117.87 269.92 119.36 261.00 119.00
C 281.10 121.33 303.15 119.34 324.00 120.00 C 332.65 123.69 325.82 139.49 328.00 148.00 C 328.00 159.00 328.00 170.00 328.00 181.00
C 294.00 181.00 260.00 181.00 226.00 181.00
C 226.00 124.33 226.00 67.67 226.00 11.00
C 246.33 11.00 266.67 11.00 287.00 11.00
M 380.00 69.00 
C 383.00 51.07 379.00 29.86 382.00 12.00
C 401.10 9.67 422.15 11.66 442.00 11.00
C 442.00 67.67 442.00 124.33 442.00 181.00
C 422.00 181.00 402.00 181.00 382.00 181.00
C 380.34 162.07 380.33 139.86 382.00 121.00
C 390.15 120.94 397.84 119.06 406.00 119.00
C 385.57 116.67 363.18 118.66 342.00 118.00
C 326.89 81.92 307.31 47.34 293.00 11.00
C 313.00 11.00 333.00 11.00 353.00 11.00
C 361.55 30.47 371.36 49.51 380.00 69.00
M 512.00 11.00 
C 512.00 67.67 512.00 124.33 512.00 181.00
C 491.67 181.00 471.33 181.00 451.00 181.00
C 451.00 124.33 451.00 67.67 451.00 11.00
C 471.33 11.00 491.67 11.00 512.00 11.00
M 505.00 195.00 
C 546.64 194.54 584.62 231.66 586.00 273.00
C 587.57 275.94 587.71 282.13 586.00 285.00
C 585.14 326.91 546.91 363.92 505.00 364.00
C 481.40 365.66 454.53 365.67 431.00 364.00
C 431.00 307.67 431.00 251.33 431.00 195.00
C 454.60 193.34 481.47 193.33 505.00 195.00
M 93.00 276.00 
C 93.08 276.35 93.98 276.81 94.00 277.00
C 99.59 249.49 106.72 222.36 113.00 195.00
C 131.00 195.00 149.00 195.00 167.00 195.00
C 173.61 221.92 180.33 248.81 186.00 276.00
C 186.00 275.83 186.95 275.35 187.00 275.00
C 180.23 246.95 193.88 221.52 199.00 195.00
C 219.33 195.00 239.67 195.00 260.00 195.00
C 245.11 251.12 228.59 306.77 214.00 363.00
C 206.52 367.55 195.03 363.75 186.00 365.00 C 176.98 363.74 165.43 367.57 158.00 363.00
C 152.80 338.89 146.68 314.36 140.00 291.00
C 133.61 314.89 127.61 338.88 122.00 363.00
C 105.69 366.98 84.03 365.01 67.00 364.00
C 51.02 307.75 35.74 251.33 20.00 195.00
C 40.33 195.00 60.67 195.00 81.00 195.00
C 86.69 222.01 100.15 247.62 93.00 276.00
M 311.00 364.00 
C 291.74 365.66 269.19 365.67 250.00 364.00
C 252.61 320.39 243.62 273.81 256.98 232.98 C 260.53 221.05 263.85 209.05 266.98 196.98 C 279.85 192.40 296.63 196.29 311.00 195.00
C 311.00 251.33 311.00 307.67 311.00 364.00
M 421.00 304.00 
C 423.33 323.10 421.34 344.15 422.00 364.00
C 389.40 365.67 353.53 365.67 321.00 364.00
C 318.67 308.57 320.67 251.18 320.00 195.00
C 340.33 195.00 360.67 195.00 381.00 195.00
C 381.00 230.00 381.00 265.00 381.00 300.00
C 370.00 300.96 359.01 302.03 348.00 303.00
C 371.43 305.33 396.82 303.33 421.00 304.00
M 455.00 396.00 
C 457.96 398.21 460.90 401.02 463.00 404.00
C 472.65 415.01 478.87 428.67 483.00 442.00
C 486.74 428.10 493.32 415.01 503.00 404.00
C 505.21 401.04 508.02 398.10 511.00 396.00
C 544.60 365.66 600.34 367.56 632.00 400.00
C 621.73 414.07 606.40 425.46 595.00 439.00
C 576.36 419.37 537.25 430.34 536.00 459.00
C 536.00 461.33 536.00 463.67 536.00 466.00
C 536.48 481.83 551.01 496.18 567.00 496.00 C 585.04 494.82 601.14 486.45 620.00 488.00
C 607.57 485.68 593.18 487.66 580.00 487.00
C 578.34 473.41 578.33 456.52 580.00 443.00
C 605.33 443.00 630.67 443.00 656.00 443.00
C 661.75 471.04 654.31 500.89 635.00 522.00
C 633.11 524.62 630.65 527.24 628.00 529.00
C 580.57 574.37 494.94 544.67 482.00 482.00
C 471.37 546.02 382.84 573.46 337.00 528.00
C 334.38 526.11 331.76 523.65 330.00 521.00
C 321.07 510.41 313.86 498.42 311.00 485.00
C 308.67 505.10 310.66 527.15 310.00 548.00
C 290.00 548.00 270.00 548.00 250.00 548.00
C 247.69 540.24 249.65 530.51 249.00 522.00 C 250.73 514.25 245.37 501.55 252.00 497.00
C 260.85 496.78 269.22 494.59 278.00 494.00
C 259.57 491.67 239.18 493.66 220.00 493.00 C 211.52 495.76 218.06 510.43 216.00 518.00 C 216.00 528.00 216.00 538.00 216.00 548.00
C 196.00 548.00 176.00 548.00 156.00 548.00
C 154.33 492.40 154.33 433.53 156.00 378.00
C 176.00 378.00 196.00 378.00 216.00 378.00
C 217.01 393.77 218.99 414.06 215.00 429.00
C 206.20 429.44 197.67 430.96 189.00 432.00
C 207.93 433.66 230.14 433.67 249.00 432.00
C 249.67 414.17 247.67 395.01 250.00 378.00
C 270.00 378.00 290.00 378.00 310.00 378.00
C 310.67 398.50 308.67 420.32 311.00 440.00
C 323.44 377.85 409.76 351.98 455.00 396.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FDFDFD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 622.00 12.00 
C 622.00 31.33 622.00 50.67 622.00 70.00
C 624.66 78.57 629.35 86.45 632.00 95.00
C 614.82 67.70 598.04 39.11 582.00 11.00
C 571.00 11.00 560.00 11.00 549.00 11.00 C 540.57 13.45 523.03 5.57 521.00 16.00 C 521.00 71.00 521.00 126.00 521.00 181.00
C 541.33 181.00 561.67 181.00 582.00 181.00
C 580.70 153.55 587.00 123.82 573.00 100.00
C 589.51 126.38 605.89 153.74 621.00 181.00
C 641.33 181.00 661.67 181.00 682.00 181.00
C 682.66 163.15 680.67 144.10 683.00 127.00
C 701.04 184.65 782.21 204.01 825.00 162.00
C 826.57 159.72 828.67 157.43 831.00 156.00
C 850.05 134.88 858.39 105.12 852.00 77.00
C 826.67 77.00 801.33 77.00 776.00 77.00
C 776.00 91.33 776.00 105.67 776.00 120.00
C 787.19 122.99 801.74 119.01 813.00 122.00
C 795.26 120.16 780.73 128.48 764.00 130.00 C 749.70 130.08 736.21 118.84 732.93 105.07 C 722.24 71.83 768.81 48.37 791.00 73.00
C 802.96 59.55 817.00 47.50 829.00 34.00
C 786.72 -11.03 702.44 5.63 683.00 65.00
C 680.67 47.99 682.67 28.83 682.00 11.00
C 662.15 11.66 641.10 9.67 622.00 12.00
M 633.00 95.00 
C 635.79 99.83 630.21 98.36 633.00 95.00
M 572.00 96.00 
C 574.79 100.83 569.21 99.36 572.00 96.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FDFDFD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 168.00 181.00 
C 168.67 169.83 166.67 157.34 169.00 147.00
C 181.67 147.00 194.33 147.00 207.00 147.00
C 207.00 126.67 207.00 106.33 207.00 86.00
C 194.33 86.00 181.67 86.00 169.00 86.00
C 166.84 83.24 168.55 78.51 168.00 75.00
C 159.88 74.75 152.18 72.92 144.00 73.00
C 142.33 73.00 140.67 73.00 139.00 73.00
C 139.83 71.25 142.44 72.34 144.00 72.00
C 168.33 72.00 192.67 72.00 217.00 72.00
C 217.00 51.67 217.00 31.33 217.00 11.00
C 180.67 11.00 144.33 11.00 108.00 11.00
C 108.00 67.67 108.00 124.33 108.00 181.00
C 128.00 181.00 148.00 181.00 168.00 181.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FCFCFC'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 287.00 11.00 
C 266.67 11.00 246.33 11.00 226.00 11.00
C 226.00 67.67 226.00 124.33 226.00 181.00
C 260.00 181.00 294.00 181.00 328.00 181.00
C 328.00 170.00 328.00 159.00 328.00 148.00 C 325.82 139.49 332.65 123.69 324.00 120.00 C 303.15 119.34 281.10 121.33 261.00 119.00
C 269.92 119.36 278.40 117.87 287.00 117.00
C 287.00 81.67 287.00 46.33 287.00 11.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FDFDFD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 382.00 121.00 
C 382.00 141.00 382.00 161.00 382.00 181.00
C 402.00 181.00 422.00 181.00 442.00 181.00
C 442.00 124.33 442.00 67.67 442.00 11.00
C 422.15 11.66 401.10 9.67 382.00 12.00
C 382.00 31.67 382.00 51.33 382.00 71.00
C 381.17 70.59 380.13 70.03 380.00 69.00
C 371.36 49.51 361.55 30.47 353.00 11.00
C 333.00 11.00 313.00 11.00 293.00 11.00
C 307.31 47.34 326.89 81.92 342.00 118.00
C 363.18 118.66 385.57 116.67 406.00 119.00
C 397.84 119.06 390.15 120.94 382.00 121.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FDFDFD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 512.00 11.00 
C 491.67 11.00 471.33 11.00 451.00 11.00
C 451.00 67.67 451.00 124.33 451.00 181.00
C 471.33 181.00 491.67 181.00 512.00 181.00
C 512.00 124.33 512.00 67.67 512.00 11.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7F7F7F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 380.00 69.00 
C 380.13 70.03 381.17 70.59 382.00 71.00
C 382.00 51.33 382.00 31.67 382.00 12.00
C 379.00 29.86 383.00 51.07 380.00 69.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#8F8F8F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 622.00 70.00 
C 622.00 50.67 622.00 31.33 622.00 12.00
C 620.33 30.19 620.34 51.74 622.00 70.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#696969'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 144.00 73.00 
C 144.00 73.00 143.96 72.00 144.00 72.00
C 142.44 72.34 139.83 71.25 139.00 73.00
C 140.67 73.00 142.33 73.00 144.00 73.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#5D5D5D'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 169.00 86.00 
C 168.45 82.49 170.16 77.76 168.00 75.00
C 168.55 78.51 166.84 83.24 169.00 86.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#6A6A6A'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 776.00 120.00 
C 776.00 105.67 776.00 91.33 776.00 77.00
C 801.33 77.00 826.67 77.00 852.00 77.00
C 828.66 74.67 803.17 76.65 779.01 76.01 C 770.81 77.58 776.85 90.65 775.00 97.00 C 775.64 104.51 773.70 113.24 776.00 120.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#868686'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 633.00 95.00 
C 630.21 98.36 635.79 99.83 633.00 95.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7B7B7B'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 572.00 96.00 
C 569.21 99.36 574.79 100.83 572.00 96.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7F7F7F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 382.00 181.00 
C 382.00 161.00 382.00 141.00 382.00 121.00
C 380.33 139.86 380.34 162.07 382.00 181.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#606060'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 168.00 181.00 
C 170.33 170.66 168.33 158.17 169.00 147.00
C 166.67 157.34 168.67 169.83 168.00 181.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#6A6A6A'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 825.00 162.00 
C 827.33 160.57 829.43 158.28 831.00 156.00
C 828.67 157.43 826.57 159.72 825.00 162.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#8F8F8F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 431.00 195.00 
C 455.67 195.00 480.33 195.00 505.00 195.00
C 481.47 193.33 454.60 193.34 431.00 195.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FDFDFD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 67.00 364.00 
C 85.25 363.34 104.20 365.32 122.00 363.00
C 127.61 338.88 133.61 314.89 140.00 291.00
C 146.68 314.36 152.80 338.89 158.00 363.00
C 176.08 364.66 195.95 364.66 214.00 363.00
C 228.59 306.77 245.11 251.12 260.00 195.00
C 239.67 195.00 219.33 195.00 199.00 195.00
C 193.88 221.52 180.23 246.95 187.00 275.00
C 188.87 276.14 187.61 279.13 188.00 281.00
C 186.36 280.03 187.41 277.39 186.00 276.00
C 180.33 248.81 173.61 221.92 167.00 195.00
C 149.00 195.00 131.00 195.00 113.00 195.00
C 106.72 222.36 99.59 249.49 94.00 277.00
C 93.09 278.83 93.66 281.74 92.00 283.00
C 92.52 280.81 90.86 277.38 93.00 276.00
C 100.15 247.62 86.69 222.01 81.00 195.00
C 60.67 195.00 40.33 195.00 20.00 195.00
C 35.74 251.33 51.02 307.75 67.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FDFDFD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 250.00 364.00 
C 270.33 364.00 290.67 364.00 311.00 364.00
C 311.00 307.67 311.00 251.33 311.00 195.00
C 296.63 196.29 279.85 192.40 266.98 196.98 C 263.85 209.05 260.53 221.05 256.98 232.98 C 243.62 273.81 252.61 320.39 250.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FDFDFD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 321.00 364.00 
C 354.33 364.00 387.67 364.00 421.00 364.00
C 421.00 344.00 421.00 324.00 421.00 304.00
C 396.82 303.33 371.43 305.33 348.00 303.00
C 359.01 302.03 370.00 300.96 381.00 300.00
C 381.00 265.00 381.00 230.00 381.00 195.00
C 360.67 195.00 340.33 195.00 320.00 195.00
C 320.67 251.18 318.67 308.57 321.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FEFEFE'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 431.00 364.00 
C 455.67 364.00 480.33 364.00 505.00 364.00
C 546.91 363.92 585.14 326.91 586.00 285.00
C 586.00 281.00 586.00 277.00 586.00 273.00
C 584.62 231.66 546.64 194.54 505.00 195.00
C 480.33 195.00 455.67 195.00 431.00 195.00
C 431.00 251.33 431.00 307.67 431.00 364.00
M 525.00 276.00 
C 526.43 277.03 526.69 281.04 525.00 282.00
C 524.75 292.66 514.68 302.77 504.00 303.00
C 491.57 305.32 477.18 303.34 464.00 304.00
C 465.41 302.71 467.86 301.96 470.00 302.00
C 478.53 300.11 495.87 304.12 492.00 290.00 C 492.00 278.33 492.00 266.67 492.00 255.00
C 506.47 252.76 523.85 260.13 525.00 276.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 470.00 302.00 
C 480.43 304.32 492.82 302.34 504.00 303.00
C 514.68 302.77 524.75 292.66 525.00 282.00
C 525.00 280.00 525.00 278.00 525.00 276.00
C 523.85 260.13 506.47 252.76 492.00 255.00
C 492.00 266.67 492.00 278.33 492.00 290.00 C 495.87 304.12 478.53 300.11 470.00 302.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#787878'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 586.00 285.00 
C 587.71 282.13 587.57 275.94 586.00 273.00
C 586.00 277.00 586.00 281.00 586.00 285.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#676767'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 186.00 276.00 
C 187.41 277.39 186.36 280.03 188.00 281.00
C 187.61 279.13 188.87 276.14 187.00 275.00
C 186.95 275.35 186.00 275.83 186.00 276.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#8A8A8A'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 94.00 277.00 
C 93.98 276.81 93.08 276.35 93.00 276.00
C 90.86 277.38 92.52 280.81 92.00 283.00
C 93.66 281.74 93.09 278.83 94.00 277.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#787878'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 525.00 282.00 
C 526.69 281.04 526.43 277.03 525.00 276.00
C 525.00 278.00 525.00 280.00 525.00 282.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#828282'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 504.00 303.00 
C 492.82 302.34 480.43 304.32 470.00 302.00
C 467.86 301.96 465.41 302.71 464.00 304.00
C 477.18 303.34 491.57 305.32 504.00 303.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#A0A0A0'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 421.00 364.00 
C 421.50 364.00 422.00 364.00 422.00 364.00
C 421.34 344.15 423.33 323.10 421.00 304.00
C 421.00 324.00 421.00 344.00 421.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#606060'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 67.00 364.00 
C 84.03 365.01 105.69 366.98 122.00 363.00
C 104.20 365.32 85.25 363.34 67.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#606060'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 214.00 363.00 
C 195.95 364.66 176.08 364.66 158.00 363.00
C 165.43 367.57 176.98 363.74 186.00 365.00 C 195.03 363.75 206.52 367.55 214.00 363.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#5F5F5F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 311.00 364.00 
C 290.67 364.00 270.33 364.00 250.00 364.00
C 269.19 365.67 291.74 365.66 311.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#5F5F5F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 422.00 364.00 
C 422.00 364.00 421.50 364.00 421.00 364.00
C 387.67 364.00 354.33 364.00 321.00 364.00
C 353.53 365.67 389.40 365.67 422.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#5F5F5F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 505.00 364.00 
C 480.33 364.00 455.67 364.00 431.00 364.00
C 454.53 365.67 481.40 365.66 505.00 364.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FEFEFE'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 250.00 378.00 
C 249.33 395.83 251.33 414.99 249.00 432.00
C 230.14 433.67 207.93 433.66 189.00 432.00
C 197.67 430.96 206.20 429.44 215.00 429.00
C 217.32 412.83 215.34 395.58 216.00 379.00
C 196.00 379.00 176.00 379.00 156.00 379.00
C 156.00 435.33 156.00 491.67 156.00 548.00
C 176.00 548.00 196.00 548.00 216.00 548.00
C 216.00 538.00 216.00 528.00 216.00 518.00 C 218.06 510.43 211.52 495.76 220.00 493.00 C 239.18 493.66 259.57 491.67 278.00 494.00
C 269.22 494.59 260.85 496.78 252.00 497.00
C 247.38 511.99 251.30 531.33 250.00 548.00
C 270.00 548.00 290.00 548.00 310.00 548.00
C 310.66 527.15 308.67 505.10 311.00 485.00
C 313.86 498.42 321.07 510.41 330.00 521.00
C 332.62 522.89 335.24 525.35 337.00 528.00
C 382.84 573.46 471.37 546.02 482.00 482.00
C 494.94 544.67 580.57 574.37 628.00 529.00
C 629.89 526.38 632.35 523.76 635.00 522.00
C 654.31 500.89 661.75 471.04 656.00 443.00
C 630.67 443.00 605.33 443.00 580.00 443.00
C 580.00 457.67 580.00 472.33 580.00 487.00
C 593.18 487.66 607.57 485.68 620.00 488.00
C 601.14 486.45 585.04 494.82 567.00 496.00 C 551.01 496.18 536.48 481.83 536.00 466.00
C 534.53 464.67 534.29 460.26 536.00 459.00
C 537.25 430.34 576.36 419.37 595.00 439.00
C 606.40 425.46 621.73 414.07 632.00 400.00
C 600.34 367.56 544.60 365.66 511.00 396.00
C 508.79 398.96 505.98 401.90 503.00 404.00
C 493.32 415.01 486.74 428.10 483.00 442.00
C 478.87 428.67 472.65 415.01 463.00 404.00
C 460.04 401.79 457.10 398.98 455.00 396.00
C 409.76 351.98 323.44 377.85 311.00 440.00
C 308.67 420.32 310.67 398.50 310.00 378.00
C 290.00 378.00 270.00 378.00 250.00 378.00
M 400.00 429.00 
C 427.26 430.02 440.39 468.04 420.25 486.25 C 402.09 506.43 364.01 493.29 363.00 466.00
C 361.50 464.36 361.28 459.57 363.00 458.00
C 364.01 442.90 377.71 429.31 393.00 429.00
C 394.33 427.53 398.74 427.29 400.00 429.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#5F5F5F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 156.00 548.00 
C 156.00 491.67 156.00 435.33 156.00 379.00
C 156.00 378.50 156.00 378.00 156.00 378.00
C 154.33 433.53 154.33 492.40 156.00 548.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#C0C0C0'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 156.00 379.00 
C 176.00 379.00 196.00 379.00 216.00 379.00
C 216.00 378.50 216.00 378.00 216.00 378.00
C 196.00 378.00 176.00 378.00 156.00 378.00
C 156.00 378.00 156.00 378.50 156.00 379.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#505050'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 216.00 379.00 
C 215.34 395.58 217.32 412.83 215.00 429.00
C 218.99 414.06 217.01 393.77 216.00 378.00
C 216.00 378.00 216.00 378.50 216.00 379.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7F7F7F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 249.00 432.00 
C 251.33 414.99 249.33 395.83 250.00 378.00
C 247.67 395.01 249.67 414.17 249.00 432.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#808080'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 463.00 404.00 
C 460.90 401.02 457.96 398.21 455.00 396.00
C 457.10 398.98 460.04 401.79 463.00 404.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7D7D7D'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 503.00 404.00 
C 505.98 401.90 508.79 398.96 511.00 396.00
C 508.02 398.10 505.21 401.04 503.00 404.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#727272'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 393.00 429.00 
C 395.33 429.00 397.67 429.00 400.00 429.00
C 398.74 427.29 394.33 427.53 393.00 429.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 363.00 458.00 
C 363.00 460.67 363.00 463.33 363.00 466.00
C 364.01 493.29 402.09 506.43 420.25 486.25 C 440.39 468.04 427.26 430.02 400.00 429.00
C 397.67 429.00 395.33 429.00 393.00 429.00
C 377.71 429.31 364.01 442.90 363.00 458.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#8F8F8F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 580.00 487.00 
C 580.00 472.33 580.00 457.67 580.00 443.00
C 578.33 456.52 578.34 473.41 580.00 487.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#717171'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 363.00 466.00 
C 363.00 463.33 363.00 460.67 363.00 458.00
C 361.28 459.57 361.50 464.36 363.00 466.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#838383'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 536.00 466.00 
C 536.00 463.67 536.00 461.33 536.00 459.00
C 534.29 460.26 534.53 464.67 536.00 466.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7F7F7F'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 250.00 548.00 
C 251.30 531.33 247.38 511.99 252.00 497.00
C 245.37 501.55 250.73 514.25 249.00 522.00 C 249.65 530.51 247.69 540.24 250.00 548.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#757575'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 337.00 528.00 
C 335.24 525.35 332.62 522.89 330.00 521.00
C 331.76 523.65 334.38 526.11 337.00 528.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#777777'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 628.00 529.00 
C 630.65 527.24 633.11 524.62 635.00 522.00
C 632.35 523.76 629.89 526.38 628.00 529.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 0.00 558.00 
L 864.00 558.00
L 864.00 0.00
L 0.00 0.00
L 0.00 558.00
M 776.00 120.00 
C 773.70 113.24 775.64 104.51 775.00 97.00 C 776.85 90.65 770.81 77.58 779.01 76.01 C 803.17 76.65 828.66 74.67 852.00 77.00
C 858.39 105.12 850.05 134.88 831.00 156.00
C 829.43 158.28 827.33 160.57 825.00 162.00
C 782.21 204.01 701.04 184.65 683.00 127.00
C 680.67 144.10 682.66 163.15 682.00 181.00
C 661.67 181.00 641.33 181.00 621.00 181.00
C 605.89 153.74 589.51 126.38 573.00 100.00
C 587.00 123.82 580.70 153.55 582.00 181.00
C 561.67 181.00 541.33 181.00 521.00 181.00
C 521.00 126.00 521.00 71.00 521.00 16.00 C 523.03 5.57 540.57 13.45 549.00 11.00 C 560.00 11.00 571.00 11.00 582.00 11.00
C 598.04 39.11 614.82 67.70 632.00 95.00
C 629.35 86.45 624.66 78.57 622.00 70.00
C 620.34 51.74 620.33 30.19 622.00 12.00
C 641.10 9.67 662.15 11.66 682.00 11.00
C 682.67 28.83 680.67 47.99 683.00 65.00
C 702.44 5.63 786.72 -11.03 829.00 34.00
C 817.00 47.50 802.96 59.55 791.00 73.00
C 768.81 48.37 722.24 71.83 732.93 105.07 C 736.21 118.84 749.70 130.08 764.00 130.00 C 780.73 128.48 795.26 120.16 813.00 122.00
C 801.74 119.01 787.19 122.99 776.00 120.00
M 144.00 72.00 
C 143.96 72.00 144.00 73.00 144.00 73.00
C 152.18 72.92 159.88 74.75 168.00 75.00
C 170.16 77.76 168.45 82.49 169.00 86.00
C 181.67 86.00 194.33 86.00 207.00 86.00
C 207.00 106.33 207.00 126.67 207.00 147.00
C 194.33 147.00 181.67 147.00 169.00 147.00
C 168.33 158.17 170.33 170.66 168.00 181.00
C 148.00 181.00 128.00 181.00 108.00 181.00
C 108.00 124.33 108.00 67.67 108.00 11.00
C 144.33 11.00 180.67 11.00 217.00 11.00
C 217.00 31.33 217.00 51.67 217.00 72.00
C 192.67 72.00 168.33 72.00 144.00 72.00
M 287.00 11.00 
C 287.00 46.33 287.00 81.67 287.00 117.00
C 278.40 117.87 269.92 119.36 261.00 119.00
C 281.10 121.33 303.15 119.34 324.00 120.00 C 332.65 123.69 325.82 139.49 328.00 148.00 C 328.00 159.00 328.00 170.00 328.00 181.00
C 294.00 181.00 260.00 181.00 226.00 181.00
C 226.00 124.33 226.00 67.67 226.00 11.00
C 246.33 11.00 266.67 11.00 287.00 11.00
M 380.00 69.00 
C 383.00 51.07 379.00 29.86 382.00 12.00
C 401.10 9.67 422.15 11.66 442.00 11.00
C 442.00 67.67 442.00 124.33 442.00 181.00
C 422.00 181.00 402.00 181.00 382.00 181.00
C 380.34 162.07 380.33 139.86 382.00 121.00
C 390.15 120.94 397.84 119.06 406.00 119.00
C 385.57 116.67 363.18 118.66 342.00 118.00
C 326.89 81.92 307.31 47.34 293.00 11.00
C 313.00 11.00 333.00 11.00 353.00 11.00
C 361.55 30.47 371.36 49.51 380.00 69.00
M 512.00 11.00 
C 512.00 67.67 512.00 124.33 512.00 181.00
C 491.67 181.00 471.33 181.00 451.00 181.00
C 451.00 124.33 451.00 67.67 451.00 11.00
C 471.33 11.00 491.67 11.00 512.00 11.00
M 505.00 195.00 
C 546.64 194.54 584.62 231.66 586.00 273.00
C 587.57 275.94 587.71 282.13 586.00 285.00
C 585.14 326.91 546.91 363.92 505.00 364.00
C 481.40 365.66 454.53 365.67 431.00 364.00
C 431.00 307.67 431.00 251.33 431.00 195.00
C 454.60 193.34 481.47 193.33 505.00 195.00
M 93.00 276.00 
C 93.08 276.35 93.98 276.81 94.00 277.00
C 99.59 249.49 106.72 222.36 113.00 195.00
C 131.00 195.00 149.00 195.00 167.00 195.00
C 173.61 221.92 180.33 248.81 186.00 276.00
C 186.00 275.83 186.95 275.35 187.00 275.00
C 180.23 246.95 193.88 221.52 199.00 195.00
C 219.33 195.00 239.67 195.00 260.00 195.00
C 245.11 251.12 228.59 306.77 214.00 363.00
C 206.52 367.55 195.03 363.75 186.00 365.00 C 176.98 363.74 165.43 367.57 158.00 363.00
C 152.80 338.89 146.68 314.36 140.00 291.00
C 133.61 314.89 127.61 338.88 122.00 363.00
C 105.69 366.98 84.03 365.01 67.00 364.00
C 51.02 307.75 35.74 251.33 20.00 195.00
C 40.33 195.00 60.67 195.00 81.00 195.00
C 86.69 222.01 100.15 247.62 93.00 276.00
M 311.00 364.00 
C 291.74 365.66 269.19 365.67 250.00 364.00
C 252.61 320.39 243.62 273.81 256.98 232.98 C 260.53 221.05 263.85 209.05 266.98 196.98 C 279.85 192.40 296.63 196.29 311.00 195.00
C 311.00 251.33 311.00 307.67 311.00 364.00
M 421.00 304.00 
C 423.33 323.10 421.34 344.15 422.00 364.00
C 389.40 365.67 353.53 365.67 321.00 364.00
C 318.67 308.57 320.67 251.18 320.00 195.00
C 340.33 195.00 360.67 195.00 381.00 195.00
C 381.00 230.00 381.00 265.00 381.00 300.00
C 370.00 300.96 359.01 302.03 348.00 303.00
C 371.43 305.33 396.82 303.33 421.00 304.00
M 455.00 396.00 
C 457.96 398.21 460.90 401.02 463.00 404.00
C 472.65 415.01 478.87 428.67 483.00 442.00
C 486.74 428.10 493.32 415.01 503.00 404.00
C 505.21 401.04 508.02 398.10 511.00 396.00
C 544.60 365.66 600.34 367.56 632.00 400.00
C 621.73 414.07 606.40 425.46 595.00 439.00
C 576.36 419.37 537.25 430.34 536.00 459.00
C 536.00 461.33 536.00 463.67 536.00 466.00
C 536.48 481.83 551.01 496.18 567.00 496.00 C 585.04 494.82 601.14 486.45 620.00 488.00
C 607.57 485.68 593.18 487.66 580.00 487.00
C 578.34 473.41 578.33 456.52 580.00 443.00
C 605.33 443.00 630.67 443.00 656.00 443.00
C 661.75 471.04 654.31 500.89 635.00 522.00
C 633.11 524.62 630.65 527.24 628.00 529.00
C 580.57 574.37 494.94 544.67 482.00 482.00
C 471.37 546.02 382.84 573.46 337.00 528.00
C 334.38 526.11 331.76 523.65 330.00 521.00
C 321.07 510.41 313.86 498.42 311.00 485.00
C 308.67 505.10 310.66 527.15 310.00 548.00
C 290.00 548.00 270.00 548.00 250.00 548.00
C 247.69 540.24 249.65 530.51 249.00 522.00 C 250.73 514.25 245.37 501.55 252.00 497.00
C 260.85 496.78 269.22 494.59 278.00 494.00
C 259.57 491.67 239.18 493.66 220.00 493.00 C 211.52 495.76 218.06 510.43 216.00 518.00 C 216.00 528.00 216.00 538.00 216.00 548.00
C 196.00 548.00 176.00 548.00 156.00 548.00
C 154.33 492.40 154.33 433.53 156.00 378.00
C 176.00 378.00 196.00 378.00 216.00 378.00
C 217.01 393.77 218.99 414.06 215.00 429.00
C 206.20 429.44 197.67 430.96 189.00 432.00
C 207.93 433.66 230.14 433.67 249.00 432.00
C 249.67 414.17 247.67 395.01 250.00 378.00
C 270.00 378.00 290.00 378.00 310.00 378.00
C 310.67 398.50 308.67 420.32 311.00 440.00
C 323.44 377.85 409.76 351.98 455.00 396.00 Z'
          />
        </g>
        <g fill='#FDFDFD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 622.00 12.00 
C 622.00 31.33 622.00 50.67 622.00 70.00
C 624.66 78.57 629.35 86.45 632.00 95.00
C 614.82 67.70 598.04 39.11 582.00 11.00
C 571.00 11.00 560.00 11.00 549.00 11.00 C 540.57 13.45 523.03 5.57 521.00 16.00 C 521.00 71.00 521.00 126.00 521.00 181.00
C 541.33 181.00 561.67 181.00 582.00 181.00
C 580.70 153.55 587.00 123.82 573.00 100.00
C 589.51 126.38 605.89 153.74 621.00 181.00
C 641.33 181.00 661.67 181.00 682.00 181.00
C 682.66 163.15 680.67 144.10 683.00 127.00
C 701.04 184.65 782.21 204.01 825.00 162.00
C 826.57 159.72 828.67 157.43 831.00 156.00
C 850.05 134.88 858.39 105.12 852.00 77.00
C 826.67 77.00 801.33 77.00 776.00 77.00
C 776.00 91.33 776.00 105.67 776.00 120.00
C 787.19 122.99 801.74 119.01 813.00 122.00
C 795.26 120.16 780.73 128.48 764.00 130.00 C 749.70 130.08 736.21 118.84 732.93 105.07 C 722.24 71.83 768.81 48.37 791.00 73.00
C 802.96 59.55 817.00 47.50 829.00 34.00
C 786.72 -11.03 702.44 5.63 683.00 65.00
C 680.67 47.99 682.67 28.83 682.00 11.00
C 662.15 11.66 641.10 9.67 622.00 12.00
M 633.00 95.00 
C 635.79 99.83 630.21 98.36 633.00 95.00
M 572.00 96.00 
C 574.79 100.83 569.21 99.36 572.00 96.00 Z'
          />
        </g>
        <g fill='#FDFDFD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 168.00 181.00 
C 168.67 169.83 166.67 157.34 169.00 147.00
C 181.67 147.00 194.33 147.00 207.00 147.00
C 207.00 126.67 207.00 106.33 207.00 86.00
C 194.33 86.00 181.67 86.00 169.00 86.00
C 166.84 83.24 168.55 78.51 168.00 75.00
C 159.88 74.75 152.18 72.92 144.00 73.00
C 142.33 73.00 140.67 73.00 139.00 73.00
C 139.83 71.25 142.44 72.34 144.00 72.00
C 168.33 72.00 192.67 72.00 217.00 72.00
C 217.00 51.67 217.00 31.33 217.00 11.00
C 180.67 11.00 144.33 11.00 108.00 11.00
C 108.00 67.67 108.00 124.33 108.00 181.00
C 128.00 181.00 148.00 181.00 168.00 181.00 Z'
          />
        </g>
        <g fill='#FCFCFC' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 287.00 11.00 
C 266.67 11.00 246.33 11.00 226.00 11.00
C 226.00 67.67 226.00 124.33 226.00 181.00
C 260.00 181.00 294.00 181.00 328.00 181.00
C 328.00 170.00 328.00 159.00 328.00 148.00 C 325.82 139.49 332.65 123.69 324.00 120.00 C 303.15 119.34 281.10 121.33 261.00 119.00
C 269.92 119.36 278.40 117.87 287.00 117.00
C 287.00 81.67 287.00 46.33 287.00 11.00 Z'
          />
        </g>
        <g fill='#FDFDFD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 382.00 121.00 
C 382.00 141.00 382.00 161.00 382.00 181.00
C 402.00 181.00 422.00 181.00 442.00 181.00
C 442.00 124.33 442.00 67.67 442.00 11.00
C 422.15 11.66 401.10 9.67 382.00 12.00
C 382.00 31.67 382.00 51.33 382.00 71.00
C 381.17 70.59 380.13 70.03 380.00 69.00
C 371.36 49.51 361.55 30.47 353.00 11.00
C 333.00 11.00 313.00 11.00 293.00 11.00
C 307.31 47.34 326.89 81.92 342.00 118.00
C 363.18 118.66 385.57 116.67 406.00 119.00
C 397.84 119.06 390.15 120.94 382.00 121.00 Z'
          />
        </g>
        <g fill='#FDFDFD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 512.00 11.00 
C 491.67 11.00 471.33 11.00 451.00 11.00
C 451.00 67.67 451.00 124.33 451.00 181.00
C 471.33 181.00 491.67 181.00 512.00 181.00
C 512.00 124.33 512.00 67.67 512.00 11.00 Z'
          />
        </g>
        <g fill='#7F7F7F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 380.00 69.00 
C 380.13 70.03 381.17 70.59 382.00 71.00
C 382.00 51.33 382.00 31.67 382.00 12.00
C 379.00 29.86 383.00 51.07 380.00 69.00 Z'
          />
        </g>
        <g fill='#8F8F8F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 622.00 70.00 
C 622.00 50.67 622.00 31.33 622.00 12.00
C 620.33 30.19 620.34 51.74 622.00 70.00 Z'
          />
        </g>
        <g fill='#696969' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 144.00 73.00 
C 144.00 73.00 143.96 72.00 144.00 72.00
C 142.44 72.34 139.83 71.25 139.00 73.00
C 140.67 73.00 142.33 73.00 144.00 73.00 Z'
          />
        </g>
        <g fill='#5D5D5D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 169.00 86.00 
C 168.45 82.49 170.16 77.76 168.00 75.00
C 168.55 78.51 166.84 83.24 169.00 86.00 Z'
          />
        </g>
        <g fill='#6A6A6A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 776.00 120.00 
C 776.00 105.67 776.00 91.33 776.00 77.00
C 801.33 77.00 826.67 77.00 852.00 77.00
C 828.66 74.67 803.17 76.65 779.01 76.01 C 770.81 77.58 776.85 90.65 775.00 97.00 C 775.64 104.51 773.70 113.24 776.00 120.00 Z'
          />
        </g>
        <g fill='#868686' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 633.00 95.00 
C 630.21 98.36 635.79 99.83 633.00 95.00 Z'
          />
        </g>
        <g fill='#7B7B7B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 572.00 96.00 
C 569.21 99.36 574.79 100.83 572.00 96.00 Z'
          />
        </g>
        <g fill='#7F7F7F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 382.00 181.00 
C 382.00 161.00 382.00 141.00 382.00 121.00
C 380.33 139.86 380.34 162.07 382.00 181.00 Z'
          />
        </g>
        <g fill='#606060' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 168.00 181.00 
C 170.33 170.66 168.33 158.17 169.00 147.00
C 166.67 157.34 168.67 169.83 168.00 181.00 Z'
          />
        </g>
        <g fill='#6A6A6A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 825.00 162.00 
C 827.33 160.57 829.43 158.28 831.00 156.00
C 828.67 157.43 826.57 159.72 825.00 162.00 Z'
          />
        </g>
        <g fill='#8F8F8F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 431.00 195.00 
C 455.67 195.00 480.33 195.00 505.00 195.00
C 481.47 193.33 454.60 193.34 431.00 195.00 Z'
          />
        </g>
        <g fill='#FDFDFD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 67.00 364.00 
C 85.25 363.34 104.20 365.32 122.00 363.00
C 127.61 338.88 133.61 314.89 140.00 291.00
C 146.68 314.36 152.80 338.89 158.00 363.00
C 176.08 364.66 195.95 364.66 214.00 363.00
C 228.59 306.77 245.11 251.12 260.00 195.00
C 239.67 195.00 219.33 195.00 199.00 195.00
C 193.88 221.52 180.23 246.95 187.00 275.00
C 188.87 276.14 187.61 279.13 188.00 281.00
C 186.36 280.03 187.41 277.39 186.00 276.00
C 180.33 248.81 173.61 221.92 167.00 195.00
C 149.00 195.00 131.00 195.00 113.00 195.00
C 106.72 222.36 99.59 249.49 94.00 277.00
C 93.09 278.83 93.66 281.74 92.00 283.00
C 92.52 280.81 90.86 277.38 93.00 276.00
C 100.15 247.62 86.69 222.01 81.00 195.00
C 60.67 195.00 40.33 195.00 20.00 195.00
C 35.74 251.33 51.02 307.75 67.00 364.00 Z'
          />
        </g>
        <g fill='#FDFDFD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 250.00 364.00 
C 270.33 364.00 290.67 364.00 311.00 364.00
C 311.00 307.67 311.00 251.33 311.00 195.00
C 296.63 196.29 279.85 192.40 266.98 196.98 C 263.85 209.05 260.53 221.05 256.98 232.98 C 243.62 273.81 252.61 320.39 250.00 364.00 Z'
          />
        </g>
        <g fill='#FDFDFD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 321.00 364.00 
C 354.33 364.00 387.67 364.00 421.00 364.00
C 421.00 344.00 421.00 324.00 421.00 304.00
C 396.82 303.33 371.43 305.33 348.00 303.00
C 359.01 302.03 370.00 300.96 381.00 300.00
C 381.00 265.00 381.00 230.00 381.00 195.00
C 360.67 195.00 340.33 195.00 320.00 195.00
C 320.67 251.18 318.67 308.57 321.00 364.00 Z'
          />
        </g>
        <g fill='#FEFEFE' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 431.00 364.00 
C 455.67 364.00 480.33 364.00 505.00 364.00
C 546.91 363.92 585.14 326.91 586.00 285.00
C 586.00 281.00 586.00 277.00 586.00 273.00
C 584.62 231.66 546.64 194.54 505.00 195.00
C 480.33 195.00 455.67 195.00 431.00 195.00
C 431.00 251.33 431.00 307.67 431.00 364.00
M 525.00 276.00 
C 526.43 277.03 526.69 281.04 525.00 282.00
C 524.75 292.66 514.68 302.77 504.00 303.00
C 491.57 305.32 477.18 303.34 464.00 304.00
C 465.41 302.71 467.86 301.96 470.00 302.00
C 478.53 300.11 495.87 304.12 492.00 290.00 C 492.00 278.33 492.00 266.67 492.00 255.00
C 506.47 252.76 523.85 260.13 525.00 276.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 470.00 302.00 
C 480.43 304.32 492.82 302.34 504.00 303.00
C 514.68 302.77 524.75 292.66 525.00 282.00
C 525.00 280.00 525.00 278.00 525.00 276.00
C 523.85 260.13 506.47 252.76 492.00 255.00
C 492.00 266.67 492.00 278.33 492.00 290.00 C 495.87 304.12 478.53 300.11 470.00 302.00 Z'
          />
        </g>
        <g fill='#787878' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 586.00 285.00 
C 587.71 282.13 587.57 275.94 586.00 273.00
C 586.00 277.00 586.00 281.00 586.00 285.00 Z'
          />
        </g>
        <g fill='#676767' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 186.00 276.00 
C 187.41 277.39 186.36 280.03 188.00 281.00
C 187.61 279.13 188.87 276.14 187.00 275.00
C 186.95 275.35 186.00 275.83 186.00 276.00 Z'
          />
        </g>
        <g fill='#8A8A8A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 94.00 277.00 
C 93.98 276.81 93.08 276.35 93.00 276.00
C 90.86 277.38 92.52 280.81 92.00 283.00
C 93.66 281.74 93.09 278.83 94.00 277.00 Z'
          />
        </g>
        <g fill='#787878' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 525.00 282.00 
C 526.69 281.04 526.43 277.03 525.00 276.00
C 525.00 278.00 525.00 280.00 525.00 282.00 Z'
          />
        </g>
        <g fill='#828282' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 504.00 303.00 
C 492.82 302.34 480.43 304.32 470.00 302.00
C 467.86 301.96 465.41 302.71 464.00 304.00
C 477.18 303.34 491.57 305.32 504.00 303.00 Z'
          />
        </g>
        <g fill='#A0A0A0' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 421.00 364.00 
C 421.50 364.00 422.00 364.00 422.00 364.00
C 421.34 344.15 423.33 323.10 421.00 304.00
C 421.00 324.00 421.00 344.00 421.00 364.00 Z'
          />
        </g>
        <g fill='#606060' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 67.00 364.00 
C 84.03 365.01 105.69 366.98 122.00 363.00
C 104.20 365.32 85.25 363.34 67.00 364.00 Z'
          />
        </g>
        <g fill='#606060' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 214.00 363.00 
C 195.95 364.66 176.08 364.66 158.00 363.00
C 165.43 367.57 176.98 363.74 186.00 365.00 C 195.03 363.75 206.52 367.55 214.00 363.00 Z'
          />
        </g>
        <g fill='#5F5F5F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 311.00 364.00 
C 290.67 364.00 270.33 364.00 250.00 364.00
C 269.19 365.67 291.74 365.66 311.00 364.00 Z'
          />
        </g>
        <g fill='#5F5F5F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 422.00 364.00 
C 422.00 364.00 421.50 364.00 421.00 364.00
C 387.67 364.00 354.33 364.00 321.00 364.00
C 353.53 365.67 389.40 365.67 422.00 364.00 Z'
          />
        </g>
        <g fill='#5F5F5F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 505.00 364.00 
C 480.33 364.00 455.67 364.00 431.00 364.00
C 454.53 365.67 481.40 365.66 505.00 364.00 Z'
          />
        </g>
        <g fill='#FEFEFE' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 250.00 378.00 
C 249.33 395.83 251.33 414.99 249.00 432.00
C 230.14 433.67 207.93 433.66 189.00 432.00
C 197.67 430.96 206.20 429.44 215.00 429.00
C 217.32 412.83 215.34 395.58 216.00 379.00
C 196.00 379.00 176.00 379.00 156.00 379.00
C 156.00 435.33 156.00 491.67 156.00 548.00
C 176.00 548.00 196.00 548.00 216.00 548.00
C 216.00 538.00 216.00 528.00 216.00 518.00 C 218.06 510.43 211.52 495.76 220.00 493.00 C 239.18 493.66 259.57 491.67 278.00 494.00
C 269.22 494.59 260.85 496.78 252.00 497.00
C 247.38 511.99 251.30 531.33 250.00 548.00
C 270.00 548.00 290.00 548.00 310.00 548.00
C 310.66 527.15 308.67 505.10 311.00 485.00
C 313.86 498.42 321.07 510.41 330.00 521.00
C 332.62 522.89 335.24 525.35 337.00 528.00
C 382.84 573.46 471.37 546.02 482.00 482.00
C 494.94 544.67 580.57 574.37 628.00 529.00
C 629.89 526.38 632.35 523.76 635.00 522.00
C 654.31 500.89 661.75 471.04 656.00 443.00
C 630.67 443.00 605.33 443.00 580.00 443.00
C 580.00 457.67 580.00 472.33 580.00 487.00
C 593.18 487.66 607.57 485.68 620.00 488.00
C 601.14 486.45 585.04 494.82 567.00 496.00 C 551.01 496.18 536.48 481.83 536.00 466.00
C 534.53 464.67 534.29 460.26 536.00 459.00
C 537.25 430.34 576.36 419.37 595.00 439.00
C 606.40 425.46 621.73 414.07 632.00 400.00
C 600.34 367.56 544.60 365.66 511.00 396.00
C 508.79 398.96 505.98 401.90 503.00 404.00
C 493.32 415.01 486.74 428.10 483.00 442.00
C 478.87 428.67 472.65 415.01 463.00 404.00
C 460.04 401.79 457.10 398.98 455.00 396.00
C 409.76 351.98 323.44 377.85 311.00 440.00
C 308.67 420.32 310.67 398.50 310.00 378.00
C 290.00 378.00 270.00 378.00 250.00 378.00
M 400.00 429.00 
C 427.26 430.02 440.39 468.04 420.25 486.25 C 402.09 506.43 364.01 493.29 363.00 466.00
C 361.50 464.36 361.28 459.57 363.00 458.00
C 364.01 442.90 377.71 429.31 393.00 429.00
C 394.33 427.53 398.74 427.29 400.00 429.00 Z'
          />
        </g>
        <g fill='#5F5F5F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 156.00 548.00 
C 156.00 491.67 156.00 435.33 156.00 379.00
C 156.00 378.50 156.00 378.00 156.00 378.00
C 154.33 433.53 154.33 492.40 156.00 548.00 Z'
          />
        </g>
        <g fill='#C0C0C0' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 156.00 379.00 
C 176.00 379.00 196.00 379.00 216.00 379.00
C 216.00 378.50 216.00 378.00 216.00 378.00
C 196.00 378.00 176.00 378.00 156.00 378.00
C 156.00 378.00 156.00 378.50 156.00 379.00 Z'
          />
        </g>
        <g fill='#505050' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 216.00 379.00 
C 215.34 395.58 217.32 412.83 215.00 429.00
C 218.99 414.06 217.01 393.77 216.00 378.00
C 216.00 378.00 216.00 378.50 216.00 379.00 Z'
          />
        </g>
        <g fill='#7F7F7F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 249.00 432.00 
C 251.33 414.99 249.33 395.83 250.00 378.00
C 247.67 395.01 249.67 414.17 249.00 432.00 Z'
          />
        </g>
        <g fill='#808080' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 463.00 404.00 
C 460.90 401.02 457.96 398.21 455.00 396.00
C 457.10 398.98 460.04 401.79 463.00 404.00 Z'
          />
        </g>
        <g fill='#7D7D7D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 503.00 404.00 
C 505.98 401.90 508.79 398.96 511.00 396.00
C 508.02 398.10 505.21 401.04 503.00 404.00 Z'
          />
        </g>
        <g fill='#727272' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 393.00 429.00 
C 395.33 429.00 397.67 429.00 400.00 429.00
C 398.74 427.29 394.33 427.53 393.00 429.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 363.00 458.00 
C 363.00 460.67 363.00 463.33 363.00 466.00
C 364.01 493.29 402.09 506.43 420.25 486.25 C 440.39 468.04 427.26 430.02 400.00 429.00
C 397.67 429.00 395.33 429.00 393.00 429.00
C 377.71 429.31 364.01 442.90 363.00 458.00 Z'
          />
        </g>
        <g fill='#8F8F8F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 580.00 487.00 
C 580.00 472.33 580.00 457.67 580.00 443.00
C 578.33 456.52 578.34 473.41 580.00 487.00 Z'
          />
        </g>
        <g fill='#717171' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 363.00 466.00 
C 363.00 463.33 363.00 460.67 363.00 458.00
C 361.28 459.57 361.50 464.36 363.00 466.00 Z'
          />
        </g>
        <g fill='#838383' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 536.00 466.00 
C 536.00 463.67 536.00 461.33 536.00 459.00
C 534.29 460.26 534.53 464.67 536.00 466.00 Z'
          />
        </g>
        <g fill='#7F7F7F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 250.00 548.00 
C 251.30 531.33 247.38 511.99 252.00 497.00
C 245.37 501.55 250.73 514.25 249.00 522.00 C 249.65 530.51 247.69 540.24 250.00 548.00 Z'
          />
        </g>
        <g fill='#757575' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 337.00 528.00 
C 335.24 525.35 332.62 522.89 330.00 521.00
C 331.76 523.65 334.38 526.11 337.00 528.00 Z'
          />
        </g>
        <g fill='#777777' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 628.00 529.00 
C 630.65 527.24 633.11 524.62 635.00 522.00
C 632.35 523.76 629.89 526.38 628.00 529.00 Z'
          />
        </g>
      </svg>
    </div>
  );
};

export default FlyingWildHog;
