const SpeakersAvenue = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 775 307'
        width='100%'
        height='100%'
      >
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 300.00 
C 19.12 286.92 20.71 262.08 29.98 241.98 C 39.24 221.87 46.69 198.66 55.00 178.00
C 67.20 173.63 70.67 183.19 73.32 191.68 C 75.98 200.17 79.67 206.55 82.32 214.68 C 84.97 222.80 88.79 229.66 91.32 237.68 C 93.86 245.70 97.91 252.64 100.31 260.69 C 102.70 268.75 107.32 275.39 109.31 283.69 C 111.29 292.00 118.70 297.75 122.00 304.00
C 104.00 304.00 86.00 304.00 68.00 304.00
C 69.05 298.23 82.68 298.73 79.07 289.93 C 75.46 281.12 74.89 278.15 72.07 269.93 C 69.26 261.70 67.98 255.48 58.00 257.00 C 48.02 258.52 40.73 255.86 31.00 257.00
C 30.20 263.40 27.00 267.88 25.08 274.08 C 23.15 280.27 21.10 286.27 22.93 293.07 C 24.76 299.88 36.21 297.29 36.00 304.00
C 24.00 304.00 12.00 304.00 0.00 304.00
L 0.00 307.00
L 237.00 307.00
C 212.10 303.91 202.07 280.27 203.00 257.00 C 203.93 233.73 214.51 213.34 240.01 210.01 C 265.51 206.68 283.79 229.99 280.00 255.00
C 270.08 254.40 259.90 255.80 250.00 255.00 C 240.10 254.20 228.33 253.46 231.00 264.00 C 233.67 274.55 232.76 278.10 239.25 285.75 C 245.75 293.39 254.29 290.81 262.23 289.23 C 270.16 287.65 272.41 276.71 280.00 278.00
C 276.96 292.51 264.21 304.63 250.00 307.00
L 420.00 307.00
C 414.37 304.81 408.35 303.40 405.08 296.92 C 401.82 290.43 401.63 282.92 402.00 275.00 C 402.37 267.08 401.49 258.27 402.00 250.00 C 402.51 241.73 401.42 234.31 401.01 225.99 C 400.59 217.67 389.72 219.10 390.00 212.00
C 402.55 214.12 415.85 212.66 428.00 210.00
C 428.01 218.58 427.98 227.45 428.00 236.00 C 428.02 244.55 427.97 253.55 428.00 262.00 C 428.03 270.45 427.78 279.55 428.33 287.67 C 428.88 295.79 438.57 298.56 444.69 294.69 C 450.82 290.82 453.58 286.39 454.78 278.78 C 455.98 271.17 454.10 261.44 455.00 253.00 C 455.90 244.56 453.82 236.64 454.22 227.78 C 454.62 218.92 443.59 218.39 443.00 212.00
C 455.55 214.12 468.85 212.66 481.00 210.00
C 480.88 218.72 481.16 227.26 481.00 236.00 C 480.84 244.74 481.32 253.19 481.00 262.00 C 480.68 270.81 481.68 279.03 481.00 288.00 C 480.32 296.97 490.47 298.04 492.00 304.00
C 479.38 303.56 467.08 304.25 455.00 306.00
C 454.36 301.83 456.29 296.34 454.00 293.00
C 450.32 301.53 440.68 304.98 433.00 307.00
L 533.00 307.00
C 508.35 303.81 497.90 280.26 499.00 257.00 C 500.10 233.74 510.49 213.41 536.01 210.01 C 561.52 206.60 580.05 229.80 576.00 255.00
C 559.67 255.00 543.33 255.00 527.00 255.00
C 526.08 265.20 527.74 273.69 532.77 282.23 C 537.80 290.77 547.24 291.74 556.22 290.22 C 565.20 288.70 568.05 277.54 576.00 278.00
C 573.62 292.39 560.47 303.82 547.00 307.00
L 775.00 307.00
L 775.00 28.00
C 772.07 37.18 765.32 47.52 755.00 50.00
C 752.77 41.44 766.35 36.69 765.00 27.00
C 747.24 36.52 733.23 7.35 752.00 1.00
C 752.16 0.94 752.00 0.00 752.00 0.00
L 63.00 0.00
C 69.30 3.74 72.70 1.38 79.23 6.77 C 85.76 12.17 87.03 1.70 93.00 2.00
C 93.35 15.83 91.23 29.06 92.00 43.00
C 83.62 42.55 86.51 27.94 81.23 21.77 C 75.94 15.60 71.47 8.99 62.78 7.22 C 54.08 5.46 43.91 8.90 40.08 17.08 C 36.26 25.27 41.48 34.80 47.75 40.25 C 54.02 45.70 60.50 49.24 67.08 53.92 C 73.66 58.60 80.23 62.20 86.23 67.77 C 92.23 73.34 97.17 79.87 98.78 88.22 C 100.39 96.58 98.85 108.33 93.23 115.23 C 87.61 122.12 81.44 125.40 72.98 127.98 C 64.51 130.55 53.13 131.05 44.00 130.00 C 34.87 128.95 27.79 123.77 19.00 122.00
C 18.35 125.74 17.09 130.57 12.00 129.00
C 13.00 113.18 13.21 97.13 13.00 81.00
C 21.69 81.47 18.31 95.87 22.32 102.68 C 26.33 109.49 28.34 116.18 36.08 119.92 C 43.82 123.66 53.35 124.40 61.23 120.23 C 69.11 116.06 72.08 108.02 70.02 99.98 C 67.97 91.93 63.09 87.02 56.75 82.25 C 50.41 77.48 45.10 73.65 38.75 69.25 C 32.40 64.85 26.51 62.01 21.25 55.75 C 15.99 49.49 13.13 41.41 13.00 33.00 C 12.87 24.59 15.42 16.61 21.75 10.75 C 28.08 4.89 35.23 1.04 44.00 1.00
C 44.01 1.00 44.00 0.00 44.00 0.00
L 0.00 0.00
L 0.00 300.00
M 431.00 1.00 
C 431.00 26.33 431.00 51.67 431.00 77.00
C 442.96 74.03 453.63 61.14 459.92 50.92 C 466.22 40.71 443.46 43.89 447.00 36.00
C 461.00 36.00 475.00 36.00 489.00 36.00
C 488.46 43.06 477.05 44.30 471.75 49.75 C 466.45 55.21 461.22 59.49 456.00 65.00
C 459.37 72.50 464.51 78.44 468.25 85.75 C 472.00 93.05 477.42 99.57 480.92 107.08 C 484.42 114.59 492.31 120.66 494.00 128.00
C 479.69 127.06 466.40 127.65 452.00 128.00
C 450.67 123.54 453.69 122.95 457.00 122.00
C 455.28 114.48 450.08 106.52 446.23 99.77 C 442.38 93.02 439.64 82.95 431.00 82.00
C 431.01 88.30 430.98 94.71 431.00 101.00 C 431.02 107.29 430.81 112.69 432.33 118.67 C 433.85 124.66 444.44 120.53 442.00 128.00
C 425.67 127.24 410.37 127.42 394.00 128.00
C 392.93 120.64 405.14 123.59 404.93 114.93 C 404.71 106.26 405.10 98.44 405.00 90.00 C 404.90 81.56 404.92 74.38 405.00 66.00 C 405.08 57.62 404.71 49.46 405.00 41.00 C 405.29 32.54 404.40 25.99 404.67 17.33 C 404.94 8.67 393.69 9.87 394.00 3.00
C 406.40 3.88 418.83 3.48 431.00 1.00
M 145.00 126.00 
C 144.94 134.32 144.33 141.49 146.78 149.22 C 149.23 156.96 162.14 151.47 161.00 160.00
C 143.08 159.62 125.83 158.60 108.00 160.00
C 107.34 152.78 119.00 155.57 118.78 146.78 C 118.56 137.99 119.17 130.51 119.00 122.00 C 118.83 113.49 118.88 106.43 119.00 98.00 C 119.12 89.57 118.56 81.52 119.00 73.00 C 119.44 64.48 118.28 58.56 118.02 49.98 C 117.76 41.39 107.12 42.86 107.00 36.00
C 119.82 35.90 132.84 36.77 145.00 34.00
C 145.00 38.67 145.00 43.33 145.00 48.00
C 154.69 30.35 184.16 27.32 195.75 45.25 C 207.34 63.17 206.40 90.51 197.69 109.69 C 188.99 128.88 162.88 136.49 145.00 126.00
M 294.00 79.00 
C 284.01 78.47 273.99 79.71 264.00 79.00 C 254.01 78.29 242.50 77.30 245.00 88.00 C 247.49 98.70 246.84 101.78 253.75 109.25 C 260.66 116.72 267.79 114.83 276.07 113.07 C 284.36 111.32 286.26 100.18 294.00 102.00
C 288.06 123.75 266.34 136.30 243.99 129.01 C 221.64 121.72 214.41 96.25 217.00 74.00 C 219.59 51.75 234.23 34.06 258.07 33.07 C 281.92 32.09 297.39 56.07 294.00 79.00
M 354.00 117.00 
C 344.20 131.79 310.40 137.11 306.22 113.78 C 302.04 90.45 325.31 82.13 344.23 76.23 C 363.15 70.33 354.08 23.31 329.00 41.00
C 335.01 44.48 340.10 51.16 338.00 59.00 C 335.90 66.84 328.00 70.10 320.33 68.67 C 312.66 67.24 309.29 58.67 310.78 51.78 C 312.27 44.89 317.33 39.54 324.23 37.23 C 331.12 34.92 340.52 32.73 348.00 33.00 C 355.49 33.28 364.17 34.17 370.25 38.75 C 376.32 43.34 380.40 48.20 379.99 57.01 C 379.58 65.82 381.29 75.12 380.00 85.00 C 378.71 94.88 383.03 101.70 380.00 113.00 C 376.97 124.30 393.03 117.26 391.64 122.70 C 390.26 128.13 375.58 129.06 370.00 129.00 C 364.42 128.94 353.18 125.69 354.00 117.00
M 574.00 79.00 
C 557.67 79.00 541.33 79.00 525.00 79.00
C 523.59 89.25 526.81 99.04 531.77 107.23 C 536.73 115.42 551.17 116.75 557.69 112.69 C 564.22 108.63 574.68 96.89 572.69 106.69 C 570.71 116.50 563.34 121.00 556.25 126.25 C 549.17 131.51 535.68 131.71 527.33 129.67 C 518.98 127.63 510.81 123.22 505.25 115.75 C 499.68 108.29 497.66 97.54 497.00 88.00 C 496.34 78.46 497.64 65.88 501.32 57.32 C 505.01 48.76 510.58 43.10 518.70 38.70 C 526.81 34.29 537.80 32.39 547.00 34.00 C 556.21 35.60 563.29 40.60 567.92 49.08 C 572.56 57.55 574.93 68.77 574.00 79.00
M 637.00 43.00 
C 637.10 42.85 636.34 42.01 636.00 42.00
C 617.12 41.67 618.29 72.09 619.00 84.00 C 619.71 95.91 618.51 102.68 619.00 114.00 C 619.49 125.32 635.78 118.81 635.00 128.00
C 616.65 127.40 599.34 127.24 581.00 128.00
C 578.71 120.10 591.70 124.80 591.92 116.92 C 592.14 109.05 591.92 101.80 592.00 94.00 C 592.08 86.20 591.90 78.82 592.00 71.00 C 592.10 63.18 591.96 56.20 591.67 48.33 C 591.38 40.46 579.47 43.37 581.00 36.00
C 593.85 36.01 606.80 36.61 619.00 34.00
C 619.64 38.17 617.71 43.66 620.00 47.00
C 624.36 36.69 641.86 28.54 651.68 35.32 C 661.50 42.10 663.17 61.71 649.77 66.77 C 636.37 71.83 621.98 52.40 637.00 43.00
M 734.00 34.00 
C 731.82 44.19 733.56 55.40 733.00 66.00
C 725.48 66.39 726.74 56.13 723.69 50.31 C 720.64 44.48 715.81 39.90 709.33 38.67 C 702.84 37.45 694.78 41.22 694.22 48.22 C 693.66 55.23 699.14 60.09 704.08 63.92 C 709.02 67.75 713.71 71.46 718.75 75.25 C 723.80 79.03 727.59 82.94 732.25 87.75 C 736.91 92.56 738.01 102.32 737.00 109.00 C 735.99 115.68 731.91 120.58 726.30 124.30 C 720.70 128.03 713.54 130.59 706.33 130.33 C 699.12 130.07 692.57 131.69 685.77 126.23 C 678.97 120.77 675.19 127.61 669.00 129.00
C 671.30 117.50 669.36 104.92 670.00 93.00
C 678.09 92.12 675.39 104.24 678.32 109.68 C 681.26 115.12 684.01 121.40 690.23 123.77 C 696.45 126.14 704.79 126.16 708.75 119.75 C 712.71 113.34 708.24 105.51 704.25 100.75 C 700.25 95.99 695.66 93.08 690.92 89.08 C 686.17 85.08 681.37 81.53 677.25 76.75 C 673.13 71.98 670.13 65.42 670.00 59.00 C 669.88 52.58 672.30 44.81 677.75 40.75 C 683.20 36.69 688.96 33.90 696.00 34.00 C 703.04 34.10 710.67 31.52 717.68 36.32 C 724.68 41.13 727.46 36.54 734.00 34.00
M 325.00 210.00 
C 325.64 214.17 323.71 219.66 326.00 223.00
C 328.37 217.22 333.36 214.44 339.08 212.08 C 344.79 209.72 354.18 209.10 360.67 210.33 C 367.16 211.56 372.46 214.24 375.30 220.70 C 378.15 227.15 378.50 234.12 378.00 242.00 C 377.50 249.88 378.67 258.76 378.00 267.00 C 377.33 275.24 378.99 281.80 378.78 290.22 C 378.57 298.65 388.59 298.14 390.00 304.00
C 374.00 304.00 358.00 304.00 342.00 304.00
C 342.02 297.87 352.47 298.88 351.98 290.98 C 351.48 283.07 352.26 274.68 352.00 267.00 C 351.74 259.32 352.17 250.24 352.00 243.00 C 351.83 235.76 353.30 227.72 348.25 222.75 C 343.20 217.79 336.87 219.90 331.92 223.92 C 326.97 227.94 325.60 234.06 325.00 241.00 C 324.41 247.94 325.78 257.28 325.00 265.00 C 324.22 272.72 326.49 280.70 325.00 289.00 C 323.51 297.30 332.47 298.52 334.00 304.00
C 318.33 304.00 302.67 304.00 287.00 304.00
C 289.97 297.94 299.23 297.27 298.00 287.00 C 296.77 276.73 298.11 268.08 298.00 258.00 C 297.89 247.92 296.94 240.28 298.00 230.00 C 299.06 219.73 288.58 219.00 287.00 212.00
C 299.55 214.12 312.86 212.66 325.00 210.00
M 158.00 212.00 
C 160.42 218.98 144.54 216.33 147.33 223.67 C 150.13 231.01 150.75 235.20 153.32 241.68 C 155.89 248.15 157.41 253.17 159.78 259.22 C 162.14 265.27 162.08 272.46 167.00 276.00
C 168.32 270.14 170.25 265.08 172.32 259.32 C 174.39 253.56 176.18 249.43 178.32 243.32 C 180.46 237.22 180.97 231.82 180.07 224.93 C 179.18 218.04 169.22 218.55 170.00 212.00
C 180.00 213.13 191.03 213.87 201.00 212.00
C 200.03 219.30 191.34 222.44 189.68 230.68 C 188.02 238.92 183.41 245.03 181.23 253.23 C 179.05 261.43 176.02 267.87 173.22 276.22 C 170.43 284.57 168.17 290.06 165.22 299.22 C 162.28 308.39 149.72 306.04 148.02 296.98 C 146.33 287.91 140.99 283.30 139.02 274.98 C 137.06 266.65 132.01 261.06 130.07 252.93 C 128.14 244.79 122.73 239.25 121.08 230.92 C 119.42 222.60 110.70 218.42 109.00 212.00
C 124.88 213.49 142.13 213.68 158.00 212.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.38'
          strokeWidth='0.3'
        >
          <path
            d='
M 44.00 1.00 
C 45.99 0.91 48.01 1.00 50.00 1.00
C 49.90 0.98 50.00 0.00 50.00 0.00
L 44.00 0.00
C 44.00 0.00 44.01 1.00 44.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.75'
          strokeWidth='0.3'
        >
          <path
            d='
M 50.00 1.00 
C 52.00 1.00 54.00 1.04 56.00 1.00
C 56.10 0.98 56.00 0.00 56.00 0.00
L 50.00 0.00
C 50.00 0.00 49.90 0.98 50.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.45'
          strokeWidth='0.3'
        >
          <path
            d='
M 56.00 1.00 
C 57.89 0.96 60.92 1.75 62.00 0.00
L 56.00 0.00
C 56.00 0.00 56.10 0.98 56.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 56.00 1.00 
C 54.00 1.04 52.00 1.00 50.00 1.00
C 48.01 1.00 45.99 0.91 44.00 1.00
C 35.23 1.04 28.08 4.89 21.75 10.75 C 15.42 16.61 12.87 24.59 13.00 33.00 C 13.13 41.41 15.99 49.49 21.25 55.75 C 26.51 62.01 32.40 64.85 38.75 69.25 C 45.10 73.65 50.41 77.48 56.75 82.25 C 63.09 87.02 67.97 91.93 70.02 99.98 C 72.08 108.02 69.11 116.06 61.23 120.23 C 53.35 124.40 43.82 123.66 36.08 119.92 C 28.34 116.18 26.33 109.49 22.32 102.68 C 18.31 95.87 21.69 81.47 13.00 81.00
C 13.21 97.13 13.00 113.18 12.00 129.00
C 17.09 130.57 18.35 125.74 19.00 122.00
C 27.79 123.77 34.87 128.95 44.00 130.00 C 53.13 131.05 64.51 130.55 72.98 127.98 C 81.44 125.40 87.61 122.12 93.23 115.23 C 98.85 108.33 100.39 96.58 98.78 88.22 C 97.17 79.87 92.23 73.34 86.23 67.77 C 80.23 62.20 73.66 58.60 67.08 53.92 C 60.50 49.24 54.02 45.70 47.75 40.25 C 41.48 34.80 36.26 25.27 40.08 17.08 C 43.91 8.90 54.08 5.46 62.78 7.22 C 71.47 8.99 75.94 15.60 81.23 21.77 C 86.51 27.94 83.62 42.55 92.00 43.00
C 91.23 29.06 93.35 15.83 93.00 2.00
C 87.03 1.70 85.76 12.17 79.23 6.77 C 72.70 1.38 69.30 3.74 63.00 0.00
L 62.00 0.00
C 60.92 1.75 57.89 0.96 56.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.58'
          strokeWidth='0.3'
        >
          <path
            d='
M 752.00 1.00 
C 755.42 1.31 760.38 1.58 763.00 0.00
L 752.00 0.00
C 752.00 0.00 752.16 0.94 752.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.93'
          strokeWidth='0.3'
        >
          <path
            d='
M 752.00 1.00 
C 733.23 7.35 747.24 36.52 765.00 27.00
C 766.35 36.69 752.77 41.44 755.00 50.00
C 765.32 47.52 772.07 37.18 775.00 28.00
L 775.00 27.00
C 773.57 26.46 774.05 24.26 774.00 23.00
C 773.95 21.67 774.00 20.33 774.00 19.00
C 774.00 17.67 774.06 16.33 774.00 15.00
C 773.72 8.49 769.99 2.40 764.00 0.00
L 763.00 0.00
C 760.38 1.58 755.42 1.31 752.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 774.00 15.00 
C 774.13 14.78 775.00 15.00 775.00 15.00
L 775.00 0.00
L 764.00 0.00
C 769.99 2.40 773.72 8.49 774.00 15.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 431.00 1.00 
C 418.83 3.48 406.40 3.88 394.00 3.00
C 393.69 9.87 404.94 8.67 404.67 17.33 C 404.40 25.99 405.29 32.54 405.00 41.00 C 404.71 49.46 405.08 57.62 405.00 66.00 C 404.92 74.38 404.90 81.56 405.00 90.00 C 405.10 98.44 404.71 106.26 404.93 114.93 C 405.14 123.59 392.93 120.64 394.00 128.00
C 410.37 127.42 425.67 127.24 442.00 128.00
C 444.44 120.53 433.85 124.66 432.33 118.67 C 430.81 112.69 431.02 107.29 431.00 101.00 C 430.98 94.71 431.01 88.30 431.00 82.00
C 439.64 82.95 442.38 93.02 446.23 99.77 C 450.08 106.52 455.28 114.48 457.00 122.00
C 453.69 122.95 450.67 123.54 452.00 128.00
C 466.40 127.65 479.69 127.06 494.00 128.00
C 492.31 120.66 484.42 114.59 480.92 107.08 C 477.42 99.57 472.00 93.05 468.25 85.75 C 464.51 78.44 459.37 72.50 456.00 65.00
C 461.22 59.49 466.45 55.21 471.75 49.75 C 477.05 44.30 488.46 43.06 489.00 36.00
C 475.00 36.00 461.00 36.00 447.00 36.00
C 443.46 43.89 466.22 40.71 459.92 50.92 C 453.63 61.14 442.96 74.03 431.00 77.00
C 431.00 51.67 431.00 26.33 431.00 1.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.42'
          strokeWidth='0.3'
        >
          <path
            d='
M 774.00 19.00 
C 774.13 18.78 775.00 19.00 775.00 19.00
L 775.00 15.00
C 775.00 15.00 774.13 14.78 774.00 15.00
C 774.06 16.33 774.00 17.67 774.00 19.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.75'
          strokeWidth='0.3'
        >
          <path
            d='
M 774.00 23.00 
C 774.13 23.22 775.00 23.00 775.00 23.00
L 775.00 19.00
C 775.00 19.00 774.13 18.78 774.00 19.00
C 774.00 20.33 773.95 21.67 774.00 23.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.47'
          strokeWidth='0.3'
        >
          <path
            d='
M 775.00 27.00 
L 775.00 23.00
C 775.00 23.00 774.13 23.22 774.00 23.00
C 774.05 24.26 773.57 26.46 775.00 27.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.96'
          strokeWidth='0.3'
        >
          <path
            d='
M 145.00 126.00 
C 162.88 136.49 188.99 128.88 197.69 109.69 C 206.40 90.51 207.34 63.17 195.75 45.25 C 184.16 27.32 154.69 30.35 145.00 48.00
C 145.00 43.33 145.00 38.67 145.00 34.00
C 132.84 36.77 119.82 35.90 107.00 36.00
C 107.12 42.86 117.76 41.39 118.02 49.98 C 118.28 58.56 119.44 64.48 119.00 73.00 C 118.56 81.52 119.12 89.57 119.00 98.00 C 118.88 106.43 118.83 113.49 119.00 122.00 C 119.17 130.51 118.56 137.99 118.78 146.78 C 119.00 155.57 107.34 152.78 108.00 160.00
C 125.83 158.60 143.08 159.62 161.00 160.00
C 162.14 151.47 149.23 156.96 146.78 149.22 C 144.33 141.49 144.94 134.32 145.00 126.00
M 165.00 44.00 
C 176.89 47.61 175.28 66.25 176.00 77.00 C 176.72 87.75 176.06 104.59 170.08 115.08 C 164.09 125.56 144.54 125.73 145.00 112.00 C 145.46 98.27 145.79 80.22 145.00 68.00 C 144.21 55.78 151.05 39.77 165.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 294.00 79.00 
C 297.39 56.07 281.92 32.09 258.07 33.07 C 234.23 34.06 219.59 51.75 217.00 74.00 C 214.41 96.25 221.64 121.72 243.99 129.01 C 266.34 136.30 288.06 123.75 294.00 102.00
C 286.26 100.18 284.36 111.32 276.07 113.07 C 267.79 114.83 260.66 116.72 253.75 109.25 C 246.84 101.78 247.49 98.70 245.00 88.00 C 242.50 77.30 254.01 78.29 264.00 79.00 C 273.99 79.71 284.01 78.47 294.00 79.00
M 271.00 69.00 
C 262.00 69.00 253.00 69.00 244.00 69.00
C 247.18 61.12 244.46 40.58 257.23 38.23 C 270.00 35.88 271.68 60.83 271.00 69.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 354.00 117.00 
C 353.18 125.69 364.42 128.94 370.00 129.00 C 375.58 129.06 390.26 128.13 391.64 122.70 C 393.03 117.26 376.97 124.30 380.00 113.00 C 383.03 101.70 378.71 94.88 380.00 85.00 C 381.29 75.12 379.58 65.82 379.99 57.01 C 380.40 48.20 376.32 43.34 370.25 38.75 C 364.17 34.17 355.49 33.28 348.00 33.00 C 340.52 32.73 331.12 34.92 324.23 37.23 C 317.33 39.54 312.27 44.89 310.78 51.78 C 309.29 58.67 312.66 67.24 320.33 68.67 C 328.00 70.10 335.90 66.84 338.00 59.00 C 340.10 51.16 335.01 44.48 329.00 41.00
C 354.08 23.31 363.15 70.33 344.23 76.23 C 325.31 82.13 302.04 90.45 306.22 113.78 C 310.40 137.11 344.20 131.79 354.00 117.00
M 354.00 78.00 
C 350.87 90.33 359.92 104.25 349.92 113.92 C 339.91 123.58 327.51 108.16 332.78 97.78 C 338.05 87.40 345.84 85.05 354.00 78.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 574.00 79.00 
C 574.93 68.77 572.56 57.55 567.92 49.08 C 563.29 40.60 556.21 35.60 547.00 34.00 C 537.80 32.39 526.81 34.29 518.70 38.70 C 510.58 43.10 505.01 48.76 501.32 57.32 C 497.64 65.88 496.34 78.46 497.00 88.00 C 497.66 97.54 499.68 108.29 505.25 115.75 C 510.81 123.22 518.98 127.63 527.33 129.67 C 535.68 131.71 549.17 131.51 556.25 126.25 C 563.34 121.00 570.71 116.50 572.69 106.69 C 574.68 96.89 564.22 108.63 557.69 112.69 C 551.17 116.75 536.73 115.42 531.77 107.23 C 526.81 99.04 523.59 89.25 525.00 79.00
C 541.33 79.00 557.67 79.00 574.00 79.00
M 551.00 69.00 
C 542.33 69.00 533.67 69.00 525.00 69.00
C 525.94 60.42 525.77 39.82 538.22 38.22 C 550.67 36.63 551.61 60.80 551.00 69.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.94'
          strokeWidth='0.3'
        >
          <path
            d='
M 636.00 42.00 
C 636.51 41.90 637.85 40.18 639.00 41.00
C 638.59 41.83 638.03 42.87 637.00 43.00
C 621.98 52.40 636.37 71.83 649.77 66.77 C 663.17 61.71 661.50 42.10 651.68 35.32 C 641.86 28.54 624.36 36.69 620.00 47.00
C 617.71 43.66 619.64 38.17 619.00 34.00
C 606.80 36.61 593.85 36.01 581.00 36.00
C 579.47 43.37 591.38 40.46 591.67 48.33 C 591.96 56.20 592.10 63.18 592.00 71.00 C 591.90 78.82 592.08 86.20 592.00 94.00 C 591.92 101.80 592.14 109.05 591.92 116.92 C 591.70 124.80 578.71 120.10 581.00 128.00
C 599.34 127.24 616.65 127.40 635.00 128.00
C 635.78 118.81 619.49 125.32 619.00 114.00 C 618.51 102.68 619.71 95.91 619.00 84.00 C 618.29 72.09 617.12 41.67 636.00 42.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.94'
          strokeWidth='0.3'
        >
          <path
            d='
M 734.00 34.00 
C 727.46 36.54 724.68 41.13 717.68 36.32 C 710.67 31.52 703.04 34.10 696.00 34.00 C 688.96 33.90 683.20 36.69 677.75 40.75 C 672.30 44.81 669.88 52.58 670.00 59.00 C 670.13 65.42 673.13 71.98 677.25 76.75 C 681.37 81.53 686.17 85.08 690.92 89.08 C 695.66 93.08 700.25 95.99 704.25 100.75 C 708.24 105.51 712.71 113.34 708.75 119.75 C 704.79 126.16 696.45 126.14 690.23 123.77 C 684.01 121.40 681.26 115.12 678.32 109.68 C 675.39 104.24 678.09 92.12 670.00 93.00
C 669.36 104.92 671.30 117.50 669.00 129.00
C 675.19 127.61 678.97 120.77 685.77 126.23 C 692.57 131.69 699.12 130.07 706.33 130.33 C 713.54 130.59 720.70 128.03 726.30 124.30 C 731.91 120.58 735.99 115.68 737.00 109.00 C 738.01 102.32 736.91 92.56 732.25 87.75 C 727.59 82.94 723.80 79.03 718.75 75.25 C 713.71 71.46 709.02 67.75 704.08 63.92 C 699.14 60.09 693.66 55.23 694.22 48.22 C 694.78 41.22 702.84 37.45 709.33 38.67 C 715.81 39.90 720.64 44.48 723.69 50.31 C 726.74 56.13 725.48 66.39 733.00 66.00
C 733.56 55.40 731.82 44.19 734.00 34.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 271.00 69.00 
C 271.68 60.83 270.00 35.88 257.23 38.23 C 244.46 40.58 247.18 61.12 244.00 69.00
C 253.00 69.00 262.00 69.00 271.00 69.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 551.00 69.00 
C 551.61 60.80 550.67 36.63 538.22 38.22 C 525.77 39.82 525.94 60.42 525.00 69.00
C 533.67 69.00 542.33 69.00 551.00 69.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.55'
          strokeWidth='0.3'
        >
          <path
            d='
M 636.00 42.00 
C 636.34 42.01 637.10 42.85 637.00 43.00
C 638.03 42.87 638.59 41.83 639.00 41.00
C 637.85 40.18 636.51 41.90 636.00 42.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 165.00 44.00 
C 151.05 39.77 144.21 55.78 145.00 68.00 C 145.79 80.22 145.46 98.27 145.00 112.00 C 144.54 125.73 164.09 125.56 170.08 115.08 C 176.06 104.59 176.72 87.75 176.00 77.00 C 175.28 66.25 176.89 47.61 165.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 354.00 78.00 
C 345.84 85.05 338.05 87.40 332.78 97.78 C 327.51 108.16 339.91 123.58 349.92 113.92 C 359.92 104.25 350.87 90.33 354.00 78.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.94'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 300.00 
L 0.00 304.00
C 12.00 304.00 24.00 304.00 36.00 304.00
C 36.21 297.29 24.76 299.88 22.93 293.07 C 21.10 286.27 23.15 280.27 25.08 274.08 C 27.00 267.88 30.20 263.40 31.00 257.00
C 40.73 255.86 48.02 258.52 58.00 257.00 C 67.98 255.48 69.26 261.70 72.07 269.93 C 74.89 278.15 75.46 281.12 79.07 289.93 C 82.68 298.73 69.05 298.23 68.00 304.00
C 86.00 304.00 104.00 304.00 122.00 304.00
C 118.70 297.75 111.29 292.00 109.31 283.69 C 107.32 275.39 102.70 268.75 100.31 260.69 C 97.91 252.64 93.86 245.70 91.32 237.68 C 88.79 229.66 84.97 222.80 82.32 214.68 C 79.67 206.55 75.98 200.17 73.32 191.68 C 70.67 183.19 67.20 173.63 55.00 178.00
C 46.69 198.66 39.24 221.87 29.98 241.98 C 20.71 262.08 19.12 286.92 0.00 300.00
M 64.00 247.00 
C 54.67 247.00 45.33 247.00 36.00 247.00
C 39.76 233.71 46.47 221.35 50.00 208.00
C 56.08 220.12 58.81 234.22 64.00 247.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 64.00 247.00 
C 58.81 234.22 56.08 220.12 50.00 208.00
C 46.47 221.35 39.76 233.71 36.00 247.00
C 45.33 247.00 54.67 247.00 64.00 247.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 237.00 307.00 
L 239.00 307.00
C 240.96 305.48 246.11 305.28 248.00 307.00
L 250.00 307.00
C 264.21 304.63 276.96 292.51 280.00 278.00
C 272.41 276.71 270.16 287.65 262.23 289.23 C 254.29 290.81 245.75 293.39 239.25 285.75 C 232.76 278.10 233.67 274.55 231.00 264.00 C 228.33 253.46 240.10 254.20 250.00 255.00 C 259.90 255.80 270.08 254.40 280.00 255.00
C 283.79 229.99 265.51 206.68 240.01 210.01 C 214.51 213.34 203.93 233.73 203.00 257.00 C 202.07 280.27 212.10 303.91 237.00 307.00
M 257.00 246.00 
C 248.00 246.00 239.00 246.00 230.00 246.00
C 233.51 237.52 229.83 217.65 243.08 215.08 C 256.32 212.50 257.70 237.21 257.00 246.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 325.00 210.00 
C 312.86 212.66 299.55 214.12 287.00 212.00
C 288.58 219.00 299.06 219.73 298.00 230.00 C 296.94 240.28 297.89 247.92 298.00 258.00 C 298.11 268.08 296.77 276.73 298.00 287.00 C 299.23 297.27 289.97 297.94 287.00 304.00
C 302.67 304.00 318.33 304.00 334.00 304.00
C 332.47 298.52 323.51 297.30 325.00 289.00 C 326.49 280.70 324.22 272.72 325.00 265.00 C 325.78 257.28 324.41 247.94 325.00 241.00 C 325.60 234.06 326.97 227.94 331.92 223.92 C 336.87 219.90 343.20 217.79 348.25 222.75 C 353.30 227.72 351.83 235.76 352.00 243.00 C 352.17 250.24 351.74 259.32 352.00 267.00 C 352.26 274.68 351.48 283.07 351.98 290.98 C 352.47 298.88 342.02 297.87 342.00 304.00
C 358.00 304.00 374.00 304.00 390.00 304.00
C 388.59 298.14 378.57 298.65 378.78 290.22 C 378.99 281.80 377.33 275.24 378.00 267.00 C 378.67 258.76 377.50 249.88 378.00 242.00 C 378.50 234.12 378.15 227.15 375.30 220.70 C 372.46 214.24 367.16 211.56 360.67 210.33 C 354.18 209.10 344.79 209.72 339.08 212.08 C 333.36 214.44 328.37 217.22 326.00 223.00
C 323.71 219.66 325.64 214.17 325.00 210.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.97'
          strokeWidth='0.3'
        >
          <path
            d='
M 420.00 307.00 
L 423.00 307.00
C 424.64 305.50 429.43 305.28 431.00 307.00
L 433.00 307.00
C 440.68 304.98 450.32 301.53 454.00 293.00
C 456.29 296.34 454.36 301.83 455.00 306.00
C 467.08 304.25 479.38 303.56 492.00 304.00
C 490.47 298.04 480.32 296.97 481.00 288.00 C 481.68 279.03 480.68 270.81 481.00 262.00 C 481.32 253.19 480.84 244.74 481.00 236.00 C 481.16 227.26 480.88 218.72 481.00 210.00
C 468.85 212.66 455.55 214.12 443.00 212.00
C 443.59 218.39 454.62 218.92 454.22 227.78 C 453.82 236.64 455.90 244.56 455.00 253.00 C 454.10 261.44 455.98 271.17 454.78 278.78 C 453.58 286.39 450.82 290.82 444.69 294.69 C 438.57 298.56 428.88 295.79 428.33 287.67 C 427.78 279.55 428.03 270.45 428.00 262.00 C 427.97 253.55 428.02 244.55 428.00 236.00 C 427.98 227.45 428.01 218.58 428.00 210.00
C 415.85 212.66 402.55 214.12 390.00 212.00
C 389.72 219.10 400.59 217.67 401.01 225.99 C 401.42 234.31 402.51 241.73 402.00 250.00 C 401.49 258.27 402.37 267.08 402.00 275.00 C 401.63 282.92 401.82 290.43 405.08 296.92 C 408.35 303.40 414.37 304.81 420.00 307.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 533.00 307.00 
L 536.00 307.00
C 537.64 305.50 542.43 305.28 544.00 307.00
L 547.00 307.00
C 560.47 303.82 573.62 292.39 576.00 278.00
C 568.05 277.54 565.20 288.70 556.22 290.22 C 547.24 291.74 537.80 290.77 532.77 282.23 C 527.74 273.69 526.08 265.20 527.00 255.00
C 543.33 255.00 559.67 255.00 576.00 255.00
C 580.05 229.80 561.52 206.60 536.01 210.01 C 510.49 213.41 500.10 233.74 499.00 257.00 C 497.90 280.26 508.35 303.81 533.00 307.00
M 553.00 246.00 
C 544.00 246.00 535.00 246.00 526.00 246.00
C 529.71 237.56 525.82 217.80 539.08 215.08 C 552.33 212.35 553.94 237.16 553.00 246.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.94'
          strokeWidth='0.3'
        >
          <path
            d='
M 158.00 212.00 
C 142.13 213.68 124.88 213.49 109.00 212.00
C 110.70 218.42 119.42 222.60 121.08 230.92 C 122.73 239.25 128.14 244.79 130.07 252.93 C 132.01 261.06 137.06 266.65 139.02 274.98 C 140.99 283.30 146.33 287.91 148.02 296.98 C 149.72 306.04 162.28 308.39 165.22 299.22 C 168.17 290.06 170.43 284.57 173.22 276.22 C 176.02 267.87 179.05 261.43 181.23 253.23 C 183.41 245.03 188.02 238.92 189.68 230.68 C 191.34 222.44 200.03 219.30 201.00 212.00
C 191.03 213.87 180.00 213.13 170.00 212.00
C 169.22 218.55 179.18 218.04 180.07 224.93 C 180.97 231.82 180.46 237.22 178.32 243.32 C 176.18 249.43 174.39 253.56 172.32 259.32 C 170.25 265.08 168.32 270.14 167.00 276.00
C 162.08 272.46 162.14 265.27 159.78 259.22 C 157.41 253.17 155.89 248.15 153.32 241.68 C 150.75 235.20 150.13 231.01 147.33 223.67 C 144.54 216.33 160.42 218.98 158.00 212.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 257.00 246.00 
C 257.70 237.21 256.32 212.50 243.08 215.08 C 229.83 217.65 233.51 237.52 230.00 246.00
C 239.00 246.00 248.00 246.00 257.00 246.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 553.00 246.00 
C 553.94 237.16 552.33 212.35 539.08 215.08 C 525.82 217.80 529.71 237.56 526.00 246.00
C 535.00 246.00 544.00 246.00 553.00 246.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.48'
          strokeWidth='0.3'
        >
          <path
            d='
M 239.00 307.00 
L 248.00 307.00
C 246.11 305.28 240.96 305.48 239.00 307.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.49'
          strokeWidth='0.3'
        >
          <path
            d='
M 423.00 307.00 
L 431.00 307.00
C 429.43 305.28 424.64 305.50 423.00 307.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FFFFFF'
          strokeOpacity='0.50'
          strokeWidth='0.3'
        >
          <path
            d='
M 536.00 307.00 
L 544.00 307.00
C 542.43 305.28 537.64 305.50 536.00 307.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 0.00 300.00 
C 19.12 286.92 20.71 262.08 29.98 241.98 C 39.24 221.87 46.69 198.66 55.00 178.00
C 67.20 173.63 70.67 183.19 73.32 191.68 C 75.98 200.17 79.67 206.55 82.32 214.68 C 84.97 222.80 88.79 229.66 91.32 237.68 C 93.86 245.70 97.91 252.64 100.31 260.69 C 102.70 268.75 107.32 275.39 109.31 283.69 C 111.29 292.00 118.70 297.75 122.00 304.00
C 104.00 304.00 86.00 304.00 68.00 304.00
C 69.05 298.23 82.68 298.73 79.07 289.93 C 75.46 281.12 74.89 278.15 72.07 269.93 C 69.26 261.70 67.98 255.48 58.00 257.00 C 48.02 258.52 40.73 255.86 31.00 257.00
C 30.20 263.40 27.00 267.88 25.08 274.08 C 23.15 280.27 21.10 286.27 22.93 293.07 C 24.76 299.88 36.21 297.29 36.00 304.00
C 24.00 304.00 12.00 304.00 0.00 304.00
L 0.00 307.00
L 237.00 307.00
C 212.10 303.91 202.07 280.27 203.00 257.00 C 203.93 233.73 214.51 213.34 240.01 210.01 C 265.51 206.68 283.79 229.99 280.00 255.00
C 270.08 254.40 259.90 255.80 250.00 255.00 C 240.10 254.20 228.33 253.46 231.00 264.00 C 233.67 274.55 232.76 278.10 239.25 285.75 C 245.75 293.39 254.29 290.81 262.23 289.23 C 270.16 287.65 272.41 276.71 280.00 278.00
C 276.96 292.51 264.21 304.63 250.00 307.00
L 420.00 307.00
C 414.37 304.81 408.35 303.40 405.08 296.92 C 401.82 290.43 401.63 282.92 402.00 275.00 C 402.37 267.08 401.49 258.27 402.00 250.00 C 402.51 241.73 401.42 234.31 401.01 225.99 C 400.59 217.67 389.72 219.10 390.00 212.00
C 402.55 214.12 415.85 212.66 428.00 210.00
C 428.01 218.58 427.98 227.45 428.00 236.00 C 428.02 244.55 427.97 253.55 428.00 262.00 C 428.03 270.45 427.78 279.55 428.33 287.67 C 428.88 295.79 438.57 298.56 444.69 294.69 C 450.82 290.82 453.58 286.39 454.78 278.78 C 455.98 271.17 454.10 261.44 455.00 253.00 C 455.90 244.56 453.82 236.64 454.22 227.78 C 454.62 218.92 443.59 218.39 443.00 212.00
C 455.55 214.12 468.85 212.66 481.00 210.00
C 480.88 218.72 481.16 227.26 481.00 236.00 C 480.84 244.74 481.32 253.19 481.00 262.00 C 480.68 270.81 481.68 279.03 481.00 288.00 C 480.32 296.97 490.47 298.04 492.00 304.00
C 479.38 303.56 467.08 304.25 455.00 306.00
C 454.36 301.83 456.29 296.34 454.00 293.00
C 450.32 301.53 440.68 304.98 433.00 307.00
L 533.00 307.00
C 508.35 303.81 497.90 280.26 499.00 257.00 C 500.10 233.74 510.49 213.41 536.01 210.01 C 561.52 206.60 580.05 229.80 576.00 255.00
C 559.67 255.00 543.33 255.00 527.00 255.00
C 526.08 265.20 527.74 273.69 532.77 282.23 C 537.80 290.77 547.24 291.74 556.22 290.22 C 565.20 288.70 568.05 277.54 576.00 278.00
C 573.62 292.39 560.47 303.82 547.00 307.00
L 775.00 307.00
L 775.00 28.00
C 772.07 37.18 765.32 47.52 755.00 50.00
C 752.77 41.44 766.35 36.69 765.00 27.00
C 747.24 36.52 733.23 7.35 752.00 1.00
C 752.16 0.94 752.00 0.00 752.00 0.00
L 63.00 0.00
C 69.30 3.74 72.70 1.38 79.23 6.77 C 85.76 12.17 87.03 1.70 93.00 2.00
C 93.35 15.83 91.23 29.06 92.00 43.00
C 83.62 42.55 86.51 27.94 81.23 21.77 C 75.94 15.60 71.47 8.99 62.78 7.22 C 54.08 5.46 43.91 8.90 40.08 17.08 C 36.26 25.27 41.48 34.80 47.75 40.25 C 54.02 45.70 60.50 49.24 67.08 53.92 C 73.66 58.60 80.23 62.20 86.23 67.77 C 92.23 73.34 97.17 79.87 98.78 88.22 C 100.39 96.58 98.85 108.33 93.23 115.23 C 87.61 122.12 81.44 125.40 72.98 127.98 C 64.51 130.55 53.13 131.05 44.00 130.00 C 34.87 128.95 27.79 123.77 19.00 122.00
C 18.35 125.74 17.09 130.57 12.00 129.00
C 13.00 113.18 13.21 97.13 13.00 81.00
C 21.69 81.47 18.31 95.87 22.32 102.68 C 26.33 109.49 28.34 116.18 36.08 119.92 C 43.82 123.66 53.35 124.40 61.23 120.23 C 69.11 116.06 72.08 108.02 70.02 99.98 C 67.97 91.93 63.09 87.02 56.75 82.25 C 50.41 77.48 45.10 73.65 38.75 69.25 C 32.40 64.85 26.51 62.01 21.25 55.75 C 15.99 49.49 13.13 41.41 13.00 33.00 C 12.87 24.59 15.42 16.61 21.75 10.75 C 28.08 4.89 35.23 1.04 44.00 1.00
C 44.01 1.00 44.00 0.00 44.00 0.00
L 0.00 0.00
L 0.00 300.00
M 431.00 1.00 
C 431.00 26.33 431.00 51.67 431.00 77.00
C 442.96 74.03 453.63 61.14 459.92 50.92 C 466.22 40.71 443.46 43.89 447.00 36.00
C 461.00 36.00 475.00 36.00 489.00 36.00
C 488.46 43.06 477.05 44.30 471.75 49.75 C 466.45 55.21 461.22 59.49 456.00 65.00
C 459.37 72.50 464.51 78.44 468.25 85.75 C 472.00 93.05 477.42 99.57 480.92 107.08 C 484.42 114.59 492.31 120.66 494.00 128.00
C 479.69 127.06 466.40 127.65 452.00 128.00
C 450.67 123.54 453.69 122.95 457.00 122.00
C 455.28 114.48 450.08 106.52 446.23 99.77 C 442.38 93.02 439.64 82.95 431.00 82.00
C 431.01 88.30 430.98 94.71 431.00 101.00 C 431.02 107.29 430.81 112.69 432.33 118.67 C 433.85 124.66 444.44 120.53 442.00 128.00
C 425.67 127.24 410.37 127.42 394.00 128.00
C 392.93 120.64 405.14 123.59 404.93 114.93 C 404.71 106.26 405.10 98.44 405.00 90.00 C 404.90 81.56 404.92 74.38 405.00 66.00 C 405.08 57.62 404.71 49.46 405.00 41.00 C 405.29 32.54 404.40 25.99 404.67 17.33 C 404.94 8.67 393.69 9.87 394.00 3.00
C 406.40 3.88 418.83 3.48 431.00 1.00
M 145.00 126.00 
C 144.94 134.32 144.33 141.49 146.78 149.22 C 149.23 156.96 162.14 151.47 161.00 160.00
C 143.08 159.62 125.83 158.60 108.00 160.00
C 107.34 152.78 119.00 155.57 118.78 146.78 C 118.56 137.99 119.17 130.51 119.00 122.00 C 118.83 113.49 118.88 106.43 119.00 98.00 C 119.12 89.57 118.56 81.52 119.00 73.00 C 119.44 64.48 118.28 58.56 118.02 49.98 C 117.76 41.39 107.12 42.86 107.00 36.00
C 119.82 35.90 132.84 36.77 145.00 34.00
C 145.00 38.67 145.00 43.33 145.00 48.00
C 154.69 30.35 184.16 27.32 195.75 45.25 C 207.34 63.17 206.40 90.51 197.69 109.69 C 188.99 128.88 162.88 136.49 145.00 126.00
M 294.00 79.00 
C 284.01 78.47 273.99 79.71 264.00 79.00 C 254.01 78.29 242.50 77.30 245.00 88.00 C 247.49 98.70 246.84 101.78 253.75 109.25 C 260.66 116.72 267.79 114.83 276.07 113.07 C 284.36 111.32 286.26 100.18 294.00 102.00
C 288.06 123.75 266.34 136.30 243.99 129.01 C 221.64 121.72 214.41 96.25 217.00 74.00 C 219.59 51.75 234.23 34.06 258.07 33.07 C 281.92 32.09 297.39 56.07 294.00 79.00
M 354.00 117.00 
C 344.20 131.79 310.40 137.11 306.22 113.78 C 302.04 90.45 325.31 82.13 344.23 76.23 C 363.15 70.33 354.08 23.31 329.00 41.00
C 335.01 44.48 340.10 51.16 338.00 59.00 C 335.90 66.84 328.00 70.10 320.33 68.67 C 312.66 67.24 309.29 58.67 310.78 51.78 C 312.27 44.89 317.33 39.54 324.23 37.23 C 331.12 34.92 340.52 32.73 348.00 33.00 C 355.49 33.28 364.17 34.17 370.25 38.75 C 376.32 43.34 380.40 48.20 379.99 57.01 C 379.58 65.82 381.29 75.12 380.00 85.00 C 378.71 94.88 383.03 101.70 380.00 113.00 C 376.97 124.30 393.03 117.26 391.64 122.70 C 390.26 128.13 375.58 129.06 370.00 129.00 C 364.42 128.94 353.18 125.69 354.00 117.00
M 574.00 79.00 
C 557.67 79.00 541.33 79.00 525.00 79.00
C 523.59 89.25 526.81 99.04 531.77 107.23 C 536.73 115.42 551.17 116.75 557.69 112.69 C 564.22 108.63 574.68 96.89 572.69 106.69 C 570.71 116.50 563.34 121.00 556.25 126.25 C 549.17 131.51 535.68 131.71 527.33 129.67 C 518.98 127.63 510.81 123.22 505.25 115.75 C 499.68 108.29 497.66 97.54 497.00 88.00 C 496.34 78.46 497.64 65.88 501.32 57.32 C 505.01 48.76 510.58 43.10 518.70 38.70 C 526.81 34.29 537.80 32.39 547.00 34.00 C 556.21 35.60 563.29 40.60 567.92 49.08 C 572.56 57.55 574.93 68.77 574.00 79.00
M 637.00 43.00 
C 637.10 42.85 636.34 42.01 636.00 42.00
C 617.12 41.67 618.29 72.09 619.00 84.00 C 619.71 95.91 618.51 102.68 619.00 114.00 C 619.49 125.32 635.78 118.81 635.00 128.00
C 616.65 127.40 599.34 127.24 581.00 128.00
C 578.71 120.10 591.70 124.80 591.92 116.92 C 592.14 109.05 591.92 101.80 592.00 94.00 C 592.08 86.20 591.90 78.82 592.00 71.00 C 592.10 63.18 591.96 56.20 591.67 48.33 C 591.38 40.46 579.47 43.37 581.00 36.00
C 593.85 36.01 606.80 36.61 619.00 34.00
C 619.64 38.17 617.71 43.66 620.00 47.00
C 624.36 36.69 641.86 28.54 651.68 35.32 C 661.50 42.10 663.17 61.71 649.77 66.77 C 636.37 71.83 621.98 52.40 637.00 43.00
M 734.00 34.00 
C 731.82 44.19 733.56 55.40 733.00 66.00
C 725.48 66.39 726.74 56.13 723.69 50.31 C 720.64 44.48 715.81 39.90 709.33 38.67 C 702.84 37.45 694.78 41.22 694.22 48.22 C 693.66 55.23 699.14 60.09 704.08 63.92 C 709.02 67.75 713.71 71.46 718.75 75.25 C 723.80 79.03 727.59 82.94 732.25 87.75 C 736.91 92.56 738.01 102.32 737.00 109.00 C 735.99 115.68 731.91 120.58 726.30 124.30 C 720.70 128.03 713.54 130.59 706.33 130.33 C 699.12 130.07 692.57 131.69 685.77 126.23 C 678.97 120.77 675.19 127.61 669.00 129.00
C 671.30 117.50 669.36 104.92 670.00 93.00
C 678.09 92.12 675.39 104.24 678.32 109.68 C 681.26 115.12 684.01 121.40 690.23 123.77 C 696.45 126.14 704.79 126.16 708.75 119.75 C 712.71 113.34 708.24 105.51 704.25 100.75 C 700.25 95.99 695.66 93.08 690.92 89.08 C 686.17 85.08 681.37 81.53 677.25 76.75 C 673.13 71.98 670.13 65.42 670.00 59.00 C 669.88 52.58 672.30 44.81 677.75 40.75 C 683.20 36.69 688.96 33.90 696.00 34.00 C 703.04 34.10 710.67 31.52 717.68 36.32 C 724.68 41.13 727.46 36.54 734.00 34.00
M 325.00 210.00 
C 325.64 214.17 323.71 219.66 326.00 223.00
C 328.37 217.22 333.36 214.44 339.08 212.08 C 344.79 209.72 354.18 209.10 360.67 210.33 C 367.16 211.56 372.46 214.24 375.30 220.70 C 378.15 227.15 378.50 234.12 378.00 242.00 C 377.50 249.88 378.67 258.76 378.00 267.00 C 377.33 275.24 378.99 281.80 378.78 290.22 C 378.57 298.65 388.59 298.14 390.00 304.00
C 374.00 304.00 358.00 304.00 342.00 304.00
C 342.02 297.87 352.47 298.88 351.98 290.98 C 351.48 283.07 352.26 274.68 352.00 267.00 C 351.74 259.32 352.17 250.24 352.00 243.00 C 351.83 235.76 353.30 227.72 348.25 222.75 C 343.20 217.79 336.87 219.90 331.92 223.92 C 326.97 227.94 325.60 234.06 325.00 241.00 C 324.41 247.94 325.78 257.28 325.00 265.00 C 324.22 272.72 326.49 280.70 325.00 289.00 C 323.51 297.30 332.47 298.52 334.00 304.00
C 318.33 304.00 302.67 304.00 287.00 304.00
C 289.97 297.94 299.23 297.27 298.00 287.00 C 296.77 276.73 298.11 268.08 298.00 258.00 C 297.89 247.92 296.94 240.28 298.00 230.00 C 299.06 219.73 288.58 219.00 287.00 212.00
C 299.55 214.12 312.86 212.66 325.00 210.00
M 158.00 212.00 
C 160.42 218.98 144.54 216.33 147.33 223.67 C 150.13 231.01 150.75 235.20 153.32 241.68 C 155.89 248.15 157.41 253.17 159.78 259.22 C 162.14 265.27 162.08 272.46 167.00 276.00
C 168.32 270.14 170.25 265.08 172.32 259.32 C 174.39 253.56 176.18 249.43 178.32 243.32 C 180.46 237.22 180.97 231.82 180.07 224.93 C 179.18 218.04 169.22 218.55 170.00 212.00
C 180.00 213.13 191.03 213.87 201.00 212.00
C 200.03 219.30 191.34 222.44 189.68 230.68 C 188.02 238.92 183.41 245.03 181.23 253.23 C 179.05 261.43 176.02 267.87 173.22 276.22 C 170.43 284.57 168.17 290.06 165.22 299.22 C 162.28 308.39 149.72 306.04 148.02 296.98 C 146.33 287.91 140.99 283.30 139.02 274.98 C 137.06 266.65 132.01 261.06 130.07 252.93 C 128.14 244.79 122.73 239.25 121.08 230.92 C 119.42 222.60 110.70 218.42 109.00 212.00
C 124.88 213.49 142.13 213.68 158.00 212.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.38' stroke='None'>
          <path
            d='
M 44.00 1.00 
C 45.99 0.91 48.01 1.00 50.00 1.00
C 49.90 0.98 50.00 0.00 50.00 0.00
L 44.00 0.00
C 44.00 0.00 44.01 1.00 44.00 1.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.75' stroke='None'>
          <path
            d='
M 50.00 1.00 
C 52.00 1.00 54.00 1.04 56.00 1.00
C 56.10 0.98 56.00 0.00 56.00 0.00
L 50.00 0.00
C 50.00 0.00 49.90 0.98 50.00 1.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.45' stroke='None'>
          <path
            d='
M 56.00 1.00 
C 57.89 0.96 60.92 1.75 62.00 0.00
L 56.00 0.00
C 56.00 0.00 56.10 0.98 56.00 1.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 56.00 1.00 
C 54.00 1.04 52.00 1.00 50.00 1.00
C 48.01 1.00 45.99 0.91 44.00 1.00
C 35.23 1.04 28.08 4.89 21.75 10.75 C 15.42 16.61 12.87 24.59 13.00 33.00 C 13.13 41.41 15.99 49.49 21.25 55.75 C 26.51 62.01 32.40 64.85 38.75 69.25 C 45.10 73.65 50.41 77.48 56.75 82.25 C 63.09 87.02 67.97 91.93 70.02 99.98 C 72.08 108.02 69.11 116.06 61.23 120.23 C 53.35 124.40 43.82 123.66 36.08 119.92 C 28.34 116.18 26.33 109.49 22.32 102.68 C 18.31 95.87 21.69 81.47 13.00 81.00
C 13.21 97.13 13.00 113.18 12.00 129.00
C 17.09 130.57 18.35 125.74 19.00 122.00
C 27.79 123.77 34.87 128.95 44.00 130.00 C 53.13 131.05 64.51 130.55 72.98 127.98 C 81.44 125.40 87.61 122.12 93.23 115.23 C 98.85 108.33 100.39 96.58 98.78 88.22 C 97.17 79.87 92.23 73.34 86.23 67.77 C 80.23 62.20 73.66 58.60 67.08 53.92 C 60.50 49.24 54.02 45.70 47.75 40.25 C 41.48 34.80 36.26 25.27 40.08 17.08 C 43.91 8.90 54.08 5.46 62.78 7.22 C 71.47 8.99 75.94 15.60 81.23 21.77 C 86.51 27.94 83.62 42.55 92.00 43.00
C 91.23 29.06 93.35 15.83 93.00 2.00
C 87.03 1.70 85.76 12.17 79.23 6.77 C 72.70 1.38 69.30 3.74 63.00 0.00
L 62.00 0.00
C 60.92 1.75 57.89 0.96 56.00 1.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.58' stroke='None'>
          <path
            d='
M 752.00 1.00 
C 755.42 1.31 760.38 1.58 763.00 0.00
L 752.00 0.00
C 752.00 0.00 752.16 0.94 752.00 1.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.93' stroke='None'>
          <path
            d='
M 752.00 1.00 
C 733.23 7.35 747.24 36.52 765.00 27.00
C 766.35 36.69 752.77 41.44 755.00 50.00
C 765.32 47.52 772.07 37.18 775.00 28.00
L 775.00 27.00
C 773.57 26.46 774.05 24.26 774.00 23.00
C 773.95 21.67 774.00 20.33 774.00 19.00
C 774.00 17.67 774.06 16.33 774.00 15.00
C 773.72 8.49 769.99 2.40 764.00 0.00
L 763.00 0.00
C 760.38 1.58 755.42 1.31 752.00 1.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 774.00 15.00 
C 774.13 14.78 775.00 15.00 775.00 15.00
L 775.00 0.00
L 764.00 0.00
C 769.99 2.40 773.72 8.49 774.00 15.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 431.00 1.00 
C 418.83 3.48 406.40 3.88 394.00 3.00
C 393.69 9.87 404.94 8.67 404.67 17.33 C 404.40 25.99 405.29 32.54 405.00 41.00 C 404.71 49.46 405.08 57.62 405.00 66.00 C 404.92 74.38 404.90 81.56 405.00 90.00 C 405.10 98.44 404.71 106.26 404.93 114.93 C 405.14 123.59 392.93 120.64 394.00 128.00
C 410.37 127.42 425.67 127.24 442.00 128.00
C 444.44 120.53 433.85 124.66 432.33 118.67 C 430.81 112.69 431.02 107.29 431.00 101.00 C 430.98 94.71 431.01 88.30 431.00 82.00
C 439.64 82.95 442.38 93.02 446.23 99.77 C 450.08 106.52 455.28 114.48 457.00 122.00
C 453.69 122.95 450.67 123.54 452.00 128.00
C 466.40 127.65 479.69 127.06 494.00 128.00
C 492.31 120.66 484.42 114.59 480.92 107.08 C 477.42 99.57 472.00 93.05 468.25 85.75 C 464.51 78.44 459.37 72.50 456.00 65.00
C 461.22 59.49 466.45 55.21 471.75 49.75 C 477.05 44.30 488.46 43.06 489.00 36.00
C 475.00 36.00 461.00 36.00 447.00 36.00
C 443.46 43.89 466.22 40.71 459.92 50.92 C 453.63 61.14 442.96 74.03 431.00 77.00
C 431.00 51.67 431.00 26.33 431.00 1.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.42' stroke='None'>
          <path
            d='
M 774.00 19.00 
C 774.13 18.78 775.00 19.00 775.00 19.00
L 775.00 15.00
C 775.00 15.00 774.13 14.78 774.00 15.00
C 774.06 16.33 774.00 17.67 774.00 19.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.75' stroke='None'>
          <path
            d='
M 774.00 23.00 
C 774.13 23.22 775.00 23.00 775.00 23.00
L 775.00 19.00
C 775.00 19.00 774.13 18.78 774.00 19.00
C 774.00 20.33 773.95 21.67 774.00 23.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.47' stroke='None'>
          <path
            d='
M 775.00 27.00 
L 775.00 23.00
C 775.00 23.00 774.13 23.22 774.00 23.00
C 774.05 24.26 773.57 26.46 775.00 27.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.96' stroke='None'>
          <path
            d='
M 145.00 126.00 
C 162.88 136.49 188.99 128.88 197.69 109.69 C 206.40 90.51 207.34 63.17 195.75 45.25 C 184.16 27.32 154.69 30.35 145.00 48.00
C 145.00 43.33 145.00 38.67 145.00 34.00
C 132.84 36.77 119.82 35.90 107.00 36.00
C 107.12 42.86 117.76 41.39 118.02 49.98 C 118.28 58.56 119.44 64.48 119.00 73.00 C 118.56 81.52 119.12 89.57 119.00 98.00 C 118.88 106.43 118.83 113.49 119.00 122.00 C 119.17 130.51 118.56 137.99 118.78 146.78 C 119.00 155.57 107.34 152.78 108.00 160.00
C 125.83 158.60 143.08 159.62 161.00 160.00
C 162.14 151.47 149.23 156.96 146.78 149.22 C 144.33 141.49 144.94 134.32 145.00 126.00
M 165.00 44.00 
C 176.89 47.61 175.28 66.25 176.00 77.00 C 176.72 87.75 176.06 104.59 170.08 115.08 C 164.09 125.56 144.54 125.73 145.00 112.00 C 145.46 98.27 145.79 80.22 145.00 68.00 C 144.21 55.78 151.05 39.77 165.00 44.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 294.00 79.00 
C 297.39 56.07 281.92 32.09 258.07 33.07 C 234.23 34.06 219.59 51.75 217.00 74.00 C 214.41 96.25 221.64 121.72 243.99 129.01 C 266.34 136.30 288.06 123.75 294.00 102.00
C 286.26 100.18 284.36 111.32 276.07 113.07 C 267.79 114.83 260.66 116.72 253.75 109.25 C 246.84 101.78 247.49 98.70 245.00 88.00 C 242.50 77.30 254.01 78.29 264.00 79.00 C 273.99 79.71 284.01 78.47 294.00 79.00
M 271.00 69.00 
C 262.00 69.00 253.00 69.00 244.00 69.00
C 247.18 61.12 244.46 40.58 257.23 38.23 C 270.00 35.88 271.68 60.83 271.00 69.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 354.00 117.00 
C 353.18 125.69 364.42 128.94 370.00 129.00 C 375.58 129.06 390.26 128.13 391.64 122.70 C 393.03 117.26 376.97 124.30 380.00 113.00 C 383.03 101.70 378.71 94.88 380.00 85.00 C 381.29 75.12 379.58 65.82 379.99 57.01 C 380.40 48.20 376.32 43.34 370.25 38.75 C 364.17 34.17 355.49 33.28 348.00 33.00 C 340.52 32.73 331.12 34.92 324.23 37.23 C 317.33 39.54 312.27 44.89 310.78 51.78 C 309.29 58.67 312.66 67.24 320.33 68.67 C 328.00 70.10 335.90 66.84 338.00 59.00 C 340.10 51.16 335.01 44.48 329.00 41.00
C 354.08 23.31 363.15 70.33 344.23 76.23 C 325.31 82.13 302.04 90.45 306.22 113.78 C 310.40 137.11 344.20 131.79 354.00 117.00
M 354.00 78.00 
C 350.87 90.33 359.92 104.25 349.92 113.92 C 339.91 123.58 327.51 108.16 332.78 97.78 C 338.05 87.40 345.84 85.05 354.00 78.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 574.00 79.00 
C 574.93 68.77 572.56 57.55 567.92 49.08 C 563.29 40.60 556.21 35.60 547.00 34.00 C 537.80 32.39 526.81 34.29 518.70 38.70 C 510.58 43.10 505.01 48.76 501.32 57.32 C 497.64 65.88 496.34 78.46 497.00 88.00 C 497.66 97.54 499.68 108.29 505.25 115.75 C 510.81 123.22 518.98 127.63 527.33 129.67 C 535.68 131.71 549.17 131.51 556.25 126.25 C 563.34 121.00 570.71 116.50 572.69 106.69 C 574.68 96.89 564.22 108.63 557.69 112.69 C 551.17 116.75 536.73 115.42 531.77 107.23 C 526.81 99.04 523.59 89.25 525.00 79.00
C 541.33 79.00 557.67 79.00 574.00 79.00
M 551.00 69.00 
C 542.33 69.00 533.67 69.00 525.00 69.00
C 525.94 60.42 525.77 39.82 538.22 38.22 C 550.67 36.63 551.61 60.80 551.00 69.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.94' stroke='None'>
          <path
            d='
M 636.00 42.00 
C 636.51 41.90 637.85 40.18 639.00 41.00
C 638.59 41.83 638.03 42.87 637.00 43.00
C 621.98 52.40 636.37 71.83 649.77 66.77 C 663.17 61.71 661.50 42.10 651.68 35.32 C 641.86 28.54 624.36 36.69 620.00 47.00
C 617.71 43.66 619.64 38.17 619.00 34.00
C 606.80 36.61 593.85 36.01 581.00 36.00
C 579.47 43.37 591.38 40.46 591.67 48.33 C 591.96 56.20 592.10 63.18 592.00 71.00 C 591.90 78.82 592.08 86.20 592.00 94.00 C 591.92 101.80 592.14 109.05 591.92 116.92 C 591.70 124.80 578.71 120.10 581.00 128.00
C 599.34 127.24 616.65 127.40 635.00 128.00
C 635.78 118.81 619.49 125.32 619.00 114.00 C 618.51 102.68 619.71 95.91 619.00 84.00 C 618.29 72.09 617.12 41.67 636.00 42.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.94' stroke='None'>
          <path
            d='
M 734.00 34.00 
C 727.46 36.54 724.68 41.13 717.68 36.32 C 710.67 31.52 703.04 34.10 696.00 34.00 C 688.96 33.90 683.20 36.69 677.75 40.75 C 672.30 44.81 669.88 52.58 670.00 59.00 C 670.13 65.42 673.13 71.98 677.25 76.75 C 681.37 81.53 686.17 85.08 690.92 89.08 C 695.66 93.08 700.25 95.99 704.25 100.75 C 708.24 105.51 712.71 113.34 708.75 119.75 C 704.79 126.16 696.45 126.14 690.23 123.77 C 684.01 121.40 681.26 115.12 678.32 109.68 C 675.39 104.24 678.09 92.12 670.00 93.00
C 669.36 104.92 671.30 117.50 669.00 129.00
C 675.19 127.61 678.97 120.77 685.77 126.23 C 692.57 131.69 699.12 130.07 706.33 130.33 C 713.54 130.59 720.70 128.03 726.30 124.30 C 731.91 120.58 735.99 115.68 737.00 109.00 C 738.01 102.32 736.91 92.56 732.25 87.75 C 727.59 82.94 723.80 79.03 718.75 75.25 C 713.71 71.46 709.02 67.75 704.08 63.92 C 699.14 60.09 693.66 55.23 694.22 48.22 C 694.78 41.22 702.84 37.45 709.33 38.67 C 715.81 39.90 720.64 44.48 723.69 50.31 C 726.74 56.13 725.48 66.39 733.00 66.00
C 733.56 55.40 731.82 44.19 734.00 34.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 271.00 69.00 
C 271.68 60.83 270.00 35.88 257.23 38.23 C 244.46 40.58 247.18 61.12 244.00 69.00
C 253.00 69.00 262.00 69.00 271.00 69.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 551.00 69.00 
C 551.61 60.80 550.67 36.63 538.22 38.22 C 525.77 39.82 525.94 60.42 525.00 69.00
C 533.67 69.00 542.33 69.00 551.00 69.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.55' stroke='None'>
          <path
            d='
M 636.00 42.00 
C 636.34 42.01 637.10 42.85 637.00 43.00
C 638.03 42.87 638.59 41.83 639.00 41.00
C 637.85 40.18 636.51 41.90 636.00 42.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 165.00 44.00 
C 151.05 39.77 144.21 55.78 145.00 68.00 C 145.79 80.22 145.46 98.27 145.00 112.00 C 144.54 125.73 164.09 125.56 170.08 115.08 C 176.06 104.59 176.72 87.75 176.00 77.00 C 175.28 66.25 176.89 47.61 165.00 44.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 354.00 78.00 
C 345.84 85.05 338.05 87.40 332.78 97.78 C 327.51 108.16 339.91 123.58 349.92 113.92 C 359.92 104.25 350.87 90.33 354.00 78.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.94' stroke='None'>
          <path
            d='
M 0.00 300.00 
L 0.00 304.00
C 12.00 304.00 24.00 304.00 36.00 304.00
C 36.21 297.29 24.76 299.88 22.93 293.07 C 21.10 286.27 23.15 280.27 25.08 274.08 C 27.00 267.88 30.20 263.40 31.00 257.00
C 40.73 255.86 48.02 258.52 58.00 257.00 C 67.98 255.48 69.26 261.70 72.07 269.93 C 74.89 278.15 75.46 281.12 79.07 289.93 C 82.68 298.73 69.05 298.23 68.00 304.00
C 86.00 304.00 104.00 304.00 122.00 304.00
C 118.70 297.75 111.29 292.00 109.31 283.69 C 107.32 275.39 102.70 268.75 100.31 260.69 C 97.91 252.64 93.86 245.70 91.32 237.68 C 88.79 229.66 84.97 222.80 82.32 214.68 C 79.67 206.55 75.98 200.17 73.32 191.68 C 70.67 183.19 67.20 173.63 55.00 178.00
C 46.69 198.66 39.24 221.87 29.98 241.98 C 20.71 262.08 19.12 286.92 0.00 300.00
M 64.00 247.00 
C 54.67 247.00 45.33 247.00 36.00 247.00
C 39.76 233.71 46.47 221.35 50.00 208.00
C 56.08 220.12 58.81 234.22 64.00 247.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 64.00 247.00 
C 58.81 234.22 56.08 220.12 50.00 208.00
C 46.47 221.35 39.76 233.71 36.00 247.00
C 45.33 247.00 54.67 247.00 64.00 247.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 237.00 307.00 
L 239.00 307.00
C 240.96 305.48 246.11 305.28 248.00 307.00
L 250.00 307.00
C 264.21 304.63 276.96 292.51 280.00 278.00
C 272.41 276.71 270.16 287.65 262.23 289.23 C 254.29 290.81 245.75 293.39 239.25 285.75 C 232.76 278.10 233.67 274.55 231.00 264.00 C 228.33 253.46 240.10 254.20 250.00 255.00 C 259.90 255.80 270.08 254.40 280.00 255.00
C 283.79 229.99 265.51 206.68 240.01 210.01 C 214.51 213.34 203.93 233.73 203.00 257.00 C 202.07 280.27 212.10 303.91 237.00 307.00
M 257.00 246.00 
C 248.00 246.00 239.00 246.00 230.00 246.00
C 233.51 237.52 229.83 217.65 243.08 215.08 C 256.32 212.50 257.70 237.21 257.00 246.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 325.00 210.00 
C 312.86 212.66 299.55 214.12 287.00 212.00
C 288.58 219.00 299.06 219.73 298.00 230.00 C 296.94 240.28 297.89 247.92 298.00 258.00 C 298.11 268.08 296.77 276.73 298.00 287.00 C 299.23 297.27 289.97 297.94 287.00 304.00
C 302.67 304.00 318.33 304.00 334.00 304.00
C 332.47 298.52 323.51 297.30 325.00 289.00 C 326.49 280.70 324.22 272.72 325.00 265.00 C 325.78 257.28 324.41 247.94 325.00 241.00 C 325.60 234.06 326.97 227.94 331.92 223.92 C 336.87 219.90 343.20 217.79 348.25 222.75 C 353.30 227.72 351.83 235.76 352.00 243.00 C 352.17 250.24 351.74 259.32 352.00 267.00 C 352.26 274.68 351.48 283.07 351.98 290.98 C 352.47 298.88 342.02 297.87 342.00 304.00
C 358.00 304.00 374.00 304.00 390.00 304.00
C 388.59 298.14 378.57 298.65 378.78 290.22 C 378.99 281.80 377.33 275.24 378.00 267.00 C 378.67 258.76 377.50 249.88 378.00 242.00 C 378.50 234.12 378.15 227.15 375.30 220.70 C 372.46 214.24 367.16 211.56 360.67 210.33 C 354.18 209.10 344.79 209.72 339.08 212.08 C 333.36 214.44 328.37 217.22 326.00 223.00
C 323.71 219.66 325.64 214.17 325.00 210.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.97' stroke='None'>
          <path
            d='
M 420.00 307.00 
L 423.00 307.00
C 424.64 305.50 429.43 305.28 431.00 307.00
L 433.00 307.00
C 440.68 304.98 450.32 301.53 454.00 293.00
C 456.29 296.34 454.36 301.83 455.00 306.00
C 467.08 304.25 479.38 303.56 492.00 304.00
C 490.47 298.04 480.32 296.97 481.00 288.00 C 481.68 279.03 480.68 270.81 481.00 262.00 C 481.32 253.19 480.84 244.74 481.00 236.00 C 481.16 227.26 480.88 218.72 481.00 210.00
C 468.85 212.66 455.55 214.12 443.00 212.00
C 443.59 218.39 454.62 218.92 454.22 227.78 C 453.82 236.64 455.90 244.56 455.00 253.00 C 454.10 261.44 455.98 271.17 454.78 278.78 C 453.58 286.39 450.82 290.82 444.69 294.69 C 438.57 298.56 428.88 295.79 428.33 287.67 C 427.78 279.55 428.03 270.45 428.00 262.00 C 427.97 253.55 428.02 244.55 428.00 236.00 C 427.98 227.45 428.01 218.58 428.00 210.00
C 415.85 212.66 402.55 214.12 390.00 212.00
C 389.72 219.10 400.59 217.67 401.01 225.99 C 401.42 234.31 402.51 241.73 402.00 250.00 C 401.49 258.27 402.37 267.08 402.00 275.00 C 401.63 282.92 401.82 290.43 405.08 296.92 C 408.35 303.40 414.37 304.81 420.00 307.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 533.00 307.00 
L 536.00 307.00
C 537.64 305.50 542.43 305.28 544.00 307.00
L 547.00 307.00
C 560.47 303.82 573.62 292.39 576.00 278.00
C 568.05 277.54 565.20 288.70 556.22 290.22 C 547.24 291.74 537.80 290.77 532.77 282.23 C 527.74 273.69 526.08 265.20 527.00 255.00
C 543.33 255.00 559.67 255.00 576.00 255.00
C 580.05 229.80 561.52 206.60 536.01 210.01 C 510.49 213.41 500.10 233.74 499.00 257.00 C 497.90 280.26 508.35 303.81 533.00 307.00
M 553.00 246.00 
C 544.00 246.00 535.00 246.00 526.00 246.00
C 529.71 237.56 525.82 217.80 539.08 215.08 C 552.33 212.35 553.94 237.16 553.00 246.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.94' stroke='None'>
          <path
            d='
M 158.00 212.00 
C 142.13 213.68 124.88 213.49 109.00 212.00
C 110.70 218.42 119.42 222.60 121.08 230.92 C 122.73 239.25 128.14 244.79 130.07 252.93 C 132.01 261.06 137.06 266.65 139.02 274.98 C 140.99 283.30 146.33 287.91 148.02 296.98 C 149.72 306.04 162.28 308.39 165.22 299.22 C 168.17 290.06 170.43 284.57 173.22 276.22 C 176.02 267.87 179.05 261.43 181.23 253.23 C 183.41 245.03 188.02 238.92 189.68 230.68 C 191.34 222.44 200.03 219.30 201.00 212.00
C 191.03 213.87 180.00 213.13 170.00 212.00
C 169.22 218.55 179.18 218.04 180.07 224.93 C 180.97 231.82 180.46 237.22 178.32 243.32 C 176.18 249.43 174.39 253.56 172.32 259.32 C 170.25 265.08 168.32 270.14 167.00 276.00
C 162.08 272.46 162.14 265.27 159.78 259.22 C 157.41 253.17 155.89 248.15 153.32 241.68 C 150.75 235.20 150.13 231.01 147.33 223.67 C 144.54 216.33 160.42 218.98 158.00 212.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 257.00 246.00 
C 257.70 237.21 256.32 212.50 243.08 215.08 C 229.83 217.65 233.51 237.52 230.00 246.00
C 239.00 246.00 248.00 246.00 257.00 246.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 553.00 246.00 
C 553.94 237.16 552.33 212.35 539.08 215.08 C 525.82 217.80 529.71 237.56 526.00 246.00
C 535.00 246.00 544.00 246.00 553.00 246.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.48' stroke='None'>
          <path
            d='
M 239.00 307.00 
L 248.00 307.00
C 246.11 305.28 240.96 305.48 239.00 307.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.49' stroke='None'>
          <path
            d='
M 423.00 307.00 
L 431.00 307.00
C 429.43 305.28 424.64 305.50 423.00 307.00 Z'
          />
        </g>
        <g fill='#FFFFFF' fillOpacity='0.50' stroke='None'>
          <path
            d='
M 536.00 307.00 
L 544.00 307.00
C 542.43 305.28 537.64 305.50 536.00 307.00 Z'
          />
        </g>
      </svg>
    </div>
  );
};

export default SpeakersAvenue;
