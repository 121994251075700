const GameDevelopersConference = () => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 216.22 75'
    >
      <g>
        <path
          d='M211.67,55.56a38.14,38.14,0,0,1-18.45,5c-6.77,0-12.22-2.23-16.33-6.78s-6.11-10.11-6.11-16.89c0-6.56,1.89-11.89,5.55-16,4.23-4.89,10.45-7.33,18.56-7.33A35.41,35.41,0,0,1,213,18.44l.33-14.66C208.33,1.22,201.89,0,194.11,0c-12.55,0-22.77,3.78-30.66,11.22-7.45,7.11-11.23,16.11-11.23,26.89s3.34,19.33,9.89,26.11C169,71.44,178.34,75,190.22,75a48.23,48.23,0,0,0,26-7.78Z'
          fill='#fff'
        />
        <path
          d='M68.56,36H41.07l.82,5.18,5.76,2.37c2.37,1,3.9,2,3.9,6.21h0V60.22a27,27,0,0,1-9.89,1.45,21.46,21.46,0,0,1-17-7.56,25.34,25.34,0,0,1-6.11-17.22c0-6.67,1.88-12,5.77-16.11,4.56-4.89,11.34-7.33,20.56-7.33a42.69,42.69,0,0,1,19.56,5l.33-15A74.25,74.25,0,0,0,42.33,0C29.78,0,19.67,3.67,11.78,11A35,35,0,0,0,0,38C0,48.56,3.22,57.33,9.78,64.22,16.56,71.44,25.78,75,37.22,75a66,66,0,0,0,31.34-7.44V36Z'
          fill='#fff'
        />
        <path
          d='M111.6.93H74.68V37.86A36.92,36.92,0,1,0,111.6.93Zm0,59.62a22.69,22.69,0,0,1-22.69-22.7V15.16H111.6a22.7,22.7,0,1,1,0,45.4Z'
          fill='#fff'
        />
      </g>
    </svg>
  );
};

export default GameDevelopersConference;
