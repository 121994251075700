import React from 'react';

const SDIMedia = () => {
  return (
    <svg
      version='1.1'
      id='LOGO_-_OUTLINED'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      viewBox='0 0 287.5 150.5'
    >
      <path
        d='M30.5 50.7c6.5 2.9 12.9 5.8 19.2 8.5v-.7c-11.9-4.8-19.2-7.8-19.2-7.8zm60.9 24.5h1.3c-3.1-1.2-6.1-2.3-9.1-3.5-.8.4-1.8.8-2.7 1l7.8 3.3c.7-.4 1.5-.8 2.7-.8zm7.8 2.5V79h-3.7c6.9 2.9 13.6 5.8 20 8.4v-3.8c-5.6-1.9-11.1-3.9-16.3-5.9zm20 11.4c.1 0 .1.1.2.1h3.7c1 0 1.2-.2 1.3-1.3V87c-1.7-.6-3.5-1.2-5.2-1.9v4zm115.1-1.4c52.5 50.6-30.8 27.4-106.1.6v.7c0 .2 0 .4-.1.7-.1 1.2-.6 2-1.2 2.5 252 104 107.4-4.5 107.4-4.5zM63.8 65.4c.9.4 1.7.8 2.6 1.1 1.8.6 3.8.9 6 .9-4.2-1.6-8.2-3.2-11.9-4.7 1 1.1 2.1 2 3.3 2.7zm-28.7-15c5 1.9 9.8 3.8 14.6 5.6v-.4c-9.1-3.2-14.6-5.2-14.6-5.2zm84.1 28.7v2.7c1.8.6 3.5 1.2 5.2 1.8v-3.4c-1.2-.4-2.5-.8-3.7-1.1 0-.1-1.5-.1-1.5 0z'
        fill='#f68d2e'
      />
      <path
        d='M128.1 84.9c5.7 2 11.2 3.9 16.4 5.6V86c-5.4-1.5-10.9-3.1-16.4-4.7v3.6zM201.8 69c40.3 34 10.7 33.7-34.1 23l-.3.9h-3.9l.6-1.8c-5.1-1.2-10.4-2.6-15.7-4.1v4.7C319.7 148.5 201.8 69 201.8 69zm-113.9-.3c-.4.5-.9.9-1.3 1.3 10.2 3.8 19.8 7.3 28.8 10.5v-3c-9.5-3-18.7-6-27.5-8.8zm-10.8-2.3c.5-.2.9-.6 1.3-.9-7.6-2.6-14.6-5-20.7-7.1.1.3.2.5.3.8 6.6 2.5 13 4.9 19.1 7.2z'
        fill='#f68d2e'
      />
      <path
        d='M106.6 71.6c-2.1-1.2-3.9-2.9-5.3-4.9-3.6-.9-7.2-1.8-10.7-2.6-.2.8-.6 1.6-1 2.3 5.9 1.8 11.6 3.6 17 5.2zM40.2 50.4C44.9 52 49.5 53.5 54 55h2.2c-9.8-2.8-16-4.6-16-4.6zm39.7 12.9c.2-.5.3-1 .3-1.6v-.3c-8.9-2.3-17-4.5-23.5-6.3.1.3.1.5.2.8 8 2.7 15.7 5.1 23 7.4zm88.2 15.1c-5.8-.3-12.5-1-19.7-2.1v7c6.2 1.6 11.9 2.9 17 4l2.7-8.9zm-23.5-2.7c-6.7-1.1-13.7-2.4-20.9-3.9-2.4 1.4-5.2 2.1-8.2 2.1-.5 0-1 0-1.5-.1l5.1 1.5h5.4c2.1 0 3.3.8 3.6 2.6 5.9 1.7 11.4 3.1 16.5 4.5v-6.7zm29.2-22.2v.4c21.5 17.6 17.2 24 .8 24.5l3.5 11.3c65.3 11.2 9-27.4-4.3-36.2z'
        fill='#f68d2e'
      />
      <path
        d='M52.2 91.4V76.8c0-2 1.3-3.4 3.3-3.4.3 0 .7.1 1 .1 1.2.2 2 1.1 2.2 2.2L60.9 86l2.4-10.2c.3-1.2 1.1-2 2.2-2.2.3-.1.7-.1 1-.1 2 0 3.3 1.4 3.3 3.4v14.6h-3.6V78.8l-2.7 10.7c-.1.4-.2.7-.4 1.1-.4.8-1.3 1.3-2.1 1.3-.6 0-1.2-.2-1.6-.7-.3-.3-.6-.9-.8-1.7l-2.7-10.7v12.7l-3.7-.1zm34-3.7V77.5c0-.3 0-.5.1-.8.3-2 1.5-3 3.6-3h7.8v3.8h-6.9c-.1 0-.2 0-.2.1-.4.2-.6.5-.6 1.1v2h7.7v3.8H90v2c0 .8.3 1.1 1 1.2h6.7v3.8h-7.3c-2.9-.1-4.2-1.4-4.2-3.8zm34.4 3.8c-2.7 0-6.7-.1-6.7-.1V73.7h9c2.6 0 3.8 1.2 3.8 3.9v9.9c0 .2 0 .4-.1.7-.3 2.1-1.5 3.2-3.6 3.2-.1 0-1.1.1-2.4.1zm.9-3.9c1 0 1.2-.2 1.3-1.3v-8c0-.5-.3-.7-1.1-.8h-4.1v10.1h3.9zm21.5 3.8V73.7h3.8v17.7H143zm19 0l4.9-15.5c.5-1.6 1.5-2.4 3-2.4 1.4 0 2.5.8 2.9 2.4l4.9 15.5h-3.9l-1.2-3.8h-5.3l-1.2 3.8H162zm9.3-7.4l-1.8-5.7-1.3 5.7h3.1zm-18.5-13.1v-6.3h4.4c2.7 0 3.8-1 3.8-3.8V36.4c0-2.8-1.1-3.8-3.8-3.8h-4.4v-6.3h19.4v34.5c0 2.8 1.1 3.8 3.8 3.8h4.7v6.3h-27.9z'
        fill='#fff'
      />
      <path
        d='M165.4 6.6c3.7 0 6.9 3.1 6.9 6.8 0 3.8-3.1 6.8-6.9 6.8s-6.9-3-6.9-6.8c0-3.7 3.2-6.8 6.9-6.8z'
        fill='#fff'
      />
      <path
        d='M129 63.1h-.2c-3.8 6.5-8.7 9.2-14.9 9.2-13.2 0-18.8-12-18.8-23.7s6.9-23.7 18.8-23.7c7.5 0 12.8 4.7 14.9 8.2h.2V18.2c0-2.9-.9-4.2-3.9-4.2h-6V7.8h21.1v52.6c0 2.9.9 4.2 3.8 4.2h4.9v6.3h-20l.1-7.8zm-11.4 1.8c6.4 0 11.4-5.3 11.4-16.8 0-10.4-5-15.7-11.4-15.7s-9.8 5.3-9.8 16.3c0 10.8 3.4 16.2 9.8 16.2zM55.1 53.5C57 61.9 62.8 66 71.2 66c3.3 0 7.4-1.8 7.4-5.7 0-9.3-30-4-30-21 0-9.4 9-14.3 17.4-14.3 5 0 9.9 1.9 14 4.7l1.9-3.2h5.2v15.9H81c-2.3-7-6.8-10.5-14.1-10.5-3.1 0-7.2 1.3-7.2 5.1 0 7.4 30 3.9 30 21.3 0 10-8.5 14.1-17.4 14.1-5.7 0-11.4-.9-15.9-4.8L54.5 71h-6.3V53.5h6.9z'
        fill='#fff'
      />
    </svg>
  );
};

export default SDIMedia;
