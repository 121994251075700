const WarsawFilmSchool = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-[23%]'>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width='100%'
        height='100%'
        viewBox='0 0 656 656'
        enableBackground='new 0 0 656 656'
        className='bg-white'
      >
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M216.022537,189.735382 
	C216.149445,193.529068 214.296936,194.701736 211.294205,194.338821 
	C207.296661,193.855667 205.868225,195.826202 204.957687,199.516922 
	C199.894775,220.038681 194.467575,240.470703 189.428284,260.998047 
	C188.337128,265.442810 186.494125,267.325226 181.686447,267.304321 
	C166.098572,267.236511 166.098709,267.412659 161.851227,252.587875 
	C158.611649,241.280960 155.369461,229.974808 151.228821,218.673370 
	C147.369843,232.408783 143.480560,246.135773 139.664200,259.882996 
	C137.645874,267.153351 137.700668,267.303772 130.310638,267.166840 
	C113.255875,266.850800 117.599419,270.030975 112.704681,253.727234 
	C107.439232,236.188705 102.608925,218.519119 97.643562,200.891281 
	C96.609032,197.218536 95.690048,193.747192 90.637794,194.190521 
	C88.522964,194.376099 87.886940,192.603439 87.866547,190.782440 
	C87.819908,186.617767 87.851646,182.452240 87.846664,178.287064 
	C87.843674,175.788223 89.130959,174.691177 91.580132,174.696381 
	C102.744095,174.720123 113.908249,174.744873 125.072029,174.698074 
	C127.788818,174.686691 129.094055,175.851242 129.063538,178.564392 
	C129.037323,180.896820 129.055405,183.229736 129.050812,185.562439 
	C129.035080,193.557129 129.034561,193.557129 120.630127,195.075607 
	C122.980515,204.502487 125.318321,213.878952 127.656136,223.255402 
	C128.065720,223.283417 128.475311,223.311432 128.884903,223.339447 
	C130.168137,218.509476 131.488098,213.688904 132.726929,208.847549 
	C135.243973,199.010803 137.854675,189.194580 140.137054,179.303024 
	C140.983765,175.633499 142.833328,174.506912 146.415604,174.629059 
	C164.566818,175.247925 160.429703,171.454849 165.616913,188.820267 
	C169.038376,200.274399 172.310898,211.773041 176.690445,223.278931 
	C178.661911,214.813980 180.666412,206.356506 182.585815,197.879791 
	C183.205124,195.144730 181.610641,194.203217 179.190659,194.394058 
	C175.570633,194.679535 174.759872,192.806396 174.670456,189.556778 
	C174.261353,174.687927 174.188858,174.688095 189.187347,174.686783 
	C196.019135,174.686172 202.867798,174.990601 209.677231,174.602615 
	C214.891556,174.305511 216.578247,176.404236 216.112656,181.276245 
	C215.860123,183.919067 216.044464,186.603638 216.022537,189.735382 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M365.466248,328.976074 
	C367.725372,338.905365 366.770050,348.600067 366.944244,358.235138 
	C367.000336,361.339447 367.100372,363.956421 371.283081,363.808044 
	C373.356537,363.734467 374.108215,365.243896 374.167450,367.141174 
	C374.172638,367.307617 374.178864,367.474457 374.194916,367.640106 
	C375.717834,383.367676 375.717896,383.367676 360.097198,383.341827 
	C345.632202,383.317841 345.634949,383.317841 345.632294,368.935181 
	C345.630798,360.939514 345.745789,352.941132 345.575653,344.948944 
	C345.401337,336.762604 342.350037,333.239685 335.827484,333.163910 
	C328.872192,333.083069 324.856445,337.019318 324.388611,344.841125 
	C324.100647,349.655548 324.370361,354.500793 324.250671,359.328766 
	C324.182251,362.089417 324.861542,363.911591 328.088348,363.717468 
	C330.660461,363.562805 331.363892,364.919708 331.550171,367.393524 
	C332.753723,383.376984 332.812012,383.372559 316.871796,383.343842 
	C302.846405,383.318573 302.853516,383.318604 302.845306,369.452667 
	C302.840179,360.790863 302.918884,352.126984 302.755127,343.467926 
	C302.629669,336.834442 300.019775,333.623474 294.922882,333.219727 
	C288.597717,332.718658 284.782410,335.458954 283.255737,341.619263 
	C281.708008,347.864410 282.586517,354.222321 282.395355,360.530609 
	C282.322784,362.924896 283.658142,363.819153 285.845093,363.703491 
	C289.000031,363.536652 289.681366,365.459778 289.941864,368.097443 
	C291.421844,383.082977 291.210907,383.346893 276.188904,383.345459 
	C270.358734,383.344910 264.524445,383.209656 258.699829,383.388702 
	C254.976486,383.503174 253.582413,381.933167 253.790497,378.336884 
	C253.953766,375.514740 253.879944,372.673340 253.796112,369.843903 
	C253.695663,366.453827 253.714661,363.397522 258.504486,363.498901 
	C260.738007,363.546234 260.874481,361.514984 260.886108,359.806152 
	C260.937103,352.310516 260.921722,344.814362 260.992798,337.319000 
	C261.017365,334.729034 260.117767,333.133545 257.280579,333.196747 
	C257.115509,333.200439 256.907684,333.220764 256.789978,333.135895 
	C250.735748,328.771149 254.955460,322.527100 254.005981,317.226532 
	C253.438934,314.060760 256.152557,313.655914 258.700745,313.679535 
	C263.031311,313.719666 267.362610,313.682434 271.693604,313.674164 
	C279.449524,313.659332 279.449524,313.658234 281.037781,320.519287 
	C293.055481,308.747498 306.273621,308.459381 319.472809,323.702911 
	C327.050812,314.185669 336.589966,309.656830 348.784424,313.229706 
	C356.814514,315.582458 362.385986,320.705170 365.466248,328.976074 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M519.199890,264.806488 
	C515.023743,250.161224 511.012085,235.898071 507.024323,221.628235 
	C506.435577,219.521484 506.095703,217.141296 503.257874,217.069397 
	C499.370361,216.970932 498.716858,214.668259 498.760651,211.409836 
	C498.995331,193.946854 496.634186,197.226898 512.685913,196.890564 
	C517.682251,196.785858 522.685364,196.986954 527.680786,196.862411 
	C531.114014,196.776794 532.536865,198.229279 532.355103,201.637970 
	C532.204468,204.462494 532.158752,207.313797 532.366638,210.131088 
	C532.646118,213.916931 532.209656,217.109390 527.251099,217.884430 
	C527.953430,220.763107 528.533508,223.324295 529.209473,225.859909 
	C529.891968,228.419891 530.669556,230.954498 531.612549,234.216461 
	C533.590088,231.754852 533.902649,229.284897 534.592163,227.057770 
	C537.152100,218.789230 539.704712,210.513855 541.991577,202.167465 
	C543.033264,198.365509 544.889343,196.656647 549.012207,196.833221 
	C562.394043,197.406326 560.144043,194.087769 564.422546,208.762558 
	C566.887207,217.216431 569.391602,225.658752 572.160950,235.066925 
	C574.634033,229.082672 576.141907,223.813309 577.495605,218.060501 
	C570.776978,216.528625 572.718689,211.211197 572.689575,206.889557 
	C572.617004,196.077454 571.480530,196.935989 582.541687,196.892242 
	C589.039917,196.866547 595.539734,196.948975 602.036011,196.830475 
	C605.163330,196.773422 606.718079,197.813248 606.432861,201.181427 
	C606.222656,203.663467 606.342468,206.177216 606.382080,208.675781 
	C606.450500,212.993546 607.391663,217.702408 600.373352,217.311478 
	C598.127808,217.186386 598.039795,219.844482 597.483032,221.495972 
	C592.961731,234.907410 588.369385,248.297684 584.101196,261.790100 
	C582.847046,265.754913 580.963867,267.318634 576.680298,267.307098 
	C562.210205,267.268188 562.210083,267.430695 558.008850,253.560104 
	C556.088501,247.219910 554.166443,240.880188 551.976685,233.654404 
	C548.264648,239.717575 547.029846,245.742188 544.711365,251.303024 
	C544.199646,252.530365 543.819031,253.812241 543.375854,255.068253 
	C538.978333,267.529877 535.093201,269.720551 522.132874,267.093445 
	C520.905884,266.844727 519.970215,266.289032 519.199890,264.806488 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M235.893280,497.581970 
	C235.770508,493.631104 235.820557,490.128784 235.676880,486.634430 
	C235.523346,482.900421 235.459427,479.507874 240.754257,479.597778 
	C242.707382,479.630951 242.824097,477.710876 242.826553,476.187347 
	C242.851044,461.022186 242.842667,445.856964 242.861282,430.691772 
	C242.864014,428.467499 241.894958,427.195282 239.598846,427.232819 
	C236.473206,427.283936 235.637817,425.506104 235.698914,422.746094 
	C235.783707,418.914948 235.793243,415.079010 235.703354,411.248199 
	C235.639542,408.528137 236.782593,407.061218 239.531189,407.326843 
	C245.871582,407.939697 252.188431,407.360565 258.504639,407.044525 
	C262.694794,406.834900 264.556000,408.286438 264.392548,412.767242 
	C264.116180,420.345123 264.316681,427.940399 264.316681,436.883636 
	C270.528198,430.887268 276.700684,427.906097 284.069061,428.355682 
	C296.427277,429.109741 305.314880,438.247986 307.360474,451.824799 
	C308.486694,459.299561 307.977997,466.791779 308.050629,474.278107 
	C308.080261,477.332550 308.101471,479.998627 312.298126,479.985229 
	C314.635620,479.977783 315.243530,481.809296 315.244629,483.837372 
	C315.246826,487.836792 315.192535,491.836548 315.231018,495.835510 
	C315.256805,498.509949 314.001740,499.911743 311.344177,499.923157 
	C304.345703,499.953217 297.346924,499.914948 290.348419,499.940582 
	C287.090332,499.952515 286.655853,497.921844 286.668762,495.289459 
	C286.718567,485.123810 286.728699,474.957397 286.647858,464.792114 
	C286.628143,462.311707 286.453857,459.779602 285.919373,457.366791 
	C284.686676,451.802124 281.578278,449.402313 276.064178,449.356598 
	C270.416931,449.309753 266.615265,451.901855 265.218292,457.080994 
	C263.452301,463.628357 264.557098,470.342651 264.365906,476.981689 
	C264.303497,479.147980 265.756500,479.878052 267.644958,479.840424 
	C271.012451,479.773438 271.922241,481.768890 271.884979,484.658264 
	C271.649078,502.937775 274.360992,499.614410 257.706848,499.977386 
	C252.210083,500.097198 246.707367,500.030640 241.209061,499.943115 
	C239.300308,499.912720 237.079437,500.417786 235.893280,497.581970 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M155.425232,468.618713 
	C156.579178,489.355682 140.720856,504.671722 121.307724,501.028198 
	C115.385338,499.916687 109.886032,499.546326 103.908150,499.794495 
	C90.190613,500.363953 91.980095,501.133331 91.886002,487.962433 
	C91.851494,483.131348 91.929359,478.299500 91.955017,473.467987 
	C91.968391,470.949127 92.772339,469.435394 95.749985,469.484528 
	C99.904305,469.553040 104.070755,469.313507 108.221451,469.043427 
	C111.769012,468.812561 113.653687,469.974335 114.387962,473.794952 
	C115.515953,479.664246 120.351227,482.230499 126.105980,480.618835 
	C129.125015,479.773346 130.930298,477.706696 131.595535,474.728760 
	C132.278198,471.672791 131.293198,469.156830 128.700226,467.310181 
	C125.659935,465.145050 122.102257,464.178314 118.658676,462.956116 
	C112.205170,460.665710 105.794319,458.306915 100.647598,453.526306 
	C92.058983,445.548706 89.507133,435.786377 93.293419,424.870270 
	C97.103645,413.885101 105.201797,407.378815 116.828003,405.859863 
	C121.966888,405.188507 127.170769,405.326447 131.804642,408.367767 
	C132.582581,408.878357 133.443008,409.581421 134.524094,408.802002 
	C139.042938,405.544128 144.174561,407.585175 149.019119,407.234589 
	C151.561707,407.050568 153.000595,408.590027 152.986069,411.275604 
	C152.950958,417.772949 152.910919,424.270477 152.928833,430.767822 
	C152.936768,433.643707 151.662903,435.097534 148.723907,435.075958 
	C145.392212,435.051483 142.053940,434.987701 138.729431,435.156158 
	C134.619598,435.364410 131.359100,434.995941 130.143600,429.924927 
	C129.320251,426.490021 125.953484,425.215668 122.553604,425.540405 
	C119.135544,425.866913 116.703125,427.891754 115.743210,431.298492 
	C114.740028,434.858826 116.202980,437.615448 119.190605,439.366608 
	C123.964111,442.164490 129.394196,443.380951 134.561447,445.224060 
	C145.617050,449.167480 153.950974,455.556458 155.425232,468.618713 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M454.975952,195.589569 
	C459.044922,196.462906 461.999146,198.627472 465.454834,201.168900 
	C466.201263,197.020264 469.030365,196.780182 472.197052,196.841080 
	C478.185547,196.956253 484.179321,196.966583 490.167694,196.851929 
	C493.779968,196.782776 495.354279,198.331039 495.206177,201.930038 
	C495.083099,204.920242 495.102905,207.922043 495.201202,210.914062 
	C495.309509,214.211746 494.900604,216.940582 490.692200,217.144165 
	C488.601990,217.245255 487.979065,218.819382 487.975281,220.660995 
	C487.959961,228.148697 487.974518,235.636429 487.968201,243.124161 
	C487.966217,245.473282 488.934509,246.933868 491.442474,247.062881 
	C491.771698,247.079819 492.178741,247.073257 492.411682,247.254745 
	C498.219788,251.781082 494.130859,257.981659 494.993164,263.347656 
	C495.544586,266.779205 492.590973,267.164673 489.919403,267.168549 
	C483.596008,267.177765 477.269958,267.079224 470.950531,267.241882 
	C468.338501,267.309143 466.453613,266.527161 465.420227,264.183746 
	C444.683258,271.599243 431.417480,269.147064 420.753906,256.056488 
	C411.020844,244.108215 409.842834,226.643417 417.849213,212.993164 
	C425.658295,199.679306 440.186005,192.755569 454.975952,195.589569 
M440.880402,219.283264 
	C429.722351,228.485458 435.021240,243.452393 444.206360,246.848450 
	C451.462280,249.531204 459.229553,247.206924 463.593842,240.760391 
	C467.869019,234.445465 467.259277,226.648239 462.050049,221.020248 
	C456.929108,215.487610 449.048431,214.659622 440.880402,219.283264 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M212.558685,213.702881 
	C220.100128,201.428436 230.552338,194.825455 244.824387,194.873459 
	C250.558609,194.892746 255.403961,197.403397 260.417908,200.802292 
	C261.436340,197.577042 263.741211,196.764923 266.843811,196.861282 
	C272.994659,197.052322 279.156311,196.965164 285.312317,196.893005 
	C288.609009,196.854370 290.288574,198.196503 290.174286,201.632172 
	C290.080292,204.457642 290.091583,207.291397 290.185822,210.117157 
	C290.303040,213.633209 290.318237,216.903625 285.463623,217.135818 
	C283.633362,217.223343 283.070282,218.756058 283.065063,220.387268 
	C283.040497,228.040878 283.051758,235.694595 283.047211,243.348282 
	C283.045959,245.478058 283.913025,246.854355 286.194519,247.035263 
	C286.525391,247.061493 286.928009,247.026962 287.170563,247.199203 
	C293.266571,251.528076 289.159973,257.783386 290.016937,263.092041 
	C290.619690,266.825836 287.468109,267.109375 284.628601,267.110687 
	C278.471893,267.113556 272.314148,267.080902 266.159027,267.189270 
	C263.556824,267.235107 261.525452,266.682007 260.986115,263.140656 
	C253.141449,268.604858 244.969879,270.408264 236.040970,268.429626 
	C228.643677,266.790405 222.397079,263.183807 217.302673,257.661713 
	C206.469925,245.919525 204.629974,229.177505 212.558685,213.702881 
M257.722412,242.169418 
	C258.596710,240.579941 259.600128,239.046783 260.322327,237.390961 
	C263.031372,231.179779 261.507568,225.574341 257.123688,220.867111 
	C252.463043,215.862717 246.385056,214.972580 240.152145,217.083984 
	C233.954361,219.183502 230.487244,223.763092 230.049210,230.495392 
	C229.555145,238.088959 232.220840,243.222977 238.579132,246.512833 
	C244.407791,249.528625 251.188995,248.163269 257.722412,242.169418 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M101.715561,348.987488 
	C101.716484,337.833984 101.641083,327.176758 101.761703,316.521759 
	C101.800957,313.055115 101.284966,310.581665 96.995079,310.838318 
	C93.691978,311.035919 92.727242,308.984955 92.798027,306.035187 
	C92.881889,302.540314 92.964867,299.037445 92.821526,295.547333 
	C92.677284,292.035431 94.350845,290.863983 97.647156,290.870880 
	C118.293678,290.914062 138.940506,290.912079 159.586853,290.830719 
	C163.099197,290.816864 164.277954,292.449005 164.233734,295.719269 
	C164.136917,302.877716 164.113708,310.038727 164.191559,317.197266 
	C164.227280,320.482361 163.011047,322.170471 159.535004,322.095917 
	C154.708755,321.992401 149.878113,322.031097 145.050262,322.087830 
	C141.948395,322.124298 140.284714,320.837677 140.665939,317.599152 
	C141.163101,313.375763 139.187744,312.175171 135.172684,312.191223 
	C125.446259,312.230164 122.658981,315.908783 125.347771,325.557129 
	C125.951134,327.722168 127.432465,327.891693 129.148026,327.907990 
	C133.310226,327.947479 137.473694,328.014130 141.634872,327.951691 
	C144.952271,327.901947 146.545654,329.239349 146.353882,332.723328 
	C146.180298,335.876953 146.192566,339.056366 146.374664,342.209900 
	C146.584045,345.835968 144.838730,346.848083 141.528427,346.654358 
	C137.710770,346.430908 133.875412,346.507965 130.047684,346.458679 
	C126.822037,346.417114 125.029030,347.733459 125.253792,351.240662 
	C125.391922,353.395935 125.413757,355.580322 125.230835,357.729797 
	C124.895851,361.665894 125.710999,364.299072 130.525284,363.753571 
	C133.022415,363.470642 134.133957,364.996765 134.134842,367.415741 
	C134.136322,371.411438 134.128677,375.407379 134.167572,379.402802 
	C134.197189,382.445801 132.566696,383.387634 129.738083,383.365875 
	C118.915825,383.282593 108.092354,383.257660 97.270134,383.329895 
	C94.062042,383.351288 92.705643,381.980682 92.825745,378.838745 
	C92.914726,376.511078 92.910477,374.175293 92.834297,371.846863 
	C92.687614,367.364136 92.240761,362.854462 99.121117,363.537445 
	C101.256821,363.749481 101.650085,361.745880 101.669403,359.974121 
	C101.707527,356.477875 101.702560,352.981201 101.715561,348.987488 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M413.625916,495.462067 
	C400.682770,485.579529 394.849518,472.850983 398.159119,457.167419 
	C401.309967,442.236420 410.830872,432.475006 425.774200,428.585266 
	C444.641571,423.674042 464.277985,434.941864 470.285767,453.922302 
	C476.033356,472.080719 466.118866,492.392731 448.254852,499.329071 
	C436.133484,504.035675 424.757996,502.621094 413.625916,495.462067 
M449.866974,463.651215 
	C448.992432,454.261932 441.278961,447.564362 432.827240,448.855682 
	C423.757263,450.241486 418.392670,457.271942 419.274323,466.617004 
	C420.035797,474.687683 426.723328,480.881287 434.565125,480.778351 
	C443.175964,480.665344 449.373840,474.082336 449.866974,463.651215 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M318.675354,467.796204 
	C316.601562,439.265015 344.837250,419.430145 370.990601,430.868408 
	C387.461090,438.071777 396.376862,456.494354 391.885956,473.762238 
	C387.458618,490.785583 370.729279,503.178619 353.650635,502.086823 
	C335.332092,500.915771 320.613373,486.674133 318.675354,467.796204 
M369.303864,472.525513 
	C370.496460,470.085968 370.995117,467.489197 371.038055,464.793365 
	C371.173981,456.261597 364.903595,449.284241 356.522522,448.634033 
	C348.536774,448.014526 341.131622,454.584839 340.294434,463.032623 
	C339.552094,470.523804 343.257965,476.785614 350.236298,479.831421 
	C356.502319,482.566315 363.051086,480.232269 369.303864,472.525513 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M228.710907,431.544617 
	C228.980774,438.616486 229.039536,445.269684 229.146408,451.922089 
	C229.187073,454.453125 228.034943,455.506805 225.500793,455.445068 
	C221.007614,455.335571 216.509125,455.310516 212.015793,455.399170 
	C209.775558,455.443359 208.352615,454.184540 206.787064,452.909454 
	C200.502213,447.790833 193.487320,447.370361 187.522278,451.661102 
	C181.884735,455.716309 179.789200,463.927521 182.584610,471.008789 
	C185.239365,477.733765 191.492508,481.434875 198.895493,480.718323 
	C200.938797,480.520569 203.202774,480.174133 204.390381,478.661713 
	C207.945633,474.134033 212.747482,474.574951 217.572433,474.668701 
	C220.734940,474.730164 223.900665,474.760010 227.062439,474.690002 
	C230.807892,474.607056 231.805023,475.991364 230.315613,479.575317 
	C224.643417,493.224274 209.050049,503.091919 194.459229,502.092316 
	C178.680237,501.011292 164.323639,488.956940 160.760132,473.797302 
	C156.212982,454.453156 166.826950,434.756744 185.004227,429.099152 
	C193.726303,426.384430 202.122665,426.910309 209.546356,432.808929 
	C212.811020,429.312958 222.987686,428.492249 228.710907,431.544617 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M371.854370,266.558899 
	C369.298615,265.350433 367.666901,264.647247 364.869110,266.381165 
	C361.576569,268.421722 357.085236,267.347748 353.138580,266.876953 
	C351.063782,266.629425 350.898956,264.669006 350.891266,262.953064 
	C350.871826,258.623322 350.949493,254.292664 350.890259,249.963715 
	C350.849579,246.988770 352.255890,245.761551 355.163177,245.819336 
	C359.158508,245.898758 363.159180,245.926712 367.152588,245.808533 
	C369.767944,245.731140 371.542328,246.527588 372.668091,249.069595 
	C374.187134,252.499588 377.262054,253.092163 380.648071,252.774902 
	C383.213867,252.534485 385.143219,251.453918 385.619080,248.721344 
	C386.054810,246.219025 384.847076,244.389923 382.617798,243.568619 
	C378.412231,242.019257 374.097992,240.767136 369.845978,239.340652 
	C367.166901,238.441864 364.404144,237.686554 361.877686,236.458054 
	C353.422913,232.346909 348.986938,225.735992 349.652985,216.065903 
	C350.286865,206.863251 355.410522,200.937302 363.611816,197.738739 
	C370.955963,194.874451 378.564789,194.505661 385.947998,197.953400 
	C387.435822,198.648163 388.349701,198.693436 389.950806,197.845734 
	C393.619995,195.903061 397.751038,196.862076 401.675629,197.208832 
	C403.728516,197.390213 404.004456,199.377869 404.028534,201.083862 
	C404.087280,205.246017 403.998688,209.410339 404.064880,213.572266 
	C404.113892,216.655914 402.554840,217.594086 399.680511,217.473984 
	C396.357147,217.335114 393.021362,217.483597 389.691132,217.523483 
	C386.953461,217.556274 384.674316,217.279602 383.005371,214.369980 
	C381.396179,211.564590 378.191925,211.771454 375.326508,212.459152 
	C373.781372,212.829987 372.697357,213.909622 372.407806,215.546494 
	C372.113159,217.212082 372.892761,218.468277 374.200165,219.392776 
	C376.556122,221.058807 379.332275,221.736374 382.026215,222.588821 
	C386.306763,223.943283 390.723419,224.958954 394.859772,226.659668 
	C405.149994,230.890579 409.070404,237.523270 408.291168,248.578568 
	C407.684692,257.182648 401.077881,264.768799 391.879639,267.199493 
	C385.303772,268.937195 378.716980,268.971222 371.854370,266.558899 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M476.008728,497.496887 
	C475.858154,493.554901 475.889099,490.058075 475.742920,486.568695 
	C475.586212,482.827057 475.569702,479.548004 480.852875,479.550720 
	C483.311798,479.551971 483.115173,476.957489 483.127808,475.223846 
	C483.194611,466.082214 483.144714,456.939697 483.116058,447.797516 
	C483.099335,442.472168 483.009735,437.146790 483.025513,431.821686 
	C483.033264,429.214386 482.579285,427.145508 479.309418,427.187683 
	C476.368958,427.225616 475.767761,425.300964 475.789886,422.860748 
	C475.826111,418.866791 475.827759,414.872437 475.832977,410.878265 
	C475.835815,408.687408 476.718353,407.518188 479.106110,407.492462 
	C485.925049,407.419128 492.743042,407.234558 499.560486,407.059021 
	C503.243591,406.964172 504.782074,408.276825 504.729736,412.374756 
	C504.461975,433.339233 504.547455,454.308044 504.437775,475.274963 
	C504.423737,477.961945 504.857269,479.908325 508.121704,479.843536 
	C511.208282,479.782257 512.072693,481.726440 512.066895,484.407928 
	C512.058899,488.069244 512.003052,491.733093 512.116394,495.391235 
	C512.212646,498.498230 510.927307,500.054535 507.746674,500.038757 
	C498.427094,499.992554 489.107208,500.003479 479.787598,499.960693 
	C478.239899,499.953583 476.787781,499.617493 476.008728,497.496887 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M239.275787,347.995178 
	C239.276367,351.323059 239.357697,354.155212 239.255753,356.980804 
	C239.124374,360.622192 238.796799,364.142700 244.237564,363.934631 
	C246.117279,363.862701 246.321030,365.780975 246.331146,367.331970 
	C246.357162,371.325897 246.298859,375.320953 246.368759,379.313873 
	C246.415192,381.965851 245.365402,383.342926 242.575378,383.332031 
	C233.089432,383.294983 223.603043,383.290955 214.117249,383.346649 
	C211.581787,383.361542 210.496918,382.316467 210.589264,379.759155 
	C210.709351,376.434479 210.732468,373.100555 210.635056,369.775360 
	C210.535049,366.361816 210.585770,363.382874 215.352585,363.488190 
	C217.519974,363.536041 217.733704,361.511261 217.735886,359.779663 
	C217.754715,344.801483 217.740524,329.823273 217.779617,314.845154 
	C217.785889,312.444733 216.879990,311.003571 214.353836,310.955811 
	C211.215775,310.896484 210.592224,309.027374 210.647537,306.309723 
	C211.022247,287.897003 207.680389,291.137360 225.572037,290.878784 
	C239.296768,290.680389 239.294159,290.846313 239.285385,304.558411 
	C239.276230,318.871124 239.278061,333.183807 239.275787,347.995178 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M321.988464,267.185669 
	C316.167511,267.178345 310.837372,266.995270 305.524506,267.219971 
	C301.306885,267.398346 299.276459,266.004700 299.694183,261.519775 
	C299.955292,258.716431 299.853851,255.863449 299.714050,253.042725 
	C299.548737,249.707397 299.952545,247.061264 304.206909,246.936020 
	C306.338379,246.873260 306.781891,245.173218 306.785431,243.353577 
	C306.799896,235.868698 306.787537,228.383591 306.850464,220.899109 
	C306.870331,218.538376 306.053375,217.046753 303.513336,216.903381 
	C300.493286,216.732941 299.661194,214.878403 299.722534,212.170471 
	C299.805359,208.512894 299.837494,204.849869 299.733276,201.193695 
	C299.648865,198.232712 300.984314,196.916641 303.882263,196.924973 
	C310.036438,196.942688 316.199371,197.114319 322.342377,196.847382 
	C327.483765,196.623932 326.633087,200.779861 327.657227,204.070602 
	C331.694885,200.830841 335.295746,197.678757 340.233459,196.771301 
	C345.425568,195.817108 346.152252,196.232346 346.244293,201.375626 
	C346.277039,203.204666 346.247284,205.034866 346.242828,206.864532 
	C346.220306,216.100937 346.197144,216.045822 337.797211,219.515701 
	C331.561249,222.091690 328.888428,227.224792 328.374054,233.623764 
	C328.134827,236.599380 328.394684,239.611801 328.270355,242.600967 
	C328.154266,245.392273 328.995178,247.060852 332.133820,247.040237 
	C334.919464,247.021957 335.746796,248.606796 335.556763,251.302002 
	C335.299805,254.945450 335.234985,258.625702 335.432648,262.271484 
	C335.634674,265.997101 334.082947,267.405212 330.468689,267.184204 
	C327.817963,267.022125 325.148102,267.173950 321.988464,267.185669 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M173.151703,383.328827 
	C169.664322,383.504669 168.495300,382.042908 168.605911,379.107056 
	C168.731155,375.782867 168.703674,372.448212 168.610229,369.121857 
	C168.523010,366.017090 168.754456,363.456970 172.938370,363.557343 
	C175.417297,363.616821 175.654541,361.559509 175.659073,359.620911 
	C175.676590,352.130768 175.624313,344.639709 175.731659,337.150909 
	C175.770798,334.419800 174.723389,333.136017 171.955734,333.186646 
	C169.328262,333.234772 168.584000,331.580750 168.612411,329.263824 
	C168.661392,325.269775 168.704483,321.274597 168.663513,317.280792 
	C168.637344,314.730347 169.883789,313.691376 172.331635,313.693390 
	C179.322128,313.699158 186.312683,313.656128 193.303207,313.637665 
	C197.337585,313.627014 197.107040,316.577820 197.114883,319.255890 
	C197.151993,331.905975 197.149841,344.556152 197.153595,357.206299 
	C197.154587,360.551727 196.557114,364.237732 201.886948,363.887482 
	C203.211212,363.800476 203.886185,364.914551 204.085846,366.187103 
	C204.162994,366.678741 204.171860,367.180908 204.216370,367.677887 
	C205.621185,383.365234 205.621246,383.365265 190.113907,383.352417 
	C184.621063,383.347839 179.128235,383.343201 173.151703,383.328827 
z'
        />
        <path
          fill='#000000'
          opacity='1.000000'
          stroke='none'
          d='
M175.686020,301.965027 
	C175.839172,289.785156 174.186493,290.892426 186.347366,290.862579 
	C197.970016,290.834076 197.154846,289.959290 197.141525,301.552856 
	C197.130814,310.865204 197.119003,310.865021 188.021744,310.878387 
	C186.695740,310.880310 185.369751,310.886932 184.043747,310.888214 
	C175.700760,310.896179 175.700760,310.895874 175.686020,301.965027 
z'
        />
      </svg>
    </div>
  );
};

export default WarsawFilmSchool;
