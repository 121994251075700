const Chaosium = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 368 350'
        width='100%'
        height='100%'
      >
        <g fill='transparent' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 0.00 350.00 
L 368.00 350.00
L 368.00 0.00
L 0.00 0.00
L 0.00 350.00
M 198.00 10.00 
C 283.32 16.49 360.06 98.68 348.07 187.07 C 342.38 274.46 253.72 344.40 166.93 333.07 C 80.39 329.11 4.17 244.86 15.98 156.98 C 21.66 68.65 110.44 0.56 198.00 10.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 198.00 10.00 
C 110.44 0.56 21.66 68.65 15.98 156.98 C 4.17 244.86 80.39 329.11 166.93 333.07 C 253.72 344.40 342.38 274.46 348.07 187.07 C 360.06 98.68 283.32 16.49 198.00 10.00
M 194.00 68.00 
C 194.26 66.61 196.05 67.22 197.00 67.00
C 197.32 68.69 195.68 70.29 194.00 70.00
C 190.11 78.75 206.29 75.19 203.00 67.00
C 199.02 65.81 194.73 63.73 191.00 67.00
C 190.04 68.57 188.68 70.25 187.00 71.00
C 181.59 82.35 195.40 89.90 205.00 89.00
C 211.77 90.87 218.71 88.05 226.00 89.00
C 227.33 89.00 228.67 89.00 230.00 89.00
C 231.83 89.91 234.74 89.34 236.00 91.00
C 247.46 92.30 263.28 94.97 269.00 105.00
C 253.90 103.43 276.31 114.91 273.00 123.00
C 269.63 121.50 268.21 116.37 264.00 117.00
C 263.00 129.91 276.34 140.04 271.00 153.00
C 268.95 148.98 269.00 142.17 263.00 142.00
C 263.84 146.99 260.52 150.81 259.00 155.00
C 262.27 158.17 264.26 167.27 259.00 170.00
C 253.91 152.00 239.13 176.29 228.00 175.00
C 232.89 184.09 220.86 179.79 219.00 185.00
C 222.78 184.88 220.63 190.58 218.00 191.00
C 220.84 195.69 229.73 198.48 227.00 205.00
C 210.83 197.61 194.12 212.15 178.00 211.00
C 177.82 207.97 181.72 207.98 182.00 205.00
C 180.12 204.53 177.07 206.06 176.00 204.00
C 177.33 200.35 180.60 198.59 184.00 198.00
C 186.06 194.28 178.17 196.70 176.00 196.00
C 168.65 198.99 159.93 198.36 152.00 200.00
C 150.90 202.77 148.66 198.39 151.00 198.00
C 152.07 195.15 155.14 194.44 157.00 193.00
C 154.80 190.91 151.10 194.16 148.00 193.00
C 148.36 189.34 152.02 185.19 156.00 185.00
C 156.74 183.61 160.32 183.35 161.00 185.00
C 168.07 183.90 179.94 189.72 181.00 179.00
C 173.97 178.22 164.87 179.44 160.00 174.00
C 153.81 176.19 147.99 180.74 143.25 185.25 C 136.68 190.14 145.50 201.94 135.00 204.00
C 138.30 197.38 127.77 200.85 124.00 200.00
C 122.86 201.87 119.87 200.61 118.00 201.00
C 112.66 201.92 107.81 204.79 102.00 204.00
C 101.05 205.86 97.37 205.12 97.00 203.00
C 95.72 202.47 95.14 203.97 94.00 204.00
C 93.08 200.72 97.03 199.52 98.00 197.00
C 98.15 196.55 97.76 195.31 98.00 195.00
C 96.67 195.00 95.33 195.00 94.00 195.00
C 90.34 197.51 93.73 191.13 96.00 191.00
C 96.45 185.81 103.54 188.80 107.00 188.00
C 108.33 188.00 109.67 188.00 111.00 188.00
C 120.97 190.54 125.16 180.85 133.00 178.00
C 133.04 176.71 134.60 172.06 136.00 174.00
C 139.23 172.37 142.20 169.89 145.00 168.00
C 140.28 168.22 132.54 163.21 132.00 171.00
C 123.01 172.20 124.50 156.92 115.00 157.00
C 113.00 157.00 111.00 157.00 109.00 157.00
C 106.65 156.74 102.93 158.03 102.00 155.00
C 100.80 153.89 100.34 151.71 100.00 150.00
C 99.95 148.24 101.53 146.93 101.00 145.00
C 103.83 145.78 102.99 150.95 108.00 149.00
C 109.10 142.56 100.30 140.89 96.00 142.00
C 97.06 137.22 91.14 137.23 89.00 140.00
C 90.08 128.36 105.75 128.71 112.75 134.25 C 119.67 140.29 121.91 153.15 132.00 152.00
C 133.46 146.18 137.13 141.02 138.00 135.00
C 139.57 132.72 141.67 130.43 144.00 129.00
C 147.41 125.28 151.22 120.74 150.00 115.00
C 147.38 114.00 146.41 117.33 144.00 117.00
C 145.21 121.31 139.86 121.77 139.00 118.00
C 138.00 118.00 137.00 118.00 136.00 118.00
C 136.90 115.01 133.10 111.30 136.00 109.00
C 137.50 108.65 140.69 108.21 142.00 106.00
C 142.13 105.20 143.09 104.89 143.00 104.00
C 142.50 104.00 141.49 103.92 141.00 104.00
C 137.40 104.86 135.03 108.87 131.00 109.00
C 130.51 110.83 128.65 111.18 127.00 111.00
C 124.69 111.16 120.66 111.45 121.00 108.00
C 119.04 105.87 119.81 99.45 123.00 99.00
C 123.24 102.43 119.38 105.20 123.00 108.00
C 130.16 108.59 136.25 101.68 140.00 97.00
C 136.16 95.49 128.69 100.79 130.00 94.00
C 126.87 92.85 125.41 100.36 125.00 94.00
C 123.57 92.97 123.31 88.96 125.00 88.00
C 129.80 85.97 135.65 85.98 141.00 87.00
C 143.07 83.69 148.04 79.59 152.00 83.00
C 152.50 83.05 153.00 83.00 153.00 83.00
C 154.76 82.95 156.07 84.53 158.00 84.00
C 160.37 84.55 163.14 82.47 163.00 80.00
C 163.47 77.43 162.46 75.35 162.00 73.00
C 164.47 72.63 165.10 75.38 166.00 77.00
C 166.00 80.00 166.00 83.00 166.00 86.00
C 169.90 85.37 172.52 80.84 172.00 77.00
C 170.40 76.47 171.28 74.25 171.00 73.00
C 172.29 73.44 173.21 74.58 173.00 76.00
C 178.86 84.82 166.92 93.75 170.98 103.02 C 172.74 114.01 168.61 123.48 166.00 133.00
C 171.49 130.37 173.35 122.41 176.00 117.00
C 185.09 122.40 188.20 109.32 178.00 110.00
C 179.10 98.68 195.69 104.92 203.67 102.67 C 211.21 102.28 222.99 108.46 227.00 98.00
C 211.45 95.20 189.47 99.91 181.00 83.00
C 177.83 81.41 178.47 75.23 180.00 73.00
C 182.20 55.67 218.53 58.19 203.23 76.23 C 197.67 83.09 184.01 71.39 194.00 68.00
M 256.00 251.00 
C 257.48 243.51 263.10 237.23 262.00 229.00 C 262.97 219.42 250.21 217.19 251.00 228.00
C 249.49 225.09 242.33 216.31 240.08 225.08 C 237.03 230.97 240.95 239.44 238.00 245.00
C 234.92 237.40 233.74 228.09 235.93 219.93 C 235.38 211.31 246.39 205.73 249.00 216.00
C 252.53 213.07 261.13 203.49 263.97 213.03 C 270.34 224.91 264.17 242.25 256.00 251.00
M 89.00 233.00 
C 87.00 230.82 85.95 225.97 89.00 224.00
C 89.40 216.78 95.75 210.50 103.01 210.01 C 111.62 208.32 114.76 224.23 106.00 225.00
C 96.40 209.75 89.57 235.25 95.93 243.07 C 96.62 249.11 105.54 249.18 106.00 254.00
C 96.46 252.34 89.05 242.56 89.00 233.00
M 191.00 226.00 
C 189.83 223.92 189.81 220.83 187.00 220.00
C 182.71 227.84 194.96 231.89 193.00 240.00 C 190.29 246.33 186.19 253.23 179.00 255.00
C 183.04 249.47 192.48 243.35 185.74 235.26 C 181.14 230.06 176.42 218.34 185.03 215.03 C 194.16 208.69 202.27 223.44 191.00 226.00
M 115.00 235.00 
C 112.56 231.80 111.65 225.01 115.00 222.00
C 113.01 213.33 125.98 213.51 121.07 222.07 C 119.61 231.80 134.94 225.38 127.23 218.77 C 128.38 209.45 138.41 220.31 135.00 226.00 C 134.84 231.87 133.74 239.95 129.00 243.00
C 132.48 235.73 129.08 227.96 120.00 231.00
C 117.92 235.73 120.25 242.04 119.00 246.00
C 115.98 243.57 115.31 238.86 115.00 235.00
M 167.00 247.00 
C 166.29 248.93 163.21 247.72 163.00 246.00
C 154.18 238.30 153.92 215.80 169.00 215.00 C 181.84 219.84 178.70 243.11 167.00 247.00
M 201.00 225.00 
C 199.53 223.67 199.29 219.26 201.00 218.00
C 199.89 213.77 205.86 214.60 208.00 216.00
C 202.85 224.26 204.64 234.80 205.00 243.00
C 200.64 238.65 201.20 231.14 201.00 225.00
M 217.00 226.00 
C 217.00 227.33 217.00 228.67 217.00 230.00
C 215.27 235.26 221.65 242.59 226.25 237.25 C 227.86 231.73 229.21 224.71 227.00 219.00
C 225.28 218.70 223.99 215.73 226.00 215.00
C 232.41 213.03 232.57 219.53 232.00 224.00
C 231.72 225.28 232.76 227.50 231.00 228.00
C 231.02 228.50 231.00 229.50 231.00 230.00
C 231.00 232.33 231.00 234.67 231.00 237.00
C 231.56 239.24 232.38 241.48 232.00 244.00
C 226.53 239.45 215.86 248.48 213.08 236.92 C 211.54 229.12 208.34 211.38 222.00 215.00
C 221.19 219.00 215.62 221.08 217.00 226.00
M 150.00 254.00 
C 149.94 247.54 150.52 238.60 147.00 232.00
C 141.13 232.41 139.05 238.83 140.00 244.00
C 132.44 238.29 139.43 216.22 148.75 217.26 C 154.64 227.65 154.66 243.42 150.00 254.00
M 168.00 266.00 
C 168.39 264.43 167.06 261.78 169.00 261.00
C 169.91 259.17 169.34 256.26 171.00 255.00
C 175.42 259.04 176.82 265.54 181.00 270.00
C 183.64 264.98 183.57 248.50 193.00 255.00
C 184.85 262.02 184.49 272.78 185.00 283.00
C 177.64 280.59 178.71 270.11 173.00 266.00
C 170.17 271.76 172.10 279.45 170.00 285.00
C 166.33 279.90 168.66 272.24 168.00 266.00
M 158.00 267.00 
C 156.43 264.06 156.29 257.87 158.00 255.00
C 160.51 254.20 163.19 253.72 166.00 254.00
C 162.59 262.73 162.06 272.96 161.00 282.00
C 159.85 277.42 157.37 272.39 158.00 267.00
M 191.00 273.00 
C 190.89 272.74 190.22 272.30 190.00 272.00
C 188.05 269.13 188.40 262.85 192.00 261.00
C 194.78 252.28 211.04 256.39 204.00 265.00
C 190.39 258.92 194.71 282.37 203.00 286.00
C 196.66 285.54 191.45 279.02 191.00 273.00
M 208.00 276.00 
C 211.79 285.44 197.92 276.91 208.00 276.00 Z'
          />
        </g>
        <g fill='#F5F5F5' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 180.00 73.00 
C 179.87 76.47 179.87 79.84 181.00 83.00
C 189.47 99.91 211.45 95.20 227.00 98.00
C 222.99 108.46 211.21 102.28 203.67 102.67 C 195.69 104.92 179.10 98.68 178.00 110.00
C 188.20 109.32 185.09 122.40 176.00 117.00
C 173.35 122.41 171.49 130.37 166.00 133.00
C 168.61 123.48 172.74 114.01 170.98 103.02 C 166.92 93.75 178.86 84.82 173.00 76.00
C 173.01 76.15 172.03 76.65 172.00 77.00
C 172.52 80.84 169.90 85.37 166.00 86.00
C 166.00 83.00 166.00 80.00 166.00 77.00
C 163.35 76.12 164.87 79.66 163.00 80.00
C 163.14 82.47 160.37 84.55 158.00 84.00
C 157.14 87.31 154.18 84.15 153.00 83.00
C 153.00 83.00 152.50 83.05 152.00 83.00
C 149.67 83.00 147.33 83.00 145.00 83.00
C 144.01 84.61 143.58 87.40 141.00 87.00
C 135.65 85.98 129.80 85.97 125.00 88.00
C 125.00 90.00 125.00 92.00 125.00 94.00
C 125.41 100.36 126.87 92.85 130.00 94.00
C 128.69 100.79 136.16 95.49 140.00 97.00
C 136.25 101.68 130.16 108.59 123.00 108.00
C 122.33 107.72 121.22 106.52 121.00 108.00
C 120.66 111.45 124.69 111.16 127.00 111.00
C 127.73 109.65 129.83 110.04 131.00 109.00
C 135.03 108.87 137.40 104.86 141.00 104.00
C 141.47 102.66 144.58 102.43 145.00 104.00
C 145.35 104.09 145.80 104.69 146.00 105.00
C 146.54 105.99 139.46 110.52 136.00 109.00
C 133.10 111.30 136.90 115.01 136.00 118.00
C 137.00 118.00 138.00 118.00 139.00 118.00
C 140.46 118.55 141.18 120.40 143.00 120.00
C 143.11 119.00 142.53 117.27 144.00 117.00
C 146.41 117.33 147.38 114.00 150.00 115.00
C 151.22 120.74 147.41 125.28 144.00 129.00
C 142.43 131.28 140.33 133.57 138.00 135.00
C 137.13 141.02 133.46 146.18 132.00 152.00
C 121.91 153.15 119.67 140.29 112.75 134.25 C 105.75 128.71 90.08 128.36 89.00 140.00
C 91.14 137.23 97.06 137.22 96.00 142.00
C 100.30 140.89 109.10 142.56 108.00 149.00
C 102.99 150.95 103.83 145.78 101.00 145.00
C 101.53 146.93 99.95 148.24 100.00 150.00
C 101.43 151.05 102.23 153.10 102.00 155.00
C 102.93 158.03 106.65 156.74 109.00 157.00
C 109.79 153.95 113.69 155.62 115.00 157.00
C 124.50 156.92 123.01 172.20 132.00 171.00
C 132.54 163.21 140.28 168.22 145.00 168.00
C 142.20 169.89 139.23 172.37 136.00 174.00
C 134.37 174.71 134.71 177.38 133.00 178.00
C 125.16 180.85 120.97 190.54 111.00 188.00
C 110.53 189.34 107.42 189.57 107.00 188.00
C 103.54 188.80 96.45 185.81 96.00 191.00
C 95.95 192.54 91.97 193.93 94.00 195.00
C 95.33 195.00 96.67 195.00 98.00 195.00
C 99.00 195.00 100.00 195.00 101.00 195.00
C 102.00 195.00 103.00 195.00 104.00 195.00
C 106.09 200.24 99.98 195.36 98.00 197.00
C 97.03 199.52 93.08 200.72 94.00 204.00
C 95.14 203.97 95.72 202.47 97.00 203.00
C 98.40 204.21 100.26 203.98 102.00 204.00
C 107.81 204.79 112.66 201.92 118.00 201.00
C 119.14 199.13 122.13 200.39 124.00 200.00
C 127.77 200.85 138.30 197.38 135.00 204.00
C 145.50 201.94 136.68 190.14 143.25 185.25 C 147.99 180.74 153.81 176.19 160.00 174.00
C 164.87 179.44 173.97 178.22 181.00 179.00
C 179.94 189.72 168.07 183.90 161.00 185.00
C 159.33 185.00 157.67 185.00 156.00 185.00
C 152.02 185.19 148.36 189.34 148.00 193.00
C 151.10 194.16 154.80 190.91 157.00 193.00
C 155.14 194.44 152.07 195.15 151.00 198.00
C 150.82 198.74 151.05 199.91 152.00 200.00
C 159.93 198.36 168.65 198.99 176.00 196.00
C 177.69 193.36 189.68 195.34 184.00 198.00
C 180.60 198.59 177.33 200.35 176.00 204.00
C 177.88 204.47 180.93 202.94 182.00 205.00
C 181.72 207.98 177.82 207.97 178.00 211.00
C 194.12 212.15 210.83 197.61 227.00 205.00
C 229.73 198.48 220.84 195.69 218.00 191.00
C 219.58 189.24 220.76 187.10 219.00 185.00
C 220.86 179.79 232.89 184.09 228.00 175.00
C 239.13 176.29 253.91 152.00 259.00 170.00
C 264.26 167.27 262.27 158.17 259.00 155.00
C 260.52 150.81 263.84 146.99 263.00 142.00
C 269.00 142.17 268.95 148.98 271.00 153.00
C 276.34 140.04 263.00 129.91 264.00 117.00
C 268.21 116.37 269.63 121.50 273.00 123.00
C 276.31 114.91 253.90 103.43 269.00 105.00
C 263.28 94.97 247.46 92.30 236.00 91.00
C 233.92 90.92 230.72 91.69 230.00 89.00
C 228.67 89.00 227.33 89.00 226.00 89.00
C 219.92 89.36 210.16 93.05 205.00 89.00
C 195.40 89.90 181.59 82.35 187.00 71.00
C 187.96 69.43 189.32 67.75 191.00 67.00
C 194.73 63.73 199.02 65.81 203.00 67.00
C 206.29 75.19 190.11 78.75 194.00 70.00
C 194.28 69.33 195.48 68.22 194.00 68.00
C 184.01 71.39 197.67 83.09 203.23 76.23 C 218.53 58.19 182.20 55.67 180.00 73.00
M 153.00 92.00 
C 150.36 92.30 146.18 91.56 147.00 88.00
C 149.66 87.35 153.11 88.98 153.00 92.00
M 247.00 106.00 
C 247.17 106.44 247.00 107.00 247.00 107.00
C 247.18 107.74 246.95 108.91 246.00 109.00
C 245.53 110.34 242.42 110.57 242.00 109.00
C 241.00 109.00 240.00 109.00 239.00 109.00
C 236.37 105.88 245.65 101.32 247.00 106.00
M 238.00 123.00 
C 237.60 118.58 245.03 117.11 248.22 118.78 C 251.11 124.80 241.39 122.67 238.00 123.00
M 246.00 135.00 
C 243.12 136.95 239.82 134.06 237.00 133.00
C 239.08 131.18 245.48 132.11 246.00 135.00
M 230.00 145.00 
C 232.37 143.93 237.49 147.14 236.00 149.00
C 232.47 151.65 228.56 152.62 224.00 152.00
C 222.23 154.03 218.52 152.53 216.00 153.00
C 214.23 153.21 212.63 151.46 212.00 150.00
C 211.13 149.05 209.32 149.46 209.00 148.00
C 216.09 147.63 222.70 141.73 230.00 145.00
M 206.00 187.00 
C 203.79 189.99 199.35 188.74 196.00 189.00
C 194.87 181.96 202.82 185.38 206.00 187.00 Z'
          />
        </g>
        <g fill='#737373' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 187.00 71.00 
C 188.68 70.25 190.04 68.57 191.00 67.00
C 189.32 67.75 187.96 69.43 187.00 71.00 Z'
          />
        </g>
        <g fill='#8E8E8E' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 194.00 68.00 
C 195.48 68.22 194.28 69.33 194.00 70.00
C 195.68 70.29 197.32 68.69 197.00 67.00
C 196.05 67.22 194.26 66.61 194.00 68.00 Z'
          />
        </g>
        <g fill='#909090' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 163.00 80.00 
C 164.87 79.66 163.35 76.12 166.00 77.00
C 165.10 75.38 164.47 72.63 162.00 73.00
C 162.46 75.35 163.47 77.43 163.00 80.00 Z'
          />
        </g>
        <g fill='#999999' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 172.00 77.00 
C 172.03 76.65 173.01 76.15 173.00 76.00
C 173.21 74.58 172.29 73.44 171.00 73.00
C 171.28 74.25 170.40 76.47 172.00 77.00 Z'
          />
        </g>
        <g fill='#808080' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 181.00 83.00 
C 179.87 79.84 179.87 76.47 180.00 73.00
C 178.47 75.23 177.83 81.41 181.00 83.00 Z'
          />
        </g>
        <g fill='#7A7A7A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 141.00 87.00 
C 143.58 87.40 144.01 84.61 145.00 83.00
C 147.33 83.00 149.67 83.00 152.00 83.00
C 148.04 79.59 143.07 83.69 141.00 87.00 Z'
          />
        </g>
        <g fill='#6C6C6C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 158.00 84.00 
C 156.07 84.53 154.76 82.95 153.00 83.00
C 154.18 84.15 157.14 87.31 158.00 84.00 Z'
          />
        </g>
        <g fill='#737373' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 125.00 94.00 
C 125.00 92.00 125.00 90.00 125.00 88.00
C 123.31 88.96 123.57 92.97 125.00 94.00 Z'
          />
        </g>
        <g fill='#1C1C1C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 153.00 92.00 
C 153.11 88.98 149.66 87.35 147.00 88.00
C 146.18 91.56 150.36 92.30 153.00 92.00 Z'
          />
        </g>
        <g fill='#727272' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 226.00 89.00 
C 218.71 88.05 211.77 90.87 205.00 89.00
C 210.16 93.05 219.92 89.36 226.00 89.00 Z'
          />
        </g>
        <g fill='#7F7F7F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 236.00 91.00 
C 234.74 89.34 231.83 89.91 230.00 89.00
C 230.72 91.69 233.92 90.92 236.00 91.00 Z'
          />
        </g>
        <g fill='#9D9D9D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 121.00 108.00 
C 121.22 106.52 122.33 107.72 123.00 108.00
C 119.38 105.20 123.24 102.43 123.00 99.00
C 119.81 99.45 119.04 105.87 121.00 108.00 Z'
          />
        </g>
        <g fill='#939393' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 141.00 104.00 
C 141.49 103.92 142.50 104.00 143.00 104.00
C 143.50 104.00 144.53 103.88 145.00 104.00
C 144.58 102.43 141.47 102.66 141.00 104.00 Z'
          />
        </g>
        <g fill='#383838' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 142.00 106.00 
C 143.20 103.98 145.32 106.66 146.00 105.00
C 145.80 104.69 145.35 104.09 145.00 104.00
C 144.53 103.88 143.50 104.00 143.00 104.00
C 143.09 104.89 142.13 105.20 142.00 106.00 Z'
          />
        </g>
        <g fill='#B0B0B0' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 239.00 109.00 
C 238.49 107.59 239.81 106.85 241.00 107.00
C 241.82 104.28 245.18 104.74 247.00 106.00
C 245.65 101.32 236.37 105.88 239.00 109.00 Z'
          />
        </g>
        <g fill='#8D8D8D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 142.00 106.00 
C 140.69 108.21 137.50 108.65 136.00 109.00
C 139.46 110.52 146.54 105.99 146.00 105.00
C 145.32 106.66 143.20 103.98 142.00 106.00 Z'
          />
        </g>
        <g fill='#656565' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 241.00 107.00 
C 242.76 105.52 245.21 105.83 247.00 107.00
C 247.00 107.00 247.17 106.44 247.00 106.00
C 245.18 104.74 241.82 104.28 241.00 107.00 Z'
          />
        </g>
        <g fill='#0B0B0B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 241.00 107.00 
C 239.81 106.85 238.49 107.59 239.00 109.00
C 240.00 109.00 241.00 109.00 242.00 109.00
C 243.33 109.00 244.67 109.00 246.00 109.00
C 246.95 108.91 247.18 107.74 247.00 107.00
C 245.21 105.83 242.76 105.52 241.00 107.00 Z'
          />
        </g>
        <g fill='#6E6E6E' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 127.00 111.00 
C 128.65 111.18 130.51 110.83 131.00 109.00
C 129.83 110.04 127.73 109.65 127.00 111.00 Z'
          />
        </g>
        <g fill='#7D7D7D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 246.00 109.00 
C 244.67 109.00 243.33 109.00 242.00 109.00
C 242.42 110.57 245.53 110.34 246.00 109.00 Z'
          />
        </g>
        <g fill='#7A7A7A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 139.00 118.00 
C 139.86 121.77 145.21 121.31 144.00 117.00
C 142.53 117.27 143.11 119.00 143.00 120.00
C 141.18 120.40 140.46 118.55 139.00 118.00 Z'
          />
        </g>
        <g fill='#141414' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 238.00 123.00 
C 241.39 122.67 251.11 124.80 248.22 118.78 C 245.03 117.11 237.60 118.58 238.00 123.00 Z'
          />
        </g>
        <g fill='#7A7A7A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 138.00 135.00 
C 140.33 133.57 142.43 131.28 144.00 129.00
C 141.67 130.43 139.57 132.72 138.00 135.00 Z'
          />
        </g>
        <g fill='#212121' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 246.00 135.00 
C 245.48 132.11 239.08 131.18 237.00 133.00
C 239.82 134.06 243.12 136.95 246.00 135.00 Z'
          />
        </g>
        <g fill='#0E0E0E' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 212.00 150.00 
C 213.46 150.55 214.18 152.40 216.00 152.00
C 218.67 152.00 221.33 152.00 224.00 152.00
C 228.56 152.62 232.47 151.65 236.00 149.00
C 234.80 146.60 230.79 147.87 230.00 145.00
C 222.70 141.73 216.09 147.63 209.00 148.00
C 209.32 149.46 211.13 149.05 212.00 150.00 Z'
          />
        </g>
        <g fill='#9D9D9D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 236.00 149.00 
C 237.49 147.14 232.37 143.93 230.00 145.00
C 230.79 147.87 234.80 146.60 236.00 149.00 Z'
          />
        </g>
        <g fill='#6F6F6F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 102.00 155.00 
C 102.23 153.10 101.43 151.05 100.00 150.00
C 100.34 151.71 100.80 153.89 102.00 155.00 Z'
          />
        </g>
        <g fill='#A4A4A4' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 216.00 153.00 
C 215.96 153.00 216.00 152.00 216.00 152.00
C 214.18 152.40 213.46 150.55 212.00 150.00
C 212.63 151.46 214.23 153.21 216.00 153.00 Z'
          />
        </g>
        <g fill='#616161' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 216.00 153.00 
C 218.52 152.53 222.23 154.03 224.00 152.00
C 221.33 152.00 218.67 152.00 216.00 152.00
C 216.00 152.00 215.96 153.00 216.00 153.00 Z'
          />
        </g>
        <g fill='#727272' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 109.00 157.00 
C 111.00 157.00 113.00 157.00 115.00 157.00
C 113.69 155.62 109.79 153.95 109.00 157.00 Z'
          />
        </g>
        <g fill='#747474' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 133.00 178.00 
C 134.71 177.38 134.37 174.71 136.00 174.00
C 134.60 172.06 133.04 176.71 133.00 178.00 Z'
          />
        </g>
        <g fill='#707070' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 156.00 185.00 
C 157.67 185.00 159.33 185.00 161.00 185.00
C 160.32 183.35 156.74 183.61 156.00 185.00 Z'
          />
        </g>
        <g fill='#161616' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 206.00 187.00 
C 202.82 185.38 194.87 181.96 196.00 189.00
C 199.35 188.74 203.79 189.99 206.00 187.00 Z'
          />
        </g>
        <g fill='#7B7B7B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 218.00 191.00 
C 220.63 190.58 222.78 184.88 219.00 185.00
C 220.76 187.10 219.58 189.24 218.00 191.00 Z'
          />
        </g>
        <g fill='#7F7F7F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 111.00 188.00 
C 109.67 188.00 108.33 188.00 107.00 188.00
C 107.42 189.57 110.53 189.34 111.00 188.00 Z'
          />
        </g>
        <g fill='#7D7D7D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 94.00 195.00 
C 91.97 193.93 95.95 192.54 96.00 191.00
C 93.73 191.13 90.34 197.51 94.00 195.00 Z'
          />
        </g>
        <g fill='#8D8D8D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 98.00 197.00 
C 99.98 195.36 106.09 200.24 104.00 195.00
C 103.66 197.06 101.32 197.03 101.00 195.00
C 100.00 195.00 99.00 195.00 98.00 195.00
C 97.76 195.31 98.15 196.55 98.00 197.00 Z'
          />
        </g>
        <g fill='#656565' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 104.00 195.00 
C 103.00 195.00 102.00 195.00 101.00 195.00
C 101.32 197.03 103.66 197.06 104.00 195.00 Z'
          />
        </g>
        <g fill='#7D7D7D' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 176.00 196.00 
C 178.17 196.70 186.06 194.28 184.00 198.00
C 189.68 195.34 177.69 193.36 176.00 196.00 Z'
          />
        </g>
        <g fill='#707070' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 152.00 200.00 
C 151.05 199.91 150.82 198.74 151.00 198.00
C 148.66 198.39 150.90 202.77 152.00 200.00 Z'
          />
        </g>
        <g fill='#727272' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 118.00 201.00 
C 119.87 200.61 122.86 201.87 124.00 200.00
C 122.13 200.39 119.14 199.13 118.00 201.00 Z'
          />
        </g>
        <g fill='#7C7C7C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 102.00 204.00 
C 100.26 203.98 98.40 204.21 97.00 203.00
C 97.37 205.12 101.05 205.86 102.00 204.00 Z'
          />
        </g>
        <g fill='#727272' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 182.00 205.00 
C 180.93 202.94 177.88 204.47 176.00 204.00
C 177.07 206.06 180.12 204.53 182.00 205.00 Z'
          />
        </g>
        <g fill='#DDDDDD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 256.00 251.00 
C 264.17 242.25 270.34 224.91 263.97 213.03 C 261.13 203.49 252.53 213.07 249.00 216.00
C 246.39 205.73 235.38 211.31 235.93 219.93 C 233.74 228.09 234.92 237.40 238.00 245.00
C 240.95 239.44 237.03 230.97 240.08 225.08 C 242.33 216.31 249.49 225.09 251.00 228.00
C 250.21 217.19 262.97 219.42 262.00 229.00 C 263.10 237.23 257.48 243.51 256.00 251.00 Z'
          />
        </g>
        <g fill='#E4E4E4' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 89.00 224.00 
C 89.00 227.00 89.00 230.00 89.00 233.00
C 89.05 242.56 96.46 252.34 106.00 254.00
C 105.54 249.18 96.62 249.11 95.93 243.07 C 89.57 235.25 96.40 209.75 106.00 225.00
C 114.76 224.23 111.62 208.32 103.01 210.01 C 95.75 210.50 89.40 216.78 89.00 224.00 Z'
          />
        </g>
        <g fill='#D9D9D9' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 191.00 226.00 
C 202.27 223.44 194.16 208.69 185.03 215.03 C 176.42 218.34 181.14 230.06 185.74 235.26 C 192.48 243.35 183.04 249.47 179.00 255.00
C 186.19 253.23 190.29 246.33 193.00 240.00 C 194.96 231.89 182.71 227.84 187.00 220.00
C 189.81 220.83 189.83 223.92 191.00 226.00 Z'
          />
        </g>
        <g fill='#DDDDDD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 115.00 222.00 
C 113.23 226.14 114.17 230.77 115.00 235.00
C 115.31 238.86 115.98 243.57 119.00 246.00
C 120.25 242.04 117.92 235.73 120.00 231.00
C 129.08 227.96 132.48 235.73 129.00 243.00
C 133.74 239.95 134.84 231.87 135.00 226.00 C 138.41 220.31 128.38 209.45 127.23 218.77 C 134.94 225.38 119.61 231.80 121.07 222.07 C 125.98 213.51 113.01 213.33 115.00 222.00 Z'
          />
        </g>
        <g fill='#DFDFDF' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 163.00 246.00 
C 164.14 246.93 165.55 247.08 167.00 247.00
C 178.70 243.11 181.84 219.84 169.00 215.00 C 153.92 215.80 154.18 238.30 163.00 246.00
M 170.00 224.00 
C 173.81 228.83 172.78 244.08 165.25 241.74 C 159.68 237.19 160.00 221.35 170.00 224.00 Z'
          />
        </g>
        <g fill='#D7D7D7' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 201.00 218.00 
C 201.00 220.33 201.00 222.67 201.00 225.00
C 201.20 231.14 200.64 238.65 205.00 243.00
C 204.64 234.80 202.85 224.26 208.00 216.00
C 205.86 214.60 199.89 213.77 201.00 218.00 Z'
          />
        </g>
        <g fill='#DEDEDE' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 231.00 237.00 
C 229.53 235.67 229.29 231.26 231.00 230.00
C 231.00 229.50 231.02 228.50 231.00 228.00
C 231.28 226.72 230.24 224.50 232.00 224.00
C 232.57 219.53 232.41 213.03 226.00 215.00
C 225.59 216.56 226.46 217.69 227.00 219.00
C 229.21 224.71 227.86 231.73 226.25 237.25 C 221.65 242.59 215.27 235.26 217.00 230.00
C 215.66 229.53 215.43 226.42 217.00 226.00
C 215.62 221.08 221.19 219.00 222.00 215.00
C 208.34 211.38 211.54 229.12 213.08 236.92 C 215.86 248.48 226.53 239.45 232.00 244.00
C 232.38 241.48 231.56 239.24 231.00 237.00 Z'
          />
        </g>
        <g fill='#3F3F3F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 227.00 219.00 
C 226.46 217.69 225.59 216.56 226.00 215.00
C 223.99 215.73 225.28 218.70 227.00 219.00 Z'
          />
        </g>
        <g fill='#D5D5D5' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 150.00 254.00 
C 154.66 243.42 154.64 227.65 148.75 217.26 C 139.43 216.22 132.44 238.29 140.00 244.00
C 139.05 238.83 141.13 232.41 147.00 232.00
C 150.52 238.60 149.94 247.54 150.00 254.00
M 147.00 223.00 
C 147.71 225.23 149.43 229.93 145.00 229.00
C 145.08 226.92 144.31 223.72 147.00 223.00 Z'
          />
        </g>
        <g fill='#6B6B6B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 201.00 225.00 
C 201.00 222.67 201.00 220.33 201.00 218.00
C 199.29 219.26 199.53 223.67 201.00 225.00 Z'
          />
        </g>
        <g fill='#5A5A5A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 115.00 235.00 
C 114.17 230.77 113.23 226.14 115.00 222.00
C 111.65 225.01 112.56 231.80 115.00 235.00 Z'
          />
        </g>
        <g fill='#3A3A3A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 147.00 223.00 
C 144.31 223.72 145.08 226.92 145.00 229.00
C 149.43 229.93 147.71 225.23 147.00 223.00 Z'
          />
        </g>
        <g fill='#7A7A7A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 89.00 233.00 
C 89.00 230.00 89.00 227.00 89.00 224.00
C 85.95 225.97 87.00 230.82 89.00 233.00 Z'
          />
        </g>
        <g fill='#040404' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 170.00 224.00 
C 160.00 221.35 159.68 237.19 165.25 241.74 C 172.78 244.08 173.81 228.83 170.00 224.00 Z'
          />
        </g>
        <g fill='#707070' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 231.00 228.00 
C 232.76 227.50 231.72 225.28 232.00 224.00
C 230.24 224.50 231.28 226.72 231.00 228.00 Z'
          />
        </g>
        <g fill='#6B6B6B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 217.00 230.00 
C 217.00 228.67 217.00 227.33 217.00 226.00
C 215.43 226.42 215.66 229.53 217.00 230.00 Z'
          />
        </g>
        <g fill='#808080' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 231.00 237.00 
C 231.00 234.67 231.00 232.33 231.00 230.00
C 229.29 231.26 229.53 235.67 231.00 237.00 Z'
          />
        </g>
        <g fill='#707070' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 167.00 247.00 
C 165.55 247.08 164.14 246.93 163.00 246.00
C 163.21 247.72 166.29 248.93 167.00 247.00 Z'
          />
        </g>
        <g fill='#D5D5D5' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 169.00 261.00 
C 168.61 262.57 169.94 265.22 168.00 266.00
C 168.66 272.24 166.33 279.90 170.00 285.00
C 172.10 279.45 170.17 271.76 173.00 266.00
C 178.71 270.11 177.64 280.59 185.00 283.00
C 184.49 272.78 184.85 262.02 193.00 255.00
C 183.57 248.50 183.64 264.98 181.00 270.00
C 176.82 265.54 175.42 259.04 171.00 255.00
C 169.34 256.26 169.91 259.17 169.00 261.00 Z'
          />
        </g>
        <g fill='#D5D5D5' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 158.00 255.00 
C 158.00 259.00 158.00 263.00 158.00 267.00
C 157.37 272.39 159.85 277.42 161.00 282.00
C 162.06 272.96 162.59 262.73 166.00 254.00
C 163.19 253.72 160.51 254.20 158.00 255.00 Z'
          />
        </g>
        <g fill='#767676' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 158.00 267.00 
C 158.00 263.00 158.00 259.00 158.00 255.00
C 156.29 257.87 156.43 264.06 158.00 267.00 Z'
          />
        </g>
        <g fill='#D2D2D2' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 192.00 261.00 
C 191.43 261.94 190.85 262.82 191.00 264.00
C 191.00 267.00 191.00 270.00 191.00 273.00
C 191.45 279.02 196.66 285.54 203.00 286.00
C 194.71 282.37 190.39 258.92 204.00 265.00
C 211.04 256.39 194.78 252.28 192.00 261.00 Z'
          />
        </g>
        <g fill='#686868' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 168.00 266.00 
C 169.94 265.22 168.61 262.57 169.00 261.00
C 167.06 261.78 168.39 264.43 168.00 266.00 Z'
          />
        </g>
        <g fill='#3A3A3A' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 190.00 272.00 
C 190.56 269.48 188.81 265.70 191.00 264.00
C 190.85 262.82 191.43 261.94 192.00 261.00
C 188.40 262.85 188.05 269.13 190.00 272.00 Z'
          />
        </g>
        <g fill='#8F8F8F' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 190.00 272.00 
C 190.22 272.30 190.89 272.74 191.00 273.00
C 191.00 270.00 191.00 267.00 191.00 264.00
C 188.81 265.70 190.56 269.48 190.00 272.00 Z'
          />
        </g>
        <g fill='#CECECE' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 208.00 276.00 
C 197.92 276.91 211.79 285.44 208.00 276.00 Z'
          />
        </g>
      </svg>
    </div>
  );
};

export default Chaosium;
