const CiGames = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center bg-white sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='-45 -92.85 390 557.1'
        width='100%'
        height='100%'
      >
        <path
          d='M289.342 334.7h-23.98c-1.382 0-2.171-.5-2.566-1-.79-.8-.888-1.9-.888-2.3V327c0-2.3 1.579-3.6 3.454-3.9h17.763c2.664 0 3.849 1.6 4.145 2.9h12.434s0-1.3-.099-1.8c-.592-11.1-11.151-13.3-11.052-13.3h-25.066c-13.026 0-13.816 13.1-13.816 13.6v8.4c1.184 12.1 11.645 13.5 11.842 13.6h21.71c1.974 0 3.455.5 4.244 1.5.395.4.79 1.2.69 2.2v4.2c0 3.9-2.368 4.2-2.96 4.2h-19.342c-1.776 0-3.158-.4-3.848-1.3-.296-.3-.494-.6-.593-.9H249.77c.493 13.6 11.447 15 11.842 15h24.572c12.928 0 13.619-12.4 13.619-12.9v-12.9c-.1-7.3-8.98-10.4-10.46-10.9m-209.31-23.8l-25.855 60.5h13.026L73.42 357h23.487l6.02 14.4h13.223l-25.065-60.5zm-1.382 34l6.81-15.6 6.513 15.6zm73.717-10.3l-17.763-23.7h-12.04v60.5h12.04v-39.3l17.27 21.8 17.27-21.8v39.3h12.04v-60.5h-11.35m22.6 60.5h47.762v-12.2h-35.723v-12h35.723v-12.5h-35.526v-12.1h35.526v-11.7h-47.763m-180.296 16c0-3 1.678-4 3.158-4h20.526c1.777 0 2.862 1.2 3.356 2.3h12.434l-.099-.6c-2.96-13.3-10.954-13.7-11.842-13.7H12.04C.987 310.9.1 323.5 0 323.9v33.8c0 11.8 12.138 13.7 12.04 13.7h24.276c13.914 0 15.395-13.3 15.395-13.7v-23.5H26.349v12.1h12.434v8.3c0 3-2.369 4.1-3.947 4.1H16.48c-1.677 0-2.862-.5-3.651-1.4-.79-.9-.79-2-.69-2.7zm220.757-52.6H300l-3.06-155.5-65.92-40m65.427 17.1L295.263 39 230.33 0l.493 56.2m-21.611 85.4l-65.625 30-.198 32.4-74.901.1 1.085-64.6 74.31-32.3-.1 26.2 65.231-29.5L208.421.5 4.737 90.8 0 274.3h209.704'
          color='#000'
        />
      </svg>
    </div>
  );
};

export default CiGames;
