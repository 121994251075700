const PolsatGames = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-[23%]'>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width='100%'
        height='100%'
        viewBox='0 0 900 900'
        enableBackground='new 0 0 900 900'
      >
        <path
          fill='#01273A'
          opacity='1.000000'
          stroke='none'
          d='
M482.000000,901.000000 
	C321.333344,901.000000 161.166672,901.000000 1.000000,901.000000 
	C1.000000,601.000000 1.000000,301.000000 1.000000,1.000000 
	C301.000000,1.000000 601.000000,1.000000 901.000000,1.000000 
	C901.000000,301.000000 901.000000,601.000000 901.000000,901.000000 
	C761.500000,901.000000 622.000000,901.000000 482.000000,901.000000 
M355.260376,182.264542 
	C355.260376,182.264542 355.348785,182.267120 354.841614,182.460144 
	C354.621429,182.739822 354.401276,183.019501 354.183624,183.191040 
	C354.183624,183.191040 354.291107,183.203278 353.777832,183.397888 
	C353.555817,183.681992 353.333801,183.966095 353.125885,184.157852 
	C353.125885,184.157852 353.216797,184.179230 352.491547,184.160858 
	C351.891571,184.950333 351.291626,185.739807 350.665222,186.649826 
	C350.665222,186.649826 350.542053,186.657471 349.852753,186.620163 
	C348.747498,187.921555 347.642212,189.222946 346.143616,190.112961 
	C346.268097,190.256134 346.392548,190.399292 345.864410,190.564850 
	C345.440643,191.218567 345.016876,191.872284 344.227936,192.174103 
	C344.341461,192.299316 344.454987,192.424530 343.970520,192.642914 
	C343.657196,193.121292 343.343872,193.599670 343.013763,194.015549 
	C343.013763,194.015549 343.076050,194.033173 342.361298,194.054016 
	C340.709686,196.162827 339.058075,198.271637 337.365387,200.303574 
	C337.365387,200.303574 337.452515,200.302139 336.882965,200.531799 
	C336.610687,201.052261 336.338409,201.572739 336.019287,202.026154 
	C336.019287,202.026154 336.094818,202.057510 335.467987,202.221664 
	C335.115417,202.931946 334.762878,203.642227 334.058136,204.141388 
	C334.058136,204.141388 334.423889,204.327545 333.854645,204.532333 
	C333.567993,205.039185 333.281342,205.546036 332.867798,206.483185 
	C332.744751,206.780731 332.529175,206.976288 331.565002,207.225311 
	C331.238770,207.963181 330.912567,208.701050 330.583282,209.492294 
	C330.583282,209.492294 330.539246,209.522644 329.847687,209.588425 
	C329.390503,210.512772 328.933350,211.437134 328.120392,211.975403 
	C328.235413,212.105774 328.350403,212.236130 327.895813,212.573303 
	C327.639069,213.091476 327.382355,213.609665 327.051147,214.056931 
	C327.051147,214.056931 327.150391,214.083984 326.751770,214.378693 
	C326.515198,214.632080 326.397675,214.932083 326.399506,215.350815 
	C326.399506,215.350815 326.330109,215.370514 325.949860,215.466888 
	C325.989441,215.857269 326.028992,216.247650 326.513489,216.975830 
	C326.696442,217.294144 326.879425,217.612457 327.278381,218.384705 
	C327.549042,218.574966 327.819672,218.765228 328.025024,218.984497 
	C328.025024,218.984497 328.052094,218.918350 328.187744,219.637161 
	C333.068115,224.816803 345.018494,223.841385 349.978210,217.985825 
	C349.978210,217.985825 349.875275,217.881607 350.491302,217.859314 
	C350.986298,217.519653 351.481323,217.179993 351.947662,216.932968 
	C351.947662,216.932968 351.855988,216.901398 352.599487,216.866455 
	C358.536835,214.364059 364.416321,211.784912 367.571808,205.518005 
	C367.571808,205.518005 367.633881,205.504883 368.335510,205.603363 
	C369.534119,204.376740 370.732727,203.150101 371.998779,201.999390 
	C371.998779,201.999390 371.926544,201.927872 372.653656,201.946289 
	C374.069092,200.584854 375.484497,199.223434 376.946045,197.956833 
	C376.946045,197.956833 376.862518,197.892471 377.469696,197.800568 
	C377.876526,197.374878 378.283356,196.949203 378.698853,196.649765 
	C378.698853,196.649765 378.572601,196.641418 379.089355,196.479538 
	C379.295837,196.195084 379.502289,195.910645 379.838989,195.886856 
	C379.838989,195.886856 379.674561,195.646301 380.364166,195.679276 
	C380.999237,194.920761 381.634338,194.162247 382.343201,193.228333 
	C382.343201,193.228333 382.533447,193.225128 383.219574,193.419434 
	C384.078247,192.535126 384.936951,191.650818 385.837463,190.846420 
	C385.837463,190.846420 385.749390,190.826752 386.248535,190.636200 
	C386.479523,190.371048 386.710510,190.105911 386.932739,189.938141 
	C386.932739,189.938141 386.835693,189.926041 387.319397,189.741516 
	C387.550842,189.491104 387.782288,189.240707 388.007935,189.014969 
	C388.007935,189.014969 387.983612,189.007904 388.632385,188.935928 
	C389.393799,188.224670 390.155182,187.513412 390.902588,186.880875 
	C390.902588,186.880875 390.825043,186.861359 391.440155,186.788483 
	C391.808014,186.339462 392.175903,185.890442 392.742188,185.709702 
	C392.742188,185.709702 392.581024,185.417511 393.302399,185.559830 
	C395.168060,183.990097 397.033722,182.420364 398.909515,180.922318 
	C398.909515,180.922318 398.837585,180.914474 399.308014,180.743637 
	C399.605896,180.571228 399.829742,180.330109 399.987823,179.969696 
	C399.987823,179.969696 400.035217,179.989136 400.734222,179.990784 
	C401.658936,179.157974 402.583649,178.325165 403.535706,177.431717 
	C403.535706,177.431717 403.602203,177.432800 404.327148,177.576935 
	C405.869354,176.376389 407.411530,175.175842 408.975525,173.975037 
	C408.975525,173.975037 408.982025,173.954208 409.648010,173.951523 
	C410.434479,173.312607 411.220917,172.673706 412.226929,171.805115 
	C412.226929,171.805115 412.538849,171.744766 413.099640,171.607635 
	C413.394592,171.363846 413.689545,171.120071 413.952332,170.948257 
	C413.952332,170.948257 413.881378,170.913910 414.595947,170.958084 
	C415.674683,170.191971 416.753387,169.425858 417.820801,168.748383 
	C417.820801,168.748383 417.734314,168.725922 418.371063,168.735168 
	C418.818115,168.335388 419.265198,167.935593 419.883820,167.756866 
	C419.883820,167.756866 419.678253,167.567017 420.434875,167.732559 
	C422.595734,166.477783 424.756592,165.223007 426.933533,163.911179 
	C426.933533,163.911179 426.991028,163.925674 427.754333,163.981049 
	C429.114685,163.269211 430.475006,162.557373 431.889557,161.904678 
	C431.889557,161.904678 431.875885,161.825653 432.679321,161.921432 
	C433.972900,161.665482 435.288849,161.490662 436.556671,161.141327 
	C453.346954,156.514847 470.145294,152.443268 487.835297,155.647629 
	C492.135284,156.426529 496.696472,155.763504 501.137543,155.763504 
	C501.175323,155.281448 501.213074,154.799377 501.250824,154.317322 
	C500.609009,153.872498 500.007324,153.350128 499.319458,152.994400 
	C487.170013,146.711380 473.801910,145.914230 460.618896,145.210449 
	C439.746796,144.096176 419.132507,146.135178 399.593506,154.285645 
	C394.552246,156.388550 389.922546,159.478012 385.000000,162.000610 
	C385.000000,162.000610 385.106476,162.111557 384.317932,162.050247 
	C382.002502,163.497681 379.687073,164.945114 377.289154,166.344116 
	C377.289154,166.344116 377.323242,166.433502 376.583832,166.356781 
	C373.403534,168.603668 370.223236,170.850555 367.034210,173.029373 
	C367.034210,173.029373 367.098480,173.053482 366.481689,173.134232 
	C366.066376,173.559631 365.651093,173.985031 365.238678,174.331863 
	C365.238678,174.331863 365.315247,174.349808 364.624725,174.295807 
	C363.949921,175.034363 363.275085,175.772934 362.556244,176.656097 
	C362.556244,176.656097 362.405396,176.646332 361.721039,176.496368 
	C360.835480,177.371368 359.949921,178.246368 359.058563,179.049286 
	C359.058563,179.049286 359.130798,179.046265 358.658905,179.245590 
	C358.440887,179.502655 358.222900,179.759720 357.998779,179.997559 
	C357.998779,179.997559 358.016785,180.007797 357.423615,180.120087 
	C357.048126,180.560196 356.672668,181.000290 356.281494,181.317535 
	C356.281494,181.317535 356.405365,181.317383 355.891357,181.493774 
	C355.684723,181.779938 355.478119,182.066101 355.260376,182.264542 
M413.761871,606.249329 
	C413.761871,606.249329 413.651978,606.256653 413.841431,606.776245 
	C414.144867,606.966431 414.448303,607.156616 414.709229,607.254822 
	C414.709229,607.254822 414.622955,607.308044 414.888397,607.935486 
	C419.496674,612.078064 424.998108,613.260681 431.665894,612.896179 
	C432.111938,612.844666 432.557983,612.793213 433.839020,612.856262 
	C437.083679,612.185852 440.387238,611.708313 443.542480,610.744995 
	C444.717133,610.386292 446.432617,608.846313 446.385773,607.921753 
	C446.096130,602.207153 445.374176,596.514465 444.794006,590.795959 
	C429.831024,593.525024 428.755280,592.641541 428.775543,577.901184 
	C428.806213,555.612427 428.890289,533.323730 428.950378,511.035004 
	C428.955597,509.100067 428.951080,507.165131 428.951080,504.901581 
	C421.354462,504.901581 414.451050,504.901581 407.203339,504.901581 
	C407.203339,513.145630 407.203339,521.091492 407.087402,529.961243 
	C407.126801,535.295593 407.166229,540.629883 407.044830,546.723816 
	C407.093445,547.494751 407.142059,548.265747 407.063538,549.901306 
	C407.101227,551.937988 407.138916,553.974731 407.001953,556.933655 
	C406.997589,568.242126 406.434845,579.590820 407.246521,590.840393 
	C407.602234,595.770508 410.763794,600.498108 412.718231,605.280396 
	C412.718231,605.280396 412.664093,605.226135 412.823090,605.771362 
	C413.134247,605.967346 413.445374,606.163330 413.761871,606.249329 
M560.719177,386.097778 
	C560.896667,385.769287 561.074158,385.440765 561.117554,385.136658 
	C561.117554,385.136658 561.148804,385.269287 561.762207,385.030548 
	C562.867615,383.697479 563.972961,382.364410 565.015137,381.015259 
	C565.015137,381.015259 565.033447,381.077850 565.579956,380.835907 
	C566.043640,380.210632 566.507324,379.585358 566.993103,378.989197 
	C566.993103,378.989197 566.959595,378.976196 567.492126,378.697021 
	C567.755493,378.171112 568.018860,377.645203 568.149292,377.120758 
	C568.149292,377.120758 568.158630,377.253265 568.769714,376.998810 
	C569.642029,375.743164 570.514404,374.487488 571.308716,373.267273 
	C571.308716,373.267273 571.357239,373.337891 571.922058,373.029968 
	C572.750427,371.783295 573.578796,370.536621 574.314697,369.225708 
	C574.314697,369.225708 574.260559,369.324432 574.784241,369.086487 
	C574.920349,368.742493 575.056519,368.398499 575.112366,368.091827 
	C575.112366,368.091827 575.154602,368.169586 575.661438,367.847168 
	C575.911316,367.309998 576.161133,366.772797 576.295349,366.204376 
	C576.295349,366.204376 576.277283,366.322784 576.795532,366.090668 
	C576.930969,365.746613 577.066467,365.402527 577.126587,365.127563 
	C577.126587,365.127563 577.199524,365.199158 577.718323,364.910431 
	C579.404480,361.344543 576.642578,360.591614 574.029419,359.783417 
	C574.029419,359.783417 574.311768,359.530396 573.867737,359.027313 
	C569.065979,356.859283 564.474243,357.694489 559.998596,360.006683 
	C559.998596,360.006683 560.005554,359.993622 559.382019,359.940491 
	C558.672180,360.339935 557.962402,360.739349 557.159241,361.079620 
	C557.159241,361.079620 557.088562,361.164673 556.396362,361.206360 
	C554.505493,362.380371 552.614685,363.554382 550.809570,364.760284 
	C550.809570,364.760284 550.829712,364.671021 550.183594,364.785278 
	C549.372009,365.442749 548.560364,366.100189 547.826782,366.800629 
	C547.826782,366.800629 547.860718,366.718323 547.213379,366.791534 
	C546.396301,367.459106 545.579163,368.126678 544.853271,368.846863 
	C544.853271,368.846863 544.899048,368.752106 544.340942,368.854919 
	C544.119080,369.141876 543.897156,369.428833 543.769287,369.672791 
	C543.769287,369.672791 543.715027,369.584808 543.138977,369.725372 
	C542.731812,370.136261 542.324646,370.547150 541.981628,370.978638 
	C541.981628,370.978638 541.962402,370.914062 541.360962,371.091370 
	C540.446167,371.904022 539.531311,372.716705 538.578735,373.610901 
	C538.578735,373.610901 538.650879,373.557343 538.023743,373.766937 
	C536.577148,375.073334 535.130615,376.379700 533.773865,377.754883 
	C533.773865,377.754883 533.833313,377.658661 533.236145,377.769653 
	C532.811890,378.178009 532.387634,378.586334 532.007812,379.013306 
	C532.007812,379.013306 532.007080,378.965149 531.491211,379.112305 
	C531.299744,379.402252 531.108337,379.692230 530.982971,379.975861 
	C530.982971,379.975861 530.957275,379.914612 530.340576,380.096832 
	C529.227905,381.074890 528.115173,382.052948 527.018433,383.021545 
	C527.018433,383.021545 527.031677,383.008514 526.406250,383.197998 
	C525.168091,384.395386 523.929932,385.592773 522.834473,386.821808 
	C522.834473,386.821808 522.856506,386.677277 522.161743,386.812347 
	C519.328735,389.090759 516.495789,391.369171 513.731995,393.692505 
	C513.731995,393.692505 513.749207,393.611816 513.106384,393.786041 
	C511.742676,394.868896 510.378967,395.951752 509.012878,397.010071 
	C509.012878,397.010071 509.035278,397.021393 508.435028,397.191589 
	C507.498413,397.975220 506.561798,398.758850 505.607666,399.749878 
	C505.607666,399.749878 505.670380,399.551453 505.072144,399.734680 
	C504.275940,400.375824 503.479736,401.016968 502.755646,401.697723 
	C502.755646,401.697723 502.767029,401.616211 502.141815,401.755188 
	C501.336517,402.417603 500.531219,403.080017 499.828583,403.790894 
	C499.828583,403.790894 499.867371,403.684174 499.261902,403.785950 
	C498.814667,404.175232 498.367462,404.564514 497.981598,404.981506 
	C497.981598,404.981506 497.996918,404.915924 497.405426,404.984528 
	C496.944092,405.334412 496.482758,405.684326 496.009888,406.002625 
	C496.009888,406.002625 496.034424,406.029846 495.350403,406.195129 
	C483.277496,414.090302 470.128815,419.595367 456.228424,423.590546 
	C456.228424,423.590546 456.067322,423.718567 455.168579,423.762085 
	C439.060150,426.776245 422.826202,426.834717 406.515320,425.451141 
	C407.424194,426.280151 408.304260,426.753357 409.241943,427.034393 
	C433.247833,434.228729 457.620972,435.376526 482.125061,430.491241 
	C495.793793,427.766144 509.107910,423.585693 521.019775,416.022766 
	C521.019775,416.022766 521.003418,416.089111 521.616028,416.055847 
	C522.098816,415.712616 522.581665,415.369385 523.009949,414.999969 
	C523.009949,414.999969 523.013428,415.060394 523.729919,414.968048 
	C527.708252,412.357544 531.686523,409.747040 535.797791,407.243683 
	C535.797791,407.243683 535.913086,407.117737 536.555054,406.994293 
	C537.369568,406.322357 538.184082,405.650421 538.991333,404.989685 
	C538.991333,404.989685 538.978210,404.993805 539.583008,404.827576 
	C540.505310,404.043427 541.427673,403.259277 542.356201,402.434692 
	C542.356201,402.434692 542.338501,402.397766 542.977051,402.228088 
	C544.436646,400.961456 545.896301,399.694824 547.320862,398.356659 
	C547.320862,398.356659 547.254578,398.401001 547.847107,398.289673 
	C548.249329,397.865326 548.651550,397.440948 549.001221,396.997803 
	C549.001221,396.997803 549.013306,397.052338 549.633606,396.854523 
	C550.835327,395.631470 552.037048,394.408417 553.144470,393.136566 
	C553.144470,393.136566 553.102173,393.233948 553.648621,393.065979 
	C553.857300,392.760681 554.065979,392.455383 554.144470,392.158112 
	C554.144470,392.158112 554.161072,392.287415 554.704712,392.103882 
	C554.896973,391.785248 555.089233,391.466644 555.146423,391.152039 
	C555.146423,391.152039 555.158997,391.286560 555.703918,391.108734 
	C555.899780,390.792694 556.095581,390.476685 556.158691,390.166504 
	C556.158691,390.166504 556.173767,390.298523 556.718506,390.116547 
	C556.907837,389.797729 557.097229,389.478943 557.157715,389.167053 
	C557.157715,389.167053 557.173828,389.295135 557.726685,389.131073 
	C557.918335,388.811310 558.109924,388.491547 558.172424,388.170227 
	C558.172424,388.170227 558.180603,388.299133 558.725586,388.122986 
	C558.916565,387.804962 559.107483,387.486908 559.167297,387.175110 
	C559.167297,387.175110 559.183228,387.305389 559.732178,387.131714 
	C559.921997,386.811188 560.111816,386.490662 560.175171,386.167084 
	C560.175171,386.167084 560.181885,386.293335 560.719177,386.097778 
M267.254181,673.196899 
	C266.976471,672.988159 266.698761,672.779419 266.510742,672.486328 
	C266.510742,672.486328 266.439545,672.385986 266.106384,671.835510 
	C253.852737,661.355896 230.382919,664.453186 221.730637,677.708191 
	C221.730637,677.708191 221.600281,677.646851 221.052490,677.985413 
	C220.067261,679.675964 219.082031,681.366577 218.020630,683.052307 
	C218.020630,683.052307 218.073944,683.106995 217.563278,683.557617 
	C211.823486,694.421570 212.505096,705.983154 214.372437,717.489929 
	C214.981873,721.245422 217.304459,724.722900 218.930603,728.691895 
	C218.930603,728.691895 219.160019,728.988220 219.252579,729.660034 
	C223.126770,733.180725 227.000977,736.701416 230.928680,740.125671 
	C230.928680,740.125671 230.833023,740.180542 231.244247,740.661621 
	C231.839127,740.840698 232.434006,741.019775 233.177979,741.345337 
	C233.177979,741.345337 233.377396,741.282654 233.896408,741.826111 
	C243.783905,745.087708 253.418518,745.629150 262.723480,739.102051 
	C262.723480,739.102051 262.963837,738.877441 263.611481,738.728882 
	C265.731750,735.815186 267.852020,732.901428 270.003662,729.995117 
	C270.003662,729.995117 269.977936,729.972961 270.398102,729.593750 
	C270.653381,729.092590 270.908691,728.591431 271.860809,728.014832 
	C272.560760,728.013672 273.260742,728.012512 273.995117,728.929993 
	C273.732849,732.285828 273.470551,735.641724 272.792999,739.479065 
	C272.658508,740.756287 272.524048,742.033508 272.256500,743.980103 
	C272.170746,744.655151 272.084991,745.330261 272.001221,746.000000 
	C272.001221,746.000000 272.003723,745.996460 271.545502,746.355835 
	C271.379791,746.899841 271.214050,747.443909 270.835358,748.210754 
	C270.835358,748.210754 270.766693,748.511230 270.226471,748.865479 
	C269.650299,749.761353 269.074127,750.657288 268.567932,751.813782 
	C268.567932,751.813782 268.544159,751.544983 268.078644,751.743408 
	C267.899658,752.021423 267.720642,752.299500 267.612671,752.524475 
	C267.612671,752.524475 267.528717,752.495911 266.881195,752.775085 
	C263.592010,754.243469 260.302826,755.711792 256.202728,757.135803 
	C255.138458,757.303223 254.074203,757.470642 252.146576,757.372009 
	C242.539108,755.202942 232.931656,753.033936 222.852631,750.758423 
	C221.398743,755.806702 219.713028,761.660034 217.975662,767.692688 
	C219.327774,768.518616 220.397552,769.261597 221.543503,769.858276 
	C235.259766,776.999634 249.989105,778.001465 264.803040,775.621460 
	C270.558258,774.696838 275.850006,770.887573 281.294769,768.287903 
	C281.294769,768.287903 281.194305,768.352539 281.833038,768.175354 
	C282.109131,767.840576 282.385193,767.505737 282.864960,767.190979 
	C282.864960,767.190979 282.972504,767.016846 283.595276,766.773071 
	C291.234863,759.436646 293.526337,749.972595 293.725281,739.930298 
	C294.068451,722.611877 294.050476,705.286133 294.157776,687.963257 
	C294.197815,681.500488 294.164246,675.037292 294.164246,668.250000 
	C286.578888,668.250000 279.515228,668.250000 271.960632,668.250000 
	C271.960632,671.785583 271.960632,675.093994 271.960632,679.046936 
	C270.637848,677.571167 269.876282,676.721497 269.048920,675.966125 
	C269.048920,675.966125 269.144806,675.902710 269.022644,675.317322 
	C268.486237,674.752563 267.949860,674.187866 267.341461,673.657532 
	C267.341461,673.657532 267.399780,673.711975 267.254181,673.196899 
M519.026123,672.978149 
	C519.026123,672.978149 519.085205,672.944763 518.819214,672.364441 
	C517.948425,671.789490 517.077637,671.214539 516.229614,670.717773 
	C516.229614,670.717773 516.303223,670.682922 515.995728,670.204468 
	C515.330139,669.892700 514.664551,669.580933 513.388916,668.826721 
	C505.952545,665.253113 498.257874,664.931091 490.554962,667.559937 
	C480.304230,671.058228 477.827179,680.188049 475.603027,689.505127 
	C473.385742,678.870178 469.565002,669.732117 457.975037,666.859436 
	C449.960022,664.872864 442.048828,665.237366 434.821045,669.735596 
	C427.464966,674.313538 425.889771,682.461609 422.802368,689.704834 
	C423.498260,682.833618 424.194153,675.962402 424.935455,668.642944 
	C418.087189,668.642944 411.715576,668.642944 405.054626,668.642944 
	C405.054626,694.269470 405.054626,719.526794 405.054626,744.934692 
	C412.288879,744.934692 419.152130,744.934692 426.522369,744.934692 
	C426.522369,729.956848 426.546234,715.471375 426.513397,700.986023 
	C426.493713,692.291870 429.720520,687.612427 437.107422,685.822632 
	C446.039276,683.658447 450.984131,685.911560 455.020996,694.799438 
	C455.182709,695.590515 455.344421,696.381531 455.299347,698.055481 
	C455.362610,708.311951 455.425873,718.568481 455.329285,729.747070 
	C455.378265,732.547302 455.427216,735.347534 455.238159,738.917297 
	C455.410736,740.794373 455.583313,742.671448 456.421326,745.119507 
	C462.989441,745.082397 469.557526,745.045288 476.701172,744.397217 
	C476.787537,742.406982 476.940491,740.416992 476.949432,738.426392 
	C477.006836,725.601929 476.891327,712.774719 477.120575,699.953552 
	C477.264160,691.923950 480.743866,687.581116 487.480438,686.044067 
	C496.365295,684.016968 501.160706,685.908569 505.130371,693.497925 
	C505.130371,693.497925 505.319122,693.944824 505.356567,694.651367 
	C505.521393,695.091248 505.686218,695.531067 505.731140,696.899048 
	C505.701965,711.375305 505.857208,725.855652 505.534546,740.325378 
	C505.433746,744.845825 506.902802,746.190247 511.192505,745.883484 
	C515.496826,745.575745 519.843018,745.721924 524.165527,745.849915 
	C526.765015,745.926880 527.674683,744.877930 527.661255,742.327576 
	C527.572693,725.523743 527.811218,708.713989 527.432861,691.917725 
	C527.282104,685.223694 525.305054,678.779419 520.004639,673.987183 
	C520.004639,673.987183 520.069946,673.970825 519.899475,673.470215 
	C519.610046,673.283630 519.320557,673.097046 519.026123,672.978149 
M535.753052,545.406372 
	C531.392090,546.980286 532.299500,550.779358 532.048645,554.026367 
	C532.048645,554.026367 532.120850,554.066162 531.434082,554.280396 
	C530.646973,555.842651 529.339172,557.349609 529.166443,558.977112 
	C528.546448,564.817200 528.642273,570.742859 527.856995,576.553650 
	C526.417847,587.201965 530.417419,595.841125 537.098022,603.579407 
	C538.053955,604.686707 539.778809,605.130249 541.045471,605.927734 
	C541.045471,605.927734 541.118225,605.841980 541.107178,606.597107 
	C543.688293,612.954651 551.992493,616.070068 559.623962,613.196533 
	C560.079407,613.269226 560.534912,613.341919 561.386353,614.009155 
	C565.670776,615.704163 569.624573,615.533936 572.990845,612.004822 
	C572.990845,612.004822 572.954956,611.965576 573.781616,611.992798 
	C577.599182,611.602356 581.416748,611.211914 585.598816,610.784241 
	C586.778198,611.497864 588.642090,613.532898 590.606873,613.635559 
	C595.496460,613.891052 600.444458,613.394775 605.342102,612.921692 
	C606.947693,612.766541 608.470520,611.754395 610.162170,610.543152 
	C610.250305,610.361694 610.338501,610.180176 611.013855,609.602600 
	C611.131409,606.380127 611.319397,603.158508 611.355103,599.935120 
	C611.585266,579.146179 611.838928,558.356934 611.879028,537.567383 
	C611.882141,535.955688 610.695618,533.522827 609.386414,532.906372 
	C607.028259,531.796021 603.944397,530.590759 601.124939,534.230652 
	C598.747803,534.166870 596.370605,534.103088 593.859436,533.411987 
	C593.214355,532.801880 592.569214,532.191772 591.924133,531.581665 
	C591.285217,532.398865 590.646240,533.216125 589.505066,534.233154 
	C589.035156,534.494019 588.565247,534.754883 587.349060,535.144531 
	C585.622437,535.078796 583.815002,535.315491 582.187256,534.880127 
	C579.389221,534.131897 576.771606,532.464050 573.955811,532.080261 
	C568.550110,531.343384 563.041382,530.821899 557.610596,531.049561 
	C555.092529,531.155151 552.656860,533.226562 550.169128,534.308655 
	C550.169128,534.308655 550.268250,534.305969 549.480652,534.166931 
	C548.017456,535.154724 546.554260,536.142517 545.007385,537.053284 
	C545.007385,537.053284 545.110779,537.100525 544.338013,537.057983 
	C539.414734,537.687195 536.667358,540.345398 536.561646,545.480591 
	C536.561646,545.480591 536.533142,545.530701 535.753052,545.406372 
M655.782166,572.899963 
	C655.737671,567.656677 655.693176,562.413452 655.810303,556.460327 
	C655.805969,555.976257 655.801636,555.492126 656.719849,554.703979 
	C662.331848,554.703979 667.943848,554.703979 674.100403,554.703979 
	C674.100403,548.394714 674.257446,542.929871 673.962830,537.489380 
	C673.906433,536.447693 672.175720,534.822449 671.036072,534.647461 
	C668.270264,534.222839 665.397583,534.496765 662.567993,534.483337 
	C655.929382,534.451843 655.930969,534.450439 655.935608,528.003357 
	C655.938599,523.924866 655.936157,519.846313 655.936157,515.355408 
	C650.171509,516.105835 645.259827,516.971985 640.311646,517.324219 
	C635.566040,517.661865 633.370056,519.573303 634.166260,524.528015 
	C634.479065,526.474487 634.108521,528.520691 634.230225,530.510376 
	C634.396118,533.221741 633.372192,534.284241 630.534424,534.425659 
	C622.380127,534.832092 622.386169,534.954956 622.404541,543.351807 
	C622.407837,544.849854 622.413696,546.347900 622.414978,547.845947 
	C622.420715,554.344299 622.427917,554.569031 628.829102,554.351807 
	C632.892273,554.213867 634.371094,555.398987 634.245178,559.662781 
	C633.945618,569.807678 633.971680,579.970032 634.167175,590.119507 
	C634.386169,601.489624 640.085449,609.468018 650.128357,612.284119 
	C658.511536,614.634766 666.953674,614.000488 675.166870,611.207825 
	C676.308777,610.819519 677.931213,609.295654 677.882874,608.373413 
	C677.591003,602.802063 676.877686,597.252747 676.309204,591.734436 
	C657.813232,593.108948 657.813232,593.108948 655.763428,583.246033 
	C655.708069,580.089539 655.652649,576.933044 655.782166,572.899963 
M387.151855,668.614929 
	C380.104675,668.614929 373.057495,668.614929 365.754883,668.614929 
	C365.923218,672.284119 366.080902,675.721802 366.231598,679.006287 
	C362.264435,675.397034 359.140564,671.235291 354.980530,669.014587 
	C341.823029,661.990906 322.920929,667.036377 314.373993,679.189880 
	C303.614685,694.489319 304.249939,720.643127 315.242249,735.723572 
	C326.070587,750.578979 354.962555,752.055481 363.394135,734.002502 
	C363.801208,736.900330 364.136322,739.811218 364.658356,742.688171 
	C364.819427,743.576111 365.490051,744.371521 366.873444,745.205383 
	C373.756256,744.836731 380.639038,744.468018 388.061218,743.617126 
	C388.319550,742.000244 388.798920,740.383972 388.802765,738.766479 
	C388.854309,717.128174 388.861359,695.489624 388.795654,673.851379 
	C388.790833,672.269348 388.139648,670.689209 387.151855,668.614929 
M334.261383,355.255554 
	C345.194824,360.719452 356.661774,362.136200 368.705139,360.204620 
	C386.013123,357.428619 401.454437,349.938538 415.675232,340.345032 
	C436.914673,326.016602 457.479675,310.687927 478.320953,295.769897 
	C496.069397,283.065735 513.946167,270.549896 534.448669,262.538696 
	C544.282471,258.696228 554.409973,256.138458 565.140991,257.962708 
	C579.710754,260.439545 591.201538,267.432037 598.608582,280.762756 
	C597.593384,272.113342 596.405151,263.626312 594.766846,255.227066 
	C590.610718,233.919647 572.807373,219.065277 551.140137,217.995605 
	C534.758179,217.186859 520.012207,222.338867 505.720795,229.397476 
	C481.544464,241.338333 460.448181,257.966919 438.783295,273.720062 
	C419.946869,287.416565 401.756683,302.109131 380.528625,312.159058 
	C361.541962,321.147827 342.030182,324.408264 322.383240,314.812225 
	C316.315430,311.848541 311.383362,306.559479 306.454041,302.740204 
	C306.944366,306.260315 307.683380,310.847778 308.207886,315.459625 
	C310.181152,332.809357 318.242096,346.213837 334.261383,355.255554 
M351.225250,248.937256 
	C348.026794,250.317245 344.902679,251.914459 341.616791,253.038940 
	C328.734375,257.447449 322.289856,255.546188 314.174194,245.220825 
	C314.036224,245.045258 313.614929,245.092377 312.908875,244.953674 
	C311.661316,249.567856 310.201447,254.149261 309.198700,258.828644 
	C306.132019,273.138855 313.170593,284.821014 327.175018,289.241394 
	C336.474213,292.176575 345.555359,291.066620 354.580505,288.157654 
	C368.902466,283.541443 381.035736,275.024689 393.035614,266.331421 
	C416.760712,249.143890 440.143219,231.468796 464.174530,214.723083 
	C485.515869,199.851822 508.504395,189.802032 535.723755,193.838501 
	C548.429932,195.722717 559.689392,199.958557 569.388672,208.355194 
	C570.834961,209.607285 572.469849,210.641495 574.017517,211.776505 
	C573.827942,209.532013 573.278442,207.497055 571.999939,206.158524 
	C563.282471,197.031967 554.749451,187.688751 545.518555,179.101608 
	C527.390137,162.237488 506.207916,159.879425 483.203064,167.021851 
	C461.627075,173.720627 442.679321,185.245255 424.370605,198.103302 
	C400.274689,215.025620 376.059082,231.777435 351.225250,248.937256 
M532.156311,299.670959 
	C527.946167,302.358124 523.573486,304.823517 519.553284,307.769836 
	C499.537170,322.439331 479.705017,337.360931 459.628754,351.946869 
	C443.725281,363.501190 427.617920,374.883179 408.954590,381.669739 
	C382.343842,391.346283 357.329987,389.088928 334.824097,370.599884 
	C333.204285,369.269196 331.441315,368.112793 329.744873,366.875336 
	C329.474915,367.235107 329.204956,367.594849 328.934967,367.954590 
	C337.302368,380.427582 347.935883,390.744720 359.229919,400.640228 
	C378.088013,417.163055 399.191162,419.496826 422.254272,412.332642 
	C440.732758,406.592621 457.515442,397.421448 473.248016,386.391144 
	C491.250214,373.769562 508.630463,360.249451 526.799561,347.881500 
	C538.307251,340.047913 550.286926,332.556488 562.925354,326.834045 
	C575.519287,321.131622 584.022827,324.438416 590.567322,333.966980 
	C590.706055,334.169006 591.161133,334.153778 591.996094,334.387665 
	C593.323669,329.444366 594.826416,324.549744 595.904968,319.563385 
	C596.526428,316.690216 597.125244,313.542603 596.591492,310.735840 
	C594.313354,298.755341 583.375793,289.908142 571.199646,288.926300 
	C557.063232,287.786377 544.702637,292.559662 532.156311,299.670959 
M225.023712,624.492798 
	C225.023712,629.085083 225.023712,633.677368 225.023712,638.825989 
	C231.759766,638.825989 237.727509,638.681091 243.683441,638.883606 
	C246.947998,638.994629 248.148758,637.863281 248.007507,634.586853 
	C247.555984,624.112732 247.293945,613.630554 246.938278,603.152100 
	C246.879944,601.433350 246.718323,599.718140 246.626236,598.330078 
	C250.480759,602.084167 253.640045,606.623596 257.976257,609.113220 
	C271.608856,616.940430 290.567627,611.834290 299.059448,598.588379 
	C306.711945,586.651611 307.464447,573.485596 304.923370,560.045227 
	C302.449341,546.959595 295.975037,536.739319 282.425812,532.645569 
	C265.260895,527.459473 251.846207,534.978394 247.250092,552.181763 
	C247.065338,552.873352 246.709320,553.519226 246.433105,554.186401 
	C246.159363,554.134888 245.885620,554.083313 245.611893,554.031799 
	C246.194397,547.198120 246.776901,540.364441 247.387207,533.204651 
	C241.307709,533.204651 236.127533,533.510193 231.001389,533.114807 
	C226.294388,532.751831 225.118912,534.484131 225.138550,539.025513 
	C225.260315,567.181274 225.095444,595.338318 225.023712,624.492798 
M386.009094,599.512146 
	C388.779205,593.355896 392.589691,587.445923 394.117157,580.995422 
	C398.112000,564.125183 391.191071,546.422791 378.418732,538.121887 
	C364.546448,529.106079 343.657928,529.571899 330.248444,539.196106 
	C316.539856,549.034973 311.229248,570.367737 317.947968,588.607544 
	C327.731964,615.168884 364.911926,621.473511 386.009094,599.512146 
M572.384094,747.104004 
	C584.034058,749.004639 595.278076,747.336609 606.144836,743.002075 
	C607.603271,742.420288 609.685486,740.753357 609.679810,739.597900 
	C609.651550,733.891052 609.018005,728.187073 608.569275,722.092957 
	C605.663025,723.139587 603.644592,723.856628 601.632996,724.592651 
	C593.061523,727.729187 584.296936,729.318542 575.167603,727.812988 
	C566.972107,726.461548 561.993225,721.005310 562.140381,713.111816 
	C563.927795,713.111816 565.726807,713.109009 567.525879,713.112244 
	C579.513672,713.134094 591.501465,713.166138 603.489258,713.177307 
	C614.640320,713.187622 614.640320,713.178040 614.118164,701.818420 
	C613.363098,685.391479 603.550903,672.286499 588.956909,668.213501 
	C565.573486,661.687439 544.119202,675.881165 540.821655,700.058777 
	C537.411743,725.059448 548.934570,742.674072 572.384094,747.104004 
M641.099182,669.507507 
	C629.451111,674.334534 624.655945,681.518066 625.238831,693.267273 
	C625.755615,703.681763 631.318604,709.837769 643.543091,713.343872 
	C650.583191,715.363098 657.767517,716.945618 664.660767,719.368164 
	C666.602844,720.050781 669.020020,723.098633 668.891968,724.895813 
	C668.767212,726.648010 665.809021,728.406372 663.807739,729.726929 
	C662.840820,730.364868 661.195679,730.197571 659.890991,730.065613 
	C649.563660,729.020630 639.244324,727.896362 628.139954,726.713074 
	C627.669922,731.255493 626.946777,736.960999 626.634949,742.688904 
	C626.590027,743.513794 628.401245,744.976807 629.562927,745.248291 
	C643.163818,748.427612 656.946899,749.462524 670.661804,746.531738 
	C679.830078,744.572571 687.302368,739.389221 688.997986,729.575439 
	C691.314514,716.168091 688.753052,704.618408 671.438110,699.696350 
	C664.718201,697.786194 657.763306,696.679260 651.096069,694.621216 
	C649.000671,693.974426 646.283936,691.610596 645.982605,689.697021 
	C645.440063,686.251648 648.699646,684.981812 651.699951,684.990784 
	C657.169495,685.007141 662.709839,684.951050 668.086487,685.787170 
	C673.415894,686.616028 678.580017,688.507996 684.396301,690.091553 
	C684.976379,684.483704 685.690552,679.244324 685.917908,673.984009 
	C685.963623,672.924500 684.354126,671.121460 683.183838,670.783875 
	C669.554504,666.851868 655.792786,665.177124 641.099182,669.507507 
M457.131073,608.776917 
	C457.963318,609.281555 458.735962,610.043579 459.636749,610.252319 
	C473.413422,613.443848 487.348602,614.561523 501.234131,611.527710 
	C513.598694,608.826172 520.213806,600.229797 520.377136,588.050781 
	C520.535339,576.254822 514.106445,568.062744 501.769775,564.723389 
	C494.860199,562.853088 487.780304,561.578979 480.945709,559.485718 
	C479.028046,558.898315 477.708191,556.359192 476.114136,554.715271 
	C477.986053,553.136230 479.758057,550.373840 481.749146,550.205750 
	C487.168030,549.748230 492.756317,549.894104 498.133026,550.730164 
	C503.639984,551.586426 508.975647,553.544434 514.736267,555.115723 
	C515.041321,552.688354 515.419495,550.417542 515.598694,548.131226 
	C516.647644,534.748901 519.583313,536.722900 504.970062,533.676758 
	C492.829346,531.145996 480.418823,530.386597 468.659790,535.830505 
	C459.418549,540.108826 455.016632,547.904236 455.645599,558.011414 
	C456.264648,567.959229 461.428802,574.406067 470.844360,577.491577 
	C472.898102,578.164673 474.998657,578.696716 477.083466,579.272522 
	C483.183716,580.957397 489.412445,582.295654 495.317474,584.501343 
	C497.255157,585.225037 499.705383,588.373535 499.471832,590.077454 
	C499.223206,591.891418 496.300903,593.949890 494.126495,594.724976 
	C491.733551,595.578003 488.821625,595.437195 486.208008,595.125000 
	C477.215118,594.050781 468.253784,592.712524 458.607727,591.382080 
	C458.104034,596.436340 457.531219,602.184021 457.131073,608.776917 
z'
        />
        <path
          fill='#05FA7E'
          opacity='1.000000'
          stroke='none'
          d='
M333.922943,355.106506 
	C318.242096,346.213837 310.181152,332.809357 308.207886,315.459625 
	C307.683380,310.847778 306.944366,306.260315 306.454041,302.740204 
	C311.383362,306.559479 316.315430,311.848541 322.383240,314.812225 
	C342.030182,324.408264 361.541962,321.147827 380.528625,312.159058 
	C401.756683,302.109131 419.946869,287.416565 438.783295,273.720062 
	C460.448181,257.966919 481.544464,241.338333 505.720795,229.397476 
	C520.012207,222.338867 534.758179,217.186859 551.140137,217.995605 
	C572.807373,219.065277 590.610718,233.919647 594.766846,255.227066 
	C596.405151,263.626312 597.593384,272.113342 598.608582,280.762756 
	C591.201538,267.432037 579.710754,260.439545 565.140991,257.962708 
	C554.409973,256.138458 544.282471,258.696228 534.448669,262.538696 
	C513.946167,270.549896 496.069397,283.065735 478.320953,295.769897 
	C457.479675,310.687927 436.914673,326.016602 415.675232,340.345032 
	C401.454437,349.938538 386.013123,357.428619 368.705139,360.204620 
	C356.661774,362.136200 345.194824,360.719452 333.922943,355.106506 
z'
        />
        <path
          fill='#DEF618'
          opacity='1.000000'
          stroke='none'
          d='
M351.560120,248.769897 
	C376.059082,231.777435 400.274689,215.025620 424.370605,198.103302 
	C442.679321,185.245255 461.627075,173.720627 483.203064,167.021851 
	C506.207916,159.879425 527.390137,162.237488 545.518555,179.101608 
	C554.749451,187.688751 563.282471,197.031967 571.999939,206.158524 
	C573.278442,207.497055 573.827942,209.532013 574.017517,211.776505 
	C572.469849,210.641495 570.834961,209.607285 569.388672,208.355194 
	C559.689392,199.958557 548.429932,195.722717 535.723755,193.838501 
	C508.504395,189.802032 485.515869,199.851822 464.174530,214.723083 
	C440.143219,231.468796 416.760712,249.143890 393.035614,266.331421 
	C381.035736,275.024689 368.902466,283.541443 354.580505,288.157654 
	C345.555359,291.066620 336.474213,292.176575 327.175018,289.241394 
	C313.170593,284.821014 306.132019,273.138855 309.198700,258.828644 
	C310.201447,254.149261 311.661316,249.567856 312.908875,244.953674 
	C313.614929,245.092377 314.036224,245.045258 314.174194,245.220825 
	C322.289856,255.546188 328.734375,257.447449 341.616791,253.038940 
	C344.902679,251.914459 348.026794,250.317245 351.560120,248.769897 
z'
        />
        <path
          fill='#0DD897'
          opacity='1.000000'
          stroke='none'
          d='
M532.466919,299.473389 
	C544.702637,292.559662 557.063232,287.786377 571.199646,288.926300 
	C583.375793,289.908142 594.313354,298.755341 596.591492,310.735840 
	C597.125244,313.542603 596.526428,316.690216 595.904968,319.563385 
	C594.826416,324.549744 593.323669,329.444366 591.996094,334.387665 
	C591.161133,334.153778 590.706055,334.169006 590.567322,333.966980 
	C584.022827,324.438416 575.519287,321.131622 562.925354,326.834045 
	C550.286926,332.556488 538.307251,340.047913 526.799561,347.881500 
	C508.630463,360.249451 491.250214,373.769562 473.248016,386.391144 
	C457.515442,397.421448 440.732758,406.592621 422.254272,412.332642 
	C399.191162,419.496826 378.088013,417.163055 359.229919,400.640228 
	C347.935883,390.744720 337.302368,380.427582 328.934967,367.954590 
	C329.204956,367.594849 329.474915,367.235107 329.744873,366.875336 
	C331.441315,368.112793 333.204285,369.269196 334.824097,370.599884 
	C357.329987,389.088928 382.343842,391.346283 408.954590,381.669739 
	C427.617920,374.883179 443.725281,363.501190 459.628754,351.946869 
	C479.705017,337.360931 499.537170,322.439331 519.553284,307.769836 
	C523.573486,304.823517 527.946167,302.358124 532.466919,299.473389 
z'
        />
        <path
          fill='#0BD697'
          opacity='1.000000'
          stroke='none'
          d='
M225.024719,623.993896 
	C225.095444,595.338318 225.260315,567.181274 225.138550,539.025513 
	C225.118912,534.484131 226.294388,532.751831 231.001389,533.114807 
	C236.127533,533.510193 241.307709,533.204651 247.387207,533.204651 
	C246.776901,540.364441 246.194397,547.198120 245.611893,554.031799 
	C245.885620,554.083313 246.159363,554.134888 246.433105,554.186401 
	C246.709320,553.519226 247.065338,552.873352 247.250092,552.181763 
	C251.846207,534.978394 265.260895,527.459473 282.425812,532.645569 
	C295.975037,536.739319 302.449341,546.959595 304.923370,560.045227 
	C307.464447,573.485596 306.711945,586.651611 299.059448,598.588379 
	C290.567627,611.834290 271.608856,616.940430 257.976257,609.113220 
	C253.640045,606.623596 250.480759,602.084167 246.626236,598.330078 
	C246.718323,599.718140 246.879944,601.433350 246.938278,603.152100 
	C247.293945,613.630554 247.555984,624.112732 248.007507,634.586853 
	C248.148758,637.863281 246.947998,638.994629 243.683441,638.883606 
	C237.727509,638.681091 231.759766,638.825989 225.023712,638.825989 
	C225.023712,633.677368 225.023712,629.085083 225.024719,623.993896 
M254.311874,589.269104 
	C266.479584,597.322327 281.112305,593.220276 283.922638,580.241455 
	C285.200409,574.340149 284.529480,567.674133 283.278046,561.659729 
	C281.900055,555.037109 276.594177,551.623474 269.804962,550.946533 
	C262.266449,550.194946 255.476746,551.414062 251.069778,558.456909 
	C245.821564,566.844238 247.106705,581.275024 254.311874,589.269104 
z'
        />
        <path
          fill='#0BD898'
          opacity='1.000000'
          stroke='none'
          d='
M253.009933,757.638000 
	C254.074203,757.470642 255.138458,757.303223 256.915527,757.331543 
	C262.161865,758.277222 265.237549,756.158447 267.528717,752.495911 
	C267.528717,752.495911 267.612671,752.524475 267.830261,752.475708 
	C268.213257,752.132996 268.378723,751.838989 268.544189,751.544983 
	C268.544159,751.544983 268.567932,751.813782 268.891785,751.623779 
	C269.732697,750.459534 270.249695,749.485413 270.766693,748.511230 
	C270.766693,748.511230 270.835358,748.210754 271.141052,747.896362 
	C271.632446,747.053528 271.818085,746.524963 272.003723,745.996460 
	C272.003723,745.996460 272.001221,746.000000 272.334412,745.862305 
	C272.970123,744.619568 273.272675,743.514648 273.519287,742.169067 
	C273.448547,741.594177 273.501862,741.271057 273.827942,740.824402 
	C273.954895,740.159180 273.877228,739.628723 273.654083,739.059509 
	C273.508575,739.020691 273.208282,738.997559 273.208282,738.997559 
	C273.470551,735.641724 273.732849,732.285828 274.106232,728.084229 
	C274.382996,724.364380 274.548645,721.490356 274.714294,718.616272 
	C274.294250,718.598511 273.874207,718.580688 273.454163,718.562927 
	C272.609192,721.370300 271.764252,724.177673 270.731262,727.244873 
	C270.354828,728.327515 270.166382,729.150208 269.977966,729.972961 
	C269.977936,729.972961 270.003662,729.995117 269.701965,730.163208 
	C267.254822,733.180054 265.109344,736.028748 262.963867,738.877441 
	C262.963837,738.877441 262.723480,739.102051 262.166504,739.152832 
	C255.982391,740.153198 250.384354,741.421509 244.717743,741.939392 
	C240.988770,742.280151 237.160950,741.539246 233.377396,741.282654 
	C233.377396,741.282654 233.177979,741.345337 232.885422,741.044189 
	C232.006256,740.555542 231.419647,740.368042 230.833023,740.180542 
	C230.833023,740.180542 230.928680,740.125671 230.760864,739.863892 
	C226.782028,736.064148 222.971039,732.526184 219.160034,728.988220 
	C219.160019,728.988220 218.930603,728.691895 218.868134,728.118652 
	C217.860794,724.541809 216.566498,721.598816 216.032822,718.523804 
	C213.960907,706.585388 214.253586,694.731018 218.073944,683.106995 
	C218.073944,683.106995 218.020630,683.052307 218.372040,682.938293 
	C219.682388,681.098450 220.641327,679.372681 221.600281,677.646851 
	C221.600281,677.646851 221.730637,677.708191 222.006958,677.633057 
	C223.118790,677.027771 224.049057,676.603088 224.775284,675.951233 
	C233.084396,668.492371 242.826447,665.824768 253.658020,667.946655 
	C258.040253,668.805115 262.186127,670.870056 266.439545,672.385986 
	C266.439545,672.385986 266.510742,672.486328 266.535034,672.789307 
	C266.839478,673.298889 267.119629,673.505432 267.399780,673.711975 
	C267.399780,673.711975 267.341461,673.657532 267.359619,673.970337 
	C267.966797,674.822998 268.555817,675.362854 269.144806,675.902710 
	C269.144806,675.902710 269.048920,675.966125 269.068542,676.296631 
	C269.944550,677.664490 270.800934,678.701904 271.657288,679.739258 
	C272.419312,678.441467 273.914948,677.084106 273.815308,675.856140 
	C273.401031,670.749329 275.882843,669.430786 280.372894,669.911865 
	C282.178772,670.105408 284.043030,670.119995 285.845276,669.910767 
	C290.575623,669.361572 292.194977,671.208191 292.146545,676.061401 
	C291.927887,697.978210 292.235229,719.901245 291.910095,741.815369 
	C291.838440,746.643066 290.390198,751.593323 288.782074,756.210693 
	C287.453827,760.024597 284.953522,763.430298 282.972534,767.016846 
	C282.972504,767.016846 282.864960,767.190979 282.463806,767.304810 
	C281.773224,767.729980 281.483795,768.041260 281.194336,768.352539 
	C281.194305,768.352539 281.294769,768.287903 280.948944,768.311401 
	C277.895447,769.539551 275.276459,771.030396 272.465332,771.901001 
	C257.448761,776.551392 242.546875,776.374939 227.705643,770.826599 
	C219.371735,767.710938 219.300293,767.921997 221.876389,759.721741 
	C222.273361,758.458069 222.728729,757.184387 222.894333,755.881897 
	C223.378723,752.072205 225.283630,751.789978 228.472137,753.097229 
	C232.449692,754.728027 236.505402,756.432190 240.693512,757.209351 
	C244.687958,757.950623 248.895859,757.541443 253.009933,757.638000 
M270.787811,710.791687 
	C270.859100,707.469238 271.218414,704.122925 270.945435,700.828979 
	C270.242859,692.351746 265.355865,687.094116 257.057190,685.443481 
	C242.766418,682.601074 233.480667,690.989258 233.975601,706.294006 
	C234.311768,716.689575 239.302277,722.939392 248.406708,724.366699 
	C259.838013,726.158752 267.107605,721.980042 270.787811,710.791687 
z'
        />
        <path
          fill='#0BD697'
          opacity='1.000000'
          stroke='none'
          d='
M387.521851,744.099365 
	C380.639038,744.468018 373.756256,744.836731 366.317322,744.742188 
	C366.365631,736.668823 366.970032,729.058533 367.574432,721.448303 
	C367.112366,721.368408 366.650299,721.288574 366.188232,721.208679 
	C365.038666,725.400330 363.889099,729.591919 362.739563,733.783569 
	C354.962555,752.055481 326.070587,750.578979 315.242249,735.723572 
	C304.249939,720.643127 303.614685,694.489319 314.373993,679.189880 
	C322.920929,667.036377 341.823029,661.990906 354.980530,669.014587 
	C359.140564,671.235291 362.264435,675.397034 366.231598,679.006287 
	C366.080902,675.721802 365.923218,672.284119 365.754883,668.614929 
	C373.057495,668.614929 380.104675,668.614929 387.353119,669.244385 
	C387.419952,687.484253 387.235535,705.094543 387.175476,722.705200 
	C387.151154,729.836121 387.398254,736.967896 387.521851,744.099365 
M332.720215,722.755493 
	C343.991547,732.402710 360.534637,728.706238 363.585999,715.224487 
	C364.950684,709.194702 364.312775,702.319702 362.981873,696.175232 
	C361.466492,689.178894 355.038910,685.418823 346.861115,685.298706 
	C339.595825,685.191895 333.029755,687.343201 330.105103,694.625671 
	C326.339996,704.000854 326.173065,713.430176 332.720215,722.755493 
z'
        />
        <path
          fill='#0CD698'
          opacity='1.000000'
          stroke='none'
          d='
M560.990356,613.414551 
	C560.534912,613.341919 560.079407,613.269226 558.890625,613.139404 
	C552.477539,610.668823 546.797852,608.255432 541.118225,605.842041 
	C541.118225,605.841980 541.045471,605.927734 540.952942,605.599487 
	C535.193848,599.305176 531.891968,592.177490 530.417969,584.133850 
	C528.555420,573.969666 529.412048,563.967407 532.120850,554.066162 
	C532.120850,554.066162 532.048645,554.026367 532.314880,553.826782 
	C533.898438,550.928406 535.215820,548.229553 536.533142,545.530701 
	C536.533142,545.530701 536.561646,545.480591 536.811096,545.284546 
	C539.743958,542.425842 542.427368,539.763184 545.110779,537.100525 
	C545.110779,537.100525 545.007385,537.053284 545.377319,537.026001 
	C547.254333,536.101074 548.761292,535.203552 550.268250,534.306030 
	C550.268250,534.305969 550.169128,534.308655 550.584961,534.334717 
	C565.736877,530.522705 579.372131,530.463745 588.387451,546.265686 
	C588.664978,545.937073 588.942505,545.608398 589.220032,545.279785 
	C588.845154,541.858459 588.470276,538.437134 588.095398,535.015808 
	C588.565247,534.754883 589.035156,534.494019 590.147888,534.228149 
	C591.858276,534.161865 592.925903,534.100586 593.993469,534.039368 
	C596.370605,534.103088 598.747803,534.166870 601.998901,534.248047 
	C610.367676,534.667603 610.338135,532.802856 610.278015,541.915161 
	C610.210144,552.190796 610.142212,562.466858 610.169800,572.742493 
	C610.203125,585.161377 610.336609,597.579956 610.426758,609.998657 
	C610.338501,610.180176 610.250305,610.361694 609.626831,610.868896 
	C602.212402,611.194702 595.333374,611.194702 587.908386,611.194702 
	C588.454590,604.760254 588.929382,599.167603 589.404114,593.575012 
	C586.617126,596.892578 585.325928,600.928528 582.805115,603.932739 
	C580.130005,607.120728 576.287598,609.329285 572.954956,611.965576 
	C572.954956,611.965576 572.990845,612.004822 572.566711,611.980835 
	C568.425171,612.442749 564.707764,612.928650 560.990356,613.414551 
M554.508423,587.892700 
	C559.719543,594.287964 566.711365,594.834961 574.049866,593.504395 
	C580.800354,592.280518 585.441040,587.917053 586.309998,581.302185 
	C587.112915,575.189819 587.061462,568.668030 585.801025,562.658997 
	C584.319153,555.593628 577.919739,551.849670 569.855042,551.466919 
	C561.871643,551.087952 555.205811,554.462219 552.627136,560.927429 
	C549.073303,569.837646 549.055603,578.761230 554.508423,587.892700 
z'
        />
        <path
          fill='#0BD798'
          opacity='1.000000'
          stroke='none'
          d='
M385.792572,599.793701 
	C364.911926,621.473511 327.731964,615.168884 317.947968,588.607544 
	C311.229248,570.367737 316.539856,549.034973 330.248444,539.196106 
	C343.657928,529.571899 364.546448,529.106079 378.418732,538.121887 
	C391.191071,546.422791 398.112000,564.125183 394.117157,580.995422 
	C392.589691,587.445923 388.779205,593.355896 385.792572,599.793701 
M338.074524,583.169373 
	C342.507263,591.617737 349.128754,594.571289 359.857697,592.885620 
	C367.310333,591.714722 372.342621,586.514221 373.434448,577.779663 
	C373.980804,573.408813 373.853607,568.774963 373.032104,564.449768 
	C371.404327,555.879211 364.985077,551.245056 355.680603,551.069336 
	C345.964996,550.885864 338.928772,555.156128 337.451569,563.827209 
	C336.426056,569.846802 337.567810,576.235657 338.074524,583.169373 
z'
        />
        <path
          fill='#0CD798'
          opacity='1.000000'
          stroke='none'
          d='
M571.956177,747.051636 
	C548.934570,742.674072 537.411743,725.059448 540.821655,700.058777 
	C544.119202,675.881165 565.573486,661.687439 588.956909,668.213501 
	C603.550903,672.286499 613.363098,685.391479 614.118164,701.818420 
	C614.640320,713.178040 614.640320,713.187622 603.489258,713.177307 
	C591.501465,713.166138 579.513672,713.134094 567.525879,713.112244 
	C565.726807,713.109009 563.927795,713.111816 562.140381,713.111816 
	C561.993225,721.005310 566.972107,726.461548 575.167603,727.812988 
	C584.296936,729.318542 593.061523,727.729187 601.632996,724.592651 
	C603.644592,723.856628 605.663025,723.139587 608.569275,722.092957 
	C609.018005,728.187073 609.651550,733.891052 609.679810,739.597900 
	C609.685486,740.753357 607.603271,742.420288 606.144836,743.002075 
	C595.278076,747.336609 584.034058,749.004639 571.956177,747.051636 
M593.325684,699.180786 
	C592.310730,690.042297 586.197998,684.767273 577.272156,685.327209 
	C568.035889,685.906677 562.134338,691.617249 562.283508,700.359192 
	C571.332397,700.572327 580.380676,700.834900 589.430786,700.939941 
	C590.647339,700.954163 591.873047,700.174500 593.325684,699.180786 
z'
        />
        <path
          fill='#0CD698'
          opacity='1.000000'
          stroke='none'
          d='
M641.473328,669.383606 
	C655.792786,665.177124 669.554504,666.851868 683.183838,670.783875 
	C684.354126,671.121460 685.963623,672.924500 685.917908,673.984009 
	C685.690552,679.244324 684.976379,684.483704 684.396301,690.091553 
	C678.580017,688.507996 673.415894,686.616028 668.086487,685.787170 
	C662.709839,684.951050 657.169495,685.007141 651.699951,684.990784 
	C648.699646,684.981812 645.440063,686.251648 645.982605,689.697021 
	C646.283936,691.610596 649.000671,693.974426 651.096069,694.621216 
	C657.763306,696.679260 664.718201,697.786194 671.438110,699.696350 
	C688.753052,704.618408 691.314514,716.168091 688.997986,729.575439 
	C687.302368,739.389221 679.830078,744.572571 670.661804,746.531738 
	C656.946899,749.462524 643.163818,748.427612 629.562927,745.248291 
	C628.401245,744.976807 626.590027,743.513794 626.634949,742.688904 
	C626.946777,736.960999 627.669922,731.255493 628.139954,726.713074 
	C639.244324,727.896362 649.563660,729.020630 659.890991,730.065613 
	C661.195679,730.197571 662.840820,730.364868 663.807739,729.726929 
	C665.809021,728.406372 668.767212,726.648010 668.891968,724.895813 
	C669.020020,723.098633 666.602844,720.050781 664.660767,719.368164 
	C657.767517,716.945618 650.583191,715.363098 643.543091,713.343872 
	C631.318604,709.837769 625.755615,703.681763 625.238831,693.267273 
	C624.655945,681.518066 629.451111,674.334534 641.473328,669.383606 
z'
        />
        <path
          fill='#0CD698'
          opacity='1.000000'
          stroke='none'
          d='
M457.044739,608.354370 
	C457.531219,602.184021 458.104034,596.436340 458.607727,591.382080 
	C468.253784,592.712524 477.215118,594.050781 486.208008,595.125000 
	C488.821625,595.437195 491.733551,595.578003 494.126495,594.724976 
	C496.300903,593.949890 499.223206,591.891418 499.471832,590.077454 
	C499.705383,588.373535 497.255157,585.225037 495.317474,584.501343 
	C489.412445,582.295654 483.183716,580.957397 477.083466,579.272522 
	C474.998657,578.696716 472.898102,578.164673 470.844360,577.491577 
	C461.428802,574.406067 456.264648,567.959229 455.645599,558.011414 
	C455.016632,547.904236 459.418549,540.108826 468.659790,535.830505 
	C480.418823,530.386597 492.829346,531.145996 504.970062,533.676758 
	C519.583313,536.722900 516.647644,534.748901 515.598694,548.131226 
	C515.419495,550.417542 515.041321,552.688354 514.736267,555.115723 
	C508.975647,553.544434 503.639984,551.586426 498.133026,550.730164 
	C492.756317,549.894104 487.168030,549.748230 481.749146,550.205750 
	C479.758057,550.373840 477.986053,553.136230 476.114136,554.715271 
	C477.708191,556.359192 479.028046,558.898315 480.945709,559.485718 
	C487.780304,561.578979 494.860199,562.853088 501.769775,564.723389 
	C514.106445,568.062744 520.535339,576.254822 520.377136,588.050781 
	C520.213806,600.229797 513.598694,608.826172 501.234131,611.527710 
	C487.348602,614.561523 473.413422,613.443848 459.636749,610.252319 
	C458.735962,610.043579 457.963318,609.281555 457.044739,608.354370 
z'
        />
        <path
          fill='#0BD697'
          opacity='1.000000'
          stroke='none'
          d='
M655.599792,584.189941 
	C657.813232,593.108948 657.813232,593.108948 676.309204,591.734436 
	C676.877686,597.252747 677.591003,602.802063 677.882874,608.373413 
	C677.931213,609.295654 676.308777,610.819519 675.166870,611.207825 
	C666.953674,614.000488 658.511536,614.634766 650.128357,612.284119 
	C640.085449,609.468018 634.386169,601.489624 634.167175,590.119507 
	C633.971680,579.970032 633.945618,569.807678 634.245178,559.662781 
	C634.371094,555.398987 632.892273,554.213867 628.829102,554.351807 
	C622.427917,554.569031 622.420715,554.344299 622.414978,547.845947 
	C622.413696,546.347900 622.407837,544.849854 622.404541,543.351807 
	C622.386169,534.954956 622.380127,534.832092 630.534424,534.425659 
	C633.372192,534.284241 634.396118,533.221741 634.230225,530.510376 
	C634.108521,528.520691 634.479065,526.474487 634.166260,524.528015 
	C633.370056,519.573303 635.566040,517.661865 640.311646,517.324219 
	C645.259827,516.971985 650.171509,516.105835 655.936157,515.355408 
	C655.936157,519.846313 655.938599,523.924866 655.935608,528.003357 
	C655.930969,534.450439 655.929382,534.451843 662.567993,534.483337 
	C665.397583,534.496765 668.270264,534.222839 671.036072,534.647461 
	C672.175720,534.822449 673.906433,536.447693 673.962830,537.489380 
	C674.257446,542.929871 674.100403,548.394714 674.100403,554.703979 
	C667.943848,554.703979 662.331848,554.703979 655.931641,555.044067 
	C654.924133,555.624695 654.516724,555.862122 654.512512,556.106445 
	C654.351135,565.465332 654.226685,574.824829 654.097412,584.184326 
	C654.598206,584.186218 655.098999,584.188110 655.599792,584.189941 
z'
        />
        <path
          fill='#98F73D'
          opacity='1.000000'
          stroke='none'
          d='
M327.062408,217.930756 
	C326.879425,217.612457 326.696442,217.294144 326.385132,216.609848 
	C326.366333,215.959930 326.390778,215.668808 326.330109,215.370514 
	C326.330109,215.370514 326.399506,215.350815 326.613281,215.186554 
	C326.934845,214.709534 327.042603,214.396759 327.150391,214.083984 
	C327.150391,214.083984 327.051147,214.056931 327.337891,213.949844 
	C327.904877,213.350662 328.185120,212.858566 328.465393,212.366470 
	C328.350403,212.236130 328.235413,212.105774 328.531067,211.990326 
	C329.474213,211.177719 330.006744,210.350174 330.539246,209.522644 
	C330.539246,209.522644 330.583282,209.492294 330.825684,209.291214 
	C331.452454,208.416702 331.836761,207.743271 332.221069,207.069839 
	C332.529175,206.976288 332.744751,206.780731 333.194763,206.142548 
	C333.822449,205.310455 334.123169,204.819000 334.423889,204.327545 
	C334.423889,204.327545 334.058136,204.141388 334.480133,204.087830 
	C335.299683,203.375351 335.697266,202.716431 336.094818,202.057510 
	C336.094818,202.057510 336.019287,202.026154 336.298798,201.917786 
	C336.869720,201.307007 337.161102,200.804565 337.452515,200.302139 
	C337.452515,200.302139 337.365387,200.303574 337.679535,200.199860 
	C339.687805,198.075150 341.381927,196.054153 343.076050,194.033173 
	C343.076050,194.033173 343.013763,194.015549 343.290405,193.956207 
	C343.900909,193.447815 344.234741,192.998795 344.568542,192.549774 
	C344.454987,192.424530 344.341461,192.299316 344.656433,192.212097 
	C345.562286,191.680878 346.039642,191.111664 346.516998,190.542450 
	C346.392548,190.399292 346.268097,190.256134 346.620483,190.175690 
	C348.245575,189.044785 349.393829,187.851135 350.542053,186.657471 
	C350.542053,186.657471 350.665222,186.649826 350.945068,186.464890 
	C351.888855,185.579712 352.552826,184.879456 353.216797,184.179230 
	C353.216797,184.179230 353.125885,184.157852 353.386719,184.120392 
	C353.862091,183.789734 354.076599,183.496506 354.291107,183.203278 
	C354.291107,183.203278 354.183624,183.191040 354.443298,183.158051 
	C354.918274,182.839096 355.133514,182.553101 355.348785,182.267120 
	C355.348785,182.267120 355.260376,182.264542 355.522186,182.220032 
	C355.991119,181.889465 356.198242,181.603424 356.405365,181.317383 
	C356.405365,181.317383 356.281494,181.317535 356.566956,181.284363 
	C357.240540,180.836731 357.628662,180.422272 358.016785,180.007812 
	C358.016785,180.007797 357.998779,179.997559 358.260193,179.940521 
	C358.724670,179.604431 358.927734,179.325348 359.130798,179.046265 
	C359.130798,179.046265 359.058563,179.049286 359.359009,179.007141 
	C360.574738,178.192108 361.490082,177.419220 362.405396,176.646332 
	C362.405396,176.646332 362.556244,176.656097 362.844360,176.467987 
	C363.860107,175.636536 364.587677,174.993179 365.315247,174.349808 
	C365.315247,174.349808 365.238678,174.331863 365.534607,174.303940 
	C366.253204,173.868500 366.675842,173.460999 367.098480,173.053497 
	C367.098480,173.053482 367.034210,173.029373 367.376892,172.974121 
	C370.920807,170.757080 374.122009,168.595291 377.323242,166.433502 
	C377.323242,166.433502 377.289154,166.344116 377.670593,166.323120 
	C380.403534,164.905273 382.755005,163.508408 385.106476,162.111542 
	C385.106476,162.111557 385.000000,162.000610 385.415955,162.014252 
	C419.475891,145.531479 454.322632,142.281693 490.343414,152.294373 
	C488.378510,153.184036 486.359467,153.245285 484.345093,153.174469 
	C466.273132,152.539246 448.546387,153.971756 431.875885,161.825653 
	C431.875885,161.825653 431.889557,161.904678 431.521606,161.874725 
	C429.766113,162.538406 428.378571,163.232025 426.991028,163.925659 
	C426.991028,163.925674 426.933533,163.911179 426.579590,163.956284 
	C424.043152,165.189926 421.860687,166.378464 419.678253,167.567017 
	C419.678253,167.567017 419.883820,167.756866 419.494843,167.678650 
	C418.648682,167.975601 418.191498,168.350754 417.734314,168.725922 
	C417.734314,168.725922 417.820801,168.748383 417.489563,168.732697 
	C416.066010,169.449310 414.973694,170.181610 413.881378,170.913910 
	C413.881378,170.913910 413.952332,170.948257 413.669220,170.948090 
	C413.103699,171.213547 412.821289,171.479156 412.538879,171.744781 
	C412.538849,171.744766 412.226929,171.805115 411.807770,171.950714 
	C410.586395,172.715591 409.784210,173.334900 408.982025,173.954193 
	C408.982025,173.954208 408.975525,173.975037 408.640137,174.030792 
	C406.737244,175.201981 405.169739,176.317398 403.602203,177.432800 
	C403.602203,177.432800 403.535706,177.431717 403.229706,177.564453 
	C401.960876,178.461166 400.998047,179.225143 400.035217,179.989136 
	C400.035217,179.989136 399.987823,179.969696 399.724731,180.037659 
	C399.253632,180.375229 399.045624,180.644852 398.837585,180.914474 
	C398.837585,180.914474 398.909515,180.922318 398.579468,180.974579 
	C396.359985,182.490402 394.470520,183.953964 392.581024,185.417511 
	C392.581024,185.417511 392.742188,185.709702 392.366699,185.668671 
	C391.602478,186.038895 391.213776,186.450134 390.825043,186.861359 
	C390.825043,186.861359 390.902588,186.880875 390.604980,186.921600 
	C389.532776,187.644196 388.758179,188.326035 387.983612,189.007904 
	C387.983612,189.007904 388.007935,189.014969 387.743347,189.048340 
	C387.264404,189.363159 387.050049,189.644592 386.835693,189.926041 
	C386.835693,189.926041 386.932739,189.938141 386.667084,189.954636 
	C386.184082,190.256332 385.966736,190.541534 385.749390,190.826752 
	C385.749390,190.826752 385.837463,190.846420 385.532593,190.898834 
	C384.329620,191.709213 383.431549,192.467163 382.533447,193.225128 
	C382.533447,193.225128 382.343201,193.228333 382.036621,193.426575 
	C381.044861,194.298660 380.359711,194.972473 379.674561,195.646301 
	C379.674561,195.646301 379.838989,195.886856 379.515869,195.837662 
	C378.986053,196.072784 378.779327,196.357117 378.572632,196.641418 
	C378.572601,196.641418 378.698853,196.649765 378.402771,196.662201 
	C377.691956,197.080597 377.277252,197.486542 376.862518,197.892471 
	C376.862518,197.892471 376.946045,197.956833 376.606934,197.995575 
	C374.820770,199.332184 373.373657,200.630035 371.926544,201.927887 
	C371.926544,201.927872 371.998779,201.999390 371.659912,202.053955 
	C370.092010,203.240646 368.862946,204.372772 367.633881,205.504883 
	C367.633881,205.504883 367.571808,205.518005 367.256470,205.671906 
	C361.912750,209.517670 356.884369,213.209518 351.855988,216.901398 
	C351.855988,216.901398 351.947662,216.932968 351.650146,216.914032 
	C350.860199,217.223953 350.367737,217.552780 349.875275,217.881607 
	C349.875275,217.881607 349.978210,217.985825 349.562378,217.976135 
	C342.236755,220.980026 335.308075,223.575912 328.052094,218.918350 
	C328.052094,218.918350 328.025024,218.984497 327.969055,218.727783 
	C327.629547,218.290970 327.345978,218.110870 327.062408,217.930756 
z'
        />
        <path
          fill='#0DD699'
          opacity='1.000000'
          stroke='none'
          d='
M455.029633,693.988770 
	C450.984131,685.911560 446.039276,683.658447 437.107422,685.822632 
	C429.720520,687.612427 426.493713,692.291870 426.513397,700.986023 
	C426.546234,715.471375 426.522369,729.956848 426.522369,744.934692 
	C419.152130,744.934692 412.288879,744.934692 405.054626,744.934692 
	C405.054626,719.526794 405.054626,694.269470 405.054626,668.642944 
	C411.715576,668.642944 418.087189,668.642944 424.935455,668.642944 
	C424.194153,675.962402 423.498260,682.833618 422.802368,689.704834 
	C425.889771,682.461609 427.464966,674.313538 434.821045,669.735596 
	C442.048828,665.237366 449.960022,664.872864 457.975037,666.859436 
	C469.565002,669.732117 473.385742,678.870178 475.603027,689.505127 
	C477.827179,680.188049 480.304230,671.058228 490.554962,667.559937 
	C498.257874,664.931091 505.952545,665.253113 513.812256,669.320251 
	C514.924805,670.103516 515.614014,670.393188 516.303223,670.682922 
	C516.303223,670.682922 516.229614,670.717773 516.323730,670.998779 
	C517.307007,671.834778 518.196106,672.389771 519.085205,672.944763 
	C519.085205,672.944763 519.026123,672.978149 519.158813,673.160339 
	C519.550903,673.552002 519.810425,673.761414 520.069946,673.970825 
	C520.069946,673.970825 520.004639,673.987183 520.031555,674.332092 
	C520.717346,675.982971 521.520630,677.237427 522.007996,678.604553 
	C523.496765,682.780212 525.928223,686.961121 526.080322,691.208435 
	C526.549500,704.311462 526.094238,717.446228 526.066223,730.568726 
	C526.034180,745.602112 526.041870,745.571777 510.747314,744.066162 
	C507.982727,743.794006 506.940521,742.598694 506.995514,739.972839 
	C507.269867,726.870850 507.574738,713.768555 507.671051,700.664917 
	C507.682526,699.104797 506.487061,697.535828 505.851074,695.970947 
	C505.686218,695.531067 505.521393,695.091248 505.404968,694.180054 
	C505.499695,693.414490 505.397644,693.182617 505.147247,693.013062 
	C501.160706,685.908569 496.365295,684.016968 487.480438,686.044067 
	C480.743866,687.581116 477.264160,691.923950 477.120575,699.953552 
	C476.891327,712.774719 477.006836,725.601929 476.949432,738.426392 
	C476.940491,740.416992 476.787537,742.406982 476.326721,744.425537 
	C475.638855,743.972900 475.325470,743.491943 474.999512,742.585327 
	C474.658783,733.751892 474.229645,725.346619 474.028381,716.935791 
	C473.783661,706.710144 474.652191,696.475220 472.178619,686.317932 
	C471.043121,681.655273 469.083038,678.508972 464.576172,676.857361 
	C463.094727,676.314514 461.385895,675.490112 460.538696,674.268799 
	C455.946259,667.648621 452.383087,666.356995 444.654846,669.076233 
	C444.896240,670.422974 445.142120,671.794922 445.417816,673.333008 
	C443.076294,672.337341 441.017822,670.661438 439.201843,670.892273 
	C436.922852,671.182068 433.773041,672.517090 432.899811,674.321106 
	C431.097137,678.045227 427.360413,681.523987 428.407043,685.376892 
	C431.635132,684.787354 433.983612,684.207153 436.366760,683.955811 
	C440.881378,683.479614 445.417023,683.205261 449.941559,682.818970 
	C451.528168,682.683533 453.907043,681.745972 454.526093,682.433533 
	C455.861450,683.916565 456.591095,686.154480 457.003357,688.197571 
	C457.380371,690.065857 457.013428,692.084290 456.650146,694.030762 
	C455.893860,694.012878 455.461761,694.000793 455.029633,693.988770 
M424.000031,728.492676 
	C424.164673,716.519470 424.329346,704.546204 424.497864,692.291077 
	C423.172729,692.291077 421.433105,692.291077 419.173065,692.291077 
	C418.549164,684.790344 424.206757,677.871094 420.791748,670.119812 
	C419.493317,671.119934 418.497559,671.886963 417.501801,672.653992 
	C414.728760,671.065979 411.793762,669.944763 409.305389,670.578186 
	C407.847870,670.949219 406.196289,674.107544 406.175568,676.028687 
	C405.949036,697.015564 405.976868,718.006287 406.143524,738.994202 
	C406.153961,740.311096 407.726440,742.537537 408.868439,742.750244 
	C412.260162,743.381775 415.799774,743.328186 419.281982,743.334534 
	C422.747009,743.340881 424.374115,741.635254 424.046539,737.978882 
	C423.794830,735.169617 423.998718,732.319580 424.000031,728.492676 
z'
        />
        <path
          fill='#0ED696'
          opacity='1.000000'
          stroke='none'
          d='
M456.067322,423.718567 
	C456.067322,423.718567 456.228424,423.590546 456.746368,423.700806 
	C471.088776,422.119476 482.780029,415.225952 494.503357,408.449341 
	C495.235291,408.026215 495.534119,406.853729 496.034424,406.029846 
	C496.034424,406.029846 496.009888,406.002625 496.307587,405.976074 
	C497.069153,405.605011 497.533051,405.260468 497.996918,404.915924 
	C497.996918,404.915924 497.981598,404.981506 498.256104,404.927368 
	C498.976227,404.476868 499.421783,404.080505 499.867371,403.684174 
	C499.867371,403.684174 499.828583,403.790894 500.121613,403.770111 
	C501.198761,403.038269 501.982880,402.327240 502.767029,401.616211 
	C502.767029,401.616211 502.755646,401.697723 503.066559,401.703735 
	C504.141754,400.990295 504.906067,400.270844 505.670349,399.551453 
	C505.670380,399.551453 505.607666,399.749878 505.965637,399.696777 
	C507.227509,398.769592 508.131378,397.895508 509.035278,397.021393 
	C509.035278,397.021393 509.012878,397.010071 509.355804,396.969086 
	C511.048889,395.822693 512.399048,394.717255 513.749207,393.611816 
	C513.749207,393.611816 513.731995,393.692505 514.065186,393.689636 
	C517.217773,391.350281 520.037109,389.013763 522.856506,386.677277 
	C522.856506,386.677277 522.834473,386.821808 523.107178,386.737457 
	C524.597168,385.438263 525.814453,384.223389 527.031738,383.008545 
	C527.031677,383.008514 527.018433,383.021545 527.366455,383.016479 
	C528.795349,381.979126 529.876282,380.946869 530.957275,379.914612 
	C530.957275,379.914612 530.982971,379.975861 531.171753,379.856018 
	C531.576111,379.479156 531.791626,379.222168 532.007080,378.965149 
	C532.007080,378.965149 532.007812,379.013306 532.285767,378.951660 
	C532.986877,378.479553 533.410095,378.069122 533.833313,377.658661 
	C533.833313,377.658661 533.773865,377.754883 534.085571,377.739990 
	C535.815125,376.335876 537.233032,374.946625 538.650879,373.557373 
	C538.650879,373.557343 538.578735,373.610901 538.951538,373.621460 
	C540.203674,372.726013 541.083008,371.820038 541.962402,370.914062 
	C541.962402,370.914062 541.981628,370.978638 542.248230,370.914856 
	C542.914917,370.428986 543.314941,370.006897 543.715027,369.584808 
	C543.715027,369.584808 543.769287,369.672791 543.977295,369.604919 
	C544.423279,369.275421 544.661194,369.013794 544.899048,368.752136 
	C544.899048,368.752106 544.853271,368.846863 545.147522,368.817566 
	C546.248108,368.098297 547.054443,367.408325 547.860718,366.718323 
	C547.860718,366.718323 547.826782,366.800629 548.128540,366.785645 
	C549.230103,366.070770 550.029907,365.370880 550.829712,364.671021 
	C550.829712,364.671021 550.809570,364.760284 551.140503,364.756409 
	C553.343811,363.556580 555.216187,362.360596 557.088562,361.164673 
	C557.088562,361.164673 557.159241,361.079620 557.514404,361.088318 
	C558.581543,360.729218 559.293579,360.361420 560.005554,359.993622 
	C560.005554,359.993622 559.998596,360.006683 560.417114,360.020081 
	C565.327637,359.865784 569.819702,359.698090 574.311768,359.530396 
	C574.311768,359.530396 574.029419,359.783417 574.252930,360.019775 
	C575.384094,361.903809 576.291809,363.551483 577.199524,365.199158 
	C577.199524,365.199158 577.126587,365.127563 576.946960,365.252930 
	C576.604004,365.693146 576.440674,366.007965 576.277283,366.322784 
	C576.277283,366.322784 576.295349,366.204376 576.071716,366.360199 
	C575.616943,367.067200 575.385742,367.618408 575.154602,368.169586 
	C575.154602,368.169586 575.112366,368.091827 574.935303,368.234711 
	C574.592285,368.693207 574.426453,369.008820 574.260620,369.324463 
	C574.260559,369.324432 574.314697,369.225708 574.011047,369.333984 
	C572.924011,370.740814 572.140625,372.039368 571.357239,373.337891 
	C571.357239,373.337891 571.308716,373.267273 571.023071,373.357361 
	C569.877869,374.716064 569.018250,375.984650 568.158630,377.253265 
	C568.158630,377.253265 568.149292,377.120758 567.950195,377.266113 
	C567.487244,377.933014 567.223389,378.454620 566.959595,378.976196 
	C566.959595,378.976196 566.993103,378.989197 566.682007,379.060669 
	C565.925049,379.780731 565.479248,380.429291 565.033447,381.077820 
	C565.033447,381.077850 565.015137,381.015259 564.712036,381.081787 
	C563.322266,382.521973 562.235535,383.895630 561.148804,385.269287 
	C561.148804,385.269287 561.117554,385.136658 560.945740,385.261353 
	C560.576538,385.688477 560.379211,385.990906 560.181885,386.293335 
	C560.181885,386.293335 560.175171,386.167084 559.989624,386.290375 
	C559.597107,386.710907 559.390198,387.008148 559.183228,387.305389 
	C559.183228,387.305389 559.167297,387.175110 558.985229,387.293884 
	C558.595581,387.708130 558.388123,388.003632 558.180603,388.299133 
	C558.180603,388.299133 558.172424,388.170227 557.988281,388.291382 
	C557.593994,388.706726 557.383911,389.000916 557.173828,389.295135 
	C557.173828,389.295135 557.157715,389.167053 556.976074,389.288391 
	C556.587524,389.705963 556.380676,390.002258 556.173767,390.298523 
	C556.173767,390.298523 556.158691,390.166504 555.976807,390.285828 
	C555.582947,390.698944 555.370972,390.992767 555.158997,391.286560 
	C555.158997,391.286560 555.146423,391.152039 554.967529,391.275726 
	C554.579407,391.695435 554.370239,391.991425 554.161072,392.287415 
	C554.161072,392.287415 554.144470,392.158112 553.961182,392.272827 
	C553.552673,392.669678 553.327454,392.951813 553.102173,393.233948 
	C553.102173,393.233948 553.144470,393.136566 552.839661,393.181152 
	C551.361023,394.501312 550.187195,395.776825 549.013306,397.052368 
	C549.013306,397.052338 549.001221,396.997803 548.728821,397.062500 
	C548.055847,397.551788 547.655212,397.976410 547.254578,398.401001 
	C547.254578,398.401001 547.320862,398.356659 546.984070,398.355225 
	C545.210999,399.701752 543.774719,401.049744 542.338501,402.397766 
	C542.338501,402.397766 542.356201,402.434692 542.006470,402.394012 
	C540.763855,403.233490 539.871033,404.113647 538.978210,404.993805 
	C538.978210,404.993805 538.991333,404.989685 538.650940,404.995911 
	C537.511414,405.707306 536.712219,406.412506 535.913086,407.117737 
	C535.913086,407.117737 535.797791,407.243683 535.347656,407.272980 
	C530.936157,409.888306 526.974792,412.474335 523.013428,415.060364 
	C523.013428,415.060394 523.009949,414.999969 522.737122,415.047119 
	C521.977356,415.425903 521.490356,415.757507 521.003418,416.089111 
	C521.003418,416.089111 521.019775,416.022766 520.668945,416.040894 
	C518.757263,416.609314 517.138855,417.035431 515.644348,417.728638 
	C493.831421,427.846283 470.878113,432.681580 446.881195,431.948944 
	C439.144623,431.712738 431.448608,430.148132 423.734009,429.191223 
	C423.779877,428.765533 423.825745,428.339844 423.871613,427.914154 
	C427.519836,427.914154 431.235779,428.360626 434.802032,427.820007 
	C441.933838,426.738922 448.983643,425.117096 456.067322,423.718567 
z'
        />
        <path
          fill='#0AD898'
          opacity='1.000000'
          stroke='none'
          d='
M433.004028,612.741699 
	C432.557983,612.793213 432.111938,612.844666 431.089447,612.578430 
	C425.216339,610.609802 419.919647,608.958923 414.622955,607.307983 
	C414.622955,607.308044 414.709229,607.254822 414.619232,607.053589 
	C414.236816,606.653809 413.944397,606.455200 413.651978,606.256653 
	C413.651978,606.256653 413.761871,606.249329 413.646179,606.054565 
	C413.241699,605.648560 412.952881,605.437317 412.664093,605.226135 
	C412.664093,605.226135 412.718231,605.280396 412.707336,604.911743 
	C407.872223,593.304993 407.164703,581.697937 408.695953,569.639404 
	C409.255615,565.232117 407.743561,560.561768 407.176605,556.011414 
	C407.138916,553.974731 407.101227,551.937988 407.386963,549.356934 
	C407.542145,547.863098 407.373901,546.913696 407.205658,545.964233 
	C407.166229,540.629883 407.126801,535.295593 407.467590,529.298706 
	C408.218506,525.080444 408.836670,521.530151 408.916138,517.967896 
	C409.221375,504.287842 407.059113,506.296600 420.794037,505.905121 
	C425.509277,505.770691 427.181824,507.187561 427.130585,512.080078 
	C426.904144,533.703735 427.020386,555.331055 427.028320,576.957092 
	C427.034271,593.066345 427.288361,593.320984 443.627899,593.794250 
	C443.844330,597.164185 443.672241,600.674438 444.387512,603.993469 
	C445.208893,607.804810 443.452637,609.091003 440.318939,609.999817 
	C437.825348,610.723145 435.438354,611.814026 433.004028,612.741699 
z'
        />
        <path
          fill='#14C391'
          opacity='1.000000'
          stroke='none'
          d='
M433.421509,612.798950 
	C435.438354,611.814026 437.825348,610.723145 440.318939,609.999817 
	C443.452637,609.091003 445.208893,607.804810 444.387512,603.993469 
	C443.672241,600.674438 443.844330,597.164185 443.627899,593.794250 
	C427.288361,593.320984 427.034271,593.066345 427.028320,576.957092 
	C427.020386,555.331055 426.904144,533.703735 427.130585,512.080078 
	C427.181824,507.187561 425.509277,505.770691 420.794037,505.905121 
	C407.059113,506.296600 409.221375,504.287842 408.916138,517.967896 
	C408.836670,521.530151 408.218506,525.080444 407.525543,528.836792 
	C407.203339,521.091492 407.203339,513.145630 407.203339,504.901581 
	C414.451050,504.901581 421.354462,504.901581 428.951080,504.901581 
	C428.951080,507.165131 428.955597,509.100067 428.950378,511.035004 
	C428.890289,533.323730 428.806213,555.612427 428.775543,577.901184 
	C428.755280,592.641541 429.831024,593.525024 444.794006,590.795959 
	C445.374176,596.514465 446.096130,602.207153 446.385773,607.921753 
	C446.432617,608.846313 444.717133,610.386292 443.542480,610.744995 
	C440.387238,611.708313 437.083679,612.185852 433.421509,612.798950 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M432.277588,161.873535 
	C448.546387,153.971756 466.273132,152.539246 484.345093,153.174469 
	C486.359467,153.245285 488.378510,153.184036 490.343414,152.294373 
	C454.322632,142.281693 419.475891,145.531479 385.467834,162.071075 
	C389.922546,159.478012 394.552246,156.388550 399.593506,154.285645 
	C419.132507,146.135178 439.746796,144.096176 460.618896,145.210449 
	C473.801910,145.914230 487.170013,146.711380 499.319458,152.994400 
	C500.007324,153.350128 500.609009,153.872498 501.250824,154.317322 
	C501.213074,154.799377 501.175323,155.281448 501.137543,155.763504 
	C496.696472,155.763504 492.135284,156.426529 487.835297,155.647629 
	C470.145294,152.443268 453.346954,156.514847 436.556671,161.141327 
	C435.288849,161.490662 433.972900,161.665482 432.277588,161.873535 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M283.283905,766.894958 
	C284.953522,763.430298 287.453827,760.024597 288.782074,756.210693 
	C290.390198,751.593323 291.838440,746.643066 291.910095,741.815369 
	C292.235229,719.901245 291.927887,697.978210 292.146545,676.061401 
	C292.194977,671.208191 290.575623,669.361572 285.845276,669.910767 
	C284.043030,670.119995 282.178772,670.105408 280.372894,669.911865 
	C275.882843,669.430786 273.401031,670.749329 273.815308,675.856140 
	C273.914948,677.084106 272.419312,678.441467 271.657288,679.739258 
	C270.800934,678.701904 269.944550,677.664490 269.101440,676.249512 
	C269.876282,676.721497 270.637848,677.571167 271.960632,679.046936 
	C271.960632,675.093994 271.960632,671.785583 271.960632,668.250000 
	C279.515228,668.250000 286.578888,668.250000 294.164246,668.250000 
	C294.164246,675.037292 294.197815,681.500488 294.157776,687.963257 
	C294.050476,705.286133 294.068451,722.611877 293.725281,739.930298 
	C293.526337,749.972595 291.234863,759.436646 283.283905,766.894958 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M455.617950,423.740326 
	C448.983643,425.117096 441.933838,426.738922 434.802032,427.820007 
	C431.235779,428.360626 427.519836,427.914154 423.871613,427.914154 
	C423.825745,428.339844 423.779877,428.765533 423.734009,429.191223 
	C431.448608,430.148132 439.144623,431.712738 446.881195,431.948944 
	C470.878113,432.681580 493.831421,427.846283 515.644348,417.728638 
	C517.138855,417.035431 518.757263,416.609314 520.700439,416.054138 
	C509.107910,423.585693 495.793793,427.766144 482.125061,430.491241 
	C457.620972,435.376526 433.247833,434.228729 409.241943,427.034393 
	C408.304260,426.753357 407.424194,426.280151 406.515320,425.451141 
	C422.826202,426.834717 439.060150,426.776245 455.617950,423.740326 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M505.791077,696.434998 
	C506.487061,697.535828 507.682526,699.104797 507.671051,700.664917 
	C507.574738,713.768555 507.269867,726.870850 506.995514,739.972839 
	C506.940521,742.598694 507.982727,743.794006 510.747314,744.066162 
	C526.041870,745.571777 526.034180,745.602112 526.066223,730.568726 
	C526.094238,717.446228 526.549500,704.311462 526.080322,691.208435 
	C525.928223,686.961121 523.496765,682.780212 522.007996,678.604553 
	C521.520630,677.237427 520.717346,675.982971 520.053589,674.306641 
	C525.305054,678.779419 527.282104,685.223694 527.432861,691.917725 
	C527.811218,708.713989 527.572693,725.523743 527.661255,742.327576 
	C527.674683,744.877930 526.765015,745.926880 524.165527,745.849915 
	C519.843018,745.721924 515.496826,745.575745 511.192505,745.883484 
	C506.902802,746.190247 505.433746,744.845825 505.534546,740.325378 
	C505.857208,725.855652 505.701965,711.375305 505.791077,696.434998 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M252.578247,757.505005 
	C248.895859,757.541443 244.687958,757.950623 240.693512,757.209351 
	C236.505402,756.432190 232.449692,754.728027 228.472137,753.097229 
	C225.283630,751.789978 223.378723,752.072205 222.894333,755.881897 
	C222.728729,757.184387 222.273361,758.458069 221.876389,759.721741 
	C219.300293,767.921997 219.371735,767.710938 227.705643,770.826599 
	C242.546875,776.374939 257.448761,776.551392 272.465332,771.901001 
	C275.276459,771.030396 277.895447,769.539551 280.977997,768.363647 
	C275.850006,770.887573 270.558258,774.696838 264.803040,775.621460 
	C249.989105,778.001465 235.259766,776.999634 221.543503,769.858276 
	C220.397552,769.261597 219.327774,768.518616 217.975662,767.692688 
	C219.713028,761.660034 221.398743,755.806702 222.852631,750.758423 
	C232.931656,753.033936 242.539108,755.202942 252.578247,757.505005 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M610.720337,609.800659 
	C610.336609,597.579956 610.203125,585.161377 610.169800,572.742493 
	C610.142212,562.466858 610.210144,552.190796 610.278015,541.915161 
	C610.338135,532.802856 610.367676,534.667603 602.438843,534.171326 
	C603.944397,530.590759 607.028259,531.796021 609.386414,532.906372 
	C610.695618,533.522827 611.882141,535.955688 611.879028,537.567383 
	C611.838928,558.356934 611.585266,579.146179 611.355103,599.935120 
	C611.319397,603.158508 611.131409,606.380127 610.720337,609.800659 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M573.368286,611.979187 
	C576.287598,609.329285 580.130005,607.120728 582.805115,603.932739 
	C585.325928,600.928528 586.617126,596.892578 589.404114,593.575012 
	C588.929382,599.167603 588.454590,604.760254 587.908386,611.194702 
	C595.333374,611.194702 602.212402,611.194702 609.561340,611.164429 
	C608.470520,611.754395 606.947693,612.766541 605.342102,612.921692 
	C600.444458,613.394775 595.496460,613.891052 590.606873,613.635559 
	C588.642090,613.532898 586.778198,611.497864 585.598816,610.784241 
	C581.416748,611.211914 577.599182,611.602356 573.368286,611.979187 
z'
        />
        <path
          fill='#026350'
          opacity='1.000000'
          stroke='none'
          d='
M387.791504,743.858215 
	C387.398254,736.967896 387.151154,729.836121 387.175476,722.705200 
	C387.235535,705.094543 387.419952,687.484253 387.671692,669.491028 
	C388.139648,670.689209 388.790833,672.269348 388.795654,673.851379 
	C388.861359,695.489624 388.854309,717.128174 388.802765,738.766479 
	C388.798920,740.383972 388.319550,742.000244 387.791504,743.858215 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M531.777466,554.173279 
	C529.412048,563.967407 528.555420,573.969666 530.417969,584.133850 
	C531.891968,592.177490 535.193848,599.305176 541.004089,605.576050 
	C539.778809,605.130249 538.053955,604.686707 537.098022,603.579407 
	C530.417419,595.841125 526.417847,587.201965 527.856995,576.553650 
	C528.642273,570.742859 528.546448,564.817200 529.166443,558.977112 
	C529.339172,557.349609 530.646973,555.842651 531.777466,554.173279 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M587.722229,535.080200 
	C588.470276,538.437134 588.845154,541.858459 589.220032,545.279785 
	C588.942505,545.608398 588.664978,545.937073 588.387451,546.265686 
	C579.372131,530.463745 565.736877,530.522705 550.592346,534.383789 
	C552.656860,533.226562 555.092529,531.155151 557.610596,531.049561 
	C563.041382,530.821899 568.550110,531.343384 573.955811,532.080261 
	C576.771606,532.464050 579.389221,534.131897 582.187256,534.880127 
	C583.815002,535.315491 585.622437,535.078796 587.722229,535.080200 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M266.272949,672.110718 
	C262.186127,670.870056 258.040253,668.805115 253.658020,667.946655 
	C242.826447,665.824768 233.084396,668.492371 224.775284,675.951233 
	C224.049057,676.603088 223.118790,677.027771 221.934937,677.634338 
	C230.382919,664.453186 253.852737,661.355896 266.272949,672.110718 
z'
        />
        <path
          fill='#14C391'
          opacity='1.000000'
          stroke='none'
          d='
M407.089294,556.472534 
	C407.743561,560.561768 409.255615,565.232117 408.695953,569.639404 
	C407.164703,581.697937 407.872223,593.304993 412.673859,604.930359 
	C410.763794,600.498108 407.602234,595.770508 407.246521,590.840393 
	C406.434845,579.590820 406.997589,568.242126 407.089294,556.472534 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M217.818604,683.332275 
	C214.253586,694.731018 213.960907,706.585388 216.032822,718.523804 
	C216.566498,721.598816 217.860794,724.541809 218.824615,727.936462 
	C217.304459,724.722900 214.981873,721.245422 214.372437,717.489929 
	C212.505096,705.983154 211.823486,694.421570 217.818604,683.332275 
z'
        />
        <path
          fill='#1ED8A3'
          opacity='1.000000'
          stroke='none'
          d='
M475.012085,743.010925 
	C475.325470,743.491943 475.638855,743.972900 476.038940,744.731079 
	C469.557526,745.045288 462.989441,745.082397 456.159241,744.409912 
	C455.756836,741.849426 455.616516,739.998596 455.476196,738.147766 
	C455.427216,735.347534 455.378265,732.547302 455.561432,728.926270 
	C455.697754,717.794495 455.601929,707.483521 455.506104,697.172607 
	C455.344421,696.381531 455.182709,695.590515 455.025330,694.394043 
	C455.461761,694.000793 455.893860,694.012878 456.656372,694.495300 
	C456.991730,709.562134 457.082001,724.159302 456.943268,738.754395 
	C456.907349,742.534119 458.056366,744.287109 462.053101,744.047791 
	C466.020142,743.810059 470.011658,743.981262 474.185944,743.839355 
	C474.623962,743.504639 474.832550,743.271484 475.002869,743.003296 
	C475.000549,743.000488 475.012085,743.010925 475.012085,743.010925 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M495.692444,406.112488 
	C495.534119,406.853729 495.235291,408.026215 494.503357,408.449341 
	C482.780029,415.225952 471.088776,422.119476 456.816345,423.776245 
	C470.128815,419.595367 483.277496,414.090302 495.692444,406.112488 
z'
        />
        <path
          fill='#026350'
          opacity='1.000000'
          stroke='none'
          d='
M363.066833,733.893066 
	C363.889099,729.591919 365.038666,725.400330 366.188232,721.208679 
	C366.650299,721.288574 367.112366,721.368408 367.574432,721.448303 
	C366.970032,729.058533 366.365631,736.668823 365.844299,744.744141 
	C365.490051,744.371521 364.819427,743.576111 364.658356,742.688171 
	C364.136322,739.811218 363.801208,736.900330 363.066833,733.893066 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M233.636902,741.554382 
	C237.160950,741.539246 240.988770,742.280151 244.717743,741.939392 
	C250.384354,741.421509 255.982391,740.153198 262.006226,739.189819 
	C253.418518,745.629150 243.783905,745.087708 233.636902,741.554382 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M541.112671,606.219604 
	C546.797852,608.255432 552.477539,610.668823 558.545959,613.183716 
	C551.992493,616.070068 543.688293,612.954651 541.112671,606.219604 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M328.119934,219.277756 
	C335.308075,223.575912 342.236755,220.980026 349.520813,217.915894 
	C345.018494,223.841385 333.068115,224.816803 328.119934,219.277756 
z'
        />
        <path
          fill='#1ED8A3'
          opacity='1.000000'
          stroke='none'
          d='
M655.681641,583.718018 
	C655.098999,584.188110 654.598206,584.186218 654.097412,584.184326 
	C654.226685,574.824829 654.351135,565.465332 654.512512,556.106445 
	C654.516724,555.862122 654.924133,555.624695 655.470398,555.196045 
	C655.801636,555.492126 655.805969,555.976257 655.574829,557.174561 
	C655.425354,563.184692 655.511353,568.480591 655.597290,573.776550 
	C655.652649,576.933044 655.708069,580.089539 655.681641,583.718018 
z'
        />
        <path
          fill='#14C391'
          opacity='1.000000'
          stroke='none'
          d='
M414.755676,607.621704 
	C419.919647,608.958923 425.216339,610.609802 430.754791,612.528320 
	C424.998108,613.260681 419.496674,612.078064 414.755676,607.621704 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M352.227722,216.883911 
	C356.884369,213.209518 361.912750,209.517670 367.251221,205.703186 
	C364.416321,211.784912 358.536835,214.364059 352.227722,216.883911 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M455.402710,697.614014 
	C455.601929,707.483521 455.697754,717.794495 455.641357,728.465210 
	C455.425873,718.568481 455.362610,708.311951 455.402710,697.614014 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M219.206314,729.324097 
	C222.971039,732.526184 226.782028,736.064148 230.734100,739.912109 
	C227.000977,736.701416 223.126770,733.180725 219.206314,729.324097 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M523.371704,415.014221 
	C526.974792,412.474335 530.936157,409.888306 535.281189,407.219421 
	C531.686523,409.747040 527.708252,412.357544 523.371704,415.014221 
z'
        />
        <path
          fill='#026350'
          opacity='1.000000'
          stroke='none'
          d='
M270.919281,726.985046 
	C271.764252,724.177673 272.609192,721.370300 273.454163,718.562927 
	C273.874207,718.580688 274.294250,718.598511 274.714294,718.616272 
	C274.548645,721.490356 274.382996,724.364380 274.089020,727.624878 
	C273.260742,728.012512 272.560760,728.013672 271.448181,727.776855 
	C270.996765,727.354187 270.958008,727.169617 270.919281,726.985046 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M574.089722,359.278870 
	C569.819702,359.698090 565.327637,359.865784 560.410400,360.015808 
	C564.474243,357.694489 569.065979,356.859283 574.089722,359.278870 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M544.724365,537.079224 
	C542.427368,539.763184 539.743958,542.425842 536.793701,545.261536 
	C536.667358,540.345398 539.414734,537.687195 544.724365,537.079224 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M561.188354,613.711914 
	C564.707764,612.928650 568.425171,612.442749 572.558350,611.955933 
	C569.624573,615.533936 565.670776,615.704163 561.188354,613.711914 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M376.953552,166.395142 
	C374.122009,168.595291 370.920807,170.757080 367.381256,173.008163 
	C370.223236,170.850555 373.403534,168.603668 376.953552,166.395142 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M267.204956,752.635498 
	C265.237549,756.158447 262.161865,758.277222 257.320984,757.353760 
	C260.302826,755.711792 263.592010,754.243469 267.204956,752.635498 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M522.509155,386.744812 
	C520.037109,389.013763 517.217773,391.350281 514.030640,393.667175 
	C516.495789,391.369171 519.328735,389.090759 522.509155,386.744812 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M263.287659,738.803162 
	C265.109344,736.028748 267.254822,733.180054 269.686279,730.159546 
	C267.852020,732.901428 265.731750,735.815186 263.287659,738.803162 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M536.143066,545.468506 
	C535.215820,548.229553 533.898438,550.928406 532.330688,553.864868 
	C532.299500,550.779358 531.392090,546.980286 536.143066,545.468506 
z'
        />
        <path
          fill='#0BD697'
          opacity='1.000000'
          stroke='none'
          d='
M655.689697,573.338257 
	C655.511353,568.480591 655.425354,563.184692 655.494019,557.529419 
	C655.693176,562.413452 655.737671,567.656677 655.689697,573.338257 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M342.718689,194.043594 
	C341.381927,196.054153 339.687805,198.075150 337.700073,200.238281 
	C339.058075,198.271637 340.709686,196.162827 342.718689,194.043594 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M384.712219,162.080902 
	C382.755005,163.508408 380.403534,164.905273 377.711853,166.347351 
	C379.687073,164.945114 382.002502,163.497681 384.712219,162.080902 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M577.458923,365.054810 
	C576.291809,363.551483 575.384094,361.903809 574.403625,359.905029 
	C576.642578,360.591614 579.404480,361.344543 577.458923,365.054810 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M556.742432,361.185486 
	C555.216187,362.360596 553.343811,363.556580 551.097656,364.740479 
	C552.614685,363.554382 554.505493,362.380371 556.742432,361.185486 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M392.941711,185.488678 
	C394.470520,183.953964 396.359985,182.490402 398.574402,180.938751 
	C397.033722,182.420364 395.168060,183.990097 392.941711,185.488678 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M420.056580,167.649780 
	C421.860687,166.378464 424.043152,165.189926 426.571533,163.984802 
	C424.756592,165.223007 422.595734,166.477783 420.056580,167.649780 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M403.964661,177.504868 
	C405.169739,176.317398 406.737244,175.201981 408.629242,174.030930 
	C407.411530,175.175842 405.869354,176.376389 403.964661,177.504868 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M538.337341,373.662170 
	C537.233032,374.946625 535.815125,376.335876 534.040649,377.705566 
	C535.130615,376.379700 536.577148,375.073334 538.337341,373.662170 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M542.657715,402.312927 
	C543.774719,401.049744 545.210999,399.701752 547.001587,398.390991 
	C545.896301,399.694824 544.436646,400.961456 542.657715,402.312927 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M221.326385,677.816162 
	C220.641327,679.372681 219.682388,681.098450 218.410126,682.940674 
	C219.082031,681.366577 220.067261,679.675964 221.326385,677.816162 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M372.290100,201.937088 
	C373.373657,200.630035 374.820770,199.332184 376.583893,197.948166 
	C375.484497,199.223434 374.069092,200.584854 372.290100,201.937088 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M367.984680,205.554138 
	C368.862946,204.372772 370.092010,203.240646 371.626221,202.015991 
	C370.732727,203.150101 369.534119,204.376740 367.984680,205.554138 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M549.323486,396.953430 
	C550.187195,395.776825 551.361023,394.501312 552.886841,393.205566 
	C552.037048,394.408417 550.835327,395.631470 549.323486,396.953430 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M593.926453,533.725708 
	C592.925903,534.100586 591.858276,534.161865 590.399048,534.128235 
	C590.646240,533.216125 591.285217,532.398865 591.924133,531.581665 
	C592.569214,532.191772 593.214355,532.801880 593.926453,533.725708 
z'
        />
        <path
          fill='#012F3D'
          opacity='1.000000'
          stroke='none'
          d='
M549.874451,534.236450 
	C548.761292,535.203552 547.254333,536.101074 545.419189,537.064453 
	C546.554260,536.142517 548.017456,535.154724 549.874451,534.236450 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M526.718994,383.103271 
	C525.814453,384.223389 524.597168,385.438263 523.035767,386.721649 
	C523.929932,385.592773 525.168091,384.395386 526.718994,383.103271 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M561.455505,385.149902 
	C562.235535,383.895630 563.322266,382.521973 564.743652,381.089844 
	C563.972961,382.364410 562.867615,383.697479 561.455505,385.149902 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M571.639648,373.183929 
	C572.140625,372.039368 572.924011,370.740814 574.057312,369.366119 
	C573.578796,370.536621 572.750427,371.783295 571.639648,373.183929 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M427.372681,163.953339 
	C428.378571,163.232025 429.766113,162.538406 431.494507,161.845169 
	C430.475006,162.557373 429.114685,163.269211 427.372681,163.953339 
z'
        />
        <path
          fill='#026350'
          opacity='1.000000'
          stroke='none'
          d='
M273.623352,740.959229 
	C273.501862,741.271057 273.448547,741.594177 273.321106,742.318970 
	C272.952820,742.958496 272.689728,743.158936 272.389557,743.310730 
	C272.524048,742.033508 272.658508,740.756287 273.000641,739.238281 
	C273.208282,738.997559 273.508575,739.020691 273.574951,739.362854 
	C273.635345,740.123108 273.629364,740.541138 273.623352,740.959229 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M530.648926,380.005737 
	C529.876282,380.946869 528.795349,381.979126 527.358459,383.021210 
	C528.115173,382.052948 529.227905,381.074890 530.648926,380.005737 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M513.427795,393.698914 
	C512.399048,394.717255 511.048889,395.822693 509.356995,396.981354 
	C510.378967,395.951752 511.742676,394.868896 513.427795,393.698914 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M541.661621,371.002686 
	C541.083008,371.820038 540.203674,372.726013 538.970398,373.580688 
	C539.531311,372.716705 540.446167,371.904022 541.661621,371.002686 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M539.280640,404.910706 
	C539.871033,404.113647 540.763855,403.233490 542.003296,402.414246 
	C541.427673,403.259277 540.505310,404.043427 539.280640,404.910706 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M350.197388,186.638824 
	C349.393829,187.851135 348.245575,189.044785 346.817139,190.381393 
	C347.642212,189.222946 348.747498,187.921555 350.197388,186.638824 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M382.876526,193.322281 
	C383.431549,192.467163 384.329620,191.709213 385.511688,190.858871 
	C384.936951,191.650818 384.078247,192.535126 382.876526,193.322281 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M362.063232,176.571350 
	C361.490082,177.419220 360.574738,178.192108 359.361877,179.043182 
	C359.949921,178.246368 360.835480,177.371368 362.063232,176.571350 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M568.464172,377.126038 
	C569.018250,375.984650 569.877869,374.716064 571.062134,373.339661 
	C570.514404,374.487488 569.642029,375.743164 568.464172,377.126038 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M508.735168,397.106506 
	C508.131378,397.895508 507.227509,398.769592 505.974426,399.593109 
	C506.561798,398.758850 507.498413,397.975220 508.735168,397.106506 
z'
        />
        <path
          fill='#14C391'
          opacity='1.000000'
          stroke='none'
          d='
M407.125244,546.343994 
	C407.373901,546.913696 407.542145,547.863098 407.450562,548.924622 
	C407.142059,548.265747 407.093445,547.494751 407.125244,546.343994 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M400.384705,179.989960 
	C400.998047,179.225143 401.960876,178.461166 403.216034,177.594788 
	C402.583649,178.325165 401.658936,179.157974 400.384705,179.989960 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M272.323029,743.645386 
	C272.689728,743.158936 272.952820,742.958496 273.377045,742.559570 
	C273.272675,743.514648 272.970123,744.619568 272.333435,745.864929 
	C272.084991,745.330261 272.170746,744.655151 272.323029,743.645386 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M414.238647,170.936005 
	C414.973694,170.181610 416.066010,169.449310 417.495209,168.688370 
	C416.753387,169.425858 415.674683,170.191971 414.238647,170.936005 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M455.357178,738.532532 
	C455.616516,739.998596 455.756836,741.849426 455.826508,744.124390 
	C455.583313,742.671448 455.410736,740.794373 455.357178,738.532532 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M505.371246,399.643066 
	C504.906067,400.270844 504.141754,400.990295 503.030487,401.683899 
	C503.479736,401.016968 504.275940,400.375824 505.371246,399.643066 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M518.952209,672.654602 
	C518.196106,672.389771 517.307007,671.834778 516.312378,670.959717 
	C517.077637,671.214539 517.948425,671.789490 518.952209,672.654602 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M547.537109,366.754944 
	C547.054443,367.408325 546.248108,368.098297 545.101929,368.791260 
	C545.579163,368.126678 546.396301,367.459106 547.537109,366.754944 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M409.315002,173.952850 
	C409.784210,173.334900 410.586395,172.715591 411.697998,172.065552 
	C411.220917,172.673706 410.434479,173.312607 409.315002,173.952850 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M352.854187,184.170044 
	C352.552826,184.879456 351.888855,185.579712 350.958282,186.404633 
	C351.291626,185.739807 351.891571,184.950333 352.854187,184.170044 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M330.193481,209.555542 
	C330.006744,210.350174 329.474213,211.177719 328.708954,212.183380 
	C328.933350,211.437134 329.390503,210.512772 330.193481,209.555542 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M380.019348,195.662781 
	C380.359711,194.972473 381.044861,194.298660 381.999725,193.514282 
	C381.634338,194.162247 380.999237,194.920761 380.019348,195.662781 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M550.506653,364.728149 
	C550.029907,365.370880 549.230103,366.070770 548.089539,366.764160 
	C548.560364,366.100189 549.372009,365.442749 550.506653,364.728149 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M502.454407,401.685669 
	C501.982880,402.327240 501.198761,403.038269 500.070312,403.745880 
	C500.531219,403.080017 501.336517,402.417603 502.454407,401.685669 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M536.234009,407.056000 
	C536.712219,406.412506 537.511414,405.707306 538.654541,404.990295 
	C538.184082,405.650421 537.369568,406.322357 536.234009,407.056000 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M270.496582,748.688354 
	C270.249695,749.485413 269.732697,750.459534 268.856812,751.493408 
	C269.074127,750.657288 269.650299,749.761353 270.496582,748.688354 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M331.893036,207.147583 
	C331.836761,207.743271 331.452454,208.416702 330.827209,209.264526 
	C330.912567,208.701050 331.238770,207.963181 331.893036,207.147583 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M364.969971,174.322815 
	C364.587677,174.993179 363.860107,175.636536 362.866394,176.395691 
	C363.275085,175.772934 363.949921,175.034363 364.969971,174.322815 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M516.149475,670.443726 
	C515.614014,670.393188 514.924805,670.103516 514.117249,669.541443 
	C514.664551,669.580933 515.330139,669.892700 516.149475,670.443726 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M269.083740,675.609985 
	C268.555817,675.362854 267.966797,674.822998 267.395630,673.953125 
	C267.949860,674.187866 268.486237,674.752563 269.083740,675.609985 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M388.307983,188.971909 
	C388.758179,188.326035 389.532776,187.644196 390.612000,186.882248 
	C390.155182,187.513412 389.393799,188.224670 388.307983,188.971909 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M335.781403,202.139587 
	C335.697266,202.716431 335.299683,203.375351 334.656250,204.193390 
	C334.762878,203.642227 335.115417,202.931946 335.781403,202.139587 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M565.306702,380.956848 
	C565.479248,380.429291 565.925049,379.780731 566.670959,379.046143 
	C566.507324,379.585358 566.043640,380.210632 565.306702,380.956848 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M559.693787,359.967041 
	C559.293579,360.361420 558.581543,360.729218 557.561096,361.117889 
	C557.962402,360.739349 558.672180,360.339935 559.693787,359.967041 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M346.190704,190.553650 
	C346.039642,191.111664 345.562286,191.680878 344.838989,192.388046 
	C345.016876,191.872284 345.440643,191.218567 346.190704,190.553650 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M270.731262,727.244873 
	C270.958008,727.169617 270.996765,727.354187 271.099731,727.814575 
	C270.908691,728.591431 270.653381,729.092590 270.188049,729.783325 
	C270.166382,729.150208 270.354828,728.327515 270.731262,727.244873 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M334.139282,204.429932 
	C334.123169,204.819000 333.822449,205.310455 333.258179,205.927399 
	C333.281342,205.546036 333.567993,205.039185 334.139282,204.429932 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M344.269531,192.596344 
	C344.234741,192.998795 343.900909,193.447815 343.298828,193.987457 
	C343.343872,193.599670 343.657196,193.121292 344.269531,192.596344 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M543.427002,369.655090 
	C543.314941,370.006897 542.914917,370.428986 542.216187,370.904541 
	C542.324646,370.547150 542.731812,370.136261 543.427002,369.655090 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M366.790100,173.093872 
	C366.675842,173.460999 366.253204,173.868500 365.533142,174.343231 
	C365.651093,173.985031 366.066376,173.559631 366.790100,173.093872 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M377.166107,197.846527 
	C377.277252,197.486542 377.691956,197.080597 378.398438,196.599091 
	C378.283356,196.949203 377.876526,197.374878 377.166107,197.846527 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M337.167725,200.416962 
	C337.161102,200.804565 336.869720,201.307007 336.322205,201.951324 
	C336.338409,201.572739 336.610687,201.052261 337.167725,200.416962 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M231.038635,740.421082 
	C231.419647,740.368042 232.006256,740.555542 232.810883,740.970947 
	C232.434006,741.019775 231.839127,740.840698 231.038635,740.421082 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M418.052673,168.730545 
	C418.191498,168.350754 418.648682,167.975601 419.409058,167.568115 
	C419.265198,167.935593 418.818115,168.335388 418.052673,168.730545 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M328.180603,212.469879 
	C328.185120,212.858566 327.904877,213.350662 327.375122,213.985306 
	C327.382355,213.609665 327.639069,213.091476 328.180603,212.469879 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M271.774597,746.176147 
	C271.818085,746.524963 271.632446,747.053528 271.247559,747.784973 
	C271.214050,747.443909 271.379791,746.899841 271.774597,746.176147 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M521.309692,416.072479 
	C521.490356,415.757507 521.977356,415.425903 522.764404,415.060242 
	C522.581665,415.369385 522.098816,415.712616 521.309692,416.072479 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M567.225830,378.836609 
	C567.223389,378.454620 567.487244,377.933014 568.016602,377.265350 
	C568.018860,377.645203 567.755493,378.171112 567.225830,378.836609 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M533.534729,377.714172 
	C533.410095,378.069122 532.986877,378.479553 532.263550,378.942352 
	C532.387634,378.586334 532.811890,378.178009 533.534729,377.714172 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M547.550842,398.345337 
	C547.655212,397.976410 548.055847,397.551788 548.755127,397.071899 
	C548.651550,397.440948 548.249329,397.865326 547.550842,398.345337 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M499.564636,403.735046 
	C499.421783,404.080505 498.976227,404.476868 498.225433,404.913483 
	C498.367462,404.564514 498.814667,404.175232 499.564636,403.735046 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M497.701172,404.950226 
	C497.533051,405.260468 497.069153,405.605011 496.313354,405.991882 
	C496.482758,405.684326 496.944092,405.334412 497.701172,404.950226 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M391.132599,186.824921 
	C391.213776,186.450134 391.602478,186.038895 392.267456,185.534546 
	C392.175903,185.890442 391.808014,186.339462 391.132599,186.824921 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M575.408020,368.008362 
	C575.385742,367.618408 575.616943,367.067200 576.129517,366.375793 
	C576.161133,366.772797 575.911316,367.309998 575.408020,368.008362 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M357.720215,180.063950 
	C357.628662,180.422272 357.240540,180.836731 356.574768,181.345795 
	C356.672668,181.000290 357.048126,180.560196 357.720215,180.063950 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M326.139984,215.418701 
	C326.390778,215.668808 326.366333,215.959930 326.162659,216.440948 
	C326.028992,216.247650 325.989441,215.857269 326.139984,215.418701 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M350.183289,217.870453 
	C350.367737,217.552780 350.860199,217.223953 351.664490,216.867722 
	C351.481323,217.179993 350.986298,217.519653 350.183289,217.870453 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M326.951080,214.231339 
	C327.042603,214.396759 326.934845,214.709534 326.613129,215.150497 
	C326.397675,214.932083 326.515198,214.632080 326.951080,214.231339 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M327.170410,218.157730 
	C327.345978,218.110870 327.629547,218.290970 328.001709,218.713287 
	C327.819672,218.765228 327.549042,218.574966 327.170410,218.157730 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M553.375366,393.149963 
	C553.327454,392.951813 553.552673,392.669678 554.026245,392.268799 
	C554.065979,392.455383 553.857300,392.760681 553.375366,393.149963 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M554.432861,392.195648 
	C554.370239,391.991425 554.579407,391.695435 555.035034,391.273743 
	C555.089233,391.466644 554.896973,391.785248 554.432861,392.195648 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M555.431458,391.197632 
	C555.370972,390.992767 555.582947,390.698944 556.043152,390.282898 
	C556.095581,390.476685 555.899780,390.792694 555.431458,391.197632 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M556.446167,390.207520 
	C556.380676,390.002258 556.587524,389.705963 557.040527,389.284912 
	C557.097229,389.478943 556.907837,389.797729 556.446167,390.207520 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M557.450256,389.213104 
	C557.383911,389.000916 557.593994,388.706726 558.052856,388.292145 
	C558.109924,388.491547 557.918335,388.811310 557.450256,389.213104 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M558.453125,388.211060 
	C558.388123,388.003632 558.595581,387.708130 559.050781,387.290741 
	C559.107483,387.486908 558.916565,387.804962 558.453125,388.211060 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M559.457703,387.218567 
	C559.390198,387.008148 559.597107,386.710907 560.052795,386.291901 
	C560.111816,386.490662 559.921997,386.811188 559.457703,387.218567 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M378.830994,196.560486 
	C378.779327,196.357117 378.986053,196.072784 379.450745,195.707336 
	C379.502289,195.910645 379.295837,196.195084 378.830994,196.560486 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M560.450562,386.195557 
	C560.379211,385.990906 560.576538,385.688477 561.012817,385.249146 
	C561.074158,385.440765 560.896667,385.769287 560.450562,386.195557 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M531.749146,379.038727 
	C531.791626,379.222168 531.576111,379.479156 531.138733,379.859161 
	C531.108337,379.692230 531.299744,379.402252 531.749146,379.038727 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M268.311401,751.644165 
	C268.378723,751.838989 268.213257,752.132996 267.794739,752.502197 
	C267.720642,752.299500 267.899658,752.021423 268.311401,751.644165 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M281.513672,768.263916 
	C281.483795,768.041260 281.773224,767.729980 282.361969,767.294800 
	C282.385193,767.505737 282.109131,767.840576 281.513672,768.263916 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M519.984741,673.720520 
	C519.810425,673.761414 519.550903,673.552002 519.161255,673.126465 
	C519.320557,673.097046 519.610046,673.283630 519.984741,673.720520 
z'
        />
        <path
          fill='#14C391'
          opacity='1.000000'
          stroke='none'
          d='
M413.746704,606.516479 
	C413.944397,606.455200 414.236816,606.653809 414.640503,607.099609 
	C414.448303,607.156616 414.144867,606.966431 413.746704,606.516479 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M544.619995,368.803528 
	C544.661194,369.013794 544.423279,369.275421 543.930298,369.626434 
	C543.897156,369.428833 544.119080,369.141876 544.619995,368.803528 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M574.522461,369.205475 
	C574.426453,369.008820 574.592285,368.693207 574.975403,368.216064 
	C575.056519,368.398499 574.920349,368.742493 574.522461,369.205475 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M358.894836,179.145935 
	C358.927734,179.325348 358.724670,179.604431 358.263245,179.950150 
	C358.222900,179.759720 358.440887,179.502655 358.894836,179.145935 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M387.077545,189.833771 
	C387.050049,189.644592 387.264404,189.363159 387.746246,189.036011 
	C387.782288,189.240707 387.550842,189.491104 387.077545,189.833771 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M505.138794,693.255493 
	C505.397644,693.182617 505.499695,693.414490 505.386261,693.826782 
	C505.319122,693.944824 505.130371,693.497925 505.138794,693.255493 
z'
        />
        <path
          fill='#14C391'
          opacity='1.000000'
          stroke='none'
          d='
M412.743591,605.498779 
	C412.952881,605.437317 413.241699,605.648560 413.643494,606.109558 
	C413.445374,606.163330 413.134247,605.967346 412.743591,605.498779 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M267.326965,673.454468 
	C267.119629,673.505432 266.839478,673.298889 266.490204,672.831482 
	C266.698761,672.779419 266.976471,672.988159 267.326965,673.454468 
z'
        />
        <path
          fill='#31B88C'
          opacity='1.000000'
          stroke='none'
          d='
M576.536377,366.206726 
	C576.440674,366.007965 576.604004,365.693146 576.984619,365.218384 
	C577.066467,365.402527 576.930969,365.746613 576.536377,366.206726 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M354.034485,183.300583 
	C354.076599,183.496506 353.862091,183.789734 353.379700,184.166565 
	C353.333801,183.966095 353.555817,183.681992 354.034485,183.300583 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M412.819275,171.676208 
	C412.821289,171.479156 413.103699,171.213547 413.685303,170.912109 
	C413.689545,171.120071 413.394592,171.363846 412.819275,171.676208 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M355.095215,182.363632 
	C355.133514,182.553101 354.918274,182.839096 354.442047,183.212128 
	C354.401276,183.019501 354.621429,182.739822 355.095215,182.363632 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M356.148376,181.405579 
	C356.198242,181.603424 355.991119,181.889465 355.527740,182.263885 
	C355.478119,182.066101 355.684723,181.779938 356.148376,181.405579 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M399.072815,180.829056 
	C399.045624,180.644852 399.253632,180.375229 399.720612,180.062943 
	C399.829742,180.330109 399.605896,180.571228 399.072815,180.829056 
z'
        />
        <path
          fill='#164517'
          opacity='1.000000'
          stroke='none'
          d='
M385.998962,190.731476 
	C385.966736,190.541534 386.184082,190.256332 386.671448,189.905945 
	C386.710510,190.105911 386.479523,190.371048 385.998962,190.731476 
z'
        />
        <path
          fill='#012B3A'
          opacity='1.000000'
          stroke='none'
          d='
M254.021362,589.041016 
	C247.106705,581.275024 245.821564,566.844238 251.069778,558.456909 
	C255.476746,551.414062 262.266449,550.194946 269.804962,550.946533 
	C276.594177,551.623474 281.900055,555.037109 283.278046,561.659729 
	C284.529480,567.674133 285.200409,574.340149 283.922638,580.241455 
	C281.112305,593.220276 266.479584,597.322327 254.021362,589.041016 
z'
        />
        <path
          fill='#012B3A'
          opacity='1.000000'
          stroke='none'
          d='
M270.687897,711.203979 
	C267.107605,721.980042 259.838013,726.158752 248.406708,724.366699 
	C239.302277,722.939392 234.311768,716.689575 233.975601,706.294006 
	C233.480667,690.989258 242.766418,682.601074 257.057190,685.443481 
	C265.355865,687.094116 270.242859,692.351746 270.945435,700.828979 
	C271.218414,704.122925 270.859100,707.469238 270.687897,711.203979 
z'
        />
        <path
          fill='#1CBD92'
          opacity='1.000000'
          stroke='none'
          d='
M273.827942,740.824402 
	C273.629364,740.541138 273.635345,740.123108 273.720459,739.401672 
	C273.877228,739.628723 273.954895,740.159180 273.827942,740.824402 
z'
        />
        <path
          fill='#012B3A'
          opacity='1.000000'
          stroke='none'
          d='
M332.472473,722.484009 
	C326.173065,713.430176 326.339996,704.000854 330.105103,694.625671 
	C333.029755,687.343201 339.595825,685.191895 346.861115,685.298706 
	C355.038910,685.418823 361.466492,689.178894 362.981873,696.175232 
	C364.312775,702.319702 364.950684,709.194702 363.585999,715.224487 
	C360.534637,728.706238 343.991547,732.402710 332.472473,722.484009 
z'
        />
        <path
          fill='#012B3A'
          opacity='1.000000'
          stroke='none'
          d='
M554.282532,587.600708 
	C549.055603,578.761230 549.073303,569.837646 552.627136,560.927429 
	C555.205811,554.462219 561.871643,551.087952 569.855042,551.466919 
	C577.919739,551.849670 584.319153,555.593628 585.801025,562.658997 
	C587.061462,568.668030 587.112915,575.189819 586.309998,581.302185 
	C585.441040,587.917053 580.800354,592.280518 574.049866,593.504395 
	C566.711365,594.834961 559.719543,594.287964 554.282532,587.600708 
z'
        />
        <path
          fill='#012B3A'
          opacity='1.000000'
          stroke='none'
          d='
M337.907898,582.814453 
	C337.567810,576.235657 336.426056,569.846802 337.451569,563.827209 
	C338.928772,555.156128 345.964996,550.885864 355.680603,551.069336 
	C364.985077,551.245056 371.404327,555.879211 373.032104,564.449768 
	C373.853607,568.774963 373.980804,573.408813 373.434448,577.779663 
	C372.342621,586.514221 367.310333,591.714722 359.857697,592.885620 
	C349.128754,594.571289 342.507263,591.617737 337.907898,582.814453 
z'
        />
        <path
          fill='#02353D'
          opacity='1.000000'
          stroke='none'
          d='
M562.303162,699.811768 
	C562.134338,691.617249 568.035889,685.906677 577.272156,685.327209 
	C586.197998,684.767273 592.310730,690.042297 592.734253,699.438599 
	C582.196289,699.734802 572.249756,699.773315 562.303162,699.811768 
z'
        />
        <path
          fill='#1ED8A3'
          opacity='1.000000'
          stroke='none'
          d='
M562.293335,700.085510 
	C572.249756,699.773315 582.196289,699.734802 592.618713,699.729370 
	C591.873047,700.174500 590.647339,700.954163 589.430786,700.939941 
	C580.380676,700.834900 571.332397,700.572327 562.293335,700.085510 
z'
        />
        <path
          fill='#08DB98'
          opacity='1.000000'
          stroke='none'
          d='
M473.992462,743.973145 
	C470.011658,743.981262 466.020142,743.810059 462.053101,744.047791 
	C458.056366,744.287109 456.907349,742.534119 456.943268,738.754395 
	C457.082001,724.159302 456.991730,709.562134 456.980530,694.501099 
	C457.013428,692.084290 457.380371,690.065857 457.003357,688.197571 
	C456.591095,686.154480 455.861450,683.916565 454.526093,682.433533 
	C453.907043,681.745972 451.528168,682.683533 449.941559,682.818970 
	C445.417023,683.205261 440.881378,683.479614 436.366760,683.955811 
	C433.983612,684.207153 431.635132,684.787354 428.407043,685.376892 
	C427.360413,681.523987 431.097137,678.045227 432.899811,674.321106 
	C433.773041,672.517090 436.922852,671.182068 439.201843,670.892273 
	C441.017822,670.661438 443.076294,672.337341 445.417816,673.333008 
	C445.142120,671.794922 444.896240,670.422974 444.654846,669.076233 
	C452.383087,666.356995 455.946259,667.648621 460.538696,674.268799 
	C461.385895,675.490112 463.094727,676.314514 464.576172,676.857361 
	C469.083038,678.508972 471.043121,681.655273 472.178619,686.317932 
	C474.652191,696.475220 473.783661,706.710144 474.028381,716.935791 
	C474.229645,725.346619 474.658783,733.751892 474.999512,742.585327 
	C475.012085,743.010925 475.000549,743.000488 474.814148,743.142822 
	C474.387726,743.488464 474.175964,743.717773 473.992462,743.973145 
M459.167908,678.899170 
	C458.203735,678.058044 457.239532,677.216858 456.275360,676.375732 
	C455.956238,676.944092 455.637146,677.512451 455.318054,678.080811 
	C456.669891,678.522766 458.021729,678.964783 459.167908,678.899170 
z'
        />
        <path
          fill='#08DB98'
          opacity='1.000000'
          stroke='none'
          d='
M424.000000,728.990173 
	C423.998718,732.319580 423.794830,735.169617 424.046539,737.978882 
	C424.374115,741.635254 422.747009,743.340881 419.281982,743.334534 
	C415.799774,743.328186 412.260162,743.381775 408.868439,742.750244 
	C407.726440,742.537537 406.153961,740.311096 406.143524,738.994202 
	C405.976868,718.006287 405.949036,697.015564 406.175568,676.028687 
	C406.196289,674.107544 407.847870,670.949219 409.305389,670.578186 
	C411.793762,669.944763 414.728760,671.065979 417.501801,672.653992 
	C418.497559,671.886963 419.493317,671.119934 420.791748,670.119812 
	C424.206757,677.871094 418.549164,684.790344 419.173065,692.291077 
	C421.433105,692.291077 423.172729,692.291077 424.497864,692.291077 
	C424.329346,704.546204 424.164673,716.519470 424.000000,728.990173 
z'
        />
        <path
          fill='#0DD699'
          opacity='1.000000'
          stroke='none'
          d='
M474.185944,743.839355 
	C474.175964,743.717773 474.387726,743.488464 474.816467,743.145630 
	C474.832550,743.271484 474.623962,743.504639 474.185944,743.839355 
z'
        />
        <path
          fill='#0DD699'
          opacity='1.000000'
          stroke='none'
          d='
M459.270752,679.152954 
	C458.021729,678.964783 456.669891,678.522766 455.318054,678.080811 
	C455.637146,677.512451 455.956238,676.944092 456.275360,676.375732 
	C457.239532,677.216858 458.203735,678.058044 459.270752,679.152954 
z'
        />
      </svg>
    </div>
  );
};

export default PolsatGames;
