const Quentin = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 225 225'
        width='100%'
        height='100%'
      >
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F5F3FD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 225.00 
L 225.00 225.00
L 225.00 0.00
L 0.00 0.00
L 0.00 225.00
M 137.00 35.00 
C 147.24 45.24 158.61 55.01 168.00 66.00
C 175.86 75.20 178.64 78.94 188.75 87.25 C 198.86 95.55 194.42 110.53 196.00 123.00 C 197.58 135.47 190.10 143.74 181.25 151.25 C 172.39 158.76 167.87 165.22 160.00 173.00
C 167.81 178.05 179.43 187.28 170.25 196.25 C 161.07 205.21 152.80 192.57 147.00 186.00
C 134.04 193.57 127.93 210.53 114.75 217.75 C 101.57 224.98 89.15 201.81 78.75 193.25 C 68.35 184.69 57.16 171.08 47.25 160.75 C 37.34 150.43 18.23 138.36 21.00 122.00
C 21.00 115.95 21.49 109.30 22.00 104.00
C 22.00 91.18 30.63 82.59 39.75 74.75 C 48.87 66.91 55.09 58.45 63.25 51.25 C 71.41 44.05 79.34 33.89 86.75 27.75 C 94.17 21.61 103.85 4.66 113.07 10.93 C 122.30 17.19 129.96 26.76 137.00 35.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#2D2452'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 22.00 104.00 
C 22.00 110.05 21.51 116.70 21.00 122.00
C 18.23 138.36 37.34 150.43 47.25 160.75 C 57.16 171.08 68.35 184.69 78.75 193.25 C 89.15 201.81 101.57 224.98 114.75 217.75 C 127.93 210.53 134.04 193.57 147.00 186.00
C 152.80 192.57 161.07 205.21 170.25 196.25 C 179.43 187.28 167.81 178.05 160.00 173.00
C 167.87 165.22 172.39 158.76 181.25 151.25 C 190.10 143.74 197.58 135.47 196.00 123.00 C 194.42 110.53 198.86 95.55 188.75 87.25 C 178.64 78.94 175.86 75.20 168.00 66.00
C 157.76 55.76 146.39 45.99 137.00 35.00
C 129.96 26.76 122.30 17.19 113.07 10.93 C 103.85 4.66 94.17 21.61 86.75 27.75 C 79.34 33.89 71.41 44.05 63.25 51.25 C 55.09 58.45 48.87 66.91 39.75 74.75 C 30.63 82.59 22.00 91.18 22.00 104.00
M 114.00 34.00 
C 113.99 33.85 114.74 33.12 115.00 33.00
C 114.18 31.72 113.48 29.51 113.00 28.00
C 114.57 28.96 116.25 30.32 117.00 32.00
C 128.23 43.23 140.70 53.95 151.00 66.00
C 156.25 71.25 163.16 76.35 167.75 82.25 C 172.35 88.14 180.81 92.34 184.00 99.00
C 184.87 100.81 184.85 101.96 185.00 104.00
C 185.40 109.40 184.45 115.38 184.00 120.00
C 184.00 122.33 185.33 132.01 182.00 130.00
C 182.25 130.22 182.00 131.00 182.00 131.00
C 184.90 135.62 172.96 142.41 169.75 146.75 C 166.54 151.09 158.79 157.21 154.75 161.75 C 150.72 166.30 143.36 172.87 139.25 177.25 C 135.13 181.63 127.78 188.54 124.25 192.25 C 120.72 195.95 110.25 207.57 109.00 204.00
C 108.95 203.84 108.00 204.00 108.00 204.00
C 106.79 204.85 108.08 204.67 107.00 204.00
C 95.19 196.71 82.95 181.47 74.00 171.00
C 70.41 167.41 66.54 163.54 63.00 160.00
C 58.68 155.68 51.16 150.18 47.75 145.25 C 44.34 140.32 34.30 134.22 35.00 129.00
C 35.02 128.88 34.22 128.30 34.00 128.00
C 30.27 122.94 33.25 107.48 33.00 101.00 C 32.75 94.52 42.60 90.28 46.25 85.25 C 49.89 80.21 56.46 75.57 60.75 70.75 C 65.05 65.93 70.66 60.84 75.25 56.25 C 79.83 51.66 85.04 45.95 89.75 41.75 C 94.46 37.56 99.33 30.11 104.00 29.00
C 104.36 28.92 105.75 28.20 106.00 28.00
C 107.47 26.82 108.03 25.10 110.00 25.00
C 110.55 28.26 112.76 30.95 114.00 34.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F2F0F9'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 106.00 28.00 
C 96.24 41.15 90.86 57.82 81.70 71.70 C 72.53 85.57 64.82 101.42 57.00 116.00
C 67.83 122.62 78.06 124.38 88.70 132.30 C 99.33 140.23 110.95 140.09 122.02 133.02 C 133.10 125.96 144.51 123.40 156.00 118.00
C 143.88 89.36 128.05 61.93 114.00 34.00
C 112.76 30.95 110.55 28.26 110.00 25.00
C 108.03 25.10 107.47 26.82 106.00 28.00
M 111.00 95.00 
C 121.92 96.57 122.15 111.16 114.25 116.25 C 106.35 121.33 88.07 122.31 94.00 109.00
C 93.92 117.70 106.28 120.11 111.25 113.25 C 116.22 106.38 109.85 96.98 102.00 96.00
C 107.78 94.80 114.36 86.95 110.07 80.93 C 105.79 74.90 96.43 79.37 95.00 85.00
C 92.88 74.40 105.81 74.23 113.68 77.32 C 121.55 80.41 116.27 91.80 111.00 95.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#ECE9F3'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 34.00 128.00 
C 38.82 119.89 54.37 119.68 57.77 109.77 C 61.17 99.86 68.58 92.35 73.25 83.25 C 77.93 74.16 82.67 64.70 88.31 56.31 C 93.94 47.91 97.22 36.39 104.00 29.00
C 99.33 30.11 94.46 37.56 89.75 41.75 C 85.04 45.95 79.83 51.66 75.25 56.25 C 70.66 60.84 65.05 65.93 60.75 70.75 C 56.46 75.57 49.89 80.21 46.25 85.25 C 42.60 90.28 32.75 94.52 33.00 101.00 C 33.25 107.48 30.27 122.94 34.00 128.00
M 76.00 72.00 
C 69.62 77.77 65.51 84.64 59.75 90.75 C 53.98 96.85 50.47 107.91 41.00 108.00
C 47.81 95.26 62.05 86.61 71.00 75.00
C 66.46 75.17 61.95 72.52 58.00 76.00
C 59.94 68.60 70.34 73.34 76.00 72.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#2F2C3C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 104.00 29.00 
C 97.22 36.39 93.94 47.91 88.31 56.31 C 82.67 64.70 77.93 74.16 73.25 83.25 C 68.58 92.35 61.17 99.86 57.77 109.77 C 54.37 119.68 38.82 119.89 34.00 128.00
C 34.22 128.30 35.02 128.88 35.00 129.00
C 42.29 128.01 48.63 116.34 56.08 117.08 C 63.52 117.81 70.16 125.95 77.69 128.31 C 85.23 130.66 91.87 135.93 99.23 139.77 C 106.59 143.62 105.10 157.34 105.78 166.22 C 106.45 175.10 107.00 188.77 107.00 198.00
C 107.17 199.50 108.01 201.84 108.00 204.00
C 108.00 204.00 108.95 203.84 109.00 204.00
C 109.88 194.24 106.32 179.91 107.00 171.00 C 107.68 162.09 101.91 144.86 109.23 140.23 C 116.55 135.60 126.06 132.89 133.68 129.68 C 141.29 126.47 149.79 118.69 158.01 119.01 C 166.23 119.32 173.70 129.78 182.00 131.00
C 182.00 131.00 182.25 130.22 182.00 130.00
C 172.03 121.28 156.69 119.54 152.69 105.31 C 148.70 91.07 139.28 83.06 134.23 69.77 C 129.18 56.48 120.95 46.20 116.00 33.00
C 115.59 33.19 115.00 33.00 115.00 33.00
C 114.74 33.12 113.99 33.85 114.00 34.00
C 128.05 61.93 143.88 89.36 156.00 118.00
C 144.51 123.40 133.10 125.96 122.02 133.02 C 110.95 140.09 99.33 140.23 88.70 132.30 C 78.06 124.38 67.83 122.62 57.00 116.00
C 64.82 101.42 72.53 85.57 81.70 71.70 C 90.86 57.82 96.24 41.15 106.00 28.00
C 105.75 28.20 104.36 28.92 104.00 29.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#C4BEDD'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 115.00 33.00 
C 115.00 33.00 115.59 33.19 116.00 33.00
C 116.26 32.88 116.71 31.94 117.00 32.00
C 116.25 30.32 114.57 28.96 113.00 28.00
C 113.48 29.51 114.18 31.72 115.00 33.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EEEBF5'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 116.00 33.00 
C 120.95 46.20 129.18 56.48 134.23 69.77 C 139.28 83.06 148.70 91.07 152.69 105.31 C 156.69 119.54 172.03 121.28 182.00 130.00
C 185.33 132.01 184.00 122.33 184.00 120.00
C 184.00 114.77 184.90 108.43 185.00 104.00
C 184.85 101.96 184.87 100.81 184.00 99.00
C 180.43 92.79 171.82 88.62 167.25 82.75 C 162.67 76.88 155.82 71.64 151.00 66.00
C 140.70 53.95 128.23 43.23 117.00 32.00
C 116.71 31.94 116.26 32.88 116.00 33.00
M 149.00 75.00 
C 147.81 75.41 146.23 74.69 145.00 75.00
C 148.39 82.63 160.10 79.01 165.75 86.25 C 171.41 93.48 174.87 99.81 167.75 107.75 C 160.62 115.68 150.65 105.62 153.00 98.00
C 159.09 112.93 173.66 100.28 164.75 90.25 C 155.83 80.22 143.02 87.23 142.00 72.00
C 143.33 72.00 144.67 72.00 146.00 72.00
C 148.19 71.63 150.78 72.00 153.00 72.00
C 153.50 72.00 154.00 72.00 154.00 72.00
C 155.57 72.54 157.18 74.30 157.00 76.00
C 154.73 74.28 151.66 75.27 149.00 75.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7E7995'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 168.00 66.00 
C 158.61 55.01 147.24 45.24 137.00 35.00
C 146.39 45.99 157.76 55.76 168.00 66.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#918CA0'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 184.00 99.00 
C 180.81 92.34 172.35 88.14 167.75 82.25 C 163.16 76.35 156.25 71.25 151.00 66.00
C 155.82 71.64 162.67 76.88 167.25 82.75 C 171.82 88.62 180.43 92.79 184.00 99.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#2C2740'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 76.00 72.00 
C 70.34 73.34 59.94 68.60 58.00 76.00
C 61.95 72.52 66.46 75.17 71.00 75.00
C 62.05 86.61 47.81 95.26 41.00 108.00
C 50.47 107.91 53.98 96.85 59.75 90.75 C 65.51 84.64 69.62 77.77 76.00 72.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#302B49'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 149.00 75.00 
C 150.39 74.53 156.04 74.41 154.00 72.00
C 154.00 72.00 153.50 72.00 153.00 72.00
C 150.41 73.92 148.06 71.82 146.00 72.00
C 144.67 72.00 143.33 72.00 142.00 72.00
C 143.02 87.23 155.83 80.22 164.75 90.25 C 173.66 100.28 159.09 112.93 153.00 98.00
C 150.65 105.62 160.62 115.68 167.75 107.75 C 174.87 99.81 171.41 93.48 165.75 86.25 C 160.10 79.01 148.39 82.63 145.00 75.00
C 146.23 74.69 147.81 75.41 149.00 75.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#6F6B7B'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 153.00 72.00 
C 150.78 72.00 148.19 71.63 146.00 72.00
C 148.06 71.82 150.41 73.92 153.00 72.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#7A7787'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 149.00 75.00 
C 151.66 75.27 154.73 74.28 157.00 76.00
C 157.18 74.30 155.57 72.54 154.00 72.00
C 156.04 74.41 150.39 74.53 149.00 75.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#2E294C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 111.00 95.00 
C 116.27 91.80 121.55 80.41 113.68 77.32 C 105.81 74.23 92.88 74.40 95.00 85.00
C 96.43 79.37 105.79 74.90 110.07 80.93 C 114.36 86.95 107.78 94.80 102.00 96.00
C 109.85 96.98 116.22 106.38 111.25 113.25 C 106.28 120.11 93.92 117.70 94.00 109.00
C 88.07 122.31 106.35 121.33 114.25 116.25 C 122.15 111.16 121.92 96.57 111.00 95.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#A097B3'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 21.00 122.00 
C 21.51 116.70 22.00 110.05 22.00 104.00
C 21.49 109.30 21.00 115.95 21.00 122.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#958EAC'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 184.00 120.00 
C 184.45 115.38 185.40 109.40 185.00 104.00
C 184.90 108.43 184.00 114.77 184.00 120.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EFEBF6'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 35.00 129.00 
C 34.30 134.22 44.34 140.32 47.75 145.25 C 51.16 150.18 58.68 155.68 63.00 160.00
C 63.92 160.45 65.08 160.03 65.00 160.00
C 60.23 153.59 52.42 139.68 56.00 131.00 C 59.58 122.32 75.41 128.33 80.75 136.25 C 86.10 144.16 92.28 150.70 92.00 163.00 C 91.72 175.30 78.39 174.39 70.00 166.00
C 71.29 167.64 72.69 169.46 74.00 171.00
C 82.95 181.47 95.19 196.71 107.00 204.00
C 106.44 202.48 107.00 199.64 107.00 198.00
C 107.00 188.77 106.45 175.10 105.78 166.22 C 105.10 157.34 106.59 143.62 99.23 139.77 C 91.87 135.93 85.23 130.66 77.69 128.31 C 70.16 125.95 63.52 117.81 56.08 117.08 C 48.63 116.34 42.29 128.01 35.00 129.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EDEBF5'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 109.00 204.00 
C 110.25 207.57 120.72 195.95 124.25 192.25 C 127.78 188.54 135.13 181.63 139.25 177.25 C 143.36 172.87 150.72 166.30 154.75 161.75 C 158.79 157.21 166.54 151.09 169.75 146.75 C 172.96 142.41 184.90 135.62 182.00 131.00
C 173.70 129.78 166.23 119.32 158.01 119.01 C 149.79 118.69 141.29 126.47 133.68 129.68 C 126.06 132.89 116.55 135.60 109.23 140.23 C 101.91 144.86 107.68 162.09 107.00 171.00 C 106.32 179.91 109.88 194.24 109.00 204.00
M 150.00 160.00 
C 146.79 165.58 141.43 170.67 136.00 174.00
C 129.38 178.06 118.74 179.78 120.00 170.00 C 121.27 160.22 126.49 159.46 131.00 152.00
C 109.14 151.30 131.98 126.35 144.00 129.00 C 156.02 131.65 144.82 140.22 142.00 145.00
C 151.96 142.27 156.22 153.35 150.00 160.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#2E274C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 65.00 160.00 
C 66.42 161.92 68.43 164.01 70.00 166.00
C 78.39 174.39 91.72 175.30 92.00 163.00 C 92.28 150.70 86.10 144.16 80.75 136.25 C 75.41 128.33 59.58 122.32 56.00 131.00 C 52.42 139.68 60.23 153.59 65.00 160.00
M 81.00 169.00 
C 73.88 170.71 66.56 160.12 65.23 154.77 C 63.90 149.42 57.26 133.84 63.25 130.25 C 69.25 126.67 76.97 138.86 78.77 144.23 C 80.57 149.60 85.00 162.92 81.00 169.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#2F2950'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 136.00 174.00 
C 135.71 173.97 133.59 172.30 133.00 172.00
C 131.71 171.35 130.57 170.69 129.00 171.00
C 127.10 164.38 128.90 156.57 134.75 151.75 C 140.61 146.93 152.20 156.29 145.00 163.00
C 145.17 162.87 148.90 161.40 150.00 160.00
C 156.22 153.35 151.96 142.27 142.00 145.00
C 144.82 140.22 156.02 131.65 144.00 129.00 C 131.98 126.35 109.14 151.30 131.00 152.00
C 126.49 159.46 121.27 160.22 120.00 170.00 C 118.74 179.78 129.38 178.06 136.00 174.00
M 142.00 132.00 
C 148.12 136.20 137.35 150.77 130.78 145.22 C 124.21 139.67 136.55 128.25 142.00 132.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EEEBF5'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 81.00 169.00 
C 85.00 162.92 80.57 149.60 78.77 144.23 C 76.97 138.86 69.25 126.67 63.25 130.25 C 57.26 133.84 63.90 149.42 65.23 154.77 C 66.56 160.12 73.88 170.71 81.00 169.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EDEAF4'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 142.00 132.00 
C 136.55 128.25 124.21 139.67 130.78 145.22 C 137.35 150.77 148.12 136.20 142.00 132.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EDE9F4'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 133.00 172.00 
C 136.99 172.20 143.05 166.32 145.00 163.00
C 152.20 156.29 140.61 146.93 134.75 151.75 C 128.90 156.57 127.10 164.38 129.00 171.00
C 130.57 170.69 131.71 171.35 133.00 172.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#C1B5D2'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 74.00 171.00 
C 72.69 169.46 71.29 167.64 70.00 166.00
C 68.43 164.01 66.42 161.92 65.00 160.00
C 65.08 160.03 63.92 160.45 63.00 160.00
C 66.54 163.54 70.41 167.41 74.00 171.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#353141'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 145.00 163.00 
C 143.05 166.32 136.99 172.20 133.00 172.00
C 133.59 172.30 135.71 173.97 136.00 174.00
C 141.43 170.67 146.79 165.58 150.00 160.00
C 148.90 161.40 145.17 162.87 145.00 163.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#8B8395'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 107.00 204.00 
C 108.08 204.67 106.79 204.85 108.00 204.00
C 108.01 201.84 107.17 199.50 107.00 198.00
C 107.00 199.64 106.44 202.48 107.00 204.00 Z'
          />
        </g>
        <g fill='#fff' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 0.00 225.00 
L 225.00 225.00
L 225.00 0.00
L 0.00 0.00
L 0.00 225.00
M 137.00 35.00 
C 147.24 45.24 158.61 55.01 168.00 66.00
C 175.86 75.20 178.64 78.94 188.75 87.25 C 198.86 95.55 194.42 110.53 196.00 123.00 C 197.58 135.47 190.10 143.74 181.25 151.25 C 172.39 158.76 167.87 165.22 160.00 173.00
C 167.81 178.05 179.43 187.28 170.25 196.25 C 161.07 205.21 152.80 192.57 147.00 186.00
C 134.04 193.57 127.93 210.53 114.75 217.75 C 101.57 224.98 89.15 201.81 78.75 193.25 C 68.35 184.69 57.16 171.08 47.25 160.75 C 37.34 150.43 18.23 138.36 21.00 122.00
C 21.00 115.95 21.49 109.30 22.00 104.00
C 22.00 91.18 30.63 82.59 39.75 74.75 C 48.87 66.91 55.09 58.45 63.25 51.25 C 71.41 44.05 79.34 33.89 86.75 27.75 C 94.17 21.61 103.85 4.66 113.07 10.93 C 122.30 17.19 129.96 26.76 137.00 35.00 Z'
          />
        </g>
        <g fill='#2D2452' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 22.00 104.00 
C 22.00 110.05 21.51 116.70 21.00 122.00
C 18.23 138.36 37.34 150.43 47.25 160.75 C 57.16 171.08 68.35 184.69 78.75 193.25 C 89.15 201.81 101.57 224.98 114.75 217.75 C 127.93 210.53 134.04 193.57 147.00 186.00
C 152.80 192.57 161.07 205.21 170.25 196.25 C 179.43 187.28 167.81 178.05 160.00 173.00
C 167.87 165.22 172.39 158.76 181.25 151.25 C 190.10 143.74 197.58 135.47 196.00 123.00 C 194.42 110.53 198.86 95.55 188.75 87.25 C 178.64 78.94 175.86 75.20 168.00 66.00
C 157.76 55.76 146.39 45.99 137.00 35.00
C 129.96 26.76 122.30 17.19 113.07 10.93 C 103.85 4.66 94.17 21.61 86.75 27.75 C 79.34 33.89 71.41 44.05 63.25 51.25 C 55.09 58.45 48.87 66.91 39.75 74.75 C 30.63 82.59 22.00 91.18 22.00 104.00
M 114.00 34.00 
C 113.99 33.85 114.74 33.12 115.00 33.00
C 114.18 31.72 113.48 29.51 113.00 28.00
C 114.57 28.96 116.25 30.32 117.00 32.00
C 128.23 43.23 140.70 53.95 151.00 66.00
C 156.25 71.25 163.16 76.35 167.75 82.25 C 172.35 88.14 180.81 92.34 184.00 99.00
C 184.87 100.81 184.85 101.96 185.00 104.00
C 185.40 109.40 184.45 115.38 184.00 120.00
C 184.00 122.33 185.33 132.01 182.00 130.00
C 182.25 130.22 182.00 131.00 182.00 131.00
C 184.90 135.62 172.96 142.41 169.75 146.75 C 166.54 151.09 158.79 157.21 154.75 161.75 C 150.72 166.30 143.36 172.87 139.25 177.25 C 135.13 181.63 127.78 188.54 124.25 192.25 C 120.72 195.95 110.25 207.57 109.00 204.00
C 108.95 203.84 108.00 204.00 108.00 204.00
C 106.79 204.85 108.08 204.67 107.00 204.00
C 95.19 196.71 82.95 181.47 74.00 171.00
C 70.41 167.41 66.54 163.54 63.00 160.00
C 58.68 155.68 51.16 150.18 47.75 145.25 C 44.34 140.32 34.30 134.22 35.00 129.00
C 35.02 128.88 34.22 128.30 34.00 128.00
C 30.27 122.94 33.25 107.48 33.00 101.00 C 32.75 94.52 42.60 90.28 46.25 85.25 C 49.89 80.21 56.46 75.57 60.75 70.75 C 65.05 65.93 70.66 60.84 75.25 56.25 C 79.83 51.66 85.04 45.95 89.75 41.75 C 94.46 37.56 99.33 30.11 104.00 29.00
C 104.36 28.92 105.75 28.20 106.00 28.00
C 107.47 26.82 108.03 25.10 110.00 25.00
C 110.55 28.26 112.76 30.95 114.00 34.00 Z'
          />
        </g>
        <g fill='#F2F0F9' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 106.00 28.00 
C 96.24 41.15 90.86 57.82 81.70 71.70 C 72.53 85.57 64.82 101.42 57.00 116.00
C 67.83 122.62 78.06 124.38 88.70 132.30 C 99.33 140.23 110.95 140.09 122.02 133.02 C 133.10 125.96 144.51 123.40 156.00 118.00
C 143.88 89.36 128.05 61.93 114.00 34.00
C 112.76 30.95 110.55 28.26 110.00 25.00
C 108.03 25.10 107.47 26.82 106.00 28.00
M 111.00 95.00 
C 121.92 96.57 122.15 111.16 114.25 116.25 C 106.35 121.33 88.07 122.31 94.00 109.00
C 93.92 117.70 106.28 120.11 111.25 113.25 C 116.22 106.38 109.85 96.98 102.00 96.00
C 107.78 94.80 114.36 86.95 110.07 80.93 C 105.79 74.90 96.43 79.37 95.00 85.00
C 92.88 74.40 105.81 74.23 113.68 77.32 C 121.55 80.41 116.27 91.80 111.00 95.00 Z'
          />
        </g>
        <g fill='#ECE9F3' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 34.00 128.00 
C 38.82 119.89 54.37 119.68 57.77 109.77 C 61.17 99.86 68.58 92.35 73.25 83.25 C 77.93 74.16 82.67 64.70 88.31 56.31 C 93.94 47.91 97.22 36.39 104.00 29.00
C 99.33 30.11 94.46 37.56 89.75 41.75 C 85.04 45.95 79.83 51.66 75.25 56.25 C 70.66 60.84 65.05 65.93 60.75 70.75 C 56.46 75.57 49.89 80.21 46.25 85.25 C 42.60 90.28 32.75 94.52 33.00 101.00 C 33.25 107.48 30.27 122.94 34.00 128.00
M 76.00 72.00 
C 69.62 77.77 65.51 84.64 59.75 90.75 C 53.98 96.85 50.47 107.91 41.00 108.00
C 47.81 95.26 62.05 86.61 71.00 75.00
C 66.46 75.17 61.95 72.52 58.00 76.00
C 59.94 68.60 70.34 73.34 76.00 72.00 Z'
          />
        </g>
        <g fill='#2F2C3C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 104.00 29.00 
C 97.22 36.39 93.94 47.91 88.31 56.31 C 82.67 64.70 77.93 74.16 73.25 83.25 C 68.58 92.35 61.17 99.86 57.77 109.77 C 54.37 119.68 38.82 119.89 34.00 128.00
C 34.22 128.30 35.02 128.88 35.00 129.00
C 42.29 128.01 48.63 116.34 56.08 117.08 C 63.52 117.81 70.16 125.95 77.69 128.31 C 85.23 130.66 91.87 135.93 99.23 139.77 C 106.59 143.62 105.10 157.34 105.78 166.22 C 106.45 175.10 107.00 188.77 107.00 198.00
C 107.17 199.50 108.01 201.84 108.00 204.00
C 108.00 204.00 108.95 203.84 109.00 204.00
C 109.88 194.24 106.32 179.91 107.00 171.00 C 107.68 162.09 101.91 144.86 109.23 140.23 C 116.55 135.60 126.06 132.89 133.68 129.68 C 141.29 126.47 149.79 118.69 158.01 119.01 C 166.23 119.32 173.70 129.78 182.00 131.00
C 182.00 131.00 182.25 130.22 182.00 130.00
C 172.03 121.28 156.69 119.54 152.69 105.31 C 148.70 91.07 139.28 83.06 134.23 69.77 C 129.18 56.48 120.95 46.20 116.00 33.00
C 115.59 33.19 115.00 33.00 115.00 33.00
C 114.74 33.12 113.99 33.85 114.00 34.00
C 128.05 61.93 143.88 89.36 156.00 118.00
C 144.51 123.40 133.10 125.96 122.02 133.02 C 110.95 140.09 99.33 140.23 88.70 132.30 C 78.06 124.38 67.83 122.62 57.00 116.00
C 64.82 101.42 72.53 85.57 81.70 71.70 C 90.86 57.82 96.24 41.15 106.00 28.00
C 105.75 28.20 104.36 28.92 104.00 29.00 Z'
          />
        </g>
        <g fill='#C4BEDD' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 115.00 33.00 
C 115.00 33.00 115.59 33.19 116.00 33.00
C 116.26 32.88 116.71 31.94 117.00 32.00
C 116.25 30.32 114.57 28.96 113.00 28.00
C 113.48 29.51 114.18 31.72 115.00 33.00 Z'
          />
        </g>
        <g fill='#EEEBF5' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 116.00 33.00 
C 120.95 46.20 129.18 56.48 134.23 69.77 C 139.28 83.06 148.70 91.07 152.69 105.31 C 156.69 119.54 172.03 121.28 182.00 130.00
C 185.33 132.01 184.00 122.33 184.00 120.00
C 184.00 114.77 184.90 108.43 185.00 104.00
C 184.85 101.96 184.87 100.81 184.00 99.00
C 180.43 92.79 171.82 88.62 167.25 82.75 C 162.67 76.88 155.82 71.64 151.00 66.00
C 140.70 53.95 128.23 43.23 117.00 32.00
C 116.71 31.94 116.26 32.88 116.00 33.00
M 149.00 75.00 
C 147.81 75.41 146.23 74.69 145.00 75.00
C 148.39 82.63 160.10 79.01 165.75 86.25 C 171.41 93.48 174.87 99.81 167.75 107.75 C 160.62 115.68 150.65 105.62 153.00 98.00
C 159.09 112.93 173.66 100.28 164.75 90.25 C 155.83 80.22 143.02 87.23 142.00 72.00
C 143.33 72.00 144.67 72.00 146.00 72.00
C 148.19 71.63 150.78 72.00 153.00 72.00
C 153.50 72.00 154.00 72.00 154.00 72.00
C 155.57 72.54 157.18 74.30 157.00 76.00
C 154.73 74.28 151.66 75.27 149.00 75.00 Z'
          />
        </g>
        <g fill='#7E7995' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 168.00 66.00 
C 158.61 55.01 147.24 45.24 137.00 35.00
C 146.39 45.99 157.76 55.76 168.00 66.00 Z'
          />
        </g>
        <g fill='#918CA0' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 184.00 99.00 
C 180.81 92.34 172.35 88.14 167.75 82.25 C 163.16 76.35 156.25 71.25 151.00 66.00
C 155.82 71.64 162.67 76.88 167.25 82.75 C 171.82 88.62 180.43 92.79 184.00 99.00 Z'
          />
        </g>
        <g fill='#2C2740' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 76.00 72.00 
C 70.34 73.34 59.94 68.60 58.00 76.00
C 61.95 72.52 66.46 75.17 71.00 75.00
C 62.05 86.61 47.81 95.26 41.00 108.00
C 50.47 107.91 53.98 96.85 59.75 90.75 C 65.51 84.64 69.62 77.77 76.00 72.00 Z'
          />
        </g>
        <g fill='#302B49' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 149.00 75.00 
C 150.39 74.53 156.04 74.41 154.00 72.00
C 154.00 72.00 153.50 72.00 153.00 72.00
C 150.41 73.92 148.06 71.82 146.00 72.00
C 144.67 72.00 143.33 72.00 142.00 72.00
C 143.02 87.23 155.83 80.22 164.75 90.25 C 173.66 100.28 159.09 112.93 153.00 98.00
C 150.65 105.62 160.62 115.68 167.75 107.75 C 174.87 99.81 171.41 93.48 165.75 86.25 C 160.10 79.01 148.39 82.63 145.00 75.00
C 146.23 74.69 147.81 75.41 149.00 75.00 Z'
          />
        </g>
        <g fill='#6F6B7B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 153.00 72.00 
C 150.78 72.00 148.19 71.63 146.00 72.00
C 148.06 71.82 150.41 73.92 153.00 72.00 Z'
          />
        </g>
        <g fill='#7A7787' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 149.00 75.00 
C 151.66 75.27 154.73 74.28 157.00 76.00
C 157.18 74.30 155.57 72.54 154.00 72.00
C 156.04 74.41 150.39 74.53 149.00 75.00 Z'
          />
        </g>
        <g fill='#2E294C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 111.00 95.00 
C 116.27 91.80 121.55 80.41 113.68 77.32 C 105.81 74.23 92.88 74.40 95.00 85.00
C 96.43 79.37 105.79 74.90 110.07 80.93 C 114.36 86.95 107.78 94.80 102.00 96.00
C 109.85 96.98 116.22 106.38 111.25 113.25 C 106.28 120.11 93.92 117.70 94.00 109.00
C 88.07 122.31 106.35 121.33 114.25 116.25 C 122.15 111.16 121.92 96.57 111.00 95.00 Z'
          />
        </g>
        <g fill='#A097B3' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 21.00 122.00 
C 21.51 116.70 22.00 110.05 22.00 104.00
C 21.49 109.30 21.00 115.95 21.00 122.00 Z'
          />
        </g>
        <g fill='#958EAC' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 184.00 120.00 
C 184.45 115.38 185.40 109.40 185.00 104.00
C 184.90 108.43 184.00 114.77 184.00 120.00 Z'
          />
        </g>
        <g fill='#EFEBF6' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 35.00 129.00 
C 34.30 134.22 44.34 140.32 47.75 145.25 C 51.16 150.18 58.68 155.68 63.00 160.00
C 63.92 160.45 65.08 160.03 65.00 160.00
C 60.23 153.59 52.42 139.68 56.00 131.00 C 59.58 122.32 75.41 128.33 80.75 136.25 C 86.10 144.16 92.28 150.70 92.00 163.00 C 91.72 175.30 78.39 174.39 70.00 166.00
C 71.29 167.64 72.69 169.46 74.00 171.00
C 82.95 181.47 95.19 196.71 107.00 204.00
C 106.44 202.48 107.00 199.64 107.00 198.00
C 107.00 188.77 106.45 175.10 105.78 166.22 C 105.10 157.34 106.59 143.62 99.23 139.77 C 91.87 135.93 85.23 130.66 77.69 128.31 C 70.16 125.95 63.52 117.81 56.08 117.08 C 48.63 116.34 42.29 128.01 35.00 129.00 Z'
          />
        </g>
        <g fill='#EDEBF5' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 109.00 204.00 
C 110.25 207.57 120.72 195.95 124.25 192.25 C 127.78 188.54 135.13 181.63 139.25 177.25 C 143.36 172.87 150.72 166.30 154.75 161.75 C 158.79 157.21 166.54 151.09 169.75 146.75 C 172.96 142.41 184.90 135.62 182.00 131.00
C 173.70 129.78 166.23 119.32 158.01 119.01 C 149.79 118.69 141.29 126.47 133.68 129.68 C 126.06 132.89 116.55 135.60 109.23 140.23 C 101.91 144.86 107.68 162.09 107.00 171.00 C 106.32 179.91 109.88 194.24 109.00 204.00
M 150.00 160.00 
C 146.79 165.58 141.43 170.67 136.00 174.00
C 129.38 178.06 118.74 179.78 120.00 170.00 C 121.27 160.22 126.49 159.46 131.00 152.00
C 109.14 151.30 131.98 126.35 144.00 129.00 C 156.02 131.65 144.82 140.22 142.00 145.00
C 151.96 142.27 156.22 153.35 150.00 160.00 Z'
          />
        </g>
        <g fill='#2E274C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 65.00 160.00 
C 66.42 161.92 68.43 164.01 70.00 166.00
C 78.39 174.39 91.72 175.30 92.00 163.00 C 92.28 150.70 86.10 144.16 80.75 136.25 C 75.41 128.33 59.58 122.32 56.00 131.00 C 52.42 139.68 60.23 153.59 65.00 160.00
M 81.00 169.00 
C 73.88 170.71 66.56 160.12 65.23 154.77 C 63.90 149.42 57.26 133.84 63.25 130.25 C 69.25 126.67 76.97 138.86 78.77 144.23 C 80.57 149.60 85.00 162.92 81.00 169.00 Z'
          />
        </g>
        <g fill='#2F2950' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 136.00 174.00 
C 135.71 173.97 133.59 172.30 133.00 172.00
C 131.71 171.35 130.57 170.69 129.00 171.00
C 127.10 164.38 128.90 156.57 134.75 151.75 C 140.61 146.93 152.20 156.29 145.00 163.00
C 145.17 162.87 148.90 161.40 150.00 160.00
C 156.22 153.35 151.96 142.27 142.00 145.00
C 144.82 140.22 156.02 131.65 144.00 129.00 C 131.98 126.35 109.14 151.30 131.00 152.00
C 126.49 159.46 121.27 160.22 120.00 170.00 C 118.74 179.78 129.38 178.06 136.00 174.00
M 142.00 132.00 
C 148.12 136.20 137.35 150.77 130.78 145.22 C 124.21 139.67 136.55 128.25 142.00 132.00 Z'
          />
        </g>
        <g fill='#EEEBF5' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 81.00 169.00 
C 85.00 162.92 80.57 149.60 78.77 144.23 C 76.97 138.86 69.25 126.67 63.25 130.25 C 57.26 133.84 63.90 149.42 65.23 154.77 C 66.56 160.12 73.88 170.71 81.00 169.00 Z'
          />
        </g>
        <g fill='#EDEAF4' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 142.00 132.00 
C 136.55 128.25 124.21 139.67 130.78 145.22 C 137.35 150.77 148.12 136.20 142.00 132.00 Z'
          />
        </g>
        <g fill='#EDE9F4' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 133.00 172.00 
C 136.99 172.20 143.05 166.32 145.00 163.00
C 152.20 156.29 140.61 146.93 134.75 151.75 C 128.90 156.57 127.10 164.38 129.00 171.00
C 130.57 170.69 131.71 171.35 133.00 172.00 Z'
          />
        </g>
        <g fill='#C1B5D2' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 74.00 171.00 
C 72.69 169.46 71.29 167.64 70.00 166.00
C 68.43 164.01 66.42 161.92 65.00 160.00
C 65.08 160.03 63.92 160.45 63.00 160.00
C 66.54 163.54 70.41 167.41 74.00 171.00 Z'
          />
        </g>
        <g fill='#353141' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 145.00 163.00 
C 143.05 166.32 136.99 172.20 133.00 172.00
C 133.59 172.30 135.71 173.97 136.00 174.00
C 141.43 170.67 146.79 165.58 150.00 160.00
C 148.90 161.40 145.17 162.87 145.00 163.00 Z'
          />
        </g>
        <g fill='#8B8395' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 107.00 204.00 
C 108.08 204.67 106.79 204.85 108.00 204.00
C 108.01 201.84 107.17 199.50 107.00 198.00
C 107.00 199.64 106.44 202.48 107.00 204.00 Z'
          />
        </g>
      </svg>
    </div>
  );
};

export default Quentin;
