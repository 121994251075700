const ChilliZet = () => {
  return (
    <div className='w-1/5 sm:h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 80 80'
        fill='#D000F0'
        width='100%'
        height='100%'
        className='bg-[#D000F0]'
      >
        <rect />
        <g id='_x2D_e-Shape_4_2_'>
          <path
            d='M34.41,34.42c0-1.36,0.65-2.18,1.91-2.18c1.07,0,1.74,0.61,1.74,1.85v7.44h5.19v-7.99
		c0-4.28-2.16-6.22-5.08-6.22c-1.63,0-2.92,0.61-3.76,1.74v-6.88h-5.19v19.35h5.19V34.42z M23.57,35.8
		c-0.36,0.69-1.15,1.19-2.08,1.19c-1.32,0-2.3-1-2.3-2.38c0-1.38,0.98-2.38,2.3-2.38c0.93,0,1.68,0.5,2.08,1.16l4.38-2.46
		c-1.24-2.18-3.65-3.62-6.46-3.62c-4.29,0-7.5,3.15-7.5,7.3c0,4.15,3.2,7.3,7.5,7.3c2.81,0,5.22-1.41,6.46-3.62L23.57,35.8z
		 M60.53,41.52h5.19V27.68h-5.19V41.52z M40.94,26.64c1.54,0,2.86-1.3,2.86-2.82c0-1.52-1.32-2.82-2.86-2.82
		c-1.57,0-2.89,1.3-2.89,2.82C38.05,25.34,39.37,26.64,40.94,26.64z M63.14,26.64c1.54,0,2.86-1.3,2.86-2.82
		C66,22.3,64.68,21,63.14,21c-1.57,0-2.89,1.3-2.89,2.82C60.25,25.34,61.56,26.64,63.14,26.64z M53,41.52h5.19V21.35H53V41.52z
		 M34.29,44.5c-4.36,0-7.34,3.18-7.34,7.25c0,4.18,3.04,7.25,7.48,7.25c2.84,0,5-1.3,6.18-3.18l-1.63-0.94
		c-0.76,1.33-2.3,2.35-4.53,2.35c-2.98,0-5.2-1.74-5.59-4.57H41.2c0.03-0.28,0.06-0.58,0.06-0.86C41.26,48.01,38.59,44.5,34.29,44.5
		z M28.86,50.89c0.37-2.71,2.47-4.62,5.43-4.62c2.42,0,4.67,1.58,5.06,4.62H28.86z M47.37,41.52h3.34V21.35h-5.19v23.48h-2.95v1.77
		h2.95v8.39c0,3.32,2.11,4.24,5.9,3.68v-1.61c-2.73,0.11-4.05,0.3-4.05-2.08V46.6h4.05v-1.77h-4.05V41.52z M24.9,46.05v-1.22H14.78
		v1.77h7.53L14.5,57.45v1.22h10.68V56.9h-8.1L24.9,46.05z'
            fill='#fff'
          />
        </g>
      </svg>
    </div>
  );
};

export default ChilliZet;
