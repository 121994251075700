const Vulpesoft = () => {
  return (
    <div className='w-1/5 h-20 flex justify-center items-center sm:w-1/4 md:w-[15%] xl:w-1/5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 600 150'
        width='100%'
        height='100%'
      >
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 0.00 150.00 
L 600.00 150.00
L 600.00 0.00
L 0.00 0.00
L 0.00 150.00
M 70.00 6.00 
C 70.68 6.87 71.31 8.01 72.00 9.00
C 79.31 19.49 83.30 25.94 88.77 38.23 C 94.25 50.52 86.54 64.88 85.00 77.00
C 84.94 77.49 85.00 78.50 85.00 79.00
C 85.00 79.85 85.43 82.29 85.00 83.00
C 86.19 84.70 87.64 87.12 90.00 87.00
C 90.88 89.99 87.39 91.22 85.00 91.00
C 84.74 91.21 84.28 91.77 84.00 92.00
C 78.03 96.86 72.48 101.63 67.08 107.08 C 61.68 112.54 59.21 121.44 54.00 126.00
C 53.36 131.48 41.78 128.95 39.00 127.00
C 36.52 125.26 32.14 118.45 33.00 115.00
C 32.33 104.98 28.16 98.53 24.00 89.00
C 13.89 65.85 15.72 37.88 33.00 18.00
C 35.43 30.49 33.18 48.01 47.00 55.00
C 55.65 45.50 62.16 33.93 65.00 22.00
C 65.13 21.54 65.00 20.50 65.00 20.00
C 65.00 18.34 64.92 16.66 65.00 15.00
C 65.05 13.95 66.01 11.29 66.00 11.00
C 65.82 9.97 65.18 9.15 65.00 8.00
C 64.64 5.72 65.27 3.29 65.00 1.00
C 66.93 2.26 68.91 4.00 70.00 6.00
M 123.00 44.00 
C 125.90 61.60 127.65 79.50 131.00 97.00
C 134.55 79.97 136.32 61.61 139.00 44.00
C 144.00 44.00 149.00 44.00 154.00 44.00
C 150.68 53.79 151.61 63.93 149.00 74.00 C 146.39 84.07 146.12 94.04 143.22 104.22 C 140.32 114.41 145.31 126.93 132.00 128.00 C 118.69 129.07 121.04 115.40 119.00 105.00 C 116.96 94.59 115.51 85.41 114.00 75.00 C 112.49 64.59 109.34 54.72 109.00 44.00
C 113.67 44.00 118.33 44.00 123.00 44.00
M 183.00 44.00 
C 183.00 67.67 183.00 91.33 183.00 115.00
C 187.00 115.00 191.00 115.00 195.00 115.00
C 195.00 91.33 195.00 67.67 195.00 44.00
C 199.33 44.00 203.67 44.00 208.00 44.00
C 210.13 66.67 205.15 89.33 208.00 112.00 C 210.85 134.67 168.72 135.61 170.00 113.00 C 171.28 90.39 169.04 66.70 170.00 44.00
C 174.33 44.00 178.67 44.00 183.00 44.00
M 239.00 44.00 
C 239.00 67.67 239.00 91.33 239.00 115.00
C 246.00 115.00 253.00 115.00 260.00 115.00
C 260.00 119.33 260.00 123.67 260.00 128.00
C 248.67 128.00 237.33 128.00 226.00 128.00
C 226.00 100.00 226.00 72.00 226.00 44.00
C 230.33 44.00 234.67 44.00 239.00 44.00
M 292.00 86.00 
C 293.05 95.95 290.59 103.84 292.00 114.00 C 293.41 124.16 290.10 131.57 278.00 128.00
C 278.00 109.67 278.00 91.33 278.00 73.00
C 282.00 73.00 286.00 73.00 290.00 73.00
C 287.19 62.95 278.73 54.69 278.00 44.00
C 288.51 47.27 301.27 39.29 311.22 44.78 C 321.17 50.26 313.65 64.01 316.00 75.00 C 318.35 85.99 300.95 88.48 292.00 86.00
M 369.00 44.00 
C 369.00 48.33 369.00 52.67 369.00 57.00
C 361.67 57.00 354.33 57.00 347.00 57.00
C 347.00 62.33 347.00 67.67 347.00 73.00
C 352.67 73.00 358.33 73.00 364.00 73.00
C 364.00 77.33 364.00 81.67 364.00 86.00
C 358.33 86.00 352.67 86.00 347.00 86.00
C 347.00 95.67 347.00 105.33 347.00 115.00
C 354.33 115.00 361.67 115.00 369.00 115.00
C 369.00 119.33 369.00 123.67 369.00 128.00
C 357.33 128.00 345.67 128.00 334.00 128.00
C 334.00 100.00 334.00 72.00 334.00 44.00
C 345.67 44.00 357.33 44.00 369.00 44.00
M 422.00 67.00 
C 417.67 67.00 413.33 67.00 409.00 67.00
C 409.00 63.67 409.00 60.33 409.00 57.00
C 405.67 57.00 402.33 57.00 399.00 57.00
C 399.00 62.33 399.00 67.67 399.00 73.00
C 416.31 67.77 424.10 80.23 422.00 95.00 C 419.90 109.77 427.39 126.45 409.00 128.00 C 390.61 129.55 382.98 123.73 386.00 105.00
C 390.33 105.00 394.67 105.00 399.00 105.00
C 399.00 108.33 399.00 111.67 399.00 115.00
C 402.33 115.00 405.67 115.00 409.00 115.00
C 409.00 105.33 409.00 95.67 409.00 86.00
C 402.79 86.61 386.41 87.35 386.08 77.92 C 385.75 68.50 383.71 56.83 387.77 48.77 C 391.83 40.71 405.37 43.90 414.00 44.00 C 422.63 44.10 423.18 60.55 422.00 67.00
M 473.00 44.00 
C 481.26 46.51 477.34 58.55 478.00 66.00 C 478.66 73.45 477.70 84.42 478.00 93.00 C 478.30 101.58 477.86 110.58 478.00 120.00 C 478.14 129.42 465.87 127.94 459.00 128.00 C 452.13 128.06 439.92 129.29 440.01 119.99 C 440.10 110.69 439.83 101.33 440.00 93.00 C 440.17 84.67 439.65 72.91 440.00 66.00 C 440.35 59.09 437.31 45.24 445.23 44.23 C 453.15 43.22 465.57 41.74 473.00 44.00
M 531.00 44.00 
C 531.00 48.33 531.00 52.67 531.00 57.00
C 523.67 57.00 516.33 57.00 509.00 57.00
C 510.77 60.83 506.29 69.17 509.78 72.22 C 513.27 75.27 521.74 71.55 526.00 73.00
C 526.00 77.33 526.00 81.67 526.00 86.00
C 520.33 86.00 514.67 86.00 509.00 86.00
C 509.00 100.00 509.00 114.00 509.00 128.00
C 504.67 128.00 500.33 128.00 496.00 128.00
C 496.11 117.17 495.86 104.67 496.00 94.00 C 496.14 83.33 492.11 67.58 508.00 72.00
C 504.31 62.54 496.90 54.31 496.00 44.00
C 507.67 44.00 519.33 44.00 531.00 44.00
M 586.00 44.00 
C 586.00 48.33 586.00 52.67 586.00 57.00
C 582.00 57.00 578.00 57.00 574.00 57.00
C 574.00 80.67 574.00 104.33 574.00 128.00
C 569.67 128.00 565.33 128.00 561.00 128.00
C 561.00 104.33 561.00 80.67 561.00 57.00
C 556.67 57.00 552.33 57.00 548.00 57.00
C 548.00 52.67 548.00 48.33 548.00 44.00
C 560.67 44.00 573.33 44.00 586.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2A'
          strokeOpacity='0.90'
          strokeWidth='0.3'
        >
          <path
            d='
M 65.00 8.00 
C 64.30 5.01 69.87 17.58 66.00 15.00
C 65.65 14.77 65.00 15.00 65.00 15.00
C 64.92 16.66 65.00 18.34 65.00 20.00
C 65.52 19.31 66.95 20.44 67.00 21.00
C 67.45 19.67 67.97 17.10 69.00 16.00
C 69.51 29.70 71.98 44.81 68.00 58.00
C 72.34 56.18 72.69 59.17 70.00 62.00
C 72.76 61.80 75.79 61.54 74.00 65.00
C 78.64 62.43 76.54 69.25 77.00 72.00
C 81.12 71.86 82.32 75.86 85.00 77.00
C 86.54 64.88 94.25 50.52 88.77 38.23 C 83.30 25.94 79.31 19.49 72.00 9.00
C 71.66 8.75 71.82 9.21 69.75 8.24 C 67.69 7.27 69.61 5.12 70.00 6.00
C 68.91 4.00 66.93 2.26 65.00 1.00
C 65.27 3.29 64.64 5.72 65.00 8.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E66B29'
          strokeOpacity='0.72'
          strokeWidth='0.3'
        >
          <path
            d='
M 72.00 9.00 
C 70.71 8.56 69.79 7.42 70.00 6.00
C 69.61 5.12 67.69 7.27 69.75 8.24 C 71.82 9.21 71.66 8.75 72.00 9.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#D65A1C'
          strokeOpacity='0.17'
          strokeWidth='0.3'
        >
          <path
            d='
M 72.00 9.00 
C 71.31 8.01 70.68 6.87 70.00 6.00
C 69.79 7.42 70.71 8.56 72.00 9.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EB6922'
          strokeOpacity='0.34'
          strokeWidth='0.3'
        >
          <path
            d='
M 65.00 8.00 
C 65.18 9.15 65.82 9.97 66.00 11.00
C 66.12 11.68 66.34 14.43 66.00 15.00
C 69.87 17.58 64.30 5.01 65.00 8.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FE0000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 65.00 15.00 
C 65.00 15.00 65.65 14.77 66.00 15.00
C 66.34 14.43 66.12 11.68 66.00 11.00
C 66.01 11.29 65.05 13.95 65.00 15.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E68F3E'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 67.00 21.00 
C 67.05 21.50 67.00 22.00 67.00 22.00
C 66.42 46.77 41.95 67.44 21.00 76.00
C 20.37 79.79 23.40 81.96 27.00 81.00
C 28.66 81.19 28.83 81.47 30.00 82.00
C 31.98 82.90 32.65 83.10 34.00 85.00
C 35.87 87.61 34.89 89.51 36.78 92.22 C 38.66 94.93 34.91 100.44 35.00 99.00
C 37.93 104.04 34.32 104.59 34.00 106.00
C 34.67 108.74 36.15 112.34 34.00 115.00
C 37.70 115.00 42.41 114.20 46.00 115.00
C 48.95 105.13 49.38 93.70 53.00 84.00
C 54.94 78.81 56.23 75.83 62.00 74.00
C 70.03 71.46 76.45 74.00 83.00 79.00
C 83.32 79.24 84.00 79.00 84.00 79.00
C 84.50 79.00 85.00 79.00 85.00 79.00
C 85.00 78.50 84.94 77.49 85.00 77.00
C 82.32 75.86 81.12 71.86 77.00 72.00
C 76.54 69.25 78.64 62.43 74.00 65.00
C 75.79 61.54 72.76 61.80 70.00 62.00
C 72.69 59.17 72.34 56.18 68.00 58.00
C 71.98 44.81 69.51 29.70 69.00 16.00
C 67.97 17.10 67.45 19.67 67.00 21.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2B'
          strokeOpacity='0.87'
          strokeWidth='0.3'
        >
          <path
            d='
M 24.00 89.00 
C 23.40 88.39 25.52 89.50 26.00 90.00
C 29.03 91.37 32.47 94.64 35.00 99.00
C 34.91 100.44 38.66 94.93 36.78 92.22 C 34.89 89.51 35.87 87.61 34.00 85.00
C 33.48 88.56 29.45 83.44 29.00 83.00
C 28.77 82.78 27.08 81.04 27.00 81.00
C 23.40 81.96 20.37 79.79 21.00 76.00
C 41.95 67.44 66.42 46.77 67.00 22.00
C 66.84 22.23 65.25 22.25 65.00 22.00
C 62.16 33.93 55.65 45.50 47.00 55.00
C 33.18 48.01 35.43 30.49 33.00 18.00
C 15.72 37.88 13.89 65.85 24.00 89.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E66A26'
          strokeOpacity='0.53'
          strokeWidth='0.3'
        >
          <path
            d='
M 65.00 22.00 
C 65.25 22.25 66.84 22.23 67.00 22.00
C 67.00 22.00 67.05 21.50 67.00 21.00
C 66.95 20.44 65.52 19.31 65.00 20.00
C 65.00 20.50 65.13 21.54 65.00 22.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2B'
          strokeOpacity='0.93'
          strokeWidth='0.3'
        >
          <path
            d='
M 123.00 44.00 
C 118.33 44.00 113.67 44.00 109.00 44.00
C 109.34 54.72 112.49 64.59 114.00 75.00 C 115.51 85.41 116.96 94.59 119.00 105.00 C 121.04 115.40 118.69 129.07 132.00 128.00 C 145.31 126.93 140.32 114.41 143.22 104.22 C 146.12 94.04 146.39 84.07 149.00 74.00 C 151.61 63.93 150.68 53.79 154.00 44.00
C 149.00 44.00 144.00 44.00 139.00 44.00
C 136.32 61.61 134.55 79.97 131.00 97.00
C 127.65 79.50 125.90 61.60 123.00 44.00
M 131.00 98.00 
C 134.15 101.64 128.14 100.31 131.00 98.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2C'
          strokeOpacity='0.99'
          strokeWidth='0.3'
        >
          <path
            d='
M 183.00 44.00 
C 178.67 44.00 174.33 44.00 170.00 44.00
C 169.04 66.70 171.28 90.39 170.00 113.00 C 168.72 135.61 210.85 134.67 208.00 112.00 C 205.15 89.33 210.13 66.67 208.00 44.00
C 203.67 44.00 199.33 44.00 195.00 44.00
C 195.00 67.67 195.00 91.33 195.00 115.00
C 191.00 115.00 187.00 115.00 183.00 115.00
C 183.00 91.33 183.00 67.67 183.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76E2B'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 239.00 44.00 
C 234.67 44.00 230.33 44.00 226.00 44.00
C 226.00 72.00 226.00 100.00 226.00 128.00
C 237.33 128.00 248.67 128.00 260.00 128.00
C 260.00 123.67 260.00 119.33 260.00 115.00
C 253.00 115.00 246.00 115.00 239.00 115.00
C 239.00 91.33 239.00 67.67 239.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2C'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 292.00 86.00 
C 300.95 88.48 318.35 85.99 316.00 75.00 C 313.65 64.01 321.17 50.26 311.22 44.78 C 301.27 39.29 288.51 47.27 278.00 44.00
C 278.73 54.69 287.19 62.95 290.00 73.00
C 286.00 73.00 282.00 73.00 278.00 73.00
C 278.00 91.33 278.00 109.67 278.00 128.00
C 290.10 131.57 293.41 124.16 292.00 114.00 C 290.59 103.84 293.05 95.95 292.00 86.00
M 303.00 57.00 
C 303.00 62.33 303.00 67.67 303.00 73.00
C 299.33 73.00 295.67 73.00 292.00 73.00
C 291.47 67.72 293.14 61.85 291.00 57.00
C 295.00 57.00 299.00 57.00 303.00 57.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2C'
          strokeOpacity='0.99'
          strokeWidth='0.3'
        >
          <path
            d='
M 369.00 44.00 
C 357.33 44.00 345.67 44.00 334.00 44.00
C 334.00 72.00 334.00 100.00 334.00 128.00
C 345.67 128.00 357.33 128.00 369.00 128.00
C 369.00 123.67 369.00 119.33 369.00 115.00
C 361.67 115.00 354.33 115.00 347.00 115.00
C 347.00 105.33 347.00 95.67 347.00 86.00
C 352.67 86.00 358.33 86.00 364.00 86.00
C 364.00 81.67 364.00 77.33 364.00 73.00
C 358.33 73.00 352.67 73.00 347.00 73.00
C 347.00 67.67 347.00 62.33 347.00 57.00
C 354.33 57.00 361.67 57.00 369.00 57.00
C 369.00 52.67 369.00 48.33 369.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76E2B'
          strokeOpacity='0.98'
          strokeWidth='0.3'
        >
          <path
            d='
M 422.00 67.00 
C 423.18 60.55 422.63 44.10 414.00 44.00 C 405.37 43.90 391.83 40.71 387.77 48.77 C 383.71 56.83 385.75 68.50 386.08 77.92 C 386.41 87.35 402.79 86.61 409.00 86.00
C 409.00 95.67 409.00 105.33 409.00 115.00
C 405.67 115.00 402.33 115.00 399.00 115.00
C 399.00 111.67 399.00 108.33 399.00 105.00
C 394.67 105.00 390.33 105.00 386.00 105.00
C 382.98 123.73 390.61 129.55 409.00 128.00 C 427.39 126.45 419.90 109.77 422.00 95.00 C 424.10 80.23 416.31 67.77 399.00 73.00
C 399.00 67.67 399.00 62.33 399.00 57.00
C 402.33 57.00 405.67 57.00 409.00 57.00
C 409.00 60.33 409.00 63.67 409.00 67.00
C 413.33 67.00 417.67 67.00 422.00 67.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2B'
          strokeOpacity='0.99'
          strokeWidth='0.3'
        >
          <path
            d='
M 473.00 44.00 
C 465.57 41.74 453.15 43.22 445.23 44.23 C 437.31 45.24 440.35 59.09 440.00 66.00 C 439.65 72.91 440.17 84.67 440.00 93.00 C 439.83 101.33 440.10 110.69 440.01 119.99 C 439.92 129.29 452.13 128.06 459.00 128.00 C 465.87 127.94 478.14 129.42 478.00 120.00 C 477.86 110.58 478.30 101.58 478.00 93.00 C 477.70 84.42 478.66 73.45 478.00 66.00 C 477.34 58.55 481.26 46.51 473.00 44.00
M 465.00 57.00 
C 465.00 76.33 465.00 95.67 465.00 115.00
C 461.00 115.00 457.00 115.00 453.00 115.00
C 453.00 95.67 453.00 76.33 453.00 57.00
C 457.00 57.00 461.00 57.00 465.00 57.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2C'
          strokeOpacity='0.97'
          strokeWidth='0.3'
        >
          <path
            d='
M 531.00 44.00 
C 519.33 44.00 507.67 44.00 496.00 44.00
C 496.90 54.31 504.31 62.54 508.00 72.00
C 492.11 67.58 496.14 83.33 496.00 94.00 C 495.86 104.67 496.11 117.17 496.00 128.00
C 500.33 128.00 504.67 128.00 509.00 128.00
C 509.00 114.00 509.00 100.00 509.00 86.00
C 514.67 86.00 520.33 86.00 526.00 86.00
C 526.00 81.67 526.00 77.33 526.00 73.00
C 521.74 71.55 513.27 75.27 509.78 72.22 C 506.29 69.17 510.77 60.83 509.00 57.00
C 516.33 57.00 523.67 57.00 531.00 57.00
C 531.00 52.67 531.00 48.33 531.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76D2B'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 586.00 44.00 
C 573.33 44.00 560.67 44.00 548.00 44.00
C 548.00 48.33 548.00 52.67 548.00 57.00
C 552.33 57.00 556.67 57.00 561.00 57.00
C 561.00 80.67 561.00 104.33 561.00 128.00
C 565.33 128.00 569.67 128.00 574.00 128.00
C 574.00 104.33 574.00 80.67 574.00 57.00
C 578.00 57.00 582.00 57.00 586.00 57.00
C 586.00 52.67 586.00 48.33 586.00 44.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 303.00 57.00 
C 299.00 57.00 295.00 57.00 291.00 57.00
C 293.14 61.85 291.47 67.72 292.00 73.00
C 295.67 73.00 299.33 73.00 303.00 73.00
C 303.00 67.67 303.00 62.33 303.00 57.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#000000'
          strokeOpacity='0.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 465.00 57.00 
C 461.00 57.00 457.00 57.00 453.00 57.00
C 453.00 76.33 453.00 95.67 453.00 115.00
C 457.00 115.00 461.00 115.00 465.00 115.00
C 465.00 95.67 465.00 76.33 465.00 57.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E77233'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 62.00 74.00 
C 68.43 77.22 53.28 83.78 53.00 84.00
C 49.38 93.70 48.95 105.13 46.00 115.00
C 46.23 115.05 46.72 115.77 47.00 116.00
C 47.81 116.68 48.15 118.16 47.00 119.00
C 48.26 120.00 54.02 107.39 56.75 103.75 C 59.48 100.11 67.58 94.25 68.00 90.00
C 75.16 90.85 76.79 78.91 83.00 84.00
C 83.10 84.20 84.00 84.00 84.00 84.00
C 83.75 83.74 84.00 83.00 84.00 83.00
C 83.21 82.23 82.40 80.33 83.00 79.00
C 76.45 74.00 70.03 71.46 62.00 74.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#110601'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 53.00 84.00 
C 53.28 83.78 68.43 77.22 62.00 74.00
C 56.23 75.83 54.94 78.81 53.00 84.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E66D2A'
          strokeOpacity='0.75'
          strokeWidth='0.3'
        >
          <path
            d='
M 84.00 83.00 
C 83.41 82.02 84.59 79.98 84.00 79.00
C 84.00 79.00 83.32 79.24 83.00 79.00
C 82.40 80.33 83.21 82.23 84.00 83.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#DF5C14'
          strokeOpacity='0.06'
          strokeWidth='0.3'
        >
          <path
            d='
M 84.00 83.00 
C 84.25 82.75 85.00 83.00 85.00 83.00
C 85.43 82.29 85.00 79.85 85.00 79.00
C 85.00 79.00 84.50 79.00 84.00 79.00
C 84.59 79.98 83.41 82.02 84.00 83.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#82471C'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 29.00 83.00 
C 29.25 82.91 29.65 82.03 30.00 82.00
C 28.83 81.47 28.66 81.19 27.00 81.00
C 27.08 81.04 28.77 82.78 29.00 83.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#2A1506'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 29.00 83.00 
C 29.45 83.44 33.48 88.56 34.00 85.00
C 32.65 83.10 31.98 82.90 30.00 82.00
C 29.65 82.03 29.25 82.91 29.00 83.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FCF8F6'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 47.00 119.00 
C 46.69 118.76 46.00 119.00 46.00 119.00
C 44.53 121.10 42.57 123.06 41.00 125.00
C 40.26 125.08 44.00 127.00 44.00 127.00
C 55.73 129.47 56.44 112.68 63.75 106.75 C 71.07 100.83 76.78 91.88 86.00 89.00
C 86.45 86.69 83.47 85.93 83.00 84.00
C 76.79 78.91 75.16 90.85 68.00 90.00
C 67.58 94.25 59.48 100.11 56.75 103.75 C 54.02 107.39 48.26 120.00 47.00 119.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EB6727'
          strokeOpacity='0.34'
          strokeWidth='0.3'
        >
          <path
            d='
M 84.00 84.00 
C 86.34 86.40 88.07 88.60 85.00 91.00
C 87.39 91.22 90.88 89.99 90.00 87.00
C 87.64 87.12 86.19 84.70 85.00 83.00
C 85.00 83.00 84.25 82.75 84.00 83.00
C 84.00 83.00 83.75 83.74 84.00 84.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#F0A984'
          strokeOpacity='0.93'
          strokeWidth='0.3'
        >
          <path
            d='
M 44.00 127.00 
C 47.30 128.25 52.07 129.06 54.00 125.00
C 56.58 118.75 59.76 113.37 64.00 108.00
C 67.39 103.71 71.66 99.23 76.00 96.00
C 78.50 94.14 81.74 91.58 84.00 92.00
C 84.28 91.77 84.74 91.21 85.00 91.00
C 88.07 88.60 86.34 86.40 84.00 84.00
C 84.00 84.00 83.10 84.20 83.00 84.00
C 83.47 85.93 86.45 86.69 86.00 89.00
C 76.78 91.88 71.07 100.83 63.75 106.75 C 56.44 112.68 55.73 129.47 44.00 127.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#EA783F'
          strokeOpacity='0.51'
          strokeWidth='0.3'
        >
          <path
            d='
M 24.00 89.00 
C 28.16 98.53 32.33 104.98 33.00 115.00
C 33.03 114.88 34.00 115.00 34.00 115.00
C 36.15 112.34 34.67 108.74 34.00 106.00
C 32.66 100.52 29.94 94.08 26.00 90.00
C 25.52 89.50 23.40 88.39 24.00 89.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#FAE7DC'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 26.00 90.00 
C 29.94 94.08 32.66 100.52 34.00 106.00
C 34.32 104.59 37.93 104.04 35.00 99.00
C 32.47 94.64 29.03 91.37 26.00 90.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E56424'
          strokeOpacity='0.25'
          strokeWidth='0.3'
        >
          <path
            d='
M 76.00 96.00 
C 72.07 99.43 67.51 104.21 64.00 108.00
C 59.76 113.37 56.58 118.75 54.00 125.00
C 53.82 125.43 54.00 126.00 54.00 126.00
C 59.21 121.44 61.68 112.54 67.08 107.08 C 72.48 101.63 78.03 96.86 84.00 92.00
C 81.74 91.58 78.50 94.14 76.00 96.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E76F2F'
          strokeOpacity='0.64'
          strokeWidth='0.3'
        >
          <path
            d='
M 64.00 108.00 
C 67.51 104.21 72.07 99.43 76.00 96.00
C 71.66 99.23 67.39 103.71 64.00 108.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E56C2B'
          strokeOpacity='0.47'
          strokeWidth='0.3'
        >
          <path
            d='
M 131.00 98.00 
C 128.14 100.31 134.15 101.64 131.00 98.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#010000'
          strokeOpacity='0.95'
          strokeWidth='0.3'
        >
          <path
            d='
M 39.00 127.00 
C 39.45 126.90 40.69 125.38 41.00 125.00
C 42.57 123.06 44.53 121.10 46.00 119.00
C 46.42 118.24 47.43 116.97 47.00 116.00
C 46.72 115.77 46.23 115.05 46.00 115.00
C 42.41 114.20 37.70 115.00 34.00 115.00
C 34.00 115.00 33.03 114.88 33.00 115.00
C 32.14 118.45 36.52 125.26 39.00 127.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#984E25'
          strokeOpacity='1.00'
          strokeWidth='0.3'
        >
          <path
            d='
M 46.00 119.00 
C 46.00 119.00 46.69 118.76 47.00 119.00
C 48.15 118.16 47.81 116.68 47.00 116.00
C 47.43 116.97 46.42 118.24 46.00 119.00 Z'
          />
        </g>
        <g
          fill='None'
          fillOpacity='0.0'
          stroke='#E67B49'
          strokeOpacity='0.39'
          strokeWidth='0.3'
        >
          <path
            d='
M 39.00 127.00 
C 41.78 128.95 53.36 131.48 54.00 126.00
C 54.00 126.00 53.82 125.43 54.00 125.00
C 52.07 129.06 47.30 128.25 44.00 127.00
C 44.00 127.00 40.26 125.08 41.00 125.00
C 40.69 125.38 39.45 126.90 39.00 127.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 0.00 150.00 
L 600.00 150.00
L 600.00 0.00
L 0.00 0.00
L 0.00 150.00
M 70.00 6.00 
C 70.68 6.87 71.31 8.01 72.00 9.00
C 79.31 19.49 83.30 25.94 88.77 38.23 C 94.25 50.52 86.54 64.88 85.00 77.00
C 84.94 77.49 85.00 78.50 85.00 79.00
C 85.00 79.85 85.43 82.29 85.00 83.00
C 86.19 84.70 87.64 87.12 90.00 87.00
C 90.88 89.99 87.39 91.22 85.00 91.00
C 84.74 91.21 84.28 91.77 84.00 92.00
C 78.03 96.86 72.48 101.63 67.08 107.08 C 61.68 112.54 59.21 121.44 54.00 126.00
C 53.36 131.48 41.78 128.95 39.00 127.00
C 36.52 125.26 32.14 118.45 33.00 115.00
C 32.33 104.98 28.16 98.53 24.00 89.00
C 13.89 65.85 15.72 37.88 33.00 18.00
C 35.43 30.49 33.18 48.01 47.00 55.00
C 55.65 45.50 62.16 33.93 65.00 22.00
C 65.13 21.54 65.00 20.50 65.00 20.00
C 65.00 18.34 64.92 16.66 65.00 15.00
C 65.05 13.95 66.01 11.29 66.00 11.00
C 65.82 9.97 65.18 9.15 65.00 8.00
C 64.64 5.72 65.27 3.29 65.00 1.00
C 66.93 2.26 68.91 4.00 70.00 6.00
M 123.00 44.00 
C 125.90 61.60 127.65 79.50 131.00 97.00
C 134.55 79.97 136.32 61.61 139.00 44.00
C 144.00 44.00 149.00 44.00 154.00 44.00
C 150.68 53.79 151.61 63.93 149.00 74.00 C 146.39 84.07 146.12 94.04 143.22 104.22 C 140.32 114.41 145.31 126.93 132.00 128.00 C 118.69 129.07 121.04 115.40 119.00 105.00 C 116.96 94.59 115.51 85.41 114.00 75.00 C 112.49 64.59 109.34 54.72 109.00 44.00
C 113.67 44.00 118.33 44.00 123.00 44.00
M 183.00 44.00 
C 183.00 67.67 183.00 91.33 183.00 115.00
C 187.00 115.00 191.00 115.00 195.00 115.00
C 195.00 91.33 195.00 67.67 195.00 44.00
C 199.33 44.00 203.67 44.00 208.00 44.00
C 210.13 66.67 205.15 89.33 208.00 112.00 C 210.85 134.67 168.72 135.61 170.00 113.00 C 171.28 90.39 169.04 66.70 170.00 44.00
C 174.33 44.00 178.67 44.00 183.00 44.00
M 239.00 44.00 
C 239.00 67.67 239.00 91.33 239.00 115.00
C 246.00 115.00 253.00 115.00 260.00 115.00
C 260.00 119.33 260.00 123.67 260.00 128.00
C 248.67 128.00 237.33 128.00 226.00 128.00
C 226.00 100.00 226.00 72.00 226.00 44.00
C 230.33 44.00 234.67 44.00 239.00 44.00
M 292.00 86.00 
C 293.05 95.95 290.59 103.84 292.00 114.00 C 293.41 124.16 290.10 131.57 278.00 128.00
C 278.00 109.67 278.00 91.33 278.00 73.00
C 282.00 73.00 286.00 73.00 290.00 73.00
C 287.19 62.95 278.73 54.69 278.00 44.00
C 288.51 47.27 301.27 39.29 311.22 44.78 C 321.17 50.26 313.65 64.01 316.00 75.00 C 318.35 85.99 300.95 88.48 292.00 86.00
M 369.00 44.00 
C 369.00 48.33 369.00 52.67 369.00 57.00
C 361.67 57.00 354.33 57.00 347.00 57.00
C 347.00 62.33 347.00 67.67 347.00 73.00
C 352.67 73.00 358.33 73.00 364.00 73.00
C 364.00 77.33 364.00 81.67 364.00 86.00
C 358.33 86.00 352.67 86.00 347.00 86.00
C 347.00 95.67 347.00 105.33 347.00 115.00
C 354.33 115.00 361.67 115.00 369.00 115.00
C 369.00 119.33 369.00 123.67 369.00 128.00
C 357.33 128.00 345.67 128.00 334.00 128.00
C 334.00 100.00 334.00 72.00 334.00 44.00
C 345.67 44.00 357.33 44.00 369.00 44.00
M 422.00 67.00 
C 417.67 67.00 413.33 67.00 409.00 67.00
C 409.00 63.67 409.00 60.33 409.00 57.00
C 405.67 57.00 402.33 57.00 399.00 57.00
C 399.00 62.33 399.00 67.67 399.00 73.00
C 416.31 67.77 424.10 80.23 422.00 95.00 C 419.90 109.77 427.39 126.45 409.00 128.00 C 390.61 129.55 382.98 123.73 386.00 105.00
C 390.33 105.00 394.67 105.00 399.00 105.00
C 399.00 108.33 399.00 111.67 399.00 115.00
C 402.33 115.00 405.67 115.00 409.00 115.00
C 409.00 105.33 409.00 95.67 409.00 86.00
C 402.79 86.61 386.41 87.35 386.08 77.92 C 385.75 68.50 383.71 56.83 387.77 48.77 C 391.83 40.71 405.37 43.90 414.00 44.00 C 422.63 44.10 423.18 60.55 422.00 67.00
M 473.00 44.00 
C 481.26 46.51 477.34 58.55 478.00 66.00 C 478.66 73.45 477.70 84.42 478.00 93.00 C 478.30 101.58 477.86 110.58 478.00 120.00 C 478.14 129.42 465.87 127.94 459.00 128.00 C 452.13 128.06 439.92 129.29 440.01 119.99 C 440.10 110.69 439.83 101.33 440.00 93.00 C 440.17 84.67 439.65 72.91 440.00 66.00 C 440.35 59.09 437.31 45.24 445.23 44.23 C 453.15 43.22 465.57 41.74 473.00 44.00
M 531.00 44.00 
C 531.00 48.33 531.00 52.67 531.00 57.00
C 523.67 57.00 516.33 57.00 509.00 57.00
C 510.77 60.83 506.29 69.17 509.78 72.22 C 513.27 75.27 521.74 71.55 526.00 73.00
C 526.00 77.33 526.00 81.67 526.00 86.00
C 520.33 86.00 514.67 86.00 509.00 86.00
C 509.00 100.00 509.00 114.00 509.00 128.00
C 504.67 128.00 500.33 128.00 496.00 128.00
C 496.11 117.17 495.86 104.67 496.00 94.00 C 496.14 83.33 492.11 67.58 508.00 72.00
C 504.31 62.54 496.90 54.31 496.00 44.00
C 507.67 44.00 519.33 44.00 531.00 44.00
M 586.00 44.00 
C 586.00 48.33 586.00 52.67 586.00 57.00
C 582.00 57.00 578.00 57.00 574.00 57.00
C 574.00 80.67 574.00 104.33 574.00 128.00
C 569.67 128.00 565.33 128.00 561.00 128.00
C 561.00 104.33 561.00 80.67 561.00 57.00
C 556.67 57.00 552.33 57.00 548.00 57.00
C 548.00 52.67 548.00 48.33 548.00 44.00
C 560.67 44.00 573.33 44.00 586.00 44.00 Z'
          />
        </g>
        <g fill='#E76D2A' fillOpacity='0.90' stroke='None'>
          <path
            d='
M 65.00 8.00 
C 64.30 5.01 69.87 17.58 66.00 15.00
C 65.65 14.77 65.00 15.00 65.00 15.00
C 64.92 16.66 65.00 18.34 65.00 20.00
C 65.52 19.31 66.95 20.44 67.00 21.00
C 67.45 19.67 67.97 17.10 69.00 16.00
C 69.51 29.70 71.98 44.81 68.00 58.00
C 72.34 56.18 72.69 59.17 70.00 62.00
C 72.76 61.80 75.79 61.54 74.00 65.00
C 78.64 62.43 76.54 69.25 77.00 72.00
C 81.12 71.86 82.32 75.86 85.00 77.00
C 86.54 64.88 94.25 50.52 88.77 38.23 C 83.30 25.94 79.31 19.49 72.00 9.00
C 71.66 8.75 71.82 9.21 69.75 8.24 C 67.69 7.27 69.61 5.12 70.00 6.00
C 68.91 4.00 66.93 2.26 65.00 1.00
C 65.27 3.29 64.64 5.72 65.00 8.00 Z'
          />
        </g>
        <g fill='#E66B29' fillOpacity='0.72' stroke='None'>
          <path
            d='
M 72.00 9.00 
C 70.71 8.56 69.79 7.42 70.00 6.00
C 69.61 5.12 67.69 7.27 69.75 8.24 C 71.82 9.21 71.66 8.75 72.00 9.00 Z'
          />
        </g>
        <g fill='#D65A1C' fillOpacity='0.17' stroke='None'>
          <path
            d='
M 72.00 9.00 
C 71.31 8.01 70.68 6.87 70.00 6.00
C 69.79 7.42 70.71 8.56 72.00 9.00 Z'
          />
        </g>
        <g fill='#EB6922' fillOpacity='0.34' stroke='None'>
          <path
            d='
M 65.00 8.00 
C 65.18 9.15 65.82 9.97 66.00 11.00
C 66.12 11.68 66.34 14.43 66.00 15.00
C 69.87 17.58 64.30 5.01 65.00 8.00 Z'
          />
        </g>
        <g fill='#FE0000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 65.00 15.00 
C 65.00 15.00 65.65 14.77 66.00 15.00
C 66.34 14.43 66.12 11.68 66.00 11.00
C 66.01 11.29 65.05 13.95 65.00 15.00 Z'
          />
        </g>
        <g fill='#E68F3E' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 67.00 21.00 
C 67.05 21.50 67.00 22.00 67.00 22.00
C 66.42 46.77 41.95 67.44 21.00 76.00
C 20.37 79.79 23.40 81.96 27.00 81.00
C 28.66 81.19 28.83 81.47 30.00 82.00
C 31.98 82.90 32.65 83.10 34.00 85.00
C 35.87 87.61 34.89 89.51 36.78 92.22 C 38.66 94.93 34.91 100.44 35.00 99.00
C 37.93 104.04 34.32 104.59 34.00 106.00
C 34.67 108.74 36.15 112.34 34.00 115.00
C 37.70 115.00 42.41 114.20 46.00 115.00
C 48.95 105.13 49.38 93.70 53.00 84.00
C 54.94 78.81 56.23 75.83 62.00 74.00
C 70.03 71.46 76.45 74.00 83.00 79.00
C 83.32 79.24 84.00 79.00 84.00 79.00
C 84.50 79.00 85.00 79.00 85.00 79.00
C 85.00 78.50 84.94 77.49 85.00 77.00
C 82.32 75.86 81.12 71.86 77.00 72.00
C 76.54 69.25 78.64 62.43 74.00 65.00
C 75.79 61.54 72.76 61.80 70.00 62.00
C 72.69 59.17 72.34 56.18 68.00 58.00
C 71.98 44.81 69.51 29.70 69.00 16.00
C 67.97 17.10 67.45 19.67 67.00 21.00 Z'
          />
        </g>
        <g fill='#E76D2B' fillOpacity='0.87' stroke='None'>
          <path
            d='
M 24.00 89.00 
C 23.40 88.39 25.52 89.50 26.00 90.00
C 29.03 91.37 32.47 94.64 35.00 99.00
C 34.91 100.44 38.66 94.93 36.78 92.22 C 34.89 89.51 35.87 87.61 34.00 85.00
C 33.48 88.56 29.45 83.44 29.00 83.00
C 28.77 82.78 27.08 81.04 27.00 81.00
C 23.40 81.96 20.37 79.79 21.00 76.00
C 41.95 67.44 66.42 46.77 67.00 22.00
C 66.84 22.23 65.25 22.25 65.00 22.00
C 62.16 33.93 55.65 45.50 47.00 55.00
C 33.18 48.01 35.43 30.49 33.00 18.00
C 15.72 37.88 13.89 65.85 24.00 89.00 Z'
          />
        </g>
        <g fill='#E66A26' fillOpacity='0.53' stroke='None'>
          <path
            d='
M 65.00 22.00 
C 65.25 22.25 66.84 22.23 67.00 22.00
C 67.00 22.00 67.05 21.50 67.00 21.00
C 66.95 20.44 65.52 19.31 65.00 20.00
C 65.00 20.50 65.13 21.54 65.00 22.00 Z'
          />
        </g>
        <g fill='#E76D2B' fillOpacity='0.93' stroke='None'>
          <path
            d='
M 123.00 44.00 
C 118.33 44.00 113.67 44.00 109.00 44.00
C 109.34 54.72 112.49 64.59 114.00 75.00 C 115.51 85.41 116.96 94.59 119.00 105.00 C 121.04 115.40 118.69 129.07 132.00 128.00 C 145.31 126.93 140.32 114.41 143.22 104.22 C 146.12 94.04 146.39 84.07 149.00 74.00 C 151.61 63.93 150.68 53.79 154.00 44.00
C 149.00 44.00 144.00 44.00 139.00 44.00
C 136.32 61.61 134.55 79.97 131.00 97.00
C 127.65 79.50 125.90 61.60 123.00 44.00
M 131.00 98.00 
C 134.15 101.64 128.14 100.31 131.00 98.00 Z'
          />
        </g>
        <g fill='#E76D2C' fillOpacity='0.99' stroke='None'>
          <path
            d='
M 183.00 44.00 
C 178.67 44.00 174.33 44.00 170.00 44.00
C 169.04 66.70 171.28 90.39 170.00 113.00 C 168.72 135.61 210.85 134.67 208.00 112.00 C 205.15 89.33 210.13 66.67 208.00 44.00
C 203.67 44.00 199.33 44.00 195.00 44.00
C 195.00 67.67 195.00 91.33 195.00 115.00
C 191.00 115.00 187.00 115.00 183.00 115.00
C 183.00 91.33 183.00 67.67 183.00 44.00 Z'
          />
        </g>
        <g fill='#E76E2B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 239.00 44.00 
C 234.67 44.00 230.33 44.00 226.00 44.00
C 226.00 72.00 226.00 100.00 226.00 128.00
C 237.33 128.00 248.67 128.00 260.00 128.00
C 260.00 123.67 260.00 119.33 260.00 115.00
C 253.00 115.00 246.00 115.00 239.00 115.00
C 239.00 91.33 239.00 67.67 239.00 44.00 Z'
          />
        </g>
        <g fill='#E76D2C' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 292.00 86.00 
C 300.95 88.48 318.35 85.99 316.00 75.00 C 313.65 64.01 321.17 50.26 311.22 44.78 C 301.27 39.29 288.51 47.27 278.00 44.00
C 278.73 54.69 287.19 62.95 290.00 73.00
C 286.00 73.00 282.00 73.00 278.00 73.00
C 278.00 91.33 278.00 109.67 278.00 128.00
C 290.10 131.57 293.41 124.16 292.00 114.00 C 290.59 103.84 293.05 95.95 292.00 86.00
M 303.00 57.00 
C 303.00 62.33 303.00 67.67 303.00 73.00
C 299.33 73.00 295.67 73.00 292.00 73.00
C 291.47 67.72 293.14 61.85 291.00 57.00
C 295.00 57.00 299.00 57.00 303.00 57.00 Z'
          />
        </g>
        <g fill='#E76D2C' fillOpacity='0.99' stroke='None'>
          <path
            d='
M 369.00 44.00 
C 357.33 44.00 345.67 44.00 334.00 44.00
C 334.00 72.00 334.00 100.00 334.00 128.00
C 345.67 128.00 357.33 128.00 369.00 128.00
C 369.00 123.67 369.00 119.33 369.00 115.00
C 361.67 115.00 354.33 115.00 347.00 115.00
C 347.00 105.33 347.00 95.67 347.00 86.00
C 352.67 86.00 358.33 86.00 364.00 86.00
C 364.00 81.67 364.00 77.33 364.00 73.00
C 358.33 73.00 352.67 73.00 347.00 73.00
C 347.00 67.67 347.00 62.33 347.00 57.00
C 354.33 57.00 361.67 57.00 369.00 57.00
C 369.00 52.67 369.00 48.33 369.00 44.00 Z'
          />
        </g>
        <g fill='#E76E2B' fillOpacity='0.98' stroke='None'>
          <path
            d='
M 422.00 67.00 
C 423.18 60.55 422.63 44.10 414.00 44.00 C 405.37 43.90 391.83 40.71 387.77 48.77 C 383.71 56.83 385.75 68.50 386.08 77.92 C 386.41 87.35 402.79 86.61 409.00 86.00
C 409.00 95.67 409.00 105.33 409.00 115.00
C 405.67 115.00 402.33 115.00 399.00 115.00
C 399.00 111.67 399.00 108.33 399.00 105.00
C 394.67 105.00 390.33 105.00 386.00 105.00
C 382.98 123.73 390.61 129.55 409.00 128.00 C 427.39 126.45 419.90 109.77 422.00 95.00 C 424.10 80.23 416.31 67.77 399.00 73.00
C 399.00 67.67 399.00 62.33 399.00 57.00
C 402.33 57.00 405.67 57.00 409.00 57.00
C 409.00 60.33 409.00 63.67 409.00 67.00
C 413.33 67.00 417.67 67.00 422.00 67.00 Z'
          />
        </g>
        <g fill='#E76D2B' fillOpacity='0.99' stroke='None'>
          <path
            d='
M 473.00 44.00 
C 465.57 41.74 453.15 43.22 445.23 44.23 C 437.31 45.24 440.35 59.09 440.00 66.00 C 439.65 72.91 440.17 84.67 440.00 93.00 C 439.83 101.33 440.10 110.69 440.01 119.99 C 439.92 129.29 452.13 128.06 459.00 128.00 C 465.87 127.94 478.14 129.42 478.00 120.00 C 477.86 110.58 478.30 101.58 478.00 93.00 C 477.70 84.42 478.66 73.45 478.00 66.00 C 477.34 58.55 481.26 46.51 473.00 44.00
M 465.00 57.00 
C 465.00 76.33 465.00 95.67 465.00 115.00
C 461.00 115.00 457.00 115.00 453.00 115.00
C 453.00 95.67 453.00 76.33 453.00 57.00
C 457.00 57.00 461.00 57.00 465.00 57.00 Z'
          />
        </g>
        <g fill='#E76D2C' fillOpacity='0.97' stroke='None'>
          <path
            d='
M 531.00 44.00 
C 519.33 44.00 507.67 44.00 496.00 44.00
C 496.90 54.31 504.31 62.54 508.00 72.00
C 492.11 67.58 496.14 83.33 496.00 94.00 C 495.86 104.67 496.11 117.17 496.00 128.00
C 500.33 128.00 504.67 128.00 509.00 128.00
C 509.00 114.00 509.00 100.00 509.00 86.00
C 514.67 86.00 520.33 86.00 526.00 86.00
C 526.00 81.67 526.00 77.33 526.00 73.00
C 521.74 71.55 513.27 75.27 509.78 72.22 C 506.29 69.17 510.77 60.83 509.00 57.00
C 516.33 57.00 523.67 57.00 531.00 57.00
C 531.00 52.67 531.00 48.33 531.00 44.00 Z'
          />
        </g>
        <g fill='#E76D2B' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 586.00 44.00 
C 573.33 44.00 560.67 44.00 548.00 44.00
C 548.00 48.33 548.00 52.67 548.00 57.00
C 552.33 57.00 556.67 57.00 561.00 57.00
C 561.00 80.67 561.00 104.33 561.00 128.00
C 565.33 128.00 569.67 128.00 574.00 128.00
C 574.00 104.33 574.00 80.67 574.00 57.00
C 578.00 57.00 582.00 57.00 586.00 57.00
C 586.00 52.67 586.00 48.33 586.00 44.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 303.00 57.00 
C 299.00 57.00 295.00 57.00 291.00 57.00
C 293.14 61.85 291.47 67.72 292.00 73.00
C 295.67 73.00 299.33 73.00 303.00 73.00
C 303.00 67.67 303.00 62.33 303.00 57.00 Z'
          />
        </g>
        <g fill='#000000' fillOpacity='0.00' stroke='None'>
          <path
            d='
M 465.00 57.00 
C 461.00 57.00 457.00 57.00 453.00 57.00
C 453.00 76.33 453.00 95.67 453.00 115.00
C 457.00 115.00 461.00 115.00 465.00 115.00
C 465.00 95.67 465.00 76.33 465.00 57.00 Z'
          />
        </g>
        <g fill='#E77233' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 62.00 74.00 
C 68.43 77.22 53.28 83.78 53.00 84.00
C 49.38 93.70 48.95 105.13 46.00 115.00
C 46.23 115.05 46.72 115.77 47.00 116.00
C 47.81 116.68 48.15 118.16 47.00 119.00
C 48.26 120.00 54.02 107.39 56.75 103.75 C 59.48 100.11 67.58 94.25 68.00 90.00
C 75.16 90.85 76.79 78.91 83.00 84.00
C 83.10 84.20 84.00 84.00 84.00 84.00
C 83.75 83.74 84.00 83.00 84.00 83.00
C 83.21 82.23 82.40 80.33 83.00 79.00
C 76.45 74.00 70.03 71.46 62.00 74.00 Z'
          />
        </g>
        <g fill='#110601' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 53.00 84.00 
C 53.28 83.78 68.43 77.22 62.00 74.00
C 56.23 75.83 54.94 78.81 53.00 84.00 Z'
          />
        </g>
        <g fill='#E66D2A' fillOpacity='0.75' stroke='None'>
          <path
            d='
M 84.00 83.00 
C 83.41 82.02 84.59 79.98 84.00 79.00
C 84.00 79.00 83.32 79.24 83.00 79.00
C 82.40 80.33 83.21 82.23 84.00 83.00 Z'
          />
        </g>
        <g fill='#DF5C14' fillOpacity='0.06' stroke='None'>
          <path
            d='
M 84.00 83.00 
C 84.25 82.75 85.00 83.00 85.00 83.00
C 85.43 82.29 85.00 79.85 85.00 79.00
C 85.00 79.00 84.50 79.00 84.00 79.00
C 84.59 79.98 83.41 82.02 84.00 83.00 Z'
          />
        </g>
        <g fill='#82471C' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 29.00 83.00 
C 29.25 82.91 29.65 82.03 30.00 82.00
C 28.83 81.47 28.66 81.19 27.00 81.00
C 27.08 81.04 28.77 82.78 29.00 83.00 Z'
          />
        </g>
        <g fill='#2A1506' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 29.00 83.00 
C 29.45 83.44 33.48 88.56 34.00 85.00
C 32.65 83.10 31.98 82.90 30.00 82.00
C 29.65 82.03 29.25 82.91 29.00 83.00 Z'
          />
        </g>
        <g fill='#FCF8F6' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 47.00 119.00 
C 46.69 118.76 46.00 119.00 46.00 119.00
C 44.53 121.10 42.57 123.06 41.00 125.00
C 40.26 125.08 44.00 127.00 44.00 127.00
C 55.73 129.47 56.44 112.68 63.75 106.75 C 71.07 100.83 76.78 91.88 86.00 89.00
C 86.45 86.69 83.47 85.93 83.00 84.00
C 76.79 78.91 75.16 90.85 68.00 90.00
C 67.58 94.25 59.48 100.11 56.75 103.75 C 54.02 107.39 48.26 120.00 47.00 119.00 Z'
          />
        </g>
        <g fill='#EB6727' fillOpacity='0.34' stroke='None'>
          <path
            d='
M 84.00 84.00 
C 86.34 86.40 88.07 88.60 85.00 91.00
C 87.39 91.22 90.88 89.99 90.00 87.00
C 87.64 87.12 86.19 84.70 85.00 83.00
C 85.00 83.00 84.25 82.75 84.00 83.00
C 84.00 83.00 83.75 83.74 84.00 84.00 Z'
          />
        </g>
        <g fill='#F0A984' fillOpacity='0.93' stroke='None'>
          <path
            d='
M 44.00 127.00 
C 47.30 128.25 52.07 129.06 54.00 125.00
C 56.58 118.75 59.76 113.37 64.00 108.00
C 67.39 103.71 71.66 99.23 76.00 96.00
C 78.50 94.14 81.74 91.58 84.00 92.00
C 84.28 91.77 84.74 91.21 85.00 91.00
C 88.07 88.60 86.34 86.40 84.00 84.00
C 84.00 84.00 83.10 84.20 83.00 84.00
C 83.47 85.93 86.45 86.69 86.00 89.00
C 76.78 91.88 71.07 100.83 63.75 106.75 C 56.44 112.68 55.73 129.47 44.00 127.00 Z'
          />
        </g>
        <g fill='#EA783F' fillOpacity='0.51' stroke='None'>
          <path
            d='
M 24.00 89.00 
C 28.16 98.53 32.33 104.98 33.00 115.00
C 33.03 114.88 34.00 115.00 34.00 115.00
C 36.15 112.34 34.67 108.74 34.00 106.00
C 32.66 100.52 29.94 94.08 26.00 90.00
C 25.52 89.50 23.40 88.39 24.00 89.00 Z'
          />
        </g>
        <g fill='#FAE7DC' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 26.00 90.00 
C 29.94 94.08 32.66 100.52 34.00 106.00
C 34.32 104.59 37.93 104.04 35.00 99.00
C 32.47 94.64 29.03 91.37 26.00 90.00 Z'
          />
        </g>
        <g fill='#E56424' fillOpacity='0.25' stroke='None'>
          <path
            d='
M 76.00 96.00 
C 72.07 99.43 67.51 104.21 64.00 108.00
C 59.76 113.37 56.58 118.75 54.00 125.00
C 53.82 125.43 54.00 126.00 54.00 126.00
C 59.21 121.44 61.68 112.54 67.08 107.08 C 72.48 101.63 78.03 96.86 84.00 92.00
C 81.74 91.58 78.50 94.14 76.00 96.00 Z'
          />
        </g>
        <g fill='#E76F2F' fillOpacity='0.64' stroke='None'>
          <path
            d='
M 64.00 108.00 
C 67.51 104.21 72.07 99.43 76.00 96.00
C 71.66 99.23 67.39 103.71 64.00 108.00 Z'
          />
        </g>
        <g fill='#E56C2B' fillOpacity='0.47' stroke='None'>
          <path
            d='
M 131.00 98.00 
C 128.14 100.31 134.15 101.64 131.00 98.00 Z'
          />
        </g>
        <g fill='#010000' fillOpacity='0.95' stroke='None'>
          <path
            d='
M 39.00 127.00 
C 39.45 126.90 40.69 125.38 41.00 125.00
C 42.57 123.06 44.53 121.10 46.00 119.00
C 46.42 118.24 47.43 116.97 47.00 116.00
C 46.72 115.77 46.23 115.05 46.00 115.00
C 42.41 114.20 37.70 115.00 34.00 115.00
C 34.00 115.00 33.03 114.88 33.00 115.00
C 32.14 118.45 36.52 125.26 39.00 127.00 Z'
          />
        </g>
        <g fill='#984E25' fillOpacity='1.00' stroke='None'>
          <path
            d='
M 46.00 119.00 
C 46.00 119.00 46.69 118.76 47.00 119.00
C 48.15 118.16 47.81 116.68 47.00 116.00
C 47.43 116.97 46.42 118.24 46.00 119.00 Z'
          />
        </g>
        <g fill='#E67B49' fillOpacity='0.39' stroke='None'>
          <path
            d='
M 39.00 127.00 
C 41.78 128.95 53.36 131.48 54.00 126.00
C 54.00 126.00 53.82 125.43 54.00 125.00
C 52.07 129.06 47.30 128.25 44.00 127.00
C 44.00 127.00 40.26 125.08 41.00 125.00
C 40.69 125.38 39.45 126.90 39.00 127.00 Z'
          />
        </g>
      </svg>
    </div>
  );
};

export default Vulpesoft;
