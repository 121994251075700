const UniversityBristishColumbia = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 192.756 192.756'
      height='70%'
      width='70%'
      className='mx-auto'
    >
      <g fillRule='evenodd' clipRule='evenodd'>
        <path fill='#fff' fillOpacity='0' d='M0 0h192.756v192.756H0V0z' />
        <path
          d='M145.656 26.548l-1.326 4.861h-.441c-1.326-1.326-2.693-2.544-3.977-3.094-1.818-.777-3.094-1.326-4.863-1.326-3.535 0-6.188 1.326-8.838 4.419-2.652 3.094-3.979 6.63-3.979 10.607s1.189 7.176 3.535 10.165c2.518 3.203 5.746 4.861 9.281 4.861 1.77 0 3.045-.548 4.863-1.326 1.283-.55 2.65-1.326 4.418-2.21h.885l-.885 4.42c-1.768.884-3.549 1.106-5.303 1.326-1.809.225-3.535.442-5.746.442-4.861 0-8.852-.868-12.375-3.094-4.871-3.076-7.07-7.955-7.07-14.584 0-5.304 2.1-9.853 6.188-13.259 3.869-3.226 8.838-4.862 14.584-4.862 1.768 0 3.535 0 5.305.442 1.768.444 3.535 1.329 5.744 2.212zM78.921 46.878c0 3.534-1.224 6.739-3.978 9.281-2.993 2.763-6.629 3.978-11.049 3.978-3.978 0-7.514-.884-11.049-2.651-3.536-1.768-5.304-3.978-5.304-7.072V24.338h7.955v23.424c0 2.65.755 4.526 2.21 6.188 1.646 1.881 3.536 2.652 6.188 2.652h.884c2.21 0 4.595-.628 6.629-2.209 1.973-1.535 3.094-3.536 3.094-5.746V24.338h4.42v22.54zM47.542 75.164c7.071 0 7.955-3.535 16.353-3.535s9.723 3.535 16.353 3.535c7.071 0 7.955-3.535 16.353-3.535 8.397 0 9.723 3.535 16.352 3.535 6.631 0 7.955-3.535 16.354-3.535 8.396 0 9.281 3.535 16.352 3.535v-7.955c-7.07 0-7.955-3.535-16.352-3.535-8.398 0-9.723 3.535-16.354 3.535-6.629 0-7.955-3.535-16.352-3.535s-9.281 3.535-16.353 3.535c-6.629 0-7.955-3.535-16.353-3.535s-9.281 3.535-16.353 3.535v7.955z'
          fill='#22276c'
        />
        <path
          d='M96.599 165.766c30.938-9.723 38.45-26.959 48.616-48.615l-22.541 16.354 12.818-28.729-20.773 22.982 9.725-35.357-16.354 29.17 4.42-36.683-12.375 32.705-3.536-41.103-3.978 41.102-12.374-32.705 4.861 36.683L68.313 92.4l10.165 35.357-21.214-22.982 12.817 28.729-22.098-16.354c10.165 21.657 17.237 38.893 48.616 48.616z'
          fill='#d2b22c'
        />
        <path
          d='M47.542 101.24c7.071 0 7.955-3.094 16.353-3.094h3.978l-2.21-7.956h-1.768c-8.397 0-9.281 3.535-16.353 3.094v7.956zm27.843-.885c1.326.441 3.094.885 4.862.885h.442l-1.326-7.956c-3.978 0-6.188-1.326-9.723-2.652l5.745 9.723zm12.817-.882c1.326-.443 2.652-.885 4.42-1.326l.442-7.956c-3.094.884-5.304 1.768-7.513 2.651l2.651 6.631zm12.374-1.327c1.77.441 3.094.883 4.42 1.326l2.652-7.072c-2.652-.442-4.42-1.768-7.955-2.209l.883 7.955zm11.934 3.094h.441c1.768 0 3.537 0 4.861-.443l5.305-9.722c-3.535.884-5.305 2.21-9.723 2.21l-.884 7.955zm12.375-3.094h4.42c8.396 0 9.281 3.094 16.352 3.094v-7.956c-7.07 0-7.955-3.094-16.352-3.094h-1.768l-2.652 7.956zM47.542 87.981c7.071 0 7.955-3.094 16.353-3.094 7.955 0 9.281 2.651 15.027 3.094l-.884-7.955c-4.861-.442-6.629-3.094-14.143-3.094-8.397 0-9.281 3.535-16.353 3.535v7.514zm36.24 0c3.536-.884 5.304-2.652 9.723-3.094l.884-7.955c-6.188.884-7.955 3.094-13.259 3.535l2.652 7.514zm15.47-3.094c4.861.442 6.629 2.21 10.164 3.094l2.652-7.514c-5.305-.441-7.072-3.094-13.701-3.535l.885 7.955zm15.025 3.094c5.746 0 7.072-3.094 15.027-3.094 8.396 0 9.281 3.094 16.352 3.094v-7.955c-7.07 0-7.955-3.094-16.352-3.094-7.514 0-9.281 2.651-14.143 3.094l-.884 7.955z'
          fill='#22276c'
        />
        <path
          d='M96.599 178.582c11.932-3.535 24.307-8.396 34.473-16.793 7.07-5.746 13.258-12.818 17.236-20.773 6.188-11.49 8.396-23.865 8.396-37.125V14.173H36.05v89.717c.442 13.26 2.21 25.635 8.397 37.125 3.978 7.955 10.165 15.027 17.236 20.773 10.166 8.398 22.541 13.259 34.916 16.794zM38.703 16.825v87.065c0 36.242 20.33 61.875 57.896 72.041 37.125-10.166 57.897-35.799 57.897-72.041V16.825H38.703z'
          fill='#22276c'
        />
        <path
          d='M95.715 42.459h-4.42v14.143h3.978c4.031 0 6.629-2.777 6.629-7.071 0-3.476-2.955-7.072-6.187-7.072zm.884 16.794H83.782V24.78h16.794c2.652 0 5.305.884 7.072 2.21 1.768 1.768 3.094 3.536 3.094 5.746 0 1.768-1.326 3.536-3.094 4.861-2.209 1.768-4.42 2.652-7.072 3.094v.442c3.094 0 5.746.442 7.955 2.209 1.77 1.326 3.094 3.094 3.094 5.746s-1.324 5.304-3.535 7.071c-2.209 2.21-5.303 3.094-8.838 3.094h-2.653zm-2.21-31.821h-3.094v12.375h3.978c3.291 0 6.188-2.809 6.188-6.629 0-3.822-3.955-5.746-7.072-5.746z'
          fill='#22276c'
        />
      </g>
    </svg>
  );
};

export default UniversityBristishColumbia;
